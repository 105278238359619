import { ColorPicker, DatePicker, Input, Flex } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import React from 'react';
import {
	AttributeDeclarationDto,
	AttributeDto,
	ClassifierAttributeDeclarationDto,
} from '../api/generatedApi/mdmgApi';
import { DropdownSelect, BooleanInput } from '../ui';
import { CellTypesEnum } from './CellValueParser';
import { ItemValuesType } from './types';

const isAttributeDtoType = (
	attribute: AttributeDeclarationDto | ClassifierAttributeDeclarationDto | AttributeDto,
): attribute is AttributeDto => {
	return (attribute as AttributeDto)?.type !== undefined;
};

export const CellInputParser = (
	value: ItemValuesType,
	attributeDeclaration:
		| AttributeDeclarationDto
		| ClassifierAttributeDeclarationDto
		| AttributeDto,
	editInfo: (declarationId: string, value: ItemValuesType) => void,
	selectOptions?: DefaultOptionType[],
) => {
	const id = attributeDeclaration.id;
	const attributeType = isAttributeDtoType(attributeDeclaration)
		? attributeDeclaration?.type
		: attributeDeclaration?.attribute?.type;
	if (attributeType) {
		if (selectOptions && selectOptions.length > 0) {
			return (
				<DropdownSelect key={id}
								options={selectOptions}
								defaultValue={value}
								onChange={(e) => editInfo(id, e)}
				/>
			);
		}
		switch (attributeType as CellTypesEnum) {
			case CellTypesEnum.COLOUR:
				return Array.isArray(value) ? (
					<Flex vertical gap={4}>
						{value.map((item, idx) => (
							<ColorPicker key={idx}
										 size="small"
										 disabledAlpha
										 defaultValue={item as string}
										 showText
										 style={{ maxWidth: 'fit-content' }}
										 allowClear
										 onChangeComplete={(color) => {
											 return color
												 ? editInfo(id, [
													 ...value,
													 color.toHexString(),
												 ])
												 : editInfo(id, [
													 ...value.filter((v) => v !== item),
												 ]);
										 }}
							/>
						))}
					</Flex>
				) : (
					<ColorPicker key={id}
								 size="small"
								 disabledAlpha
								 defaultValue={value as string}
								 showText
								 allowClear
								 onChangeComplete={(color) =>
									 editInfo(
										 id,
										 color.cleared
											 ? null
											 : color.toHexString(),
									 )
								 }
					/>
				);
			case CellTypesEnum.DATETIME:
				return Array.isArray(value) ? (
					<Flex gap={4} vertical>
						{value.map((v, idx) => (
							<DatePicker key={idx}
										{...(v !== '' ? { value: dayjs(new Date(v as string)) } : {})}
										style={{ maxWidth: 'fit-content' }}
										onChange={(_, dateString) => {
											dateString
												? editInfo(id, [
													...value,
													new Date(`${dateString}`).getTime(),
												])
												: editInfo(id, [
													...value.filter((item) => item !== v),
												]);
										}}
							/>
						))}
					</Flex>
				) : (
					<DatePicker key={id}
								{...(value ? { value: dayjs(new Date(value as string)) } : {})}
								onChange={(_, dateString) =>
									editInfo(id, dateString)
								}
					/>
				);

			case CellTypesEnum.BOOL:
			case CellTypesEnum.BOOLEAN:
				return (<BooleanInput key={id}
									  value={value}
									  onChange={(e) =>
										  editInfo(id, e)
									  }
					/>
				);
			case CellTypesEnum.INT:
			case CellTypesEnum.DOUBLE:
			case CellTypesEnum.FLOAT:
				return Array.isArray(value) ? (
					<Flex gap={4} vertical>
						{value.map((v, idx) => (
							<Input key={idx}
								   defaultValue={v as string | number}
								   onBlur={(e) =>
									   editInfo(id, [
										   ...value,
										   e.target.value,
									   ])
								   }
								   allowClear
								   type="number"
							/>
						))}
					</Flex>
				) : (
					<Input key={id}
						   type="number"
						   defaultValue={value as string | number}
						   onBlur={(e) =>
							   editInfo(id, e.target.value)
						   }
						   allowClear
					/>
				);
			case CellTypesEnum.RELATION:
				return Array.isArray(value) ? (
					<Flex gap={4} vertical>
						{value.map((v, idx) => (
							<DropdownSelect key={idx}
											style={{ maxWidth: 'fit-content' }}
											options={selectOptions}
											defaultValue={v}
											onChange={(e) =>
												editInfo(id, [ ...value, e ])
											}
							/>
						))}
					</Flex>
				) : (
					<DropdownSelect key={id}
									options={selectOptions}
									defaultValue={value}
									onChange={(e) => editInfo(id, e)}
					/>
				);
			default:
				return Array.isArray(value) ? (
					<Flex gap={4} vertical>
						{value.map((v, idx) => (
							<Input.TextArea key={idx}
											autoSize
											defaultValue={v as string | number}
											allowClear
											onBlur={(e) =>
												editInfo(id, [ ...value, e.target.value ])
											}
							/>
						))}
					</Flex>
				) : (
					<Input.TextArea key={id}
									autoSize
									defaultValue={value as string | number}
									allowClear
									onBlur={(e) =>
										editInfo(id, e.target.value)
									}
					/>
				);
		}
	}
};
