import { RiAddCircleLine } from '@remixicon/react';
import { Button, Flex, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { IntegrationsListWidget } from 'widgets/Integrations/IntegrationsList';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { routes } from 'shared/config';
import { useAppDispatch } from 'shared/hooks';

const IntegrationsPageUi: React.FC = () => {
	const navigate = useNavigate();
	const handleNavigate = () =>
		navigate(`/${routes.integrations.main}/${routes.integrations.create}`);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(
			setBreadcrumbs({
				displayName: 'Интеграции',
				url: routes.integrations.main,
				child: {
					displayName: 'Карточка подключения',
					url: `${routes.integrations.main}/${routes.integrations.create}`,
				},
			})
		);
	}, []);

	return (
		<>
			<Flex align="start" gap={24} vertical>
				<Flex vertical>
					<Typography.Title level={1} style={{ marginBottom: 12 }}>
						Интеграции
					</Typography.Title>
					<Typography.Paragraph>
						Выберите интеграцию из перечня для просмотра настроек и информации о
						конфигурациях обмена
					</Typography.Paragraph>
				</Flex>
				<Flex align="start" vertical gap={12}>
					<Button icon={<RiAddCircleLine />} onClick={handleNavigate}>
						Создать новое подключение
					</Button>
				</Flex>
				<IntegrationsListWidget />
			</Flex>
		</>
	);
};

export const IntegrationsPage = React.memo(IntegrationsPageUi);
