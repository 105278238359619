import { App } from 'antd';
import React from 'react';
import { AuthProvider } from './AuthProvider';
import { AppRouter } from './RouterProvider';
import { StoreProvider } from './StoreProvider';
import { ThemeProvider } from './ThemeProvider';

export const Providers = () => (
	<AuthProvider>
		<StoreProvider>
			<ThemeProvider>
				<App>
					<AppRouter />
				</App>
			</ThemeProvider>
		</StoreProvider>
	</AuthProvider>
);
