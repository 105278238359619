import { useRef, useEffect, EffectCallback } from 'react';

/* 
Хук, который работает и применяется как обычный useEffect,
но без срабатывания при первичном рендере страницы.

Пример: нам необходимо отслеживать изменения переменной,
но не надо выполнять коллбэк при первом рендере 
*/
export const useDidUpdateEffect = (fn: EffectCallback, inputs: unknown[]) => {
	const isMountingRef = useRef(false);

	useEffect(() => {
		isMountingRef.current = true;
	}, []);

	useEffect(() => {
		if (!isMountingRef.current) {
			return fn();
		} else {
			isMountingRef.current = false;
		}
	}, inputs);
};
