import { Flex } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './ChartGantt.module.scss';
import { ChartGanttItem } from './ChartGanttItem';
import { ChartGanttViewType, IChartGanttInternalItem, IChartGanttProps } from './models';

const ChartGantt = ({ data, startMoment, endMoment, onClickItem }: IChartGanttProps) => {

	const [ labels, setLabels ] = useState<Array<string>>([]);
	const [ countColumns, setCountColumns ] = useState<number>(0);

	//	автоматически выбираем масштаб
	let viewType: ChartGanttViewType = ChartGanttViewType.Year;
	const diff = endMoment.diff(startMoment, 'hours');
	if (diff > 2160) {
		viewType = ChartGanttViewType.Year;
	} else if (diff > 168) {
		viewType = ChartGanttViewType.Month;
	} else {
		viewType = ChartGanttViewType.Week;
	}

	useEffect(() => {
		if (viewType == ChartGanttViewType.Year) {

			const arr = [];
			for (let moment = startMoment; moment < endMoment; moment = moment.clone().add(1, 'month')) {
				arr.push(moment.format('MMMM'));
			}

			setLabels(arr);
			setCountColumns(endMoment.diff(startMoment, 'days'));
		} else if (viewType == ChartGanttViewType.Month) {
			const arr = [];
			for (let moment = startMoment; moment < endMoment; moment = moment.clone().add(5, 'days')) {
				arr.push(moment.format('DD'));
			}

			setLabels(arr);
			setCountColumns(endMoment.diff(startMoment, 'days'));
		} else if (viewType == ChartGanttViewType.Week) {
			const arr = [];
			for (let moment = startMoment; moment < endMoment; moment = moment.clone().add(1, 'days')) {
				arr.push(moment.format('dddd – D'));
			}

			setLabels(arr);
			setCountColumns(endMoment.diff(startMoment, 'hours'));
		} else if (viewType == ChartGanttViewType.Quarter) {
			const arr = [];
			for (let moment = startMoment; moment < endMoment; moment = moment.clone().add(1, 'month')) {
				arr.push(moment.format('MMMM'));
			}

			setLabels(arr);
			setCountColumns(endMoment.diff(startMoment, 'days'));
		}
	}, [ startMoment, endMoment, viewType ]);

	const internalData = data
		.filter(x => {
			return !(x.dateFrom < startMoment && x.dateTo < startMoment) &&
				!(x.dateFrom > endMoment && x.dateTo > endMoment);
		})
		.sort((a, b) => a.dateFrom > b.dateFrom ? 1 : -1)
		.map<IChartGanttInternalItem>((item, idx) => {

			let startColumn = 0, endColumn = 0;

			if (viewType == ChartGanttViewType.Year) {
				startColumn = Math.max(item.dateFrom.diff(startMoment, 'days'), 1);
				endColumn = Math.min(item.dateTo.diff(startMoment, 'days'), countColumns);
			} else if (viewType == ChartGanttViewType.Month) {
				startColumn = Math.max(item.dateFrom.diff(startMoment, 'days'), 1);
				endColumn = Math.min(item.dateTo.diff(startMoment, 'days'), countColumns);
			} else if (viewType == ChartGanttViewType.Week) {
				startColumn = Math.max(item.dateFrom.diff(startMoment, 'hours'), 1);
				endColumn = Math.min(item.dateTo.diff(startMoment, 'hours'), countColumns);
			} else if (viewType == ChartGanttViewType.Quarter) {
				startColumn = Math.max(item.dateFrom.diff(startMoment, 'days'), 1);
				endColumn = Math.min(item.dateTo.diff(startMoment, 'days'), countColumns);
			}

			return {
				id: item.id,
				title: item.title,
				startRow: idx + 1,
				startColumn: startColumn,
				width: endColumn - startColumn + 1,
			};
		});

	return (<div>

		<Flex>
			{labels.map((item) =>
				<div key={item} className={styles.title}>
					{item}
				</div>,
			)}
		</Flex>

		<Flex style={{ height: '500px', position: 'relative' }}>
			<>
				{labels.map((item) =>
					<div key={item} className={styles.column}></div>,
				)}

				<div style={{
					position: 'absolute',
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
					width: '100%',
					height: '100%',
					display: 'grid',
					gridTemplateColumns: `repeat(${countColumns}, 1fr)`,
					gridTemplateRows: `repeat(${internalData.length}, 40px)`,
				}}>
					{
						internalData.map((item) =>
							<ChartGanttItem {...item}
											key={item.id}
											onClickItem={() => onClickItem(item.id)}
							/>,
						)
					}
				</div>
			</>
		</Flex>
	</div>);
};

export {
	ChartGantt,
};