import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IConstraintTable {
	id?: string;
	displayName?: string;
	itemCount?: number;
	description?: string;
}

interface IDeleteTableAction {
	id: string;
}

interface IRenameTableAction {
	id: string;
	displayName: string;
	description?: string;
}

interface IUpdateRowsCountAction {
	id: string;
	changedRowsCount: number;
}

interface IConstraintTablesStore {
	constraintTables: IConstraintTable[] | null;
}

const initialState: IConstraintTablesStore = {
	constraintTables: null,
};

const constraintTablesSlice = createSlice({
	name: 'constraintTables',
	initialState,
	reducers: {
		setConstraintTables(state, { payload }: PayloadAction<IConstraintTable[]>) {
			state.constraintTables = payload;
		},
		editConstraintTable(state, { payload }: PayloadAction<IConstraintTable>) {
			state.constraintTables = state.constraintTables.map((item) => {
				if (item.id === payload.id) {
					return payload;
				}
				return item;
			});
		},
		decreaseTableRowsCount(state, { payload }: PayloadAction<IUpdateRowsCountAction>) {
			state.constraintTables = state.constraintTables.map((item) => {
				if (item.id === payload.id) {
					return {
						...item,
						rowsCount: item.itemCount - payload.changedRowsCount,
					};
				}
				return item;
			});
		},
		increaseTableRowsCount(state, { payload }: PayloadAction<IUpdateRowsCountAction>) {
			state.constraintTables = state.constraintTables.map((item) => {
				if (item.id === payload.id) {
					return {
						...item,
						rowsCount: item.itemCount + payload.changedRowsCount,
					};
				}
				return item;
			});
		},
		renameConstraintTable(state, { payload }: PayloadAction<IRenameTableAction>) {
			state.constraintTables = state.constraintTables.map((item) => {
				if (item.id === payload.id) {
					return {
						...item,
						...payload,
					};
				}
				return item;
			});
		},
		pushConstraintTable(state, { payload }: PayloadAction<IConstraintTable>) {
			state.constraintTables = [payload, ...state.constraintTables];
		},
		deleteConstraintTable(state, { payload }: PayloadAction<IDeleteTableAction>) {
			state.constraintTables = state.constraintTables.filter(
				(table) => table.id !== payload.id
			);
		},
		removeConstraintTables(state) {
			state.constraintTables = null;
		},
	},
});

export const constraintTablesReducer = constraintTablesSlice.reducer;
export const {
	editConstraintTable,
	removeConstraintTables,
	setConstraintTables,
	pushConstraintTable,
	deleteConstraintTable,
	renameConstraintTable,
	decreaseTableRowsCount,
	increaseTableRowsCount,
} = constraintTablesSlice.actions;
