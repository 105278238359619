import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	DeduplicationAttributeMappingDto,
	DeduplicationOptionDto,
	SliceSuspectedDuplicateDto,
} from 'shared/api/generatedApi/deduplicationApi';
import { isEmpty } from 'shared/helpers';

interface ICatalogDeduplicationState {
	catalogDeduplication: DeduplicationOptionDto[];
	deletingSuspectedDuplicatesIds: string[];
	suspectedDuplicates: SliceSuspectedDuplicateDto;
	potentialOriginalItemId: string;
}

interface IDeleteMapping {
	deduplicationOptionId: string;
	mappingId: string;
}

const initialState: ICatalogDeduplicationState = {
	catalogDeduplication: [],
	deletingSuspectedDuplicatesIds: [],
	suspectedDuplicates: null,
	potentialOriginalItemId: '',
};

const catalogDeduplicationSlice = createSlice({
	initialState,
	name: 'catalogDeduplication',
	reducers: {
		setCatalogDeduplicationOption(state, action: PayloadAction<DeduplicationOptionDto[]>) {
			state.catalogDeduplication = [...action.payload];
		},
		addCatalogDeduplicationOption(state, action: PayloadAction<DeduplicationOptionDto>) {
			state.catalogDeduplication = [...state.catalogDeduplication, action.payload];
		},
		updateCatalogDeduplicationOption(state, action: PayloadAction<DeduplicationOptionDto>) {
			const updateDeduplication = (
				currentState: DeduplicationOptionDto[],
				option: DeduplicationOptionDto
			) => {
				if (!currentState.length) return [option];
				return currentState.map((item) => {
					if (item.id === option.id) return option;
					return item;
				});
			};
			state.catalogDeduplication = updateDeduplication(
				state.catalogDeduplication,
				action.payload
			);
		},
		deleteCatalogDeduplicationOption(state, action: PayloadAction<string>) {
			state.catalogDeduplication = state.catalogDeduplication.filter(
				(item) => item.id !== action.payload
			);
		},
		createAttributeMapping(state, action: PayloadAction<DeduplicationAttributeMappingDto>) {
			state.catalogDeduplication = state.catalogDeduplication.map((item) =>
				item.id === action.payload.deduplicationOptionId
					? {
							...item,
							deduplicationAttributeMappings: [
								...item.deduplicationAttributeMappings,
								action.payload,
							],
						}
					: item
			);
		},
		deleteAttributeMapping(state, action: PayloadAction<IDeleteMapping>) {
			state.catalogDeduplication = state.catalogDeduplication.map((item) => {
				if (item.id === action.payload.deduplicationOptionId) {
					const updatedMappings = item.deduplicationAttributeMappings.filter(
						(mapping) => mapping.id !== action.payload.mappingId
					);

					return {
						...item,
						deduplicationAttributeMappings: isEmpty(updatedMappings)
							? []
							: updatedMappings,
					};
				}
				return item;
			});
		},

		updateAttributeMapping(state, action: PayloadAction<DeduplicationAttributeMappingDto>) {
			state.catalogDeduplication = state.catalogDeduplication.map((item) =>
				item.id === action.payload.deduplicationOptionId
					? {
							...item,
							deduplicationAttributeMappings: item.deduplicationAttributeMappings.map(
								(mapping) =>
									mapping.id === action.payload.id ? action.payload : mapping
							),
						}
					: item
			);
		},

		setDeletingSuspectedDuplicatesIds(state, action: PayloadAction<Array<string>>) {
			state.deletingSuspectedDuplicatesIds = [...action.payload];
		},

		setSuspectedDuplicates(state, action: PayloadAction<SliceSuspectedDuplicateDto>) {
			state.suspectedDuplicates = action.payload;
		},

		setPotentialOriginalItemId(state, action: PayloadAction<string>) {
			state.potentialOriginalItemId = action.payload;
		},
	},
});

export const {
	setCatalogDeduplicationOption,
	addCatalogDeduplicationOption,
	updateCatalogDeduplicationOption,
	deleteCatalogDeduplicationOption,
	createAttributeMapping,
	deleteAttributeMapping,
	updateAttributeMapping,
	setDeletingSuspectedDuplicatesIds,
	setSuspectedDuplicates,
	setPotentialOriginalItemId,
} = catalogDeduplicationSlice.actions;

export const catalogDeduplicationReducer = catalogDeduplicationSlice.reducer;
