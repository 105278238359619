import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IBreadcrumb {
	displayName: string;
	url: string;
	child?: IBreadcrumb;
}

interface IBreadcrumbsStore {
	root: IBreadcrumb | null;
}

const initialState: IBreadcrumbsStore = {
	root: null,
};

const breadcrumbsSlice = createSlice({
	name: 'breadcrumbs',
	initialState,
	reducers: {
		setBreadcrumbs(state, { payload }: PayloadAction<IBreadcrumb>) {
			state.root = payload;
		},
		clearBreadcrumbs(state) {
			state.root = null;
		},
	},
});

export const breadcrumbsReducer = breadcrumbsSlice.reducer;
export const { setBreadcrumbs, clearBreadcrumbs } = breadcrumbsSlice.actions;
