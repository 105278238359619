import { App } from 'antd';
import { useDeleteTransactionMutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';

export const useDeleteTransaction = () => {
	const [fetchDeleteTransaction] = useDeleteTransactionMutation();
	const { notification } = App.useApp();

	const deleteTransaction = async (id: string) => {
		try {
			const result = await fetchDeleteTransaction({ id });

			if ('error' in result) {
				errorHelper('Ошибка при удалении транзакции!', result.error, notification);
			}

			if ('data' in result) {
				notification.success({ message: 'Транзакция удалена' });
			}
		} catch (err) {
			errorHelper('Ошибка при удалении транзакции!', err, notification);
		}
	};

	return { deleteTransaction };
};
