import { RiDeleteBinLine } from '@remixicon/react';
import React from 'react';
import { useTypedTranslation } from 'shared/hooks';
import { DropdownLink } from 'shared/ui';
import { useDeleteAttributeDeclaration } from './deleteAttributeDeclaration.model';

interface IDeleteAttributeDeclarationProps {
	id: string;
}

const DeleteAttributeDeclarationUi: React.FC<IDeleteAttributeDeclarationProps> = ({ id }) => {
	const { t } = useTypedTranslation();

	const { handleDelete } = useDeleteAttributeDeclaration();

	return (
		<DropdownLink
			title={t(l => l.common.buttons.delete)}
			icon={<RiDeleteBinLine size={16} />}
			callback={() => handleDelete(id)}
		/>
	);
};

export const DeleteAttributeDeclaration = React.memo(DeleteAttributeDeclarationUi);
