import { RiArrowRightCircleLine, RiListSettingsLine } from '@remixicon/react';
import { Table, TableProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { chipTitle } from 'entities/catalogs/catalogRecords/catalogRecords.model';
import { routes } from 'shared/config';
import { randomString } from 'shared/helpers';
import { Chip, DropdownLink, ChipStatus } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';
import { useClassifierLinkAttributesFilter } from './ClassifierItemCatalogItemsListWidget.model';

const defaultCols: TableProps['columns'] = [
	{
		dataIndex: 'displayName',
		key: 'displayName',
		title: 'Наименование позиции справочника',
	},
	//TODO добавить колонку с именем атрибута и dataindex его айди, потом заполнять ее из values которые приходят в ответе
	{
		dataIndex: 'status',
		key: 'status',
		title: 'Статус',
	},
	{
		title: <RiListSettingsLine size={20}/>,
		dataIndex: 'menu',
		key: 'menu',
		width: '50px',
	},
];

const ClassifierItemCatalogItemsListWidget = React.memo(({ classifier, attributeId }: any) => {
	const navigate = useNavigate();

	const [ data, setData ] = useState([]);
	const [ cols, setCols ] = useState<TableProps<any>['columns']>([]);

	const { getAttributesByIds } = useClassifierLinkAttributesFilter();

	useEffect(() => {
		setCols([
			{
				dataIndex: attributeId,
				title: classifier
					.linkClassifierAttributes
					.find((item) => item.id === attributeId)
					?.displayName,
			},
			...defaultCols,
		]);
	}, []);

	useEffect(() => {
		getAttributesByIds(attributeId)
			.then((res) => {
				if (res) {
					setData(
						res.map((item) => ({
							...item,
							...item.values,
							key: item.id,
							status: (
								<Chip status={item.status.toLowerCase() as ChipStatus}>
									{chipTitle(item.status)}
								</Chip>
							),
							menu: (
								<ItemActions
									items={[
										{
											key: `${randomString(5)}`,
											label: (
												<DropdownLink
													icon={<RiArrowRightCircleLine size={16}/>}
													title="Переход в карточку записи"
													callback={() =>
														navigate(
															`/${routes.catalogs.main}/${item.catalogId}/${routes.catalogs.record}/${item.id}`,
														)
													}
												/>
											),
										},
									]}
								/>
							),
						})),
					);
				} else {
					setData([]);
				}
			});

	}, [ attributeId ]);

	return (
		<Table<any>
			columns={cols}
			dataSource={data}
			onChange={undefined}
			pagination={{ defaultCurrent: 1, pageSize: 10 }}
			scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}
		/>
	);
});

export {
	ClassifierItemCatalogItemsListWidget,
};