import { RiEditLine } from '@remixicon/react';
import { Table, Flex, Result } from 'antd';
import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CreateExchangeConfig } from 'features/integrations/ExchangeConfiguration/CreateExchangeConfig';
import { DeleteExchangeConfig } from 'features/integrations/ExchangeConfiguration/deleteExchangeConfig';
import { useListConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGetQuery } from 'shared/api/generatedApi/integrationsApi';
import { routes } from 'shared/config';
import { Chip, DetailFooter } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';

const ExchangeConfigWidgetUi: React.FC = () => {
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [pageSize, setPageSize] = useState(20);

	const onSelectChange = (selectedKeys) => {
		setSelectedRowKeys(selectedKeys);
	};

	const navigate = useNavigate();
	const { integrationId } = useParams();

	const { data, isLoading, error } =
		useListConfigurationsMdmIntegrationsV1ConnectionsConnectionIdConfigurationsGetQuery({
			connectionId: integrationId,
			pageSize,
		});

	const handleNavigate = useCallback((id: string) => navigate(id), []);
	const customHandleBack = useCallback(() => navigate(`/${routes.integrations.main}`), []);

	const columns = [
		{
			title: 'Наименование конфигурации',
			dataIndex: 'name',
			key: 'name',
			sorter: (a, b) => a.name.localeCompare(b.name),
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Связанные справочники',
			dataIndex: 'linkedReference',
			key: 'linkedReference',
			filters: [
				{ text: 'Справочник 1', value: 'Справочник 1' },
				{ text: 'Справочник 2', value: 'Справочник 2' },
				{ text: 'Справочник 3', value: 'Справочник 3' },
			],
			onFilter: (value, record) => record.linkedReference.includes(value),
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Направление репликации',
			dataIndex: 'replicationDirection',
			key: 'replicationDirection',
			sorter: (a, b) => a.replicationDirection.localeCompare(b.replicationDirection),
			render: (text) => <span>{text}</span>,
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			filters: [
				{ text: 'Выполняется', value: 'Выполняется' },
				{ text: 'Остановлена', value: 'Остановлена' },
			],
			onFilter: (value, record) => record.status.includes(value),
			render: (status) => (
				<Chip status={status}>{status === 'error' ? 'Остановлена' : 'Выполняется'}</Chip>
			),
		},
		{
			title: '',
			key: 'action',
			render: (_, record) => (
				<ItemActions
					items={[
						{
							label: (
								<Flex gap={4}>
									<RiEditLine size={16} onClick={() => navigate(record.id)} />
									Редактировать
								</Flex>
							),
							key: '1',
						},
						{
							label: <DeleteExchangeConfig id={[record.id]} />,
							key: '2',
						},
					]}
				/>
			),
		},
	];

	if (error)
		return (
			<Flex justify="center">
				<Result
					status="error"
					title="Ошибка пр получении списка конфигураций обмена!"
					subTitle={JSON.stringify(error)}
				/>
			</Flex>
		);

	return (
		<Flex vertical gap={24}>
			<CreateExchangeConfig />
			<Table
				rowSelection={{
					selectedRowKeys,
					onChange: onSelectChange,
				}}
				onRow={(row) => ({
					onClick: () => handleNavigate(row.configuration_id),
				})}
				columns={columns}
				dataSource={data.data}
				loading={isLoading}
				pagination={{
					pageSize,
					total: data.data.length,
					showTotal: (total) => `Всего ${total}`,
					showSizeChanger: true,
					onShowSizeChange: (current, size) => setPageSize(size),
				}}
			/>
			<DetailFooter customHandleBack={customHandleBack}>
				<DeleteExchangeConfig id={selectedRowKeys} disabled={!selectedRowKeys.length} />
			</DetailFooter>
		</Flex>
	);
};

export const ExchangeConfigWidget = React.memo(ExchangeConfigWidgetUi);
