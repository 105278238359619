import { Flex, Result, Spin, Table, TableProps, Tree } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import { useAddAttributeTree } from 'features/catalogs/AttrbuteDeclarations/AddAttributeDeclaration/AddAttributeDeclaration.model';
import { DisplayTreeNode } from 'entities/catalogs/catalogGroups/catalog.model';
import { useMetaAttibutes } from 'entities/metadata/metaAttributes/metaAttributes.model';
import { SelectModal } from 'shared/ui';
import s from './AddAttributeDeclaration.module.scss';
import { useSelectAttribute } from './selectAttribute.model';

interface ISelectAttribute {
	selectedAttributes?: Array<{ id: string; displayName: string }>;
}

const columns: TableProps['columns'] = [
	{
		title: 'Наименование',
		dataIndex: 'displayName',
		key: 'displayName',
	},
	{
		title: 'Тип',
		dataIndex: 'type',
		key: 'type',
	},
	{
		title: 'Множественный',
		dataIndex: 'isMulti',
		key: 'isMulti',
	},
];

const SelectAttributeUi: React.FC<ISelectAttribute> = ({ selectedAttributes }) => {
	const [defaultSelectedChips, setDefaultSelectedChips] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
	const [selectedRow, setSelectedRow] = useState<boolean>(false);
	const [selectedRows, setSelectedRows] = useState([]);

	const { tree, selectedTreeItem, onExpandTree, selectCurrentAttributeGroup, loading, error } =
		useAddAttributeTree();

	const {
		dataSource,
		handleTableChange,
		fetchAttributes,
		loading: metaAttributesLoading,
		error: metaAttributesError,
		pagination,
		fetchAttributesByIds,
	} = useMetaAttibutes();

	const { handleUpdate } = useSelectAttribute();

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRows(selectedRows);
			setSelectedKeys(selectedRowKeys);
			setSelectedRow(!selectedRow);
		},
	};

	const onSelect = (
		key,
		info: { node: DisplayTreeNode; selectedNodes: Array<DisplayTreeNode> }
	) => {
		setSelectedKeys(key);
		selectCurrentAttributeGroup(info.selectedNodes[0]);
	};

	const onOkCallback = useCallback(async () => {
		await handleUpdate([
			...(selectedRows?.map((item) => item.id) ?? []),
			...(selectedAttributes?.map((item) => item.id) ?? []),
		]);
	}, [selectedRows, selectedAttributes]);

	const handleDeleteAttribute = async (id: string) => {
		await handleUpdate(
			[
				...selectedAttributes.map((item) => item.id),
				...selectedRows.map((item) => item.id),
			].filter((item) => item !== id)
		);
	};

	useEffect(() => {
		if (selectedTreeItem) fetchAttributes(1, 10, selectedTreeItem?.id, 'RELATION');
	}, [selectedTreeItem]);

	useEffect(() => {
		const items = selectedAttributes?.filter((item) => !!item);
		if (items?.length && items[0] !== undefined) {
			const getData = async () => {
				const data = await fetchAttributesByIds(selectedAttributes.map((item) => item.id));
				if (data) {
					setDefaultSelectedChips(
						data?.map((item) => ({
							label: item.displayName,
							callback: () => handleDeleteAttribute(item.id),
						}))
					);
					setSelectedKeys(data.map((item) => item.id));
				}
			};
			getData();
		} else {
			setDefaultSelectedChips([]);
			setSelectedKeys([]);
		}
	}, [selectedAttributes]);

	return (
		<SelectModal
			modalTitle="Настойка атрибутов для классификации"
			selectValues={defaultSelectedChips}
			onOkCallback={onOkCallback}
		>
			<Flex vertical gap={24}>
				<p>Для добавления атрибута необходимо выбрать атрибут из списка</p>
				<Flex gap={24} justify="space-between">
					<div className={s.columnTree}>
						{loading && <Spin />}
						<Tree
							treeData={tree}
							onSelect={onSelect}
							selectedKeys={selectedKeys}
							loadData={(treeNode) => onExpandTree(treeNode.id)}
							checkStrictly
						/>
						{error && (
							<Result
								status="error"
								title="Не удалось загрузить дерево атрибутов!"
								subTitle={JSON.stringify(error)}
							/>
						)}
					</div>

					<div className={s.columnAttributes}>
						{dataSource && (
							<Table
								columns={columns}
								dataSource={dataSource}
								loading={metaAttributesLoading}
								pagination={pagination}
								rowKey="id"
								onChange={(pagination) =>
									handleTableChange(pagination, selectedTreeItem?.id)
								}
								rowSelection={rowSelection}
							/>
						)}
						{error && (
							<Result
								status="error"
								title="Не удалось загрузить список атрибутов!"
								subTitle={JSON.stringify(metaAttributesError)}
							/>
						)}
					</div>
				</Flex>
			</Flex>
		</SelectModal>
	);
};

export const SelectAttribute = SelectAttributeUi;
