import { RiDeleteBin2Line } from '@remixicon/react';
import { Button, Checkbox, Flex, Form, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { DropdownLink } from 'shared/ui';
import { useDeleteAttributeGroup } from './deleteAttributeGroup.model';

interface IDeleteAttributeGroupProps {
	groupId: string;
}

const DeleteAttributeGroupUi: React.FC<IDeleteAttributeGroupProps> = ({ groupId }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isChecked, setIsChecked] = useState<boolean>(false);

	const { loading, error, deleteGroup } = useDeleteAttributeGroup();

	const [form] = Form.useForm();

	const onFinish = async () => {
		await deleteGroup(groupId);
		if (!error) {
			setIsChecked(false);
			setIsModalOpen(false);
		}
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const toggleCheck = () => setIsChecked(!isChecked);

	return (
		<>
			<DropdownLink
				icon={<RiDeleteBin2Line size={16} />}
				title="Удалить"
				callback={showModal}
			/>

			<Modal
				title="Удаление группы атрибутов"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={onFinish}>
					<Form.Item>
						<Typography.Text>
							Вы действительно хотите удалить данную группу атрибутов? Группа
							атрибутов и входящие в нее атрибуты будут удалены без возможности
							восстановления
						</Typography.Text>
					</Form.Item>
					<Form.Item>
						<Checkbox checked={isChecked} onChange={toggleCheck}>
							Я согласен удалить группу атрибутов и входящие в нее атрибуты
						</Checkbox>
					</Form.Item>
					<Flex gap={4} justify="flex-end">
						<Button type="text" onClick={handleCancel}>
							Отменить
						</Button>
						<Button
							htmlType="submit"
							type="primary"
							loading={loading}
							disabled={!isChecked}
						>
							Удалить
						</Button>
					</Flex>
				</Form>
			</Modal>
		</>
	);
};

export const DeleteAttributeGroup = React.memo(DeleteAttributeGroupUi);
