import { RiAddCircleLine, RiEditLine } from '@remixicon/react';
import { Button, Flex, Form, Input, Modal } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Measure } from 'entities/metadata/measures';
import { randomString } from 'shared/helpers';
import { DropdownLink } from 'shared/ui';
import { useEditMeasuresGroup } from './measuresGroupEditor.model';

interface IMeasuresGroupEditorProps {
	measure?: Measure;
}

const MeasuresGroupEditorUi: React.FC<IMeasuresGroupEditorProps> = ({ measure }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [name, setName] = useState<string>('');
	const [desc, setDesc] = useState<string>('');

	const { editMeasureGroup, contextHolder, loading, error } = useEditMeasuresGroup();

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = async () => {
		await editMeasureGroup(name, desc, measure);
		setIsModalOpen(false);
		if (!measure) {
			setName('');
			setDesc('');
		} else {
			setName(measure.displayName);
			setDesc(measure.description);
		}
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		if (!measure) {
			setName('');
			setDesc('');
		} else {
			setName(measure.displayName);
			setDesc(measure.description);
		}
	};

	const handleSetName = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
	const handleSetDesc = (e: React.ChangeEvent<HTMLInputElement>) => setDesc(e.target.value);

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					Отмена
				</Button>

				<Button
					key="submit"
					type="primary"
					onClick={handleOk}
					disabled={!name}
					loading={loading}
				>
					{measure ? 'Сохранить' : 'Создать'}
				</Button>
			</Flex>
		),
		[name, desc, loading, measure]
	);

	useEffect(() => {
		if (error) {
			setIsModalOpen(false);
			setName('');
			setDesc('');
		}
	}, [error]);

	useEffect(() => {
		if (measure) {
			setName(measure.displayName);
			setDesc(measure.description);
		}
	}, [measure]);

	return (
		<>
			{contextHolder}
			{measure ? (
				<DropdownLink
					title="Редактировать"
					icon={<RiEditLine size={16} />}
					callback={showModal}
				/>
			) : (
				<Button icon={<RiAddCircleLine />} onClick={showModal}>
					Добавить группу единиц измерения
				</Button>
			)}

			<Modal
				title={
					measure
						? 'Редактирование группы единицы измерения'
						: 'Создание группы единицы измерения'
				}
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[footerButtons]}
				closeIcon={null}
			>
				<Flex vertical gap={12}>
					<Form layout="vertical">
						<Form.Item label="Наименование группы единицы измерения" required>
							<Input allowClear value={name} onChange={handleSetName} />
						</Form.Item>
						<Form.Item label="Описание">
							<Input allowClear value={desc} onChange={handleSetDesc} />
						</Form.Item>
					</Form>
				</Flex>
			</Modal>
		</>
	);
};

export const MeasuresGroupEditor = React.memo(MeasuresGroupEditorUi);
