import { RemixiconComponentType, RiCalendarLine, RiFileList2Line, RiTable3 } from '@remixicon/react';
import { useEnumStateViaSearchParam } from 'shared/hooks/useEnumStateViaSearchParam';

const viewTypeSearchParamKey = 'view';

enum CatalogItemsViewType {
	Table = 'Table',
	Gantt = 'Gantt',
	Calendar = 'Calendar',
}

const viewTypesIcons: Record<CatalogItemsViewType, RemixiconComponentType> = {
	[CatalogItemsViewType.Table]: RiTable3,
	[CatalogItemsViewType.Gantt]: RiFileList2Line,
	[CatalogItemsViewType.Calendar]: RiCalendarLine,
};

/*
* Хук для подключения логики смены видов отображения каталогов: Табличное, Диаграмма Гантта, Календарь
* */
const useCatalogViewType = () => {

	const [ catalogRecordsViewType, setCatalogRecordsViewType ] = useEnumStateViaSearchParam(
		CatalogItemsViewType,
		viewTypeSearchParamKey,
		CatalogItemsViewType.Table,
	);

	const viewTypesButtonFactory = (viewType: CatalogItemsViewType) => ({
		type: viewType,
		icon: viewTypesIcons[viewType],
		onClick: () => {
			setCatalogRecordsViewType(viewType);
		},
	});

	return {
		catalogRecordsViewType,
		viewTypesButtonFactory,
	};
};

export {
	useCatalogViewType,
	CatalogItemsViewType,
};