import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'shared/api';

export interface IGetToken {
	token: string;
}

export interface IGetTokenProps {
	username: string;
	password: string;
}

export const sessionApi = createApi({
	reducerPath: 'sessionApi',
	baseQuery,
	endpoints: (build) => ({
		getToken: build.query<IGetToken, IGetTokenProps>({
			query({ username, password }) {
				return {
					url: 'ws/token',
					params: {
						username,
						password,
					},
				};
			},
		}),
	}),
});

export const { useLazyGetTokenQuery } = sessionApi;
