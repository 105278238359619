import { ColorPicker } from 'antd';
import React from 'react';

interface ColorInputProps {
	value: string | null;
	onChange: (value: string | null) => void;
}

const ColorInput = React.memo(({
								   value,
								   onChange,
							   }: ColorInputProps) => {
	return <ColorPicker size="small"
						disabledAlpha
						value={value}
						showText
						allowClear
						onChangeComplete={(color) => onChange(color.cleared
							? null
							: color.toHexString())
						}
	/>;
});

export {
	ColorInput,
};