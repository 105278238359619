import { RiAddCircleLine } from '@remixicon/react';
import { Button, Flex, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAddConstraintTable } from './addConstraintTable.model';

export const AddConstraintTable: React.FC = () => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isValid, setIsValid] = useState<boolean>(false);
	const [tableName, setTableName] = useState<string>('');
	const [tableDescription, setTableDescription] = useState<string>('');

	const handleModalClose = () => {
		setIsModalOpen(false);
		setTableName('');
	};
	
	const handleModalOpen = () => setIsModalOpen(true);

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setTableName(e.target.value);

	const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setTableDescription(e.target.value);

	const { isLoading, onAddTable } = useAddConstraintTable();

	useEffect(() => {
		setIsValid(tableName.length > 0 ? true : false);
	}, [tableName]);

	const handleAddTable = async () => {
		await onAddTable(tableName, tableDescription);
		setIsModalOpen(false);
		setTableName('');
	};

	return (
		<>
			<Button icon={<RiAddCircleLine size={16} />} onClick={handleModalOpen}>
				Добавить новую ограничительную таблицу
			</Button>

			<Modal
				open={isModalOpen}
				title="Создание ограничительной таблицы"
				closeIcon={null}
				onCancel={handleModalClose}
				onOk={handleAddTable}
				footer={
					<Flex gap={12} justify="flex-end">
						<Button onClick={handleModalClose} key="back" type="text">
							Отменить
						</Button>

						<Button
							disabled={!isValid}
							type="primary"
							key="submit"
							loading={isLoading}
							onClick={handleAddTable}
						>
							Создать
						</Button>
					</Flex>
				}
			>
				<Form layout="vertical">
					<Form.Item label="Наименование ограничительной таблицы" required>
						<Input value={tableName} onChange={handleNameChange} allowClear />
					</Form.Item>
					<Form.Item label="Описание ограничительной таблицы">
						<Input
							value={tableDescription}
							onChange={handleDescriptionChange}
							allowClear
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};
