import { colors } from '../../cssConstants';

export const tableStyles = {
	borderColor: colors.grayTableBorder,
	cellFontSize: 12,
	cellPaddingBlock: 14,
	cellPaddingInline: 12,
	headerColor: colors.textFaded,
	headerBg: 'transparent',
	headerSplitColor: 'transparent',
	rowHoverBg: 'transparent',
	footerBg: 'transparent',
	footerColor: colors.primary,
	rowSelectedBg: colors.grayBgTable,
	rowSelectedHoverBg: colors.grayBgTable,
	selectionColumnWidth: 11,
};
