import { RiMenuLine } from '@remixicon/react';
import { Button } from 'antd';
import React from 'react';

interface IMenuProps {
	onClick(): void;
}

export const Menu = ({ onClick }: IMenuProps) => {
	return <Button type="text" icon={<RiMenuLine />} onClick={onClick} />;
};
