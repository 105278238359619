import { RiCheckboxCircleLine, RiCloseLine, RiFolderAddLine } from '@remixicon/react';
import { App, Flex, Typography, Upload, UploadProps, Spin } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload';
import React, { useState } from 'react';
import { errorHelper } from '../../../helpers';
import { colors } from '../../../styles/cssConstants';

interface IUploadFileProps {
	multiple?: boolean;
	setString: React.Dispatch<React.SetStateAction<Array<string>>>;
}

const UploadBase64FileUi: React.FC<IUploadFileProps> = ({ multiple = false, setString }) => {
	const [fileList, setFileList] = useState<Array<UploadFile>>([]);
	const [uploading, setUploading] = useState<boolean>(false);
	const reader = new FileReader();

	const { notification } = App.useApp();

	const props: UploadProps = {
		multiple,
		beforeUpload: (file: RcFile) => {
			reader.readAsDataURL(file);

			reader.onload = async () => {
				setUploading(true);

				const base64Data = reader.result as string;

				setFileList((prev) => {
					if (multiple === false) return [file];
					return prev ? [...prev, file] : [file];
				});
				setString((prev) => {
					if (multiple === false) return [base64Data.split(',')[1]];
					return prev ? [...prev, base64Data.split(',')[1]] : [base64Data.split(',')[1]];
				});
				setUploading(false);
			};

			reader.onerror = (error) => {
				errorHelper(
					'Ошибка при конвертации файла в base64!',
					new Error(JSON.stringify(error)),
					notification
				);
				setUploading(false);
				setFileList([]);
			};

			return false;
		},
		showUploadList: false,
		maxCount: multiple ? 100000 : 1,
	};

	const handleCancel = async (e, item: UploadFile) => {
		e.stopPropagation();

		const index = fileList.findIndex((file) => file.uid === item.uid);

		if (index !== -1) {
			setFileList((prev) => prev.filter((_, i) => i !== index));
			setString((prev) => prev.filter((_, i) => i !== index));
		}
	};

	return (
		<Upload.Dragger {...props}>
			<Spin spinning={uploading}>
				<Flex align="center" justify="center" gap={4}>
					{fileList.length > 0 ? (
						<Flex vertical gap={4}>
							{fileList.map((item, i) => (
								<Flex gap={4} justify="space-between" align="center" key={i}>
									<RiCheckboxCircleLine color={colors.success} />
									{item.name}
									<RiCloseLine
										size={16}
										color={colors.grayIcon}
										onClick={(e) => handleCancel(e, item)}
									/>
								</Flex>
							))}
						</Flex>
					) : (
						<>
							<RiFolderAddLine />
							<Typography.Title level={3} style={{ color: colors.primary }}>
								Добавить файл для импорта
							</Typography.Title>
						</>
					)}
				</Flex>
			</Spin>
		</Upload.Dragger>
	);
};

export const UploadBase64File = React.memo(UploadBase64FileUi);
