import { App } from 'antd';
import { useNavigate, useParams } from 'react-router';
import {
	addNewClassifierItem,
	updateClassifierChildTreeItem,
} from 'entities/classifiers/classifiersItems/classifierItems.store';
import { addNewClassifierTreeItem } from 'entities/classifiers/classifiersItemsTree/classifiersItemsTree.store';
import {
	ClassifierItemDto,
	useCreateClassifierItemMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useAddClassifierRecord = () => {
	const [fetchCreateClassifierRecord] = useCreateClassifierItemMutation();

	const { notification } = App.useApp();

	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const { classifierGroupId } = useParams();

	const createClassifierRecord = (parentItemId?: string) => {
		fetchCreateClassifierRecord({
			classifierId: classifierGroupId,
			createClassifierItemRequest: {
				values: {},
				status: 'NON_NORMALIZED',
				...(parentItemId && { parentItemId }),
			},
		})
			.unwrap()
			.then((res) => {
				if (parentItemId) {
					dispatch(
						updateClassifierChildTreeItem({
							item: res as unknown as ClassifierItemDto,
							parentItemId,
						})
					);
				} else {
					dispatch(addNewClassifierItem(res as unknown as ClassifierItemDto));
					dispatch(addNewClassifierTreeItem(res as unknown as ClassifierItemDto));
				}

				navigate(
					`/${routes.classifiers.main}/${classifierGroupId}/${routes.classifiers.records}/${res.id}/${routes.classifiers.edit}`
				);
			})
			.catch((err) => {
				errorHelper(
					'Ошибка при добавлении новой записи в классификаторе!',
					err,
					notification
				);
			});
	};

	return {
		createClassifierRecord,
	};
};
