import { Flex, Typography } from 'antd';
import React, { useState } from 'react';
import {
	DeleteConstraintTableModal,
	useDeleteConstraintTable,
} from 'features/metadata/ConstraintTables';
import {
	EditConstraintTableModal,
	useEditConstraintTable,
} from 'features/metadata/ConstraintTables';
import { DetailCard, DropdownButtons } from 'shared/ui';

interface IConstraintTableCardProps {
	displayName: string;
	rowsCount: number;
	tableId: string;
	tooltip?: string;
}

export const ConstraintTableCard: React.FC<IConstraintTableCardProps> = (props) => {
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

	const handleSetFavourite = () => {};

	const { handleDeleteConstraintTable, isLoading: isDeleteLoading } = useDeleteConstraintTable();

	const { handleEditConstraintTable, isLoading: isEditLoading } = useEditConstraintTable();

	const onDeleteClick = () => setIsDeleteModalOpen(true);

	const handleDelete = () =>
		handleDeleteConstraintTable(props.tableId).finally(() => setIsDeleteModalOpen(false));

	const handleCancelDelete = () => setIsDeleteModalOpen(false);

	const onEditClick = () => setIsEditModalOpen(true);

	const handleEdit = (tableName: string, tableDescr?: string) =>
		handleEditConstraintTable(props.tableId, tableName, tableDescr).finally(() =>
			setIsEditModalOpen(false)
		);

	const handleCancelEdit = () => setIsEditModalOpen(false);

	const MockIsFavourite: boolean = false;

	return (
		<>
			<DeleteConstraintTableModal
				isDeleteLoading={isDeleteLoading}
				isModalOpen={isDeleteModalOpen}
				handleDeleteTable={handleDelete}
				handleCancel={handleCancelDelete}
			/>

			<EditConstraintTableModal
				isEditLoading={isEditLoading}
				isModalOpen={isEditModalOpen}
				handleEditTable={handleEdit}
				handleCancel={handleCancelEdit}
				description={props.tooltip}
				title={props.displayName}
			/>

			<DetailCard
				dropdownButtons={DropdownButtons({
					handleDelete: onDeleteClick,
					handleEdit: onEditClick,
					handleSetFavourite,
					isFavorite: MockIsFavourite,
				})}
				link={`${window.location.pathname}/${props.tableId}`}
				tooltip={props.tooltip}
				secondSection={
					<Flex vertical justify="start" gap={4}>
						<Typography.Title level={3}>{props.displayName}</Typography.Title>
						<Typography.Paragraph>
							Количество позиций: {props.rowsCount}
						</Typography.Paragraph>
					</Flex>
				}
			/>
		</>
	);
};
