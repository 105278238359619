import { colors } from 'shared/styles';

const appStyles = {
	fontFamily: 'Manrope',
	fontSize: 12,
	lineHeight: 1.3,
	colorPrimary: colors.primary,
	colorPrimaryBg: colors.primaryBg,
	colorSuccess: colors.success,
	colorSuccessBg: colors.successBg,
	colorError: colors.errorBg,
	colorTextBase: colors.text,
};

export default appStyles;
