import { javascript } from '@codemirror/lang-javascript';
import CodeMirror from '@uiw/react-codemirror';
import { Button, Flex, Result, Typography } from 'antd';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { routes } from 'shared/config';
import { useAppDispatch } from 'shared/hooks';
import { Chip, DetailFooter } from 'shared/ui';

interface IFields {
	id: number | string;
	externalSystemId: string;
	exchangeClassId: string;
	exchangeClassDisplayName: string;
	statusDate: string | Date;
	status: string | ReactElement;
	conformsExportConditions: string;
	group: string;
	directoryPosition: string;
	isDupe: string;
	itemStatus: string | ReactElement;
	processingStatus: string;
}

const MessagePageUi: React.FC = () => {
	const [fields] = useState<IFields>({
		id: '111',
		externalSystemId: 'a9c40f97-5a76-44ad-ab83-b0cfbbb803a6',
		exchangeClassId: '',
		exchangeClassDisplayName: '',
		statusDate: '17.10.2022 10:48',
		status: <Chip status="success">Обработана успешно</Chip>,
		conformsExportConditions: '',
		group: 'Российские организации',
		directoryPosition: 'АО «Ромашка»',
		isDupe: 'Да',
		itemStatus: <Chip status="success">Нормализована</Chip>,
		processingStatus:
			'Ошибка в настройках условий экспорта для обменных классов OkForImport-eizwj8, ForImport-eizwj8_copy, ТП_2_copy, ТП_1 позволяет выполнить экспорт позиции с идентификатором "921439f0-5492-44bf-b893-7e51a97c7660" более чем для одного обменного класса. Требуется скорректировать условиях экспорта таким образом, чтобы позиция могла быть выгружена только через один обменный класс',
	});
	const navigate = useNavigate();
	const customHandleBack = useCallback(
		() => navigate(`/${routes.integrations.main}/${routes.integrations.journalList}`),
		[]
	);

	const dispatch = useAppDispatch();

	const { integrationId } = useParams();

	useEffect(() => {
		dispatch(
			setBreadcrumbs({
				displayName: 'Интеграции',
				url: `${routes.integrations.main}`,
				child: {
					displayName: 'Карточка интеграции',
					url: `${routes.integrations.main}/${routes.integrations.detail}/${integrationId}/${routes.integrations.journalList}`,
					child: {
						displayName: 'Карточка сообщения',
						url: window.location.pathname,
					},
				},
			})
		);
	}, []);

	const { Text, Title } = Typography;

	const error = false;
	if (error)
		return (
			<Result
				status="error"
				title="Ошибка при получении данных о реплицированном сообщении"
				subTitle="Попробуйте зайти позднее"
				extra={[
					<Button type="primary" key="console" onClick={() => navigate(-1)}>
						Назад
					</Button>,
				]}
			/>
		);

	return (
		<>
			<Flex vertical gap={24} style={{ marginBottom: 54, maxWidth: 920 }}>
				<Flex vertical gap={12}>
					<Title level={1}>Общая информация</Title>
				</Flex>
				<Flex vertical gap={12}>
					<Flex vertical gap={4}>
						<Text type="secondary">Идентификатор</Text>
						<Text>{fields.id}</Text>
					</Flex>
					<Flex vertical gap={4}>
						<Text type="secondary">Переходной ключ</Text>
						<Text>{fields.externalSystemId}</Text>
					</Flex>
					<Flex vertical gap={4}>
						<Text type="secondary">Идентификатор интеграции</Text>
						<Text>{fields.exchangeClassId}</Text>
					</Flex>
					<Flex vertical gap={4}>
						<Text type="secondary">Конфигурация обмена</Text>
						<Text>{fields.exchangeClassDisplayName}</Text>
					</Flex>
					<Flex vertical gap={4}>
						<Text type="secondary">Дата статуса</Text>
						<Text>{`${fields.statusDate}`}</Text>
					</Flex>
					<Flex vertical gap={4}>
						<Text type="secondary">Статус</Text>
						<Text>{fields.status}</Text>
					</Flex>
					<Flex vertical gap={4}>
						<Text type="secondary">Удовлетворяет условиям экспорта</Text>
						<Text>{fields.conformsExportConditions}</Text>
					</Flex>
					<Flex vertical gap={4}>
						<Text type="secondary">Группа</Text>
						<Text>{fields.group}</Text>
					</Flex>
					<Flex vertical gap={4}>
						<Text type="secondary">Позиция</Text>
						<Text>{fields.directoryPosition}</Text>
					</Flex>
					<Flex vertical gap={4}>
						<Text type="secondary">Позиция-дубликат</Text>
						<Text>{fields.isDupe}</Text>
					</Flex>
					<Flex vertical gap={4}>
						<Text type="secondary">Статус позиции</Text>
						<Text>{fields.itemStatus}</Text>
					</Flex>
					<Flex vertical gap={4}>
						<Text type="secondary">Результат обработки</Text>
						<Text>{fields.processingStatus}</Text>
					</Flex>
				</Flex>
				<Flex vertical gap={12}>
					<Title level={2}>Полученные данные</Title>

					<CodeMirror
						theme="light"
						value={JSON.stringify('', null, 2)}
						maxHeight="827"
						extensions={[javascript({ jsx: true })]}
					/>
				</Flex>
				<DetailFooter customHandleBack={customHandleBack} />
			</Flex>
		</>
	);
};

export const MessagePage = React.memo(MessagePageUi);
