import { App } from 'antd';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAttributesDeclarations } from 'entities/catalogs/attributeDeclarations/attributeDeclarations.model';
import { setAttributeDeclarationsList } from 'entities/catalogs/attributeDeclarations/attributeDeclarations.store';
import {
	AttributeDeclarationDto,
	UpdateCatalogAttributeDeclarationApiArg,
	useUpdateCatalogAttributeDeclarationMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { useLazyGetSearchResultQuery } from 'shared/api/generatedApi/searchApi';

import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';
import { useDebounce } from 'shared/hooks/useDebounce';

export const useEditCatalogRestrictionsTables = () => {
	const [editDeclarations] = useUpdateCatalogAttributeDeclarationMutation();
	const [getSearchResult, { isFetching: isSearchItemsLoading }] = useLazyGetSearchResultQuery();
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useAppDispatch();
	const { notification } = App.useApp();
	const { attributes, getAttributes } = useAttributesDeclarations();
	const { catalogGroupId } = useParams();

	const edit = async (arg: UpdateCatalogAttributeDeclarationApiArg) => {
		editDeclarations({ ...arg })
			.unwrap()
			.then((res) => {
				dispatch(
					setAttributeDeclarationsList(
						attributes.map((decl) => {
							if (res.id === decl.id) {
								return {
									...decl,
									attribute: {
										...decl.attribute,
										restrictions: {
											...res.restrictions,
										},
									},
								};
							} else {
								return decl;
							}
						})
					)
				);
			})
			.catch((e) => {
				errorHelper('Ошибка при добавлении атрибута в ограничение!', e, notification);
				dispatch(setAttributeDeclarationsList(attributes));
			});
	};

	const searchHandler = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		searchParams.set('searchValue', e.target.value);
		setSearchParams(searchParams);
	}, 1000);

	const fetchSearch = (searchValue: string) => {
		getSearchResult({
			entityType: 'ATTRIBUTE_DECLARATION',
			textRequest: searchValue,
			parentId: catalogGroupId,
		})
			.unwrap()
			.then((res) => {
				dispatch(setAttributeDeclarationsList(res as AttributeDeclarationDto[]));
			})
			.catch((err) => {
				errorHelper('Ошибка при получении списка атрибутов!', err, notification);
			});
	};

	useEffect(() => {
		const searchValue = searchParams.get('searchValue');
		if (searchValue) {
			fetchSearch(searchValue);
		} else {
			getAttributes(catalogGroupId).then((res) =>
				dispatch(setAttributeDeclarationsList(res))
			);
		}
	}, [searchParams.get('searchValue')]);

	return {
		edit,
		searchHandler,
		searchValue: searchParams.get('searchValue'),
		isLoading: isSearchItemsLoading,
	};
};
