import { Flex, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ConstraintTables } from 'widgets/Metadata/ConstraintTables';
import { MeasureWidget } from 'widgets/Metadata/Measures';
import { MetadataAttributesListWidget } from 'widgets/Metadata/MetadataAttributesListWidget';
import { MetadataGroupTreeWidget } from 'widgets/Metadata/MetadataGroupTreeWidget';
import { Transactions } from 'widgets/Transactions';
import { useMetaAttributesGroups } from 'entities/metadata/attributesGroups/attributesGroups.model';
import { routes } from 'shared/config';
import { useAppDispatch } from 'shared/hooks';
import { setSubMenuCollapsed } from 'shared/ui';
import { SubSider } from 'shared/ui/components/SubSider';

const items: TabsProps['items'] = [
	{
		key: 'attributes',
		label: 'Атрибуты',
		children: (
			<>
				<SubSider>
					<MetadataGroupTreeWidget />
				</SubSider>
				<MetadataAttributesListWidget />
			</>
		),
	},
	{
		key: 'measures',
		label: 'Единицы измерения',
		children: <MeasureWidget />,
	},
	{
		key: 'constraintTables',
		label: 'Ограничительные таблицы',
		children: <ConstraintTables />,
	},
];

export const MetadataPage: React.FC = () => {
	const [activeKey, setActiveKey] = useState<string>('');
	const [title, setTitle] = useState<string>('Метаданные');
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { currAttrGroup } = useMetaAttributesGroups();

	const onChange = (key: string) => {
		setActiveKey(key);
		navigate(`/metadata/${key}`);
	};

	useEffect(() => {
		dispatch(setSubMenuCollapsed(false));
		return () => {
			dispatch(setSubMenuCollapsed(true));
		};
	}, []);

	useEffect(() => {
		if (pathname.includes('attributes')) {
			setActiveKey('attributes');
			if (currAttrGroup) {
				setTitle(currAttrGroup.displayName);
			} else setTitle('Метаданные');
		} else if (pathname.includes('measures')) {
			setActiveKey('measures');
			setTitle('Метаданные');
		} else if (pathname.includes(routes.metadata.constraintTables)) {
			setActiveKey('constraintTables');
			setTitle('Метаданные');
		} else {
			setActiveKey('attributes');
			setTitle('Метаданные');
		}
	}, [pathname, currAttrGroup]);

	return (
		<>
			<Flex vertical align="flex-end">
				<Flex justify="space-between" style={{ width: '100%' }}>
					<Typography.Title level={1}>{title}</Typography.Title>
					<Transactions />
				</Flex>

				<Tabs
					items={items}
					style={{ paddingBottom: 65, width: '100%' }}
					destroyInactiveTabPane
					activeKey={activeKey}
					onChange={onChange}
				/>
			</Flex>
		</>
	);
};
