import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { App } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import {
	ClassifierAttributeDeclarationDto,
	useLazyGetClassifierAttributeDeclarationsQuery
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
	removeClassifierAttributeDeclarationList,
	setClassifierAttributeDeclarationsList,
} from './attributeDeclarations.store';

export const useClassifierAttributesDeclarations = () => {
	const dispatch = useAppDispatch();
	const { classifierAttributeDeclarations } = useAppSelector(
		(state) => state.entities.classifiers.classifierAttributesDeclarations
	);

	const [getDeclarations, { isLoading, error }] = useLazyGetClassifierAttributeDeclarationsQuery();

	const { classifierGroupId } = useParams();

	const { notification } = App.useApp();

	const getAttributes = async (
		id?: string
	): Promise<
		Array<ClassifierAttributeDeclarationDto> | FetchBaseQueryError | SerializedError
	> => {
		return await getDeclarations({
			classifierId: id ? id : classifierGroupId,
		})
			.unwrap()
			.then((res) => res)
			.catch((e) => {
				errorHelper('Ошибка при получении списка деклараций!', e, notification);
				return e;
			});
	};

	useEffect(() => {
		if (classifierGroupId) {
			getAttributes(classifierGroupId).then((res) => {
				if (Array.isArray(res)) {
					dispatch(setClassifierAttributeDeclarationsList(res));
				} else {
					dispatch(removeClassifierAttributeDeclarationList());
				}
			});
		}

		return () => {
			dispatch(removeClassifierAttributeDeclarationList());
		};
	}, [classifierGroupId]);

	return {
		classifierAttributeDeclarations,
		getAttributes,
		loading: isLoading,
		error,
	};
};
