import { RiAddCircleLine, RiListSettingsLine } from '@remixicon/react';
import { Button, Flex, Modal, Result, Table, TableProps } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import {
	DataSourceType,
	useMetaAttibutes,
} from 'entities/metadata/metaAttributes/metaAttributes.model';
import { useTypedTranslation } from 'shared/hooks';
import { DropdownTreeSelect } from 'shared/ui/components/AppTreeSelect';
import { useAddAttributeTree } from './AddAttributeDeclaration.model';
import s from './AddAttributeDeclaration.module.scss';

export const AddAttributeDeclaration = () => {
	const { t } = useTypedTranslation();

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selectedRow, setSelectedRow] = useState<boolean>(false);
	const [selectedRows, setSelectedRows] = useState<Array<DataSourceType> | []>([]);

	const {
		tree,
		selectedTreeItem,
		onExpandTree,
		selectCurrentAttributeGroup,
		updateCatalogAttributes,
		loading,
		error,
	} = useAddAttributeTree();

	const {
		dataSource,
		handleTableChange,
		fetchAttributes,
		loading: metaAttributesLoading,
		error: metaAttributesError,
		pagination,
	} = useMetaAttibutes();

	const columns = useMemo<TableProps['columns']>(() => [
		{
			title: t(l => l.common.defaultNames.name),
			dataIndex: 'displayName',
			key: 'displayName',
		},
		{
			title: t(l => l.common.defaultNames.type),
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: t(l => l.common.defaultNames.isMulti),
			dataIndex: 'isMulti',
			key: 'isMulti',
		},
		{
			title: <RiListSettingsLine size={20} />,
			dataIndex: 'menu',
			key: 'menu',
		},
	], [ t ]);

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRows(selectedRows);
			setSelectedRow(!selectedRow);
		},
	};

	const onSelect = (_, node) => {
		selectCurrentAttributeGroup(node);
	};

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const onConfirm = async () => {
		const data = await updateCatalogAttributes(selectedRows);
		if (data) {
			setSelectedRows([]);
			setSelectedRow(false);
			setIsOpen(false);
		}
	};

	useEffect(() => {
		if (selectedTreeItem) fetchAttributes(1, 10, selectedTreeItem?.id);
	}, [selectedTreeItem]);

	return (
		<>
			<Button
				style={{ maxWidth: 'fit-content' }}
				icon={<RiAddCircleLine size={16} />}
				onClick={openModal}
			>
				{t(l => l.catalogs.attributesDeclarations.addAttribute)}
			</Button>

			<Modal
				title={t(l => l.catalogs.attributesDeclarations.addAttribute)}
				open={isOpen}
				closable={false}
				width="50vw"
				footer={
					<Flex gap={12} justify="flex-end">
						<Button type="text" onClick={closeModal}>
							{t(l => l.common.buttons.cancel)}
						</Button>
						<Button type="primary" disabled={!selectedRows.length} onClick={onConfirm}>
							{t(l => l.common.buttons.confirm)}
						</Button>
					</Flex>
				}
			>
				<Flex vertical gap={24}>
					<p>{t(l => l.catalogs.attributesDeclarations.addAttributeDeclModal)}</p>
					<Flex gap={12} vertical flex={1}>
						<DropdownTreeSelect
							multiple={false}
							loading={loading}
							treeData={tree}
							value={selectedTreeItem && selectedTreeItem}
							loadData={(treeNode) => onExpandTree(treeNode.id)}
							onSelect={onSelect}
							error={!!error}
						/>

						<div className={s.columnAttributes}>
							{dataSource && (
								<Table
									columns={columns}
									dataSource={dataSource}
									loading={metaAttributesLoading}
									pagination={pagination}
									rowKey="id"
									onChange={(pagination) =>
										handleTableChange(pagination, selectedTreeItem?.id)
									}
									rowSelection={rowSelection}
								/>
							)}
							{error && (
								<Result
									status="error"
									title={t(l => l.common.statuses.error)}
									subTitle={JSON.stringify(metaAttributesError)}
								/>
							)}
						</div>
					</Flex>
				</Flex>
			</Modal>
		</>
	);
};
