import { RiArrowRightCircleLine, RiSaveLine } from '@remixicon/react';
import { Button, Flex, Steps, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ApprovalRouteWidget } from 'widgets/Applications/ApprovalRouteWidget/ApprovalRouteWidget';
import { clearBreadcrumbs, setBreadcrumbs } from 'entities/breadcrumbs';
import { routes } from 'shared/config';
import { useAppDispatch, useEnumStateViaSearchParam } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';

enum ApplicationTemplatePageTab {
	General = 'General',
	DataModel = 'DataModel',
	ApprovalRoute = 'ApprovalRoute',
}

const tabSearchParamKey = 'step';

const ApplicationTemplatePage = () => {
	const dispatch = useAppDispatch();
	const { templateId } = useParams();
	const [ current, setCurrent ] = useState<number | null>(null);
	const [ tab, setTab ] = useEnumStateViaSearchParam(
		ApplicationTemplatePageTab,
		tabSearchParamKey,
		ApplicationTemplatePageTab.General);

	const steps = useMemo(
		() => [
			{
				key: ApplicationTemplatePageTab.General,
				title: 'Основные параметры',
				content: (<>
					<span>Основные параметры</span>
					<Button
						block
						icon={<RiArrowRightCircleLine size={16}/>}
						onClick={() => setTab(ApplicationTemplatePageTab.DataModel)}
					>
						Перейти к следующему этапу
					</Button>
				</>),
			},
			{
				key: ApplicationTemplatePageTab.DataModel,
				title: 'Модель данных',
				content: (<>
					<Typography.Title level={2}>
						Атрибуты заявки
					</Typography.Title>
					<Button
						block
						icon={<RiArrowRightCircleLine size={16}/>}
						onClick={() => setTab(ApplicationTemplatePageTab.ApprovalRoute)}
					>
						Перейти к следующему этапу
					</Button>
				</>),
			},
			{
				key: ApplicationTemplatePageTab.ApprovalRoute,
				title: 'Маршрут согласования',
				content: (<>
					<Typography.Title level={2}>
						Этапы согласования
					</Typography.Title>

					<ApprovalRouteWidget/>

					<Button
						block
						icon={<RiSaveLine size={16}/>}
						onClick={() => console.log('save')}
					>
						Сохранить шаблон заявки
					</Button>
				</>),
			},
		],
		[],
	);

	const items = steps
		.map((item) => ({
			key: item.key,
			title: item.title,
		}));

	const handleBackClick = () => {
		if (!!current && current > 0) {
			setTab(items[current - 1].key);
		}
	};

	useEffect(() => {
		dispatch(
			setBreadcrumbs({
				displayName: 'Заявки',
				url: `${routes.applications.main}`,
				child: {
					displayName: 'Карточка шаблона заявки',
					url: `${routes.applications.main}/template/${templateId}`,
				},
			}),
		);

		return () => {
			dispatch(clearBreadcrumbs());
		};
	}, []);

	useEffect(() => {
		const idx = items.findIndex(x => x.key === tab);

		if (idx != -1) {
			setCurrent(idx);
		}
	}, [ tab ]);

	return (<>
		<Flex vertical={true} gap={24} style={{ marginBottom: 64 }}>
			<Flex justify="space-between" align="top">
				<Typography.Title level={1}>
					Создание нового шаблона заявки
				</Typography.Title>
			</Flex>

			{current != null && <>
                <Steps current={current} items={items}/>

				{steps[current].content}
            </>}
		</Flex>

		{current !== 0 && <DetailFooter customHandleBack={handleBackClick}/>}
	</>);
};

export {
	ApplicationTemplatePage,
	tabSearchParamKey,
};