import { App } from 'antd';
import { useState } from 'react';
import {
	decreasePaginationTotal,
	deleteCatalogRecord,
} from 'entities/catalogs/catalogRecords/catalogRecords.store';
import { useTransactions } from 'entities/transactions';
import { useDeleteCatalogItemMutation } from 'shared/api/generatedApi/mdmgApi';

import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteCatalogRecord = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const { notification } = App.useApp();

	const [fetchDeleteCatalogRecord, { error }] = useDeleteCatalogItemMutation();

	const dispatch = useAppDispatch();

	const { getTransactions } = useTransactions();

	const handleDeleteCatalogRecord = async (id: string) => {
		setLoading(true);
		const data = await fetchDeleteCatalogRecord({
			ids: [id],
		});
		if ('error' in data) {
			errorHelper('Ошибка при добавлении новой группы атрибутов!', data.error, notification);
		}

		if ('data' in data) {
			getTransactions();
			dispatch(deleteCatalogRecord({ id }));
			dispatch(decreasePaginationTotal());
		}
		setLoading(false);
	};

	return {
		loading,
		error,
		handleDeleteCatalogRecord,
	};
};
