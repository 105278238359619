import { exportApi as api } from './newCreateApiFile';
const injectedRtkApi = api.injectEndpoints({
	endpoints: (build) => ({
		exportItemsXlsx: build.query<ExportItemsXlsxApiResponse, ExportItemsXlsxApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/catalogs/${queryArg.id}/items/export`,
				params: { attributeDeclarationIds: queryArg.attributeDeclarationIds },
			}),
		}),
	}),
	overrideExisting: false,
});
export { injectedRtkApi as exportApi };
export type ExportItemsXlsxApiResponse = unknown;
export type ExportItemsXlsxApiArg = {
	/** Id справочника */
	id: string;
	/** Id деклараций, которые будут включены в файл. Если параметр не указан - используются все декларации справочника */
	attributeDeclarationIds?: string[];
};
export const { useExportItemsXlsxQuery, useLazyExportItemsXlsxQuery } = injectedRtkApi;
