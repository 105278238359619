import { RiDeleteBin2Line } from '@remixicon/react';
import { Form, Modal, Typography, Checkbox, Flex, Button } from 'antd';
import React, { useState } from 'react';
import { DropdownLink } from 'shared/ui';
import { useDeleteClassifierRecord } from './deleteClassifierRecord.model';
import s from './deleteClassifierRecord.module.scss';

interface IDeleteCatalogRecordUiProps {
	recordId: string;
	isButton?: boolean;
}

const DeleteClassifierRecordUi = ({ recordId, isButton }: IDeleteCatalogRecordUiProps) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isChecked, setIsChecked] = useState<boolean>(false);

	const { loading, error, handleDeleteClassifierRecord } = useDeleteClassifierRecord();

	const [form] = Form.useForm();

	const onFinish = async () => {
		await handleDeleteClassifierRecord(recordId, isButton);
		if (!error) {
			setIsChecked(false);
			setIsModalOpen(false);
		}
	};

	const showModal = () => setIsModalOpen(true);
	const handleOk = () => setIsModalOpen(false);

	const handleCancel = () => setIsModalOpen(false);

	const toggleCheck = () => setIsChecked(!isChecked);

	return (
		<>
			{isButton ? (
				<Button icon={<RiDeleteBin2Line size={16} />} onClick={showModal}>
					Удалить
				</Button>
			) : (
				<DropdownLink
					icon={<RiDeleteBin2Line size={16} />}
					title="Удалить"
					callback={showModal}
				/>
			)}

			<Modal
				title="Удаление записи классификатора"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={onFinish}>
					<Form.Item>
						<Typography.Text>
							Вы действительно хотите удалить данную запись в классификаторе? Запись и
							сопутствующие данные будут удалены без возможности восстановления
						</Typography.Text>
					</Form.Item>
					<Form.Item>
						<Typography.Text>
							Сопутствующие данные:
							<ul>
								<li className={s.removeListItem}>сообщения обмена с интеграцией</li>
								<li className={s.removeListItem}>переходные ключи</li>
							</ul>
						</Typography.Text>
					</Form.Item>
					<Form.Item>
						<Checkbox checked={isChecked} onChange={toggleCheck}>
							Я согласен удалить все вышеперечисленные сопутствующие данные
						</Checkbox>
					</Form.Item>
					<Flex gap={4} justify="flex-end">
						<Button type="text" onClick={handleCancel}>
							Отменить
						</Button>
						<Button
							htmlType="submit"
							type="primary"
							loading={loading}
							disabled={!isChecked}
						>
							Удалить
						</Button>
					</Flex>
				</Form>
			</Modal>
		</>
	);
};

export const DeleteClassifierRecord = DeleteClassifierRecordUi;
