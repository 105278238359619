import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { App } from 'antd';
import { useEffect, useState } from 'react';
import {
	TransactionDto,
	TransactionResponse,
	useLazyGetTransactionsDataQuery,
} from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setTransactionsStore } from './transactions.store';

export type transactionStatusType = 'ACTIVE' | 'PAUSED' | 'COMMITED';

export const useTransactions = () => {
	const [transactions, setTransactions] = useState<TransactionResponse[]>([]);
	const [transactionsError, setTransactionsError] = useState<
		FetchBaseQueryError | SerializedError
	>(null);
	const [fetchGetTransactionsData, { error: getTransactionDataError }] =
		useLazyGetTransactionsDataQuery();
	const [transactionsLoading, setTransactionsLoading] = useState<boolean>(false);

	const { notification } = App.useApp();
	const dispatch = useAppDispatch();
	const userId = useAppSelector((state) => state.entities.session.userId);

	const getTransactions = async () => {
		setTransactionsLoading(true);

		try {
			const data = await fetchGetTransactionsData({
				userId,
				full: false,
				status: ['ACTIVE', 'PAUSED'],
			});

			if ('error' in data) {
				errorHelper('Ошибка при получении списка транзакций!', data.error, notification);
				setTransactionsError(data.error);
			}

			if ('data' in data && data.data) {
				const res: Array<TransactionDto> = [];
				for (const v of data.data) {
					res.push(v.transaction);
				}
				setTransactions(data.data);
				dispatch(setTransactionsStore(res));
			}
		} catch (err) {
			errorHelper('Ошибка при получении списка транзакций!', err, notification);
			setTransactionsError(err);
		}

		setTransactionsLoading(false);
	};

	useEffect(() => {
		if (userId) getTransactions();
	}, [userId]);

	useEffect(() => {
		if (!getTransactionDataError) setTransactionsError(null);
	}, [getTransactionDataError]);

	return { transactions, transactionsError, transactionsLoading, getTransactions };
};
