import { App } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { addConstraintTableItem } from 'entities/metadata/constraintTables';
import { useTransactions } from 'entities/transactions';
import { useCreateRestrictionTableItemMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';

export const useAddNewRow = () => {
	const [createRestrictionTableItem] = useCreateRestrictionTableItemMutation();

	const { columns } = useAppSelector((state) => state.entities.metadata.constraintTableDetails);

	const { notification } = App.useApp();

	const { getTransactions } = useTransactions();

	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const { constraintTableId } = useParams();

	const isDisabled = !(columns?.length > 0);

	const addNewRow = async () => {
		const newAttributeValue: { [key: string]: object } = {};
		columns?.forEach((col) => (newAttributeValue[col.id] = []));

		createRestrictionTableItem({
			restrictionTableId: constraintTableId,
			createRestrictionTableItemRequest: { values: newAttributeValue },
		})
			.unwrap()
			.then((res) => {
				if (res) {
					getTransactions();
					dispatch(addConstraintTableItem(res));
					navigate(`${res.id}`);
				}
			})
			.catch((err) =>
				errorHelper('Ошибка создания строки ограничительной таблицы!', err, notification)
			);
	};

	return {
		addNewRow,
		isDisabled,
	};
};
