import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Token = string;
type Roles = Array<string>;
interface AuthState {
	status: 'auth' | 'nonAuth' | 'init';
	accessToken: Token | null;
	userName: string;
	userRoles: Roles;
	userId: string;
}

const initialState: AuthState = {
	status: 'init',
	accessToken: null,
	userName: 'Нет данных',
	userRoles: [],
	userId: '',
};

const sessionSlice = createSlice({
	name: 'session',
	initialState,
	reducers: {
		setToken(state, { payload }: PayloadAction<Token>) {
			state.accessToken = payload;
			state.status = 'auth';
			localStorage.setItem('token', payload);
		},
		removeToken(state) {
			state.accessToken = null;
			state.status = 'nonAuth';
			localStorage.removeItem('token');
		},
		setUserName(state, { payload }: PayloadAction<string>) {
			state.userName = payload;
		},
		removeUserName(state) {
			state.userName = 'Нет данных';
		},
		setUserRoles(state, { payload }: PayloadAction<Roles>) {
			state.userRoles = payload;
		},
		removeUserRoles(state) {
			state.userRoles = [];
		},
		setUserId(state, { payload }: PayloadAction<string>) {
			state.userId = payload;
		},
		removeUserId(state) {
			state.userId = '';
		},
	},
});

export const {
	setToken,
	removeToken,
	setUserName,
	removeUserName,
	setUserRoles,
	removeUserRoles,
	setUserId,
	removeUserId,
} = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;
