import en from './en';
import ru from './ru';

export type {
	Localization,
	TypedTranslationSelectorFunction,
} from './types';

export const translations = {
	en,
	ru,
};
