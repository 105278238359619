type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

type Color = RGB | RGBA | HEX;

export const colors: Record<string, Color> = {
	primary: '#4760CB',
	primaryBg: 'rgba(71, 96, 203, 0.12)',
	primaryFaded: 'rgba(108, 128, 213, 0.4)',
	primaryBorderFaded: 'rgba(71, 96, 203, 0.5)',

	text: '#151D34E5',
	secondaryText: '#151D34A6',
	textFaded: 'rgba(21, 29, 52, 0.65)',

	inputBg: 'rgba(224, 224, 224, 0.4)',

	success: 'rgba(78, 174, 111, 1)',
	successBg: 'rgba(78, 174, 111, 0.12)',

	warning: 'rgba(233, 123, 45, 1)',
	warningBg: 'rgba(233, 123, 45, 0.12)',

	error: 'rgba(243, 88, 77, 1)',
	errorBg: 'rgba(243, 88, 77, 0.12)',
	errorFaded: 'rgba(243, 88, 77, 0.05)',

	whiteBase: '#fff',
	disabled: 'rgba(224, 224, 224, 1)',
	lightGray: 'rgba(224, 224, 224, 0.4)',
	grayTableBorder: 'rgba(224, 224, 224, 1)',
	gray: 'rgba(21, 29, 52, 0.24)',
	grayIcon: 'rgba(21, 29, 52, 0.4)',
	grayBgTable: 'rgba(224, 224, 224, 0.2)',

	tabUnderline: 'rgba(233, 123, 45, 1)',

	blocked: 'rgba(120, 117, 117, 1)',
	blockedBg: 'rgba(120, 117, 117, 0.12)',
};
