import Keycloak, { KeycloakConfig } from 'keycloak-js';

// Конфигурация Keycloak
const initOptions: KeycloakConfig = {
	url: process.env.REACT_APP_KEYCLOAK_URL,
	realm: process.env.REACT_APP_KEYCLOAK_REALM,
	clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

// Создание экземпляра Keycloak
const keycloak = Keycloak(initOptions);

export const initializeKeycloak = () =>
	keycloak.init({
		onLoad: 'login-required',
		responseMode: 'fragment',
		flow: 'standard',
	});

export default keycloak;
