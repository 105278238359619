import { Spin } from 'antd';
import { initializeKeycloak } from 'keycloak';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { providerStyles } from '../styles/providerStyles';

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [authenticated, setAuthenticated] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		initializeKeycloak()
			.then((authenticated) => {
				setAuthenticated(authenticated);
				setLoading(false);
				if (!authenticated) {
					window.location.reload();
				}
			})
			.catch(() => {
				setLoading(false);
			});
	}, []);

	if (loading) {
		return <Spin style={providerStyles} size="large" />;
	}

	return authenticated ? <>{children}</> : <div>Not authenticated</div>;
};
