import { RiAddCircleLine, RiListSettingsLine } from '@remixicon/react';
import { Button, Flex, Modal, Result, Table, TableProps } from 'antd';
import React, { useEffect, useState } from 'react';
import {
	DataSourceType,
	useMetaAttibutes,
} from 'entities/metadata/metaAttributes/metaAttributes.model';
import { DropdownTreeSelect } from 'shared/ui/components/AppTreeSelect';
import { useAddAttributeTree } from './AddClassifierAttributeDeclaration.model';
import s from './AddClassifierAttributeDeclaration.module.scss';

const columns: TableProps['columns'] = [
	{
		title: 'Наименование',
		dataIndex: 'displayName',
		key: 'displayName',
	},
	{
		title: 'Тип',
		dataIndex: 'type',
		key: 'type',
	},
	{
		title: 'Множественный',
		dataIndex: 'isMulti',
		key: 'isMulti',
	},
	{
		title: <RiListSettingsLine size={20} />,
		dataIndex: 'menu',
		key: 'menu',
	},
];

export const AddClassifierAttributeDeclaration = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selectedRow, setSelectedRow] = useState<boolean>(false);
	const [selectedRows, setSelectedRows] = useState<Array<DataSourceType> | []>([]);

	const {
		tree,
		selectedTreeItem,
		onExpandTree,
		selectCurrentAttributeGroup,
		updateCatalogAttributes,
		loading,
		error,
	} = useAddAttributeTree();

	const {
		dataSource,
		handleTableChange,
		fetchAttributes,
		loading: metaAttributesLoading,
		error: metaAttributesError,
		pagination,
	} = useMetaAttibutes();

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRows(selectedRows);
			setSelectedRow(!selectedRow);
		},
	};

	const onSelect = (_, node) => {
		selectCurrentAttributeGroup(node);
	};

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const onConfirm = async () => {
		const data = await updateCatalogAttributes(selectedRows);
		if (data) {
			setSelectedRows([]);
			setSelectedRow(false);
			setIsOpen(false);
		}
	};

	useEffect(() => {
		if (selectedTreeItem) fetchAttributes(1, 10, selectedTreeItem?.id);
	}, [selectedTreeItem]);

	return (
		<>
			<Button
				style={{ maxWidth: 'fit-content' }}
				icon={<RiAddCircleLine size={16} />}
				onClick={openModal}
			>
				Добавить атрибут
			</Button>

			<Modal
				title="Добавление атрибута"
				open={isOpen}
				closable={false}
				width="50vw"
				footer={
					<Flex gap={12} justify="flex-end">
						<Button type="text" onClick={closeModal}>
							Отменить
						</Button>
						<Button type="primary" disabled={!selectedRows.length} onClick={onConfirm}>
							Подтвердить
						</Button>
					</Flex>
				}
			>
				<Flex vertical gap={24}>
					<p>Для добавления атрибута необходимо выбрать атрибут из списка</p>
					<Flex gap={12} vertical>
						<DropdownTreeSelect
							multiple={false}
							loading={loading}
							treeData={tree}
							value={selectedTreeItem && selectedTreeItem}
							loadData={(treeNode) => onExpandTree(treeNode.id)}
							onSelect={onSelect}
							error={!!error}
						/>

						<div className={s.columnAttributes}>
							{dataSource && (
								<Table
									columns={columns}
									dataSource={dataSource}
									loading={metaAttributesLoading}
									pagination={pagination}
									rowKey="id"
									onChange={(pagination) =>
										handleTableChange(pagination, selectedTreeItem?.id)
									}
									rowSelection={rowSelection}
								/>
							)}
							{error && (
								<Result
									status="error"
									title="Не удалось загрузить список атрибутов!"
									subTitle={JSON.stringify(metaAttributesError)}
								/>
							)}
						</div>
					</Flex>
				</Flex>
			</Modal>
		</>
	);
};
