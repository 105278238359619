import { RiEditLine, RiListSettingsLine } from '@remixicon/react';
import { PaginationProps, TablePaginationConfig, TableProps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { DeleteClassifierRecord } from 'features/classifiers/ClassifierRecords/DeleteClassifierRecord';
import { chipTitle } from 'entities/catalogs/catalogRecords/catalogRecords.model';
import {
	useClassifierAttributesDeclarations,
} from 'entities/classifiers/classifierAttributeDeclarations/classifierAttributeDeclarations.model';
import { useClassifierItems } from 'entities/classifiers/classifiersItems/classifierItems.model';
import { setCurrentClassifierItem } from 'entities/classifiers/classifiersItems/classifierItems.store';
import { ClassifierItemDto } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { randomString, tablePaginationShowTotalFactory } from 'shared/helpers';
import { CellTypesEnum, CellValueParser } from 'shared/helpers/CellValueParser';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { Chip, DropdownLink } from 'shared/ui';
import { ChipStatus } from 'shared/ui/components/Chip/chipStylehelper';
import ItemActions from 'shared/ui/components/ItemActions';

const defaultPagination = {
	pageSizeOptions: [ 20, 50, 100, 200 ],
	current: 1,
	pageSize: 20,
};

type ItemValuesType = string | number | boolean;

export type ICatalogRecord = {
	id: string;
	recStatus: JSX.Element;
	menu: JSX.Element;
	key: string;
};
export type ICatalogRecords = Array<ICatalogRecord>;

export interface IRecordValue {
	[key: string]: {
		attributeName: string;
		value: ItemValuesType;
	};
}

export interface IMappedRecordValue {
	[key: string]: string | JSX.Element;
}

export const useClassifierColumns = () => {
	const { t } = useTypedTranslation();

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { classifierGroupId, classifierItemId } = useParams();

	const { classifierAttributeDeclarations, loading } = useClassifierAttributesDeclarations();
	const {
		classifiersList,
		selectedClassifier,
		getClassifiers,
		loading: tableLoading,
		pagination,
		setPagination,
		classifierItemsTotal,
	} = useClassifierItems();

	const classifierGroupIdRef = useRef<string | null>();
	const classifierItemIdRef = useRef<string | null>();

	const [ columns, setColumns ] = useState<TableProps<any>['columns']>(null);
	const [ dataSource, setDataSource ] = useState(null);

	const convertValues = (values: IRecordValue): IMappedRecordValue =>
		Object.fromEntries(
			classifierAttributeDeclarations
				?.map(attributeDeclaration => {
					const { id, attribute } = attributeDeclaration;

					return [
						id,
						CellValueParser(
							values[id]?.value,
							attribute.type as CellTypesEnum,
						),
					];
				}) ?? [],
		);

	const mapRecordsDtoToTable = (
		recordsDto: ClassifierItemDto[] | null,
	): ICatalogRecords => {
		if (!recordsDto) {
			return null;
		}
		return recordsDto.map((item) => ({
			id: item.id,
			key: item.id,
			recStatus: (
				<Chip status={item.status.toLowerCase() as ChipStatus}>
					{chipTitle(item.status)}
				</Chip>
			),
			menu: (
				<ItemActions
					items={[
						{
							key: `${item?.id}-${randomString(4)}`,
							label: <DeleteClassifierRecord recordId={item.id}/>,
						},
						{
							key: `${item?.id}-${randomString(4)}`,
							label: (
								<DropdownLink
									icon={<RiEditLine size={16}/>}
									title="Редактировать"
									callback={() => {
										dispatch(setCurrentClassifierItem(item));
										navigate(`${item.id}/${routes.classifiers.edit}`);
									}}
								/>
							),
						},
					]}
				/>
			),
			...(item.values && convertValues(item.values as IRecordValue)),
		}));
	};

	useEffect(() => {
		const prevClassifierItemId = classifierItemIdRef.current;
		const prevClassifierGroupId = classifierGroupIdRef.current;
		classifierItemIdRef.current = classifierItemId;
		classifierGroupIdRef.current = classifierGroupId;
		if (prevClassifierGroupId != classifierGroupId || prevClassifierItemId != classifierItemId) {
			setPagination(prev => ({
				...prev,
				...defaultPagination,
			}));
			return;
		}

		getClassifiers(
			pagination.current,
			pagination.pageSize,
			classifierItemId,
		).then();
	}, [ classifierItemId, pagination ]);

	const handleTableChange = (newPagination?: TablePaginationConfig) => {
		setPagination(prev => ({
			...prev,
			current: newPagination.current,
			pageSize: newPagination.pageSize,
		}));
	};

	useEffect(() => {
		if (classifiersList && (classifierItemId == null || selectedClassifier)) {
			setDataSource(mapRecordsDtoToTable([
				...(selectedClassifier ? [ selectedClassifier ] : []),
				...classifiersList,
			]));
		}
	}, [ selectedClassifier, classifiersList ]);

	useEffect(() => {
		if (classifierAttributeDeclarations) {
			const newColumns = [
				{
					title: 'Статус',
					dataIndex: 'recStatus',
					key: 'recStatus',
				},
				...classifierAttributeDeclarations
					.map((decl) => ({
						key: decl.id,
						dataIndex: decl.id,
						title: decl.attribute.displayName,
					})),
				{
					title: <RiListSettingsLine size={20}/>,
					dataIndex: 'menu',
					key: 'menu',
					width: '50px',
				},
			];
			setColumns(newColumns);
		}
	}, [ classifierAttributeDeclarations ]);

	return {
		columns,
		dataSource,
		handleTableChange,
		pagination: {
			...pagination,
			total: classifierItemsTotal,
			showTotal: tablePaginationShowTotalFactory(t),
		} as PaginationProps,
		loading,
		tableLoading,
		classifierGroupId,
	};
};
