import { App } from 'antd';
import { deleteAttributeMapping } from 'entities/catalogs/catalogDeduplication/catalogDeduplication.store';
import { useDeleteDeduplicationAttributeMappingMutation } from 'shared/api/generatedApi/deduplicationApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';

export const deleteDeduplicationAttributeMapping = () => {
	const { t } = useTypedTranslation();

	const [fetchDeleteMapping, { isLoading: isDeletingMapping }] =
		useDeleteDeduplicationAttributeMappingMutation();

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const deleteMapping = (deduplicationOptionId: string, mappingId: string) => {
		fetchDeleteMapping({ id: mappingId })
			.unwrap()
			.then(() => {
				dispatch(deleteAttributeMapping({ deduplicationOptionId, mappingId }));
				notification.success({
					message: t(l => l.catalogs.attributesDeclarations.mappingDeleted),
				});
			})
			.catch((err) => errorHelper(t(l => l.common.statuses.error), err, notification));
	};

	return { isDeletingMapping, deleteMapping };
};
