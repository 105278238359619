import { RiDeleteBinLine } from '@remixicon/react';
import { Form, Modal, Typography, Checkbox, Flex, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { DropdownLink } from 'shared/ui';
import { useDeleteSimpleAttribute } from './deleteSimpleAttribute.model';

interface IDeleteSimpleAttributeProps {
	ids: Array<string>;
	isButton?: boolean;
}

export const DeleteSimpleAttributeUi = ({
	ids,
	isButton,
}: IDeleteSimpleAttributeProps) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);

	const { loading, error, deleteAttribute } = useDeleteSimpleAttribute();

	const [form] = Form.useForm();

	const onFinish = async () => {
		await deleteAttribute(ids);
		if (!error) {
			setIsChecked(false);
			setIsModalOpen(false);
			setIsDisabled(true);
		}
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const toggleCheck = () => setIsChecked(!isChecked);

	useEffect(() => {
		setIsDisabled(!ids?.length);
	}, [ids]);

	return (
		<>
			{isButton ? (
				<Button
					icon={<RiDeleteBinLine size={16} />}
					onClick={showModal}
					type="text"
					disabled={isDisabled}
				>
					Удалить
				</Button>
			) : (
				<DropdownLink
					icon={<RiDeleteBinLine size={16} />}
					title="Удалить"
					callback={showModal}
				/>
			)}

			<Modal
				title={
					ids?.length === 1 ? 'Удаление простого атрибута' : 'Удаление атрибутов'
				}
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={onFinish}>
					<Form.Item>
						<Typography.Text>
							{ids?.length === 1
								? 'Вы действительно хотите удалить данный атрибут? Атрибут и связанные декларации в справочниках будут удалены без возможности восстановления.'
								: 'Вы действительно хотите удалить данные атрибуты? Атрибуты и связанные декларации в справочниках будут удалены без возможности восстановления.'}
						</Typography.Text>
					</Form.Item>
					<Form.Item>
						<Checkbox checked={isChecked} onChange={toggleCheck}>
							{ids?.length === 1
								? 'Я согласен удалить атрибут и связанные декларации в справочниках'
								: 'Я согласен удалить атрибуты и связанные декларации в справочниках'}
						</Checkbox>
					</Form.Item>
					<Flex gap={4} justify="flex-end">
						<Button type="text" onClick={handleCancel}>
							Отменить
						</Button>
						<Button
							htmlType="submit"
							type="primary"
							loading={loading}
							disabled={!isChecked}
						>
							Удалить
						</Button>
					</Flex>
				</Form>
			</Modal>
		</>
	);
};
