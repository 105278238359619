import { App, Button, Flex } from 'antd';
import SkeletonButton from 'antd/es/skeleton/Button';
import React, { useEffect, useState } from 'react';
import { useLazyListConnectionsMdmIntegrationsV1ConnectionsGetQuery } from 'shared/api/generatedApi/integrationsApi';
import { errorHelper } from 'shared/helpers';

type FilterButtonType = 'all' | 'active' | 'failed';

const FilterByActivityTypeUi: React.FC = () => {
	const [activeButton, setActiveButton] = useState<FilterButtonType>('all');

	const [getIntegrations, { isLoading, error }] =
		useLazyListConnectionsMdmIntegrationsV1ConnectionsGetQuery();

	const { notification } = App.useApp();

	const handleClickButton = (arg: FilterButtonType) => {
		switch (arg) {
			case 'all':
				getIntegrations({ reverse: false })
					.unwrap()
					.catch((e) => {
						errorHelper(
							'Ошибка при получении отфильтрованных данных ',
							e,
							notification
						);
						setActiveButton('all');
					});
				setActiveButton('all');
				break;
			case 'active':
				getIntegrations({
					reverse: false,
					filterBy: encodeURI('is_active'),
				})
					.unwrap()
					.then(() => setActiveButton('active'))
					.catch((e) => {
						errorHelper(
							'Ошибка при получении отфильтрованных данных ',
							e,
							notification
						);
						setActiveButton('all');
					});
				break;
			case 'failed':
				getIntegrations({
					reverse: false,
					filterBy: encodeURI('is_failed'),
				})
					.unwrap()
					.then(() => setActiveButton('failed'))
					.catch((e) => {
						errorHelper(
							'Ошибка при получении отфильтрованных данных ',
							e,
							notification
						);
						setActiveButton('all');
					});
				break;
		}
	};

	useEffect(() => {
		if (activeButton) {
			handleClickButton(activeButton);
		}
	}, [activeButton]);

	return (
		<>
			<Flex gap={12}>
				{isLoading && (
					<>
						<SkeletonButton active />
						<SkeletonButton active />
						<SkeletonButton active />
					</>
				)}
				{!error && !isLoading && (
					<>
						<Button
							name="all"
							type={activeButton === 'all' ? 'primary' : undefined}
							onClick={() => handleClickButton('all')}
						>
							Показать все
						</Button>
						<Button
							name="active"
							type={activeButton === 'active' ? 'primary' : undefined}
							onClick={() => handleClickButton('active')}
						>
							Показать активные интеграции
						</Button>
						<Button
							name="failed"
							type={activeButton === 'failed' ? 'primary' : undefined}
							onClick={() => handleClickButton('failed')}
						>
							Показать интеграции со сбоями
						</Button>
					</>
				)}
			</Flex>
		</>
	);
};

export const FilterByActivityType = React.memo(FilterByActivityTypeUi);
