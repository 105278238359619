import { RiDeleteBinLine } from '@remixicon/react';
import { Button } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { routes } from 'shared/config';
import { DropdownLink } from 'shared/ui';
import { useDeleteClassifier } from './deleteClassifier.model';

interface IDeleteClassifierProps {
	id: string;
	isButton?: boolean;
}

const DeleteClassifierUi: React.FC<IDeleteClassifierProps> = ({ isButton, id }) => {
	const { handleDelete, isLoading } = useDeleteClassifier();
	const navigate = useNavigate();
	const { classifierItemId, classifierGroupId } = useParams();

	const handleDeleteClassifier = async () => {
		const res = await handleDelete(id);
		if (classifierItemId === id && res && !isButton)
			navigate(
				`/${routes.classifiers.main}/${classifierGroupId}/${routes.classifiers.records}`
			);
		if (res && isButton) navigate(`/${routes.classifiers.main}`);
	};

	return (
		<>
			{isButton ? (
				<Button
					icon={<RiDeleteBinLine size={16} />}
					onClick={handleDeleteClassifier}
					loading={isLoading}
				>
					Удалить
				</Button>
			) : (
				<DropdownLink
					icon={<RiDeleteBinLine size={16} />}
					title="Удалить"
					callback={handleDeleteClassifier}
				/>
			)}
		</>
	);
};

export const DeleteClassifier = React.memo(DeleteClassifierUi);
