import { RiCircleFill } from '@remixicon/react';
import { Tag } from 'antd';
import { TagProps } from 'antd/es/tag';
import cn from 'classnames';
import React, { PropsWithChildren, ReactElement } from 'react';
import { colors } from '../../../styles';
import s from './chip.module.scss';
import { ChipStatus, chipStyleHelper } from './chipStylehelper';

interface IChipProps extends TagProps {
	status?: ChipStatus;
	small?: boolean;
	icon?: ReactElement | null;
	selectChip?: boolean;
	cancel?: ReactElement;
	clearBg?: boolean;
	onClick?: () => void;
}

export const Chip: React.FC<PropsWithChildren<IChipProps>> = ({
	children,
	color,
	status,
	small,
	icon,
	cancel,
	selectChip,
	clearBg,
	onClick,
}) => {
	const iconSize: number = 16;
	const iconColor: string = status ? chipStyleHelper(status).main : colors[color as string];

	const iconComponent: ReactElement = icon ? (
		React.cloneElement(icon, { size: iconSize, color: iconColor })
	) : (
		<RiCircleFill size={10} color={iconColor} />
	);

	return (
		<Tag
			className={cn([
				selectChip ? s.selectChip : s.chip,
				small && s.small,
				clearBg && s.clearBg,
			])}
			color={status ? chipStyleHelper(status, clearBg).bg : color}
			icon={icon !== null && !selectChip ? iconComponent : null}
			onClick={onClick}
		>
			{children}
			{cancel && cancel}
		</Tag>
	);
};
