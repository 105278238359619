import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RiEditLine } from '@remixicon/react';
import { App, TablePaginationConfig } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { DeleteSimpleAttributeUi } from 'features/metadata/deleteSimpleAttribute';
import { AttributeDto, useLazyGetAttributesQuery } from 'shared/api/generatedApi/mdmgApi';
import { useLazyGetSearchResultQuery } from 'shared/api/generatedApi/searchApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { useDebounce } from 'shared/hooks/useDebounce';
import { AttributeType } from 'shared/models';
import { DropdownLink } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';
import {
	removeMetaAttributes,
	setCurrentMetaAttribute,
	setMetaAttributes,
	updateMetaAttributes,
} from './metaAttributes.store';

export type DataSourceType = Array<
	Omit<AttributeDto, 'type'> & { type: string; menu: React.ReactElement; isMulti: string }
>;

export const translateType = (type: string | undefined): string => {
	if (type) {
		switch (type) {
			case 'FLOAT':
				return 'Число с плавающей точкой';
			case 'BOOLEAN':
				return 'Логический тип';
			case 'STRING':
				return 'Строка';
			case 'DATE_TIME':
				return 'Дата';
			case 'FILE':
				return 'Файл';
			case 'INTEGER':
				return 'Целое число';
			case 'IMAGE':
				return 'Изображение';
			case 'TEXT':
				return 'Многострочный текст';
			case 'RELATION':
				return 'Связь';
			case 'FORMULA':
				return 'Формула';
			case 'COLOR':
				return 'Цвет';
			case undefined:
				return 'не определен тип атрибута в AttributeTransfer';
		}
	} else return '@type не отпределен';
};

export const useMetaAttibutes = () => {
	const [dataSource, setDataSource] = useState<DataSourceType>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<FetchBaseQueryError | SerializedError>(null);
	const [pagination, setPagination] = useState<{
		current: number;
		pageSize: number;
		total?: number;
		showTotal?: (n: number) => string;
	}>({
		current: 1,
		pageSize: 10,
	});

	const { metaAttributes } = useAppSelector((state) => state.entities.metadata.metaAttributes);
	const dispatch = useAppDispatch();

	const { notification } = App.useApp();

	const { metaAttributeGroupId } = useParams();
	const navigate = useNavigate();

	const [getAttributes, { error: attributesError }] = useLazyGetAttributesQuery();
	const [getSearchResult, { isFetching: isSearchItemsLoading }] = useLazyGetSearchResultQuery();

	const [searchParams, setSearchParams] = useSearchParams();

	const searchHandler = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		searchParams.set('searchValue', e.target.value);
		setSearchParams(searchParams);
	}, 1000);

	const fetchSearch = (searchValue: string) => {
		getSearchResult({
			entityType: 'attribute',
			textRequest: searchValue,
			parentId: metaAttributeGroupId,
		})
			.unwrap()
			.then((res) => {
				dispatch(setMetaAttributes(res as AttributeDto[]));
			})
			.catch((err) => {
				errorHelper('Ошибка при получении списка атрибутов!', err, notification);
			});
	};

	const fetchAttributes = async (
		page: number = 1,
		pageSize: number = 10,
		attributeGroupId: string,
		type?: AttributeType
	) => {
		setLoading(true);
		const data = await getAttributes({
			limit: pageSize,
			page: page,
			attributeGroupIds: [attributeGroupId],
			...(type && { type }),
		});
		if ('error' in data) {
			errorHelper('Ошибка при получении списка атрибутов!', data.error, notification);
			setError(data.error);
		}
		if ('data' in data) {
			if (page > 1) {
				dispatch(updateMetaAttributes(data.data.data));
			} else {
				dispatch(setMetaAttributes(data.data.data));
			}

			setPagination({
				...pagination,
				current: page,
				pageSize,
				total: data?.data?.meta?.total,
				showTotal: (n) => `Всего: ${n}`,
			});
		}
		setLoading(false);
	};

	const fetchAttributesByIds = async (ids: Array<string>): Promise<Array<AttributeDto> | void> =>
		await getAttributes({ ids })
			.unwrap()
			.then((res) => res.data)
			.catch((e) => {
				errorHelper('Ошибка при получении выбранных атрибутов!', e, notification);
			});

	const handleTableChange = (pagination: TablePaginationConfig, id: string) => {
		const searchValue = searchParams.get('searchValue');
		if (searchValue) {
			fetchSearch(searchValue);
		} else {
			fetchAttributes(pagination.current, pagination.pageSize, id);
		}
	};

	const convertToDatasource = (arr: Array<AttributeDto>): DataSourceType => {
		return arr.map((item) => ({
			...item,
			type: translateType(item.type),
			menu: (
				<ItemActions
					items={[
						{
							key: '0',
							label: (
								<DropdownLink
									icon={<RiEditLine size={16} />}
									title="Редактировать"
									callback={() => {
										dispatch(setCurrentMetaAttribute(item));
										navigate(
											`${window.location.pathname}/${routes.metadata.attribute}/${item.id}`
										);
									}}
								/>
							),
						},
						{
							key: '1',
							label: <DeleteSimpleAttributeUi ids={[item.id]} />,
						},
					]}
				/>
			),
			isMulti: item.list ? 'да' : 'нет',
		}));
	};

	useEffect(() => {
		if (metaAttributes) setDataSource(convertToDatasource(metaAttributes));
	}, [metaAttributes]);

	useEffect(() => {
		if (metaAttributeGroupId) {
			dispatch(removeMetaAttributes());
			fetchAttributes(pagination.current, pagination.pageSize, metaAttributeGroupId);
		}
	}, [metaAttributeGroupId]);

	useEffect(() => {
		if (!attributesError) setError(null);
	}, [attributesError]);

	useEffect(() => {
		if (searchParams.size) {
			handleTableChange({}, metaAttributeGroupId);
		}
	}, [searchParams]);

	return {
		dataSource,
		handleTableChange,
		fetchAttributes,
		metaAttributeGroupId,
		fetchAttributesByIds,
		loading: loading || isSearchItemsLoading,
		error,
		pagination,
		searchHandler,
		searchValue: searchParams.get('searchValue'),
	};
};
