import { RiDeleteBinLine } from '@remixicon/react';
import React, { FC, useCallback } from 'react';
import { ButtonVsDropdownLink } from 'shared/ui/components/ButtonVsDropdownLink';
import { useDeleteExchangeConfig } from './deleteExchangeConfig.model';

interface IDeleteExchangeConfigProps {
	id: Array<string>;
	isButton?: boolean;
	disabled?: boolean;
	fromConfigPage?: boolean;
}

const DeleteExchangeConfigUi: FC<IDeleteExchangeConfigProps> = ({
	id,
	isButton,
	disabled,
	fromConfigPage,
}) => {
	const { isLoading, handleDelete } = useDeleteExchangeConfig();

	const handleClick = useCallback(() => handleDelete(id, fromConfigPage), [id, fromConfigPage]);

	return (
		<ButtonVsDropdownLink
			title="Удалить"
			callback={handleClick}
			icon={<RiDeleteBinLine />}
			type={!isButton ? 'dropdownLink' : null}
			isDisabled={disabled}
			isLoading={isLoading}
		/>
	);
};

export const DeleteExchangeConfig = React.memo(DeleteExchangeConfigUi);
