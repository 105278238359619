import { App } from 'antd';
import { deleteAttributeDeclaration } from 'entities/catalogs/attributeDeclarations/attributeDeclarations.store';
import { useTransactions } from 'entities/transactions';
import { useDeleteCatalogAttributeDeclarationMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';

export const useDeleteAttributeDeclaration = () => {
	const { t } = useTypedTranslation();

	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const [deleteDeclaration] = useDeleteCatalogAttributeDeclarationMutation();

	const { getTransactions } = useTransactions();

	const handleDelete = async (id: string) => {
		await deleteDeclaration({ ids: [id] })
			.unwrap()
			.then(() => {
				getTransactions();
				dispatch(deleteAttributeDeclaration(id));
			})
			.catch((err) => errorHelper(t(l => l.common.statuses.error), err, notification));
	};

	return {
		handleDelete,
	};
};
