import { TreeSelectProps } from 'antd';
import React, { useEffect, useState } from 'react';
import {
	ClassifierItemTreeNodeResponse,
	useLazyGetClassifierItemTreeQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { DropdownTreeSelect } from 'shared/ui';

interface TreeSelectClassifierItemProps {
	classifierId: string;
	value: string | null;
	onChange: (value: string) => void;
}

const mapDtoToItems = (
	items: ClassifierItemTreeNodeResponse[] | null | undefined,
	parentItemId?: string,
) => {
	if (!items?.length) {
		return [];
	}
	return items.reduce((acc, { id, displayName, parent, children }) => {
		acc.push(
			{
				id: id,
				key: id,
				pId: parentItemId ?? null,
				value: id,
				title: displayName,
				isLeaf: !parent,
			},
			...mapDtoToItems(children, id),
		);
		return acc;
	}, []);
};

const RelationToClassifierInput = React.memo(({
												  classifierId,
												  value,
												  onChange,
											  }: TreeSelectClassifierItemProps) => {

	const [ treeData, setTreeData ] = useState<any[] | null>(null);

	const [ getClassifierItemTree ] = useLazyGetClassifierItemTreeQuery();

	useEffect(() => {
		getClassifierItemTree({
			classifierId: classifierId,
			...(value ? { includePathToItemId: value } : {}),
		})
			.unwrap()
			.then((res) => {
				setTreeData(mapDtoToItems(res));
			});
	}, [ classifierId, value ]);

	const onLoadData: TreeSelectProps['loadData'] = ({ id }) =>
		getClassifierItemTree({
			classifierId: classifierId,
			fromItemId: id,
		})
			.unwrap()
			.then(res => {
				setTreeData(
					treeData.concat(mapDtoToItems(res, id)),
				);
			});

	return <DropdownTreeSelect allowClear
							   value={value}
							   treeDefaultExpandedKeys={[ value ]}
							   treeDataSimpleMode
							   treeData={treeData}
							   loadData={onLoadData}
							   onChange={onChange}
	/>;
});

export {
	RelationToClassifierInput,
};