import { App } from 'antd';
import { deleteConstraintTableItems } from 'entities/metadata/constraintTables';
import { useTransactions } from 'entities/transactions';
import { useDeleteRestrictionTableItemsMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteRow = () => {
	const [deleteRestrictionTableItems, { isLoading: isPatchTableInfoLoading }] =
		useDeleteRestrictionTableItemsMutation();

	const dispatch = useAppDispatch();

	const { notification } = App.useApp();

	const { getTransactions } = useTransactions();

	const deleteRows = async (rowIds: string[]) => {
		deleteRestrictionTableItems({ ids: rowIds })
			.unwrap()
			.then(() => {
				dispatch(deleteConstraintTableItems(rowIds));
				getTransactions();
			})
			.catch((err) =>
				errorHelper('Ошибка удаления строки ограничительной таблицы!', err, notification)
			);
	};

	return {
		deleteRows,
		isDeleteLoading: isPatchTableInfoLoading,
	};
};
