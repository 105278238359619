import { RiLogoutBoxLine } from '@remixicon/react';
import React from 'react';;
import { useLogout } from './logout.model';
import { styles } from './logout.styles';

export const LogoutUi = () => {
	const {logout} = useLogout();
	return (
		<a onClick={logout} type="text" style={styles}>
			<RiLogoutBoxLine />
			Выйти
		</a>
	);
};
