import { RiDeleteBinLine } from '@remixicon/react';
import { Button, Checkbox, Flex, Form, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { DropdownLink } from 'shared/ui';
import { useDeleteCatalog } from './DeleteCatalog.model';

interface IDeleteCatalogUi {
	id: string;
}

const DeleteCatalogUi: React.FC<IDeleteCatalogUi> = ({ id }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [form] = Form.useForm();
	const { loading, error, deleteCatalog } = useDeleteCatalog();

	const onFinish = async () => {
		await deleteCatalog(id);
		if (!error) {
			setIsChecked(false);
			setIsModalOpen(false);
		}
	};

	const showModal = () => setIsModalOpen(true);

	const handleOk = () => setIsModalOpen(false);

	const handleCancel = () => {
		setIsChecked(false);
		setIsModalOpen(false);
	};

	const toggleCheck = () => setIsChecked(!isChecked);

	return (
		<>
			<DropdownLink
				icon={<RiDeleteBinLine size={16} />}
				title="Удалить"
				callback={showModal}
			/>

			<Modal
				title="Удаление справочника"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={onFinish}>
					<Form.Item>
						<Typography.Text>
							Вы действительно хотите удалить справочник? Справочник и входящие в него
							справочники будут удалены без возможности восстановления.
						</Typography.Text>
					</Form.Item>
					<Form.Item>
						<Checkbox checked={isChecked} onChange={toggleCheck}>
							Я согласен удалить справочник и входящие в него справочники
						</Checkbox>
					</Form.Item>
					<Flex gap={4} justify="flex-end">
						<Button type="text" onClick={handleCancel}>
							Отменить
						</Button>
						<Button
							htmlType="submit"
							type="primary"
							loading={loading}
							disabled={!isChecked}
						>
							Удалить
						</Button>
					</Flex>
				</Form>
			</Modal>
		</>
	);
};

export const DeleteCatalog = React.memo(DeleteCatalogUi);
