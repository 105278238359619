import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RiCheckLine } from '@remixicon/react';
import { Result, Spin, Form, Input, Flex } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect } from 'react';
import { CatalogDto } from 'shared/api/generatedApi/mdmgApi';
import { DropdownLink } from 'shared/ui';
import { useEditCatalogMainInfo } from './editCatalogMainInfo.model';

interface IEditCatalogMainInfoProps {
	selectedCatalog: CatalogDto;
	loading: boolean;
	error: FetchBaseQueryError | SerializedError;
	isDropdownLink?: boolean;
	displayNameAttributeDeclarationId?: string;
}

const EditCatalogMainInfoUi: React.FC<IEditCatalogMainInfoProps> = ({
	selectedCatalog,
	loading,
	error,
	isDropdownLink,
	displayNameAttributeDeclarationId,
}) => {
	const [form] = useForm();
	const { handleEditInfo } = useEditCatalogMainInfo();

	const handleBlur = async () => {
		const vals = form.getFieldsValue();
		if (
			vals.displayName === selectedCatalog?.displayName &&
			vals.description === selectedCatalog?.description
		)
			return;

		vals.displayNameAttributeDeclarationId = selectedCatalog?.displayNameAttributeDeclarationId
			? selectedCatalog?.displayNameAttributeDeclarationId
			: null;
		await handleEditInfo(vals, selectedCatalog);
	};

	const dropdownLinkHandler = async (id: string) => {
		const body = {
			displayName: selectedCatalog?.displayName,
			description: selectedCatalog?.description,
			id: selectedCatalog?.id,
			displayNameAttributeDeclarationId: id,
		};

		await handleEditInfo(body, selectedCatalog);
	};

	useEffect(() => {
		if (selectedCatalog) {
			form.setFieldsValue(selectedCatalog);
		}
	}, [selectedCatalog]);

	if (error)
		return (
			<Result
				status="error"
				title="Ошибка при получении данных для справочника!"
				subTitle={`${error}`}
			/>
		);

	return isDropdownLink ? (
		<DropdownLink
			title="Установить, как название записи"
			icon={<RiCheckLine size={16} />}
			callback={() => dropdownLinkHandler(displayNameAttributeDeclarationId)}
		/>
	) : (
		<div>
			{loading && (
				<Flex justify="center" align="center">
					<Spin size="large" />
				</Flex>
			)}
			{!loading && (
				<Form layout="vertical" form={form}>
					<Form.Item label="Наименование" required name="displayName">
						<Input onBlur={handleBlur} />
					</Form.Item>
					<Form.Item label="Описание" name="description">
						<Input onBlur={handleBlur} />
					</Form.Item>
				</Form>
			)}
		</div>
	);
};

export const EditCatalogMainInfo = React.memo(EditCatalogMainInfoUi);
