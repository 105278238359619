import { App } from 'antd';
import { useParams } from 'react-router';
import { useCatalogDeduplication } from 'entities/catalogs/catalogDeduplication';
import { useTransactions } from 'entities/transactions';
import { useSetDuplicateItemMutation } from 'shared/api/generatedApi/deduplicationApi';
import { errorHelper } from 'shared/helpers';

export const useSetDuplicateItem = () => {
	const { deduplicationOptionId } = useParams();
	const { notification } = App.useApp();
	const [fetchSetDuplicateItem, { isLoading }] = useSetDuplicateItemMutation();
	const { getTransactions } = useTransactions();
	const { getSuspected } = useCatalogDeduplication();

	const setDuplicateItem = async (ids: string[]) => {
		await fetchSetDuplicateItem({ id: deduplicationOptionId, suspectedDuplicateIds: ids })
			.unwrap()
			.then(() => {
				getSuspected();
				notification.success({ message: 'Статус «Дубликат» установлен' });
				getTransactions();
			})
			.catch((err) =>
				errorHelper('Ошибка при установке статуса «Дубликат»', err, notification)
			);
	};

	return { setDuplicateItem, isLoading };
};
