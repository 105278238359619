import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionDto } from 'shared/api/generatedApi/transactionServiceApi';

interface ITransactions {
	transactionsStore: Array<TransactionDto>;
}

const initialState: ITransactions = {
	transactionsStore: [],
};

const transactionsStoreSlice = createSlice({
	name: 'transactionsStore',
	initialState,
	reducers: {
		setTransactionsStore(state, { payload }: PayloadAction<Array<TransactionDto>>) {
			state.transactionsStore = payload;
		},
	},
});

export const { setTransactionsStore } = transactionsStoreSlice.actions;

export const transactionsStoreReducer = transactionsStoreSlice.reducer;
