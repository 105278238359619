import { Flex, Typography } from 'antd';
import React from 'react';
import { AddCatalogRestrictionTable } from 'features/catalogs/AddCatalogRestrictionTable/AddCatalogRestrictionTable.ui';
import { EditCatalogRestrictionsTables } from 'features/catalogs/EditCatalogRestrictionsTables';

const CatalogRestrictionsWidgetUi = () => {
	return (
		<Flex vertical gap={24}>
			<Typography.Title level={1}>Таблицы допустимых значений</Typography.Title>
			<Flex>
				<AddCatalogRestrictionTable />
			</Flex>
			<EditCatalogRestrictionsTables />
		</Flex>
	);
};

export const CatalogRestrictionsWidget = React.memo(CatalogRestrictionsWidgetUi);
