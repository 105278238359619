import { Flex, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Transactions } from 'widgets/Transactions';
import { EditConstraintTableRow } from 'features/metadata/ConstraintTables';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { routes } from 'shared/config';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { titleStyle, paragraphStyle } from '../configPage.style';

export const ConstraintTableRowPage = () => {
	const dispatch = useAppDispatch();

	const { constraintTableId } = useParams();

	const tableInfo = useAppSelector(
		(state) => state.entities.metadata.constraintTableDetails.table
	);

	useEffect(() => {
		if (tableInfo) {
			dispatch(
				setBreadcrumbs({
					displayName: 'Метаданные',
					url: `${routes.metadata.main}/${routes.metadata.constraintTables}`,
					child: {
						displayName: tableInfo.displayName,
						url: `${routes.metadata.main}/${routes.metadata.constraintTables}/${constraintTableId}`,
						child: {
							displayName: 'Настройка строки таблицы',
							url: window.location.pathname,
						},
					},
				})
			);
		}
	}, [tableInfo]);

	return (
		<Flex align="start" gap={24} vertical style={{ height: '100%' }}>
			<Flex vertical style={{ width: '100%' }}>
				<Flex justify="space-between" style={{ width: '100%' }}>
					<Typography.Title level={1} style={titleStyle}>
						Настройка строки таблицы
					</Typography.Title>
					<Transactions />
				</Flex>
				<Typography.Paragraph style={paragraphStyle}>
					Карточка для настройки строки таблицы
				</Typography.Paragraph>
			</Flex>

			<EditConstraintTableRow />
		</Flex>
	);
};
