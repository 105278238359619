import { App } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAttributesDeclarations } from 'entities/catalogs/attributeDeclarations/attributeDeclarations.model';
import { setCurrentCatalog } from 'entities/catalogs/catalogGroups/catalog.store';
import {
	CatalogDto,
	useLazyGetCatalogQuery,
	useLazyGetCatalogsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { translateType } from 'shared/helpers/CellTypeParser';
import { declarationRestrictionParser } from 'shared/helpers/declarationRestrictionParser';
import { useAppDispatch } from 'shared/hooks';
import { colors } from 'shared/styles';

export const useEditAttributeList = () => {
	const [descriptions, setDescriptions] = useState<{ [key: string]: React.ReactNode }>(null);
	const [catalogs, setCatalogs] = useState<Array<CatalogDto>>([]);

	const [fetchGetCatalogs] = useLazyGetCatalogsQuery();
	const [fetchGetCatalog] = useLazyGetCatalogQuery();
	const { attributes, loading, error } = useAttributesDeclarations();

	const { notification } = App.useApp();
	const { catalogGroupId } = useParams();

	const dispatch = useAppDispatch();

	const getCatalogs = (ids: string[]) => {
		fetchGetCatalogs({ ids })
			.unwrap()
			.then((res) => setCatalogs(res))
			.catch((err) =>
				errorHelper('Ошибка при получении названия каталога', err, notification)
			);
	};

	useEffect(() => {
		if (!attributes) return;
		const newDescriptions = Object.fromEntries(
			attributes?.map((item) => [
				item.id,
				<>
					<span>
						Тип данных:
						<span style={{ color: colors.text }}>
							{` ${translateType(item?.attribute?.type)}`}
						</span>
					</span>
					{Object.keys(item?.restrictions)
						.filter((r) => item?.restrictions[r] !== null)
						.map((r) =>
							declarationRestrictionParser(
								item?.attribute?.type,
								r,
								item?.restrictions[r],
								catalogs
							)
						)}
				</>,
			])
		);
		setDescriptions(newDescriptions);
	}, [catalogs, attributes]);

	useEffect(() => {
		if (!attributes) return;
		let ids = [];
		attributes.forEach((d) => {
			if (
				'scopeId' in d.restrictions &&
				d?.restrictions?.scopeId !== null &&
				Array.isArray(typeof d?.restrictions?.scopeId)
			)
				ids = [...ids, ...(d?.restrictions?.scopeId as string[])];
		});

		if (!ids.length) return;
		getCatalogs(ids);
	}, [attributes]);

	useEffect(() => {
		if (!catalogGroupId) return;
		fetchGetCatalog({ id: catalogGroupId })
			.unwrap()
			.then((res) => dispatch(setCurrentCatalog(res)))
			.catch((err) =>
				errorHelper('Ошибка при получении текущего каталога', err, notification)
			);
	}, [catalogGroupId]);

	return { descriptions, attributes, loading, error };
};
