import { RiAddCircleLine } from '@remixicon/react';
import { Button, Checkbox, Flex, Modal, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import { useCatalogRestrictionTableDeclarations } from 'entities/catalogs/catalogRestrictions';
import { useConstraintTables } from 'entities/metadata/constraintTables';
import { RestrictionTableResponse } from 'shared/api/generatedApi/mdmgApi';
import { randomString } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks';
import { Placeholder } from 'shared/ui';
import { useAddCatalogRestrictionTable } from './AddCatalogRestrictionTable.model';

const AddCatalogRestrictionTableUi = () => {
	const { t } = useTypedTranslation();

	const { catalogRestrictionTablesDeclarations, isLoading: isDeclarationsLoading } =
		useCatalogRestrictionTableDeclarations();

	const { getConstraintTables, loading: isTablesLoading } = useConstraintTables();

	const { handleAddTablesDeclarations, isLoading: isAddTableLoading } =
		useAddCatalogRestrictionTable();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const [constraintTables, setConstraintTables] = useState<RestrictionTableResponse[] | null>(
		null
	);

	const [checkedTableIds, setCheckedTableIds] = useState<string[]>([]);

	const onModalOpen = () => {
		if (!constraintTables)
			getConstraintTables().then((res) => Array.isArray(res) && setConstraintTables(res));
		setIsModalOpen(true);
	};

	const handleOk = () => {
		handleAddTablesDeclarations(checkedTableIds).finally(() => setIsModalOpen(false));
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleCheckClick = (newId: string, checked: boolean) => {
		checked
			? setCheckedTableIds((prev) => [...prev, newId])
			: setCheckedTableIds((prev) => prev.filter((id) => id !== newId));
	};

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					{t(l => l.common.buttons.cancel)}
				</Button>

				<Button
					key="submit"
					type="primary"
					onClick={handleOk}
					disabled={checkedTableIds.length === 0}
					loading={isAddTableLoading}
				>
					{t(l => l.common.buttons.confirm)}
				</Button>
			</Flex>
		),
		[handleOk, handleCancel, isAddTableLoading, checkedTableIds]
	);
	return (
		<>
			<Button onClick={onModalOpen} icon={<RiAddCircleLine />}>
				Добавить таблицу допустимых значений
			</Button>
			<Modal
				title={t(l => l.catalogs.restrictions.addTable)}
				open={isModalOpen}
				closable={false}
				onOk={handleOk}
				onCancel={handleCancel}
				width={600}
				footer={footerButtons}
			>
				{isTablesLoading || isDeclarationsLoading ? (
					<Flex justify="center">
						<Spin />
					</Flex>
				) : !constraintTables || constraintTables.length === 0 ? (
					<Placeholder title={t(l => l.catalogs.restrictions.noTableErr)} small />
				) : (
					<Flex vertical gap={15}>
						{constraintTables
							.filter(
								(table) =>
									!catalogRestrictionTablesDeclarations.find(
										(decl) => decl.restrictionTable.id === table.id
									)
							)
							.map((table) => (
								<div key={table.id}>
									<Checkbox
										onChange={(e) =>
											handleCheckClick(table.id, e.target.checked)
										}
									>
										{table.displayName}
									</Checkbox>
								</div>
							))}
					</Flex>
				)}
			</Modal>
		</>
	);
};

export const AddCatalogRestrictionTable = React.memo(AddCatalogRestrictionTableUi);
