import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClassifierResponse } from 'shared/api/generatedApi/mdmgApi';

interface IClassifiersState {
	classifiersGroups: Array<ClassifierResponse>;
	currentClassifierGroup: ClassifierResponse;
}

const initialState: IClassifiersState = {
	classifiersGroups: null,
	currentClassifierGroup: null,
};

const classifiersSlice = createSlice({
	name: 'classifiersGroups',
	initialState,
	reducers: {
		setClassifiersGroups(state, action: PayloadAction<ClassifierResponse[]>) {
			state.classifiersGroups = action.payload;
		},
		setCurrentClassifier(state, action: PayloadAction<ClassifierResponse>) {
			state.currentClassifierGroup = action.payload;
		},
		editCurrentClassifier(
			state,
			{ payload }: PayloadAction<Partial<ClassifierResponse>>
		) {
			const classifier = { ...state.currentClassifierGroup, ...payload };
			state.currentClassifierGroup = classifier;
			if (state.classifiersGroups && state.classifiersGroups.length) {
				state.classifiersGroups = state.classifiersGroups.map((item) => {
					if (item.id === classifier.id) return classifier;
					return item;
				});
			}
		},
		addNewClassifierGroup(state, action: PayloadAction<ClassifierResponse>) {
			state.classifiersGroups.push(action.payload);
		},
		deleteClassifierGroup(state, { payload }: PayloadAction<string>) {
			state.classifiersGroups = state.classifiersGroups.filter(
				(Classifier) => Classifier.id !== payload
			);
		},
		removeCurrentClassifierGroup(state) {
			state.currentClassifierGroup = null;
		},
	},
});

export const {
	setClassifiersGroups,
	setCurrentClassifier,
	deleteClassifierGroup,
	removeCurrentClassifierGroup,
	addNewClassifierGroup,
	editCurrentClassifier,
} = classifiersSlice.actions;

export const classifiersReducer = classifiersSlice.reducer;
