import { exportApi } from './generatedApi/exportApi';

const enhancedApi = exportApi.enhanceEndpoints({
	endpoints: {
		exportItemsXlsx: {
			query: (queryArg) => ({
				url: `/api/v1/catalogs/${queryArg.id}/items/export`,
				params: { attributeDeclarationIds: queryArg.attributeDeclarationIds },
				cache: 'no-cache',
				responseHandler: async (response) => {
					const blobFile = await response.blob();
					return {
						fileUrl: window.URL.createObjectURL(blobFile),
						filenameHeader: response.headers.get('Content-Disposition')
					};
				}
			}),
		},
	},
});

export { enhancedApi as exportApi };
export const { useLazyExportItemsXlsxQuery } = enhancedApi;
