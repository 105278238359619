import { Button, Checkbox, Flex, Modal, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useEffect, useState } from 'react';

interface IDeleteConstraintTableModalProps {
	isModalOpen: boolean;
	isDeleteLoading: boolean;
	handleDeleteTable: () => void;
	handleCancel: () => void;
}

export const DeleteConstraintTableModal = ({
	handleDeleteTable,
	handleCancel,
	isModalOpen,
	isDeleteLoading,
}: IDeleteConstraintTableModalProps) => {
	const [isValid, setIsValid] = useState<boolean>(false);

	const onSubmit = () => {
		handleDeleteTable();
	};

	const onCancel = () => {
		handleCancel();
	};

	const onConfirmDelete = (e: CheckboxChangeEvent) => {
		setIsValid(e.target.checked);
	};

	useEffect(() => {
		if (!isModalOpen) setIsValid(false);
	}, [isModalOpen]);
	return (
		<Modal
			open={isModalOpen}
			title="Удаление ограничительных таблиц"
			closeIcon={null}
			onCancel={onCancel}
			onOk={onSubmit}
			destroyOnClose
			width={600}
			footer={
				<Flex gap={12} justify="flex-end">
					<Button onClick={onCancel} key="back" type="text">
						Отменить
					</Button>

					<Button
						disabled={!isValid}
						type="primary"
						key="submit"
						loading={isDeleteLoading}
						onClick={onSubmit}
					>
						Удалить
					</Button>
				</Flex>
			}
		>
			<Flex vertical gap={38}>
				<Typography.Paragraph>
					Вы действительно хотите удалить данную ограничительную таблицу? Ограничительная
					таблица и входящие в нее строки будут удалены без возможности восстановления
				</Typography.Paragraph>

				<Checkbox onChange={onConfirmDelete} checked={isValid}>
					Я согласен удалить ограничительную таблицу и входящие в нее строки
				</Checkbox>
			</Flex>
		</Modal>
	);
};
