import { RiListSettingsLine } from '@remixicon/react';
import { Flex, Result, Table, TableProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AddSimpleAttributeUi } from 'features/metadata/addSimpleAttribute';
import { DeleteSimpleAttributeUi } from 'features/metadata/deleteSimpleAttribute';
import { useMetaAttributesGroups } from 'entities/metadata/attributesGroups/attributesGroups.model';
import {
	DataSourceType,
	useMetaAttibutes,
} from 'entities/metadata/metaAttributes/metaAttributes.model';
import { routes } from 'shared/config';
import { useAppDispatch } from 'shared/hooks';
import { DetailFooter, Placeholder, Search, setSubMenuCollapsed } from 'shared/ui';

const columns: TableProps<any>['columns'] = [
	{
		title: 'Наименование',
		dataIndex: 'displayName',
		key: 'displayName',
		width: '33%',
	},
	{
		title: 'Тип',
		dataIndex: 'type',
		key: 'type',
		width: '33%',
	},
	{
		title: 'Множественный',
		dataIndex: 'isMulti',
		key: 'isMulti',
		width: '33%',
	},
	{
		title: <RiListSettingsLine size={20} />,
		dataIndex: 'menu',
		key: 'menu',
		width: '50px',
	},
];

const MetadataAttributesListWidgetUi: React.FC = () => {
	const [atrIdArr, setAtrIdArr] = useState<Array<string>>(null);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { currAttrGroup } = useMetaAttributesGroups();

	const {
		dataSource,
		handleTableChange,
		metaAttributeGroupId,
		loading: metaAttributesLoading,
		error: metaAttributesError,
		pagination,
		searchHandler,
		searchValue,
	} = useMetaAttibutes();

	const rowSelection = {
		onChange: (_: React.Key[], selectedRows: DataSourceType) => {
			setAtrIdArr(selectedRows.map((row) => row.id));
		},
	};

	useEffect(() => {
		dispatch(setSubMenuCollapsed(false));
		return () => {
			dispatch(setSubMenuCollapsed(true));
		};
	}, []);

	return (
		<Flex vertical gap={24}>
			{metaAttributeGroupId && currAttrGroup && (
				<>
					<Search onChange={searchHandler} defaultValue={searchValue} />
					<Flex>
						<AddSimpleAttributeUi />
					</Flex>
				</>
			)}

			{metaAttributesError && (
				<Result
					status="error"
					title="Не удалось загрузить список атрибутов"
					subTitle={JSON.stringify(metaAttributesError)}
				/>
			)}
			{!metaAttributesError &&
				(metaAttributeGroupId && currAttrGroup ? (
					<>
						<Table
							columns={columns}
							dataSource={dataSource}
							loading={metaAttributesLoading}
							pagination={pagination}
							rowKey="id"
							onChange={(pagination) =>
								handleTableChange(pagination, metaAttributeGroupId)
							}
							onRow={(r) => ({
								onClick: () =>
									navigate(
										`${window.location.pathname}/${routes.metadata.attribute}/${r.id}`
									),
							})}
							rowSelection={{
								...rowSelection,
							}}
							scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}

						/>

						<DetailFooter isNavHidden={true}>
							<DeleteSimpleAttributeUi ids={atrIdArr} isButton={true} />
						</DetailFooter>
					</>
				) : (
					<Placeholder
						title="Не выбрана ни одна группа"
						subTitle="Выберите группу для отображения списка"
					/>
				))}
		</Flex>
	);
};

export const MetadataAttributesListWidget = React.memo(MetadataAttributesListWidgetUi);
