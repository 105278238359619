import { App, TreeDataNode } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
	ClassifierItemDto,
	ClassifierItemTreeNode,
	ClassifierItemTreeNodeResponse,
	SliceClassifierItemDto,
	useLazyGetClassifierItemQuery,
	useLazyGetClassifierItemsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { PaginationModel } from 'shared/models';
import {
	removeCurrentClassifierItem,
	setCurrentClassifierItem,
	setClassifierItems,
	setClassifierItemsTotal,
	setPagination as setPaginationAction,
} from './classifierItems.store';

export type ClassifierItem = ClassifierItemTreeNode &
	TreeDataNode & {
	key: React.Key;
	title: string;
	children?: Array<ClassifierItem>;
};

export type DisplayTreeNode = Omit<ClassifierItem, 'title'> & { title: React.ReactNode };

const findChild = (
	arr: Array<ClassifierItemTreeNode | ClassifierItemTreeNodeResponse>,
	id: string,
): ClassifierItemTreeNode | ClassifierItemTreeNodeResponse | undefined => {
	if (arr) {
		for (const attr of arr) {
			if (attr.id === id) {
				return attr;
			}
			if (attr.children && attr.children.length) {
				const child = findChild(attr.children, id);
				if (child) {
					return child;
				}
			}
		}
	}

	return undefined;
};

export const useClassifierItems = () => {
	const [ selectedClassifier, setSelectedClassifier ] = useState<ClassifierItemDto>(null);
	const [ classifiersList, setClassifiersList ] = useState<Array<ClassifierItemDto>>([]);
	const [ loading, setLoading ] = useState<boolean>(false);

	const [ getItemsList, { error: itemsListError } ] = useLazyGetClassifierItemsQuery();
	const [ getClassifierItem ] = useLazyGetClassifierItemQuery();

	const { notification } = App.useApp();

	const { classifierItemId, classifierGroupId } = useParams();

	const dispatch = useAppDispatch();
	const { classifierItemsList, currentClassifierItem, classifierItemsTotal, pagination } = useAppSelector(
		(state) => state.entities.classifiers.classifierItems,
	);

	const setPagination = (fn: (prev: PaginationModel) => PaginationModel) =>
		dispatch(setPaginationAction(fn(pagination)));

	const getClassifiers = async (
		page?,
		limit?,
		parentItemId?: string,
	): Promise<SliceClassifierItemDto | null> => {
		const response = await getItemsList({
			classifierId: classifierGroupId,
			...(page ? { page } : {}),
			...(limit ? { limit } : {}),
			...(parentItemId ? { parentItemId } : { }),
			sortBy: 'displayName',
			sortType: 'asc',
		});
		if ('error' in response) {
			errorHelper(
				'Ошибка при получении корневой группы справочников!',
				response.error,
				notification,
			);
			return null;
		}

		if ('data' in response) {
			const { data } = response;
			dispatch(setClassifierItems(data.data));
			dispatch(setClassifierItemsTotal(data.meta.total + (classifierItemId ? 1 : 0)));
		}

		return response?.data;
	};

	const getGroups = async () => {
		setLoading(true);

		const [ item, list ] = await Promise.all([
			classifierItemId ? getClassifierItem({
				id: classifierItemId,
			}) : Promise.resolve(null),
			getClassifiers(1, 20, classifierItemId),
		]);

		if (item?.error) {
			errorHelper(
				'Ошибка при получении дерева позиции классификатора!',
				item.error,
				notification,
			);
		}

		if (item?.data) {
			dispatch(setCurrentClassifierItem(item.data));
		}

		if (list?.data) {
			dispatch(setClassifierItems(list.data));
			dispatch(setClassifierItemsTotal(list.meta.total + (classifierItemId ? 1 : 0)));
		}

		setLoading(false);
	};

	useEffect(() => {
		if (!classifierItemsList) {
			getGroups();
		}
	}, []);

	useEffect(() => {
		if (classifierItemsList) {
			setClassifiersList(classifierItemsList);
		}
	}, [ classifierItemsList ]);

	useEffect(() => {
		setSelectedClassifier(currentClassifierItem);
	}, [ currentClassifierItem ]);

	useEffect(() => {
		if (!classifierItemId && currentClassifierItem) {
			dispatch(removeCurrentClassifierItem());
		}
		if (classifierItemId) {
			getGroups().then();
		}
	}, [ classifierItemId ]);

	return {
		selectedClassifier,
		classifiersList,
		classifierItemsTotal,
		pagination,
		setPagination,
		getClassifiers,
		error: itemsListError,
		loading,
		setLoading,
		findChild,
	};
};
