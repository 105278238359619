import breadcrumbsStyles from './breadcrumbsStyles/breadcrumbsStyles';
import buttonStyles from './buttonStyles/buttonStyles';
import { carouselStyles } from './carouselStyles/carouselStyles';
import { checkboxStyles } from './checkboxStyles/checkboxStyles';
import { datePickerStyles } from './datePickerStyles/datePickerStyles';
import { formStyles } from './formStyles/formStyles';
import { inputStyles } from './inputStyles/inputStyles';
import menuStyles from './menuStyles/menuStyles';
import { modalStyles } from './modalStyles/modalStyles';
import { notificationStyles } from './notificationsStyles/notificationStyles';
import { paginationStyles } from './paginationStyles/paginationStyles';
import { selectStyles } from './selectStyles/selectStyles';
import { tableStyles } from './tableStyles/tableStyles';
import { tabStyles } from './tabStyles/tabStyles';
import { treeSelectStyles } from './treeSelectStyles/treeSelectStyles';
import typographyStyles from './typographyStyles/typographyStyles';

export const componentsStyles = {
	Button: { ...buttonStyles },
	Breadcrumb: { ...breadcrumbsStyles },
	Menu: { ...menuStyles },
	Typography: { ...typographyStyles },
	Notification: { ...notificationStyles },
	Form: { ...formStyles },
	Tabs: { ...tabStyles },
	Input: { ...inputStyles },
	Select: { ...selectStyles },
	Modal: { ...modalStyles },
	Table: { ...tableStyles },
	Pagination: { ...paginationStyles },
	Carousel: { ...carouselStyles },
	DatePicker: { ...datePickerStyles },
	Checkbox: { ...checkboxStyles },
	TreeSelect: { ...treeSelectStyles },
};

export { notificationConfig } from './notificationsStyles/notificationStyles';
