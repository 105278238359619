import React from 'react';

export const Logo = () => {
	return (
		<svg
			width="81"
			height="28"
			viewBox="0 0 81 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_15674_48238)">
				<path
					d="M46.8905 6.54499C46.8905 5.80298 46.5354 5.43728 45.8092 5.43728H45.1255V11.6277H45.841C46.546 11.6277 46.8958 11.2673 46.8958 10.5465V6.55029L46.8905 6.54499ZM42.0091 25.8106V2.32617H45.8039C47.235 2.32617 48.2738 2.66007 48.9416 3.33848C49.6041 4.01688 49.938 5.09808 49.938 6.57679V10.4299C49.938 11.9139 49.6094 12.9898 48.9522 13.6629C48.295 14.3254 47.2562 14.6593 45.8251 14.6593H45.1096V25.8159H41.9985L42.0091 25.8106Z"
					fill="#231F20"
				/>
				<path
					d="M65.6719 2.32617V5.43728H62.3382V25.8106H59.2271V2.32617H65.6772H65.6719Z"
					fill="#231F20"
				/>
				<path
					d="M70.6815 25.8106L70.3423 22.4662H68.058L67.6552 25.8106H64.6606L67.5174 2.32617H71.1108L73.7979 25.8106H70.6815ZM69.9925 19.6413C69.8388 18.1043 69.701 16.5673 69.5579 15.025C69.4254 13.4827 69.3035 11.9086 69.1869 10.2868C68.9643 13.4827 68.6781 16.5991 68.3389 19.6413H69.9872H69.9925Z"
					fill="#231F20"
				/>
				<path
					d="M75.5875 25.8106V5.43728H73.3032V2.32617H80.9512V5.43728H78.6986V25.8106H75.5928H75.5875Z"
					fill="#231F20"
				/>
				<path
					d="M35.1974 25.8106H32.0916V23.2294H31.763C30.2207 23.2294 29.0017 22.6252 28.106 21.4168C27.7456 20.9451 27.4806 20.4098 27.3004 19.8056C27.1202 19.2014 27.0248 18.7297 27.0142 18.3958C27.0036 18.0619 26.9983 17.4789 26.9983 16.6521V11.1507C26.9983 8.7127 27.47 7.03789 28.4081 6.11569C29.3462 5.21998 30.4751 4.77478 31.7948 4.77478H32.0916V2.32617H35.1974V4.77478H35.3458C36.6655 4.77478 37.7944 5.21998 38.7325 6.11569C39.6706 7.03259 40.1423 8.7127 40.1423 11.1507V16.6521C40.1423 17.9029 40.0681 18.8622 39.925 19.5194C39.7819 20.1819 39.4851 20.8126 39.0346 21.4168C38.0965 22.6252 36.8775 23.2294 35.3776 23.2294H35.1921V25.8106H35.1974ZM32.0863 20.7437V7.25519H31.8584C31.0952 7.25519 30.8196 7.54139 30.4168 8.07669C30.0829 8.50069 29.9133 9.3646 29.9133 10.6578V17.0973C29.9133 18.1944 30.0458 19.0318 30.3161 19.6148C30.7189 20.378 30.9998 20.7384 31.7577 20.7384H32.0863V20.7437ZM35.1974 20.7437H35.3829C36.1408 20.7437 36.586 20.3674 36.9888 19.6095C37.2591 19.1166 37.2856 18.2156 37.2856 16.9436V10.6366C37.2856 9.3381 37.116 8.47949 36.7821 8.05549C36.3581 7.52019 36.0189 7.26049 35.2822 7.26049H35.1974V20.749V20.7437Z"
					fill="#231F20"
				/>
				<path
					d="M51.5315 25.8106V2.32617H57.9816V5.21468H54.4359V11.7496H56.5453V15.0462H54.4359V22.922H57.9816V25.8106H51.5315Z"
					fill="#231F20"
				/>
				<path
					d="M11.3147 0V28"
					stroke="#231F20"
					strokeWidth="3.02101"
					strokeMiterlimit="10"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M22.0324 2.42773H0.000244141V25.5729H22.0324V2.42773ZM3.06895 5.49644H18.969V22.5095H3.06895V5.49644Z"
					fill="#231F20"
				/>
			</g>
			<defs>
				<clipPath id="clip0_15674_48238">
					<rect width="80.9525" height="28" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
