import React from 'react';
import { colors } from '../../../styles';
import { styles } from './dropdownLink.styles';

interface IDropdownLinkProps {
	title: string;
	icon?: React.ReactElement;
	defaultColor?: boolean;
	callback: () => void;
	loading?: boolean;
	disabled?: boolean;
}

export const DropdownLink: React.FC<IDropdownLinkProps> = ({
	title,
	icon,
	callback,
	defaultColor,
	disabled,
	loading,
}) => {
	const handleClick = (e: React.SyntheticEvent) => {
		e.preventDefault();
		e.stopPropagation();
		if (disabled) return;
		callback();
	};
	return (
		<a
			onClick={handleClick}
			style={{
				...styles,
				...(disabled && {
					color: colors.disabled,
					cursor: 'not-allowed',
				}),
				...(defaultColor && {
					color: colors.text,
					fontWeight: 500,
					opacity: `${loading ? '0,3' : 'unset'}`,
				}),
			}}
		>
			{icon && icon}
			{title}
		</a>
	);
};
