import {
	DndContext,
	KeyboardSensor,
	useSensor,
	useSensors,
	closestCorners,
	MouseSensor,
	TouchSensor,
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
	useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { RiDraggable, RiListSettingsLine } from '@remixicon/react';
import { Button, Checkbox, Flex, GetProp, Spin } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { colors } from '../../../styles';
import { setCatalogRecordsAjustedColumns } from '../../ui.store';
import { RightDrawer } from '../RightDrawer';

type IColumnsAdjustmentWidget = {
	title: string | React.ReactElement;
	dataIndex: string;
	key: string;
	width?: string;
	isColumnVisible?: boolean;
	id: string;
}[];

export const ColumnsAdjustmentWidget = () => {
	const originalColumns = useAppSelector(
		(state) => state.shared.ui.catalogRecordsOriginalColumns
	);

	const hiddenColunmsKeys = ['recStatus', 'menu', 'этой колонки нет'];
	const hiddenColunms = Object.fromEntries(
		hiddenColunmsKeys
			.filter((item) => originalColumns.map((item) => item.key).includes(item))
			.map((item) => [
				originalColumns.findIndex((x) => x.key === item),
				originalColumns.find((x) => x.key === item),
			])
	);

	const { pathname } = useLocation();

	const initialAdjustedColumns =
		(JSON.parse(localStorage.getItem(pathname))?.columnSettings as IColumnsAdjustmentWidget) ||
		originalColumns
			?.filter((item) => !hiddenColunmsKeys.includes(item.key))
			.map((item) => ({
				...item,
				id: item.key,
				isColumnVisible: true,
			}));

	const [adjustedColumns, setAdjustedColumns] = useState<IColumnsAdjustmentWidget | null>(
		initialAdjustedColumns
	);

	const dispatch = useAppDispatch();

	const onChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
		setAdjustedColumns(
			adjustedColumns.map((item) =>
				checkedValues.includes(item.key)
					? { ...item, isColumnVisible: true }
					: { ...item, isColumnVisible: false }
			)
		);
	};

	const chooseAll = () =>
		setAdjustedColumns(adjustedColumns?.map((item) => ({ ...item, isColumnVisible: true })));

	const clearAll = () =>
		setAdjustedColumns(
			adjustedColumns?.map((item) =>
				item.key === 'menu'
					? { ...item, isColumnVisible: true }
					: { ...item, isColumnVisible: false }
			)
		);

	const applyChanges = () => {
		localStorage.setItem(
			pathname,
			JSON.stringify({
				...JSON.parse(localStorage.getItem(pathname)),
				columnSettings: adjustedColumns,
			})
		);

		const visibleColumns = adjustedColumns?.filter((item) => item.isColumnVisible);
		Object.entries(hiddenColunms)?.forEach((c) =>
			visibleColumns?.splice(+c[0], 0, { ...c[1], id: c[1].key, isColumnVisible: true })
		);

		dispatch(setCatalogRecordsAjustedColumns(visibleColumns));
	};

	const getTaskPos = (key) => adjustedColumns.findIndex((item) => item.key === key);

	const handleDragEnd = (ev) => {
		const { active, over } = ev;

		if (active.id === over.id) return;

		setAdjustedColumns((items) => {
			const originalPos = getTaskPos(active.id);
			const newPos = getTaskPos(over.id);

			return arrayMove(items, originalPos, newPos);
		});
	};

	const ListItem = (props: { id: string; title: string | ReactElement }) => {
		const { id, title } = props;

		const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

		const style = {
			border: `solid thin ${adjustedColumns?.find((item) => item.id === id)?.isColumnVisible ? colors.primary : colors.grayTableBorder}`,
			borderRadius: '6px',
			padding: '12px',
			backgroundColor: `${colors.whiteBase}`,
			touchAction: 'none',
			transition,
			transform: CSS.Transform.toString(transform),
		};

		return (
			<div ref={setNodeRef} style={style} {...attributes} {...listeners}>
				<Flex justify="space-between" align="center">
					<Checkbox value={id} disabled={id === 'menu'}>
						{title}
					</Checkbox>

					<RiDraggable
						size={20}
						color={colors.gray}
						style={{ transform: 'rotate(90deg)' }}
					/>
				</Flex>
			</div>
		);
	};

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: 200,
				tolerance: 6,
			},
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	useEffect(() => {
		const localColumnSetting = JSON.parse(localStorage.getItem(pathname))?.columnSettings;

		const localKeys = localColumnSetting?.map((item) => item.key);
		const originalKeys = originalColumns
			?.map((item) => item.key)
			.filter((x) => !hiddenColunmsKeys.includes(x));

		const isEqual =
			localKeys?.reduce((acc, item) => (acc &&= originalKeys.includes(item)), true) &&
			originalKeys?.reduce((acc, item) => (acc &&= localKeys.includes(item)), true);

		if (isEqual) {
			applyChanges();
			return;
		} else {
			const initialAdjustedColumns = originalColumns
				?.filter((item) => !hiddenColunmsKeys.includes(item.key))
				.map((item) => ({
					...item,
					id: item.key,
					isColumnVisible: true,
				}));

			setAdjustedColumns(initialAdjustedColumns);
		}
	}, [originalColumns]);

	return (
		<RightDrawer title="Настройка таблицы" openButton={<RiListSettingsLine size={20} />}>
			{!originalColumns ? (
				<Flex>
					<Spin />
				</Flex>
			) : (
				<Flex vertical gap={8}>
					<Flex justify="space-between">
						<Button type="text" onClick={chooseAll}>
							Выбрать все
						</Button>
						<Button type="text" onClick={clearAll}>
							Очистить выбор
						</Button>
					</Flex>
					<Checkbox.Group
						onChange={onChange}
						defaultValue={initialAdjustedColumns?.map((item) => item.key)}
						value={adjustedColumns
							?.filter((item) => item.isColumnVisible)
							.map((item) => item.key)}
					>
						<DndContext
							sensors={sensors}
							collisionDetection={closestCorners}
							onDragEnd={handleDragEnd}
						>
							<Flex gap={12} vertical style={{ width: '100%' }}>
								<SortableContext
									items={adjustedColumns}
									strategy={verticalListSortingStrategy}
								>
									{adjustedColumns?.map((item) => (
										<ListItem id={item.key} title={item.title} key={item.key} />
									))}
								</SortableContext>
							</Flex>
						</DndContext>
					</Checkbox.Group>

					<Button type="primary" onClick={applyChanges}>
						Применить
					</Button>
				</Flex>
			)}
		</RightDrawer>
	);
};
