import { App } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router';
import { updateConstraintTableItem } from 'entities/metadata/constraintTables';
import { useTransactions } from 'entities/transactions';
import { useUpdateRestrictionTableItemMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { ItemValuesType } from 'shared/helpers/types';
import { useAppDispatch, useAppSelector } from 'shared/hooks';

const filterNullAndEmptyValues = v => v !== '' && v != null;

export const useEditConstraintTableRow = () => {
	const { notification } = App.useApp();

	const [ updateRestrictionTableItem ] = useUpdateRestrictionTableItemMutation();

	const [ loading, setLoading ] = useState<string | null>();

	const { constraintTableId, constraintTableRowId } = useParams();

	const { getTransactions } = useTransactions();

	const dispatch = useAppDispatch();

	const currentValues = useAppSelector(
		(state) => state.entities.metadata.constraintTableDetails.tableItems,
	)?.find((row) => row.id === constraintTableRowId)?.values;

	const editInfo = async (attributeId: string, attributeValues: ItemValuesType) => {
		setLoading(attributeId);

		updateRestrictionTableItem({
			restrictionTableId: constraintTableId,
			id: constraintTableRowId,
			updateRestrictionTableItemRequest: {
				values: {
					...Object.fromEntries(
						Object.entries(currentValues)
							.map(([ key, values ]) => [
								key,
								(values as any[]).filter(filterNullAndEmptyValues),
							])),
					[attributeId]:
						[ attributeValues ]
							.flat()
							.filter(filterNullAndEmptyValues),
				},
			},
		})
			.unwrap()
			.then((res) => {
				dispatch(updateConstraintTableItem(res));
				getTransactions();
			})
			.catch((err) =>
				errorHelper(
					'Ошибка редактирования значения атрибута ограничительной таблицы!',
					err,
					notification,
				),
			)
			.finally(() => setLoading(null));
	};

	return {
		editInfo,
		attributeLoading: loading,
	};
};
