import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RiArrowLeftRightLine } from '@remixicon/react';
import { App, Button, Form, Input, Result, Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import {
	GetConnectionResponse,
	useUpdateConnectionMdmIntegrationsV1ConnectionsConnectionIdPutMutation,
} from 'shared/api/generatedApi/integrationsApi';
import { errorHelper, randomString } from 'shared/helpers';
import { DetailFooter } from 'shared/ui';
import { DeleteExchangeConfig } from '../ExchangeConfiguration/deleteExchangeConfig';

interface IEditIntegrationMainInfoProps {
	isLoading: boolean;
	error: FetchBaseQueryError | SerializedError;
	data: GetConnectionResponse;
}

const EditIntegrationMainInfoUi: React.FC<IEditIntegrationMainInfoProps> = ({
	data,
	error,
	isLoading,
}) => {
	const [form] = Form.useForm();
	const { notification } = App.useApp();

	const { integrationId } = useParams();

	const [updateConnection, { isLoading: updateLoading }] =
		useUpdateConnectionMdmIntegrationsV1ConnectionsConnectionIdPutMutation();

	const onFinish = () => {
		const vals = form.getFieldsValue();
		let err: boolean = false;

		for (const param of data.connection_properties) {
			if (param.mandatory && !vals[param.return_parameter]) {
				errorHelper(
					'Ошибка!',
					new Error('Необходимо заполнить все обязательные поля!'),
					notification
				);
				err = true;
			}
		}

		if (vals.extra) {
			try {
				JSON.parse(vals.extra);
			} catch (e) {
				errorHelper(
					'Ошибка!',
					new Error('Поле "extra" должно содержать данные в формате JSON!'),
					notification
				);
				err = true;
			}
		}

		if (err) return;

		const { name, extra, ...connectionParams } = vals;

		updateConnection({
			connectionId: integrationId,
			connectionUpdateRequest: {
				conn_name: name,
				connection_params: {
					...connectionParams,
					...(connectionParams.port && { port: +connectionParams.port }),
					...(extra && { extra: JSON.parse(extra) }),
				},
			},
		})
			.unwrap()
			.catch((e) =>
				errorHelper('Ошибка при редактировании информации об интеграции!', e, notification)
			);
	};

	useEffect(() => {
		if (data && data.connection_properties.length) {
			form.setFieldValue('name', data.connection_info.name);
			for (const item of data.connection_properties) {
				form.setFieldValue(`${item.return_parameter}`, item.current_value);
			}
		}
	}, [data]);

	return (
		<>
			{isLoading && <Spin />}
			{error ? (
				<Result
					status="error"
					title="Ошибка при получении списка возможных подключений!"
					subTitle={JSON.stringify(error)}
				/>
			) : (
				!isLoading && (
					<Form
						layout="vertical"
						form={form}
						style={{ marginBottom: 54 }}
						onFinish={onFinish}
					>
						<Typography.Title level={2} style={{ marginBottom: 24 }}>
							Общая информация
						</Typography.Title>
						<Form.Item
							label="Наименование подключения"
							rules={[{ required: true, message: 'Это поле обязательно' }]}
							name="name"
						>
							<Input />
						</Form.Item>
						<Typography.Title level={2} style={{ marginBottom: 24 }}>
							Настройки параметров подключения
						</Typography.Title>
						{data.connection_properties.map((param) => (
							<Form.Item
								key={randomString(5)}
								name={param.return_parameter}
								rules={[
									{
										required: param.mandatory,
										message: 'Это поле обязательно',
									},
								]}
								label={param.title || param.return_parameter}
							>
								{param.type === 'json' ? (
									<Input.TextArea rows={5} />
								) : (
									<Input type={param.type === 'integer' ? 'number' : 'text'} />
								)}
							</Form.Item>
						))}

						<Button
							icon={<RiArrowLeftRightLine />}
							style={{ width: '100%' }}
							onClick={onFinish}
							loading={updateLoading}
						>
							Обновить подключение
						</Button>
					</Form>
				)
			)}
			<DetailFooter>
				<DeleteExchangeConfig
					id={[integrationId]}
					disabled={!integrationId}
					fromConfigPage
				/>
			</DetailFooter>
		</>
	);
};

export const EditIntegrationMainInfo = React.memo(EditIntegrationMainInfoUi);
