import { App } from 'antd';
import { createAttributeMapping } from 'entities/catalogs/catalogDeduplication/catalogDeduplication.store';
import {
	CreateDeduplicationAttributeMappingRequest,
	useCreateDeduplicationAttributeMappingMutation,
} from 'shared/api/generatedApi/deduplicationApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';

export const useCreateDeduplicationAttributeMapping = () => {
	const { t } = useTypedTranslation();

	const [fetchCreateMapping, { isLoading: isCreatingMapping }] =
		useCreateDeduplicationAttributeMappingMutation();

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const createMapping = (
		createDeduplicationAttributeMappingRequest: CreateDeduplicationAttributeMappingRequest
	) => {
		fetchCreateMapping({ createDeduplicationAttributeMappingRequest })
			.unwrap()
			.then((res) => {
				dispatch(createAttributeMapping(res));
				notification.success({
					message: t(l => l.catalogs.attributesDeclarations.mappingCreated),
				});
			})
			.catch((err) => errorHelper(t(l => l.common.statuses.error), err, notification));
	};

	return { isCreatingMapping, createMapping };
};
