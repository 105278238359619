import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { changeSearchParam } from '../helpers';

type IsEnumType = Record<string, string | number>
type ChangeParamFn<T> = (param: keyof T | T[keyof T] | undefined | null | void) => void;

/*
* Хук для подключения состояния меняющегося через строку запроса (query string)
* Первым аргументом передается enum-объект *enumObject*
* Начальное состояние берется из переменной строки запроса с именем *searchParamKey*
* Если значение отсутствует - берется значение по умолчанию *defaultValue*
* */
const useEnumStateViaSearchParam = <T extends IsEnumType>(
	enumObject: T,
	searchParamKey: string,
	defaultValue: keyof T,
): [ T[keyof T], ChangeParamFn<T> ] => {
	const [ searchParams, setSearchParams ] = useSearchParams();

	const [ param, setParam ] = useState<T[keyof T] | null>(
		null,
	);

	const changeParam: ChangeParamFn<T> = (param) => {
		setSearchParams(
			changeSearchParam(searchParams, searchParamKey, param)
		);
	};

	useEffect(() => {
		const viewTypeParam = searchParams.get(searchParamKey);

		const viewType = Object
			.values(enumObject)
			.find(x => x === viewTypeParam);

		if (viewTypeParam && !viewType) {
			changeParam();
		}

		setParam(viewType as T[keyof T] ?? defaultValue as T[keyof T]);
	}, [ searchParams.get(searchParamKey) ]);

	return [ param, changeParam ];
};

export {
	useEnumStateViaSearchParam,
};