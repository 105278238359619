import { RiAddCircleLine } from '@remixicon/react';
import { Button } from 'antd';
import React from 'react';
import { useAddCatalogRecord } from './addCatalogRecord.model';

export const AddCatalogRecordUi = () => {
	const { createCatalogRecord } = useAddCatalogRecord();

	const onClick = () => {
		createCatalogRecord();
	};

	return (
		<Button icon={<RiAddCircleLine />} onClick={onClick}>
			Добавить справочную запись
		</Button>
	);
};
