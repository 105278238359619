import { Button, Flex, Table } from 'antd';
import React, { useEffect } from 'react';
import { useTypedTranslation } from 'shared/hooks';
import { AddCopyIcon, CancelCopyIcon } from 'shared/ui';
import { useSuspectedDuplicate } from './SuspectedDuplicate.model';

interface ISuspectedDuplicate {
	id: string;
	closeModal: () => void;
}

export const SuspectedDuplicate = ({ id, closeModal }: ISuspectedDuplicate) => {
	const {
		dataSource,
		columns,
		passSuspectedDuplicateId,
		deleteDuplicatedItems,
		setDuplicatedStatus,
		isDuplicateDeleting,
		isDuplicateSetting,
		isOriginalItemSetting,
	} = useSuspectedDuplicate();

	const { t } = useTypedTranslation();

	const delDuplicat = () => deleteDuplicatedItems(closeModal);

	const setDuplicat = () => setDuplicatedStatus(closeModal);

	useEffect(() => {
		if (id) {
			passSuspectedDuplicateId(id);
		}
	}, [id]);
	return (
		<Flex vertical gap={24}>
			<Flex gap={6}>
				<Button
					icon={<CancelCopyIcon />}
					onClick={delDuplicat}
					loading={isOriginalItemSetting || isDuplicateDeleting}
				>
					{t(l => l.catalogs.deduplication.unionAndDelete)}
				</Button>

				<Button
					icon={<AddCopyIcon />}
					onClick={setDuplicat}
					loading={isOriginalItemSetting || isDuplicateSetting}
				>
					{t(l => l.catalogs.deduplication.setStatus)}
				</Button>
			</Flex>

			<Table
				columns={columns}
				dataSource={dataSource}
				scroll={{ x: 'max-content' }}
				size="small"
			/>
		</Flex>
	);
};
