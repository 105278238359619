import { App } from 'antd';
import { useState } from 'react';
import { useCatalogDeduplication } from 'entities/catalogs/catalogDeduplication';
import { useTransactions } from 'entities/transactions';
import { useDefineOriginalItemMutation } from 'shared/api/generatedApi/deduplicationApi';
import { errorHelper } from 'shared/helpers';

interface ISetOriginalItem {
	suspectedDuplicateId: string;
	newOriginalItemId: string;
}

export const useSetOriginalItem = () => {
	const [isOriginalItemSetting, setIsOriginalItemSetting] = useState<boolean>(false);
	const [fetchSetOriginalItem] = useDefineOriginalItemMutation();
	const { notification } = App.useApp();
	const { getTransactions } = useTransactions();
	const { getSuspected } = useCatalogDeduplication();

	const setOriginalItem = async ({
		suspectedDuplicateId,
		newOriginalItemId,
	}: ISetOriginalItem) => {
		setIsOriginalItemSetting(true);

		await fetchSetOriginalItem({
			id: suspectedDuplicateId,
			defineOriginalItemRequest: { newOriginalItemId },
		})
			.unwrap()
			.then(() => {
				getSuspected();
				notification.success({ message: 'Назначен статус «Оригинал»' });
				getTransactions();
			})
			.catch((err) =>
				errorHelper('Ошибка при установке статуса «Оригинал»', err, notification)
			)
			.finally(() => setIsOriginalItemSetting(false));
	};

	return { setOriginalItem, isOriginalItemSetting };
};
