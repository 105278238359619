import { App } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDeleteConfigurationMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdDeleteMutation } from 'shared/api/generatedApi/integrationsApi';
import { errorHelper } from 'shared/helpers';

export const useDeleteExchangeConfig = () => {
	const [deleteConfig, { isLoading }] =
		useDeleteConfigurationMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdDeleteMutation();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { integrationId } = useParams();
	const { notification } = App.useApp();

	const handleDelete = async (exchangeIds: Array<string>, fromList?: boolean) => {
		await deleteConfig({
			//TODO у
			configurationId: exchangeIds as unknown as string,
			connectionId: integrationId,
		})
			.unwrap()
			.then(() => {
				if (!fromList) {
					const newpath = pathname;
					newpath.split('/').pop();
					navigate(newpath.concat('/'));
				}
			})
			.catch((e) => {
				errorHelper('Ошибка при удалении конфигурации обмена!', e, notification);
			});
	};

	return {
		handleDelete,
		isLoading,
	};
};
