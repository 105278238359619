import { Button, Flex, Modal } from 'antd';
import React from 'react';
import { useDeleteRow } from './deleteRow.model';

interface IDeleteRowModalProps {
	isOpen: boolean;
	onClose: () => void;
	onOk: () => void;
	rowsToDelete: string[];
}

export const DeleteRowModal = ({ isOpen, onClose, rowsToDelete, onOk }: IDeleteRowModalProps) => {
	const { deleteRows, isDeleteLoading } = useDeleteRow();

	const handleOk = () => {
		deleteRows(rowsToDelete).finally(() => {
			onOk();
		});
	};

	const handleCancel = () => {
		onClose();
	};

	return (
		<Modal
			title="Удаление строки ограничительной таблицы"
			onOk={handleOk}
			onCancel={handleCancel}
			open={isOpen}
			footer={[
				<Flex key="back" justify="flex-end" gap={4}>
					<Button type="text" onClick={handleCancel}>
						Отменить
					</Button>
					<Button loading={isDeleteLoading} type="primary" onClick={handleOk}>
						Удалить
					</Button>
				</Flex>,
			]}
		>
			Вы действительно хотите удалить данную строку ограничительной таблицы? Строка
			ограничительной таблицы будет удалена без возможности восстановления
		</Modal>
	);
};
