import { CSSProperties } from 'react';
import { colors } from '../../../styles';

export const styles: CSSProperties = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	color: colors.primary,
	fontWeight: 'bold',
	gap: 5,
};

export const aStyles: CSSProperties = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	color: colors.primary,
	fontWeight: 'bold',
	gap: 5,
};