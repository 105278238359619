import { App } from 'antd';
import { useState } from 'react';
import { pushConstraintTable } from 'entities/metadata/constraintTables';
import { useTransactions } from 'entities/transactions';
import { useCreateRestrictionTableMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useAddConstraintTable = () => {
	const [isLoading, setIsLoading] = useState(false);

	const [createTable] = useCreateRestrictionTableMutation();

	const dispatch = useAppDispatch();

	const { notification } = App.useApp();

	const { getTransactions } = useTransactions();

	const onAddTable = async (tableName: string, tableDescr: string) => {
		setIsLoading(true);
		await createTable({
			createRestrictionTableRequest: {
				displayName: tableName,
				description: tableDescr,
				attributeIds: [],
			},
		})
			.unwrap()
			.then((res) => {
				getTransactions();
				dispatch(pushConstraintTable({ ...res }));
			})
			.catch((err) =>
				errorHelper('Ошибка создания ограничительной таблицы', err, notification)
			);
		setIsLoading(false);
	};

	return {
		onAddTable: onAddTable,
		isLoading,
	};
};
