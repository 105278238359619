import { Layout, Menu } from 'antd';
import React from 'react';
import { useLocation } from 'react-router';
import { navItems } from '../../../config';
import { menuStyles, siderStyles, sidebarItemsStyles } from './sidebarStyles';

interface ISidebarProps {
	collapsed: boolean;
}

const { Sider } = Layout;

export const Sidebar: React.FC<ISidebarProps> = ({ collapsed }) => {
	const location = useLocation();
	const path = location.pathname.split('/')[1];
	return (
		<Sider
			trigger={null}
			collapsible
			collapsed={collapsed}
			defaultCollapsed
			collapsedWidth={55}
			style={siderStyles}
			width={250}
		>
			<Menu
				mode="inline"
				selectedKeys={[`${path}`]}
				style={menuStyles}
				inlineIndent={13}
				items={navItems.map((item) => ({
					...item,
					key: item.title,
					style: sidebarItemsStyles,
				}))}
			/>
		</Sider>
	);
};
