import { DefaultOptionType } from 'antd/es/select';
import React from 'react';
import { ClassifierItemDto } from 'shared/api/generatedApi/mdmgApi';
import { Chip, DropdownSelect } from 'shared/ui';
import { ChipStatusEnum, ChipStatusRuEnum } from 'shared/ui/components/Chip/chipStylehelper';

interface IEditRecordStatus {
	data: ClassifierItemDto;
	onChange: (status: ChipStatusEnum) => void;
}

const statusOptions: Array<DefaultOptionType> = Object.keys(ChipStatusEnum)
	.map((key) => ({
		label: (
			<Chip status={ChipStatusEnum[key]} clearBg>
				{ChipStatusRuEnum[key]}
			</Chip>
		),
		value: ChipStatusEnum[key],
	}))
	.filter((item) => item.value !== ChipStatusEnum.duplicate);

export const EditRecordStatus = ({ data, onChange }: IEditRecordStatus) => {
	return (
		<DropdownSelect
			options={statusOptions}
			value={data.status.toLowerCase()}
			onChange={onChange}
		/>
	);
};
