import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { notification } from 'antd';
import { useState } from 'react';
import { Measure, addMeasuresListItem, editMeasuresList } from 'entities/metadata/measures';
import { useTransactions } from 'entities/transactions';
import { MeasurementGroupResponse } from 'shared/api/generatedApi/mdmgApi';
import {
	useCreateMeasurementGroupMutation,
	useUpdateMeasurementGroupMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';
import { notificationConfig } from 'shared/styles';

export const useEditMeasuresGroup = () => {
	const [error, setError] = useState<FetchBaseQueryError | SerializedError>(null);

	const [api, contextHolder] = notification.useNotification(notificationConfig);
	const dispatch = useAppDispatch();

	const [createGroup, { isLoading: createLoading }] = useCreateMeasurementGroupMutation();
	const [editGroup, { isLoading: editLoading }] = useUpdateMeasurementGroupMutation();
	const { getTransactions } = useTransactions();

	const editMeasureGroup = async (name: string, desc?: string, measure?: Measure) => {
		let currentMeasure: Measure = measure;
		if (!currentMeasure) {
			const create = await createGroup({
				createMeasurementGroupRequest: { displayName: name, description: desc },
			});
			if ('error' in create) {
				setError(create.error);
				errorHelper('Ошибка при создании группы ЕИ', create.error, api);
			}
			if ('data' in create) {
				currentMeasure = {
					...(create.data as unknown as MeasurementGroupResponse),
					childrenMeasures: [],
				};
				dispatch(addMeasuresListItem(currentMeasure));
				getTransactions();
				return;
			}
		} else {
			const edit = await editGroup({
				id: currentMeasure.id,
				updateMeasurementGroupRequest: {
					displayName: name || currentMeasure?.displayName,
					description: desc || currentMeasure?.description,
				},
			});

			if ('error' in edit) {
				setError(edit.error);
				errorHelper('Ошибка при редактировании группы ЕИ', edit.error, api);
			}

			if ('data' in edit) {
				dispatch(
					editMeasuresList({ ...currentMeasure, displayName: name, description: desc })
				);
				getTransactions();
			}
		}
	};

	return {
		editMeasureGroup,
		contextHolder,
		loading: createLoading || editLoading,
		error,
	};
};
