import { App } from 'antd';
import { updateMetaAttributesGroup } from 'entities/metadata/attributesGroups/attributesGroups.store';
import { useTransactions } from 'entities/transactions';
import {
	AttributeGroupDto,
	useUpdateAttributeGroupMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useEditAttributeGroup = () => {
	const { notification } = App.useApp();

	const [useEditAttributeGroup, { isLoading, error }] = useUpdateAttributeGroupMutation();

	const dispatch = useAppDispatch();

	const { getTransactions } = useTransactions();

	const editNewGroup = async (
		displayName: string,
		description: string,
		id: string,
		parentId?: string
	) => {
		await useEditAttributeGroup({
			id,
			updateAttributeGroupRequest: {
				displayName,
				description,
				...(parentId && { parentId }),
			},
		})
			.unwrap()
			.then((res) => {
				getTransactions();
				dispatch(updateMetaAttributesGroup(res as AttributeGroupDto));
			})
			.catch((err) =>
				errorHelper('Ошибка при добавлении новой группы атрибутов!', err, notification)
			);
	};

	return {
		loading: isLoading,
		error,
		editNewGroup,
	};
};
