import { App } from 'antd';
import { useNavigate } from 'react-router';
import {
	addNewClassifierGroup,
	setCurrentClassifier,
} from 'entities/classifiers/classifiersGroups/classifier.store';
import { useCreateClassifierMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useCreateClassifier = () => {
	const [createGroup, { isLoading }] = useCreateClassifierMutation();
	const navigate = useNavigate();
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const create = async () => {
		await createGroup({
			createClassifierRequest: { displayName: '', linkClassifierAttributes: [] },
		})
			.unwrap()
			.then((res) => {
				dispatch(addNewClassifierGroup(res));
				dispatch(setCurrentClassifier(res));
				navigate(`${res.id}`);
			})
			.catch((e) => {
				errorHelper('Ошибка при создании классификатора!', e, notification);
			});
	};

	return {
		create,
		isLoading,
	};
};
