import { RiArrowRightSLine, RiArrowLeftSLine } from '@remixicon/react';
import { Flex, Button, Typography } from 'antd';
import cn from 'classnames';
import React, { PropsWithChildren, ReactNode, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { addSider, removeSider, setSubMenuCollapsed } from '../../index';
import s from './subSider.module.scss';

interface IsubSiderProps {
	title?: string;
	children?: ReactNode;
}

export const SubSider: React.FC<PropsWithChildren<IsubSiderProps>> = ({ title, children }) => {
	const dispatch = useAppDispatch();
	const { subMenuCollapsed, hasSider } = useAppSelector((state) => state.shared.ui);

	const toggler = () => {
		dispatch(setSubMenuCollapsed(!subMenuCollapsed));
	};

	useEffect(() => {
		dispatch(addSider());

		return () => {
			dispatch(removeSider());
		};
	}, []);

	return (
		<div className={cn([s.subSider, hasSider && (subMenuCollapsed ? s.collapsed : s.open)])}>
			<Button onClick={toggler} size="small" className={s.subSiderButton}>
				{subMenuCollapsed ? (
					<RiArrowRightSLine size={16} />
				) : (
					<RiArrowLeftSLine size={16} />
				)}
			</Button>

			<div className={s.subSiderContent}>
				{title && (
					<Typography.Title level={1} style={{ marginBottom: '24px' }}>
						{title}
					</Typography.Title>
				)}
				{children && (
					<Flex gap={'12px'} vertical>
						{children}
					</Flex>
				)}
			</div>
		</div>
	);
};
