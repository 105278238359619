import { App } from 'antd';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useMetaAttributesGroups } from 'entities/metadata/attributesGroups/attributesGroups.model';
import {
	setCurrentAttributeGroup,
	setMetaAttributesGroups,
} from 'entities/metadata/attributesGroups/attributesGroups.store';
import {
	AttributeGroupDto,
	useLazyGetAttributeGroupTreeQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { useLazyGetSearchResultQuery } from 'shared/api/generatedApi/searchApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';
import { useDebounce } from 'shared/hooks/useDebounce';

export const useSearchAttributeGroups = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState<boolean>(false);

	const { metaAttributeGroupId } = useParams();

	const dispatch = useAppDispatch();

	const { findChild } = useMetaAttributesGroups();

	const searchHandler = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 0) {
			searchParams.set('searchParentValue', e.target.value);
			setSearchParams(searchParams);
		} else {
			searchParams.delete('searchParentValue');
			setSearchParams(searchParams);
		}
	}, 1000);

	const [getSearchResult] = useLazyGetSearchResultQuery();
	const [getAttributeGroupWithParentsById] = useLazyGetAttributeGroupTreeQuery();

	const { notification } = App.useApp();

	const addParentInsteadOfChild = (
		arr: AttributeGroupDto[],
		parent: AttributeGroupDto
	): AttributeGroupDto[] =>
		arr.map((catalog) => {
			if (findChild([parent], catalog.id)) {
				return parent;
			} else {
				return catalog;
			}
		});

	const initSearch = () => {
		const searchParentValue = searchParams.get('searchParentValue');
		searchParentValue && searchItems(searchParentValue);
	};

	useEffect(() => {
		initSearch();
	}, [searchParams.get('searchParentValue')]);

	useEffect(() => {
		initSearch();
	}, []);

	const searchItems = (searchParentValue: string) => {
		setLoading(true);
		getSearchResult({
			entityType: 'attribute_group',
			textRequest: searchParentValue,
		})
			.unwrap()
			.then(async (res) => {
				let completeCatalogs = res as AttributeGroupDto[];
				const parentRequests: Promise<void>[] = [];
				const parentIds: string[] = [];
				(res as AttributeGroupDto[]).forEach(async (catalog) => {
					if (catalog.parentId && !parentIds.includes(catalog.parentId)) {
						parentIds.push(catalog.parentId);
						parentRequests.push(
							getAttributeGroupWithParentsById({
								id: catalog.id,
								direction: 'up',
								includeChildren: true,
							})
								.unwrap()
								.then((res) => {
									completeCatalogs = addParentInsteadOfChild(
										completeCatalogs,
										res
									);
								})
								.catch((err) => {
									errorHelper(
										'Ошибка при получении справочников!',
										err,
										notification
									);
								})
						);
					}
				});
				await Promise.all(parentRequests);
				const filterHashMap = {};
				dispatch(
					setMetaAttributesGroups(
						completeCatalogs.filter((catalog) => {
							return filterHashMap[catalog.id]
								? false
								: (filterHashMap[catalog.id] = true);
						})
					)
				);
				if (metaAttributeGroupId) {
					dispatch(
						setCurrentAttributeGroup(findChild(completeCatalogs, metaAttributeGroupId))
					);
				}
				setLoading(false);
			})
			.catch((err) => {
				errorHelper('Ошибка при поиске справочников!', err, notification);
				setLoading(false);
			});
	};

	return {
		searchHandler,
		searchValue: searchParams.get('searchParentValue'),
		loading,
	};
};
