import { Flex, Tabs, TabsProps, Typography } from 'antd';
import React from 'react';
import { useEnumStateViaSearchParam } from 'shared/hooks';

enum ApplicationPageTab {
	Actual = 'Actual',
	All = 'All',
	My = 'My',
	Templates = 'Templates',
}

const tabSearchParamKey = 'tab';

const items: TabsProps['items'] = [
	{
		key: ApplicationPageTab.Actual,
		label: 'Текущие',
		children: <span>Текущие</span>,
	},
	{
		key: ApplicationPageTab.All,
		label: 'Все заявки',
		children: <span>Все заявки</span>,
	},
	{
		key: ApplicationPageTab.My,
		label: 'Мои заявки',
		children: <span>Мои заявки</span>,
	},
	{
		key: ApplicationPageTab.Templates,
		label: 'Шаблоны заявок',
		children: <span>Шаблоны заявок</span>,
	},
];

const ApplicationsMainPage = () => {
	const [ activeKey, setActiveKey ] = useEnumStateViaSearchParam(
		ApplicationPageTab,
		tabSearchParamKey,
		ApplicationPageTab.Actual);

	const onChange = (key: string) => setActiveKey(key as ApplicationPageTab);

	return <>
		<Flex vertical gap={24}>
			<Flex justify="space-between" align="top">
				<Typography.Title level={1}>
					Заявки
				</Typography.Title>
			</Flex>

			<Tabs items={items}
				  destroyInactiveTabPane
				  activeKey={activeKey}
				  onChange={onChange}
			/>
		</Flex>
	</>;
};

export {
	ApplicationsMainPage,
	ApplicationPageTab,
	tabSearchParamKey,
};