import { colors } from '../../cssConstants';

export const tabStyles = {
	titleFontSize: 12,
	inkBarColor: colors.tabUnderline,
	itemSelectedColor: colors.text,
	itemHoverColor: colors.text,
	itemActiveColor: colors.text,
	itemColor: colors.textFaded,
	colorBorderSecondary: colors.grayTableBorder,
};
