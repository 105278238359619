import { transactionServiceApi as api } from './newCreateApiFile';
const injectedRtkApi = api.injectEndpoints({
	endpoints: (build) => ({
		getTransactionData: build.query<GetTransactionDataApiResponse, GetTransactionDataApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/transactions/${queryArg.id}`,
				params: { full: queryArg.full },
			}),
		}),
		updateTransaction: build.mutation<UpdateTransactionApiResponse, UpdateTransactionApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/transactions/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateTransactionRequest,
			}),
		}),
		deleteTransaction: build.mutation<DeleteTransactionApiResponse, DeleteTransactionApiArg>({
			query: (queryArg) => ({ url: `/api/v1/transactions/${queryArg.id}`, method: 'DELETE' }),
		}),
		commitTransaction: build.mutation<CommitTransactionApiResponse, CommitTransactionApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/transactions/commit',
				method: 'POST',
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getTransactionsData: build.query<GetTransactionsDataApiResponse, GetTransactionsDataApiArg>(
			{
				query: (queryArg) => ({
					url: '/api/v1/transactions',
					params: {
						userId: queryArg.userId,
						full: queryArg.full,
						status: queryArg.status,
					},
				}),
			}
		),
		createTransaction: build.mutation<CreateTransactionApiResponse, CreateTransactionApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/transactions',
				method: 'POST',
				body: queryArg.createTransactionRequest,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		createRestrictionTable: build.mutation<
			CreateRestrictionTableApiResponse,
			CreateRestrictionTableApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/restriction-tables',
				method: 'POST',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		deleteRestrictionTable: build.mutation<
			DeleteRestrictionTableApiResponse,
			DeleteRestrictionTableApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/restriction-tables',
				method: 'DELETE',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		updateRestrictionTable: build.mutation<
			UpdateRestrictionTableApiResponse,
			UpdateRestrictionTableApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/restriction-tables',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		createRestrictionTable1: build.mutation<
			CreateRestrictionTable1ApiResponse,
			CreateRestrictionTable1ApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/restriction-table-declarations',
				method: 'POST',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		deleteRestrictionTable1: build.mutation<
			DeleteRestrictionTable1ApiResponse,
			DeleteRestrictionTable1ApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/restriction-table-declarations',
				method: 'DELETE',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		updateRestrictionTable1: build.mutation<
			UpdateRestrictionTable1ApiResponse,
			UpdateRestrictionTable1ApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/restriction-table-declarations',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		convert: build.mutation<ConvertApiResponse, ConvertApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurements/conversion',
				method: 'POST',
				body: queryArg.convertValueRequest,
			}),
		}),
		addCreateEvent: build.mutation<AddCreateEventApiResponse, AddCreateEventApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurements',
				method: 'POST',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addDeleteEvent: build.mutation<AddDeleteEventApiResponse, AddDeleteEventApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurements',
				method: 'DELETE',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addUpdateEvent: build.mutation<AddUpdateEventApiResponse, AddUpdateEventApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurements',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addCreateEvent1: build.mutation<AddCreateEvent1ApiResponse, AddCreateEvent1ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurement-groups',
				method: 'POST',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addDeleteEvent1: build.mutation<AddDeleteEvent1ApiResponse, AddDeleteEvent1ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurement-groups',
				method: 'DELETE',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addUpdateEvent1: build.mutation<AddUpdateEvent1ApiResponse, AddUpdateEvent1ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/measurement-groups',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addCreateEvent2: build.mutation<AddCreateEvent2ApiResponse, AddCreateEvent2ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/items',
				method: 'POST',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addDeleteEvent2: build.mutation<AddDeleteEvent2ApiResponse, AddDeleteEvent2ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/items',
				method: 'DELETE',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addUpdateEvent2: build.mutation<AddUpdateEvent2ApiResponse, AddUpdateEvent2ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/items',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addCreateEvent3: build.mutation<AddCreateEvent3ApiResponse, AddCreateEvent3ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/classifiers',
				method: 'POST',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addDeleteEvent3: build.mutation<AddDeleteEvent3ApiResponse, AddDeleteEvent3ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/classifiers',
				method: 'DELETE',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addUpdateEvent3: build.mutation<AddUpdateEvent3ApiResponse, AddUpdateEvent3ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/classifiers',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addCreateEvent4: build.mutation<AddCreateEvent4ApiResponse, AddCreateEvent4ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/classifier-items',
				method: 'POST',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addDeleteEvent4: build.mutation<AddDeleteEvent4ApiResponse, AddDeleteEvent4ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/classifier-items',
				method: 'DELETE',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addUpdateEvent4: build.mutation<AddUpdateEvent4ApiResponse, AddUpdateEvent4ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/classifier-items',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addCreateEvent5: build.mutation<AddCreateEvent5ApiResponse, AddCreateEvent5ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/classifier-attribute-declarations',
				method: 'POST',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addDeleteEvent5: build.mutation<AddDeleteEvent5ApiResponse, AddDeleteEvent5ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/classifier-attribute-declarations',
				method: 'DELETE',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addUpdateEvent5: build.mutation<AddUpdateEvent5ApiResponse, AddUpdateEvent5ApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/classifier-attribute-declarations',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addItemImportEvent: build.mutation<AddItemImportEventApiResponse, AddItemImportEventApiArg>(
			{
				query: (queryArg) => ({
					url: `/api/v1/catalogs/${queryArg.id}/items/import`,
					method: 'POST',
					body: queryArg.importItemRequest,
					headers: { Authorization: queryArg.authorization },
				}),
			}
		),
		addCreateCatalogGroupEvent: build.mutation<
			AddCreateCatalogGroupEventApiResponse,
			AddCreateCatalogGroupEventApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/catalogs',
				method: 'POST',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addDeleteCatalogGroupEvent: build.mutation<
			AddDeleteCatalogGroupEventApiResponse,
			AddDeleteCatalogGroupEventApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/catalogs',
				method: 'DELETE',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addUpdateCatalogGroupEvent: build.mutation<
			AddUpdateCatalogGroupEventApiResponse,
			AddUpdateCatalogGroupEventApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/catalogs',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addCreateAttributeGroupEvent: build.mutation<
			AddCreateAttributeGroupEventApiResponse,
			AddCreateAttributeGroupEventApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/attributes',
				method: 'POST',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addDeleteAttributeGroupEvent: build.mutation<
			AddDeleteAttributeGroupEventApiResponse,
			AddDeleteAttributeGroupEventApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/attributes',
				method: 'DELETE',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addUpdateAttributeGroupEvent: build.mutation<
			AddUpdateAttributeGroupEventApiResponse,
			AddUpdateAttributeGroupEventApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/attributes',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addCreateAttributeGroupEvent1: build.mutation<
			AddCreateAttributeGroupEvent1ApiResponse,
			AddCreateAttributeGroupEvent1ApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/attribute-groups',
				method: 'POST',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addDeleteAttributeGroupEvent1: build.mutation<
			AddDeleteAttributeGroupEvent1ApiResponse,
			AddDeleteAttributeGroupEvent1ApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/attribute-groups',
				method: 'DELETE',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addUpdateAttributeGroupEvent1: build.mutation<
			AddUpdateAttributeGroupEvent1ApiResponse,
			AddUpdateAttributeGroupEvent1ApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/attribute-groups',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addCreateAttributeDeclarationGroupEvent: build.mutation<
			AddCreateAttributeDeclarationGroupEventApiResponse,
			AddCreateAttributeDeclarationGroupEventApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/attribute-declarations',
				method: 'POST',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addDeleteAttributeDeclarationGroupEvent: build.mutation<
			AddDeleteAttributeDeclarationGroupEventApiResponse,
			AddDeleteAttributeDeclarationGroupEventApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/attribute-declarations',
				method: 'DELETE',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addUpdateAttributeDeclarationGroupEvent: build.mutation<
			AddUpdateAttributeDeclarationGroupEventApiResponse,
			AddUpdateAttributeDeclarationGroupEventApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/attribute-declarations',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		updateRestrictionTableAttributeValue: build.mutation<
			UpdateRestrictionTableAttributeValueApiResponse,
			UpdateRestrictionTableAttributeValueApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/restriction-tables/attribute-values',
				method: 'PATCH',
				body: queryArg.body,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getEventsByEntityId: build.query<GetEventsByEntityIdApiResponse, GetEventsByEntityIdApiArg>(
			{
				query: (queryArg) => ({
					url: `/api/v1/transactions/${queryArg.id}/events`,
					params: {
						limit: queryArg.limit,
						page: queryArg.page,
						sortBy: queryArg.sortBy,
						sortType: queryArg.sortType,
						entityIds: queryArg.entityIds,
						entityTypes: queryArg.entityTypes,
					},
				}),
			}
		),
		getBlockingEntities: build.query<GetBlockingEntitiesApiResponse, GetBlockingEntitiesApiArg>(
			{
				query: (queryArg) => ({
					url: '/api/v1/transactions/validation',
					headers: { Authorization: queryArg.authorization },
				}),
			}
		),
		getBlockedEntities: build.query<GetBlockedEntitiesApiResponse, GetBlockedEntitiesApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/transactions/locks',
				params: {
					parentEntityId: queryArg.parentEntityId,
					entityType: queryArg.entityType,
				},
			}),
		}),
		removeLastEvent: build.mutation<RemoveLastEventApiResponse, RemoveLastEventApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/transactions/events',
				method: 'DELETE',
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		addDeleteDuplicateItemEvent: build.mutation<
			AddDeleteDuplicateItemEventApiResponse,
			AddDeleteDuplicateItemEventApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/duplicate-items',
				method: 'DELETE',
				headers: { Authorization: queryArg.authorization },
				params: { ids: queryArg.ids },
			}),
		}),
	}),
	overrideExisting: false,
});
export { injectedRtkApi as transactionServiceApi };
export type GetTransactionDataApiResponse = /** status 200 OK */ TransactionResponse;
export type GetTransactionDataApiArg = {
	id: string;
	full?: boolean;
};
export type UpdateTransactionApiResponse = /** status 200 OK */ TransactionResponse;
export type UpdateTransactionApiArg = {
	id: string;
	updateTransactionRequest: UpdateTransactionRequest;
};
export type DeleteTransactionApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteTransactionApiArg = {
	id: string;
};
export type CommitTransactionApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type CommitTransactionApiArg = {
	authorization?: string;
};
export type GetTransactionsDataApiResponse = /** status 200 OK */ TransactionResponse[];
export type GetTransactionsDataApiArg = {
	userId?: string;
	full?: boolean;
	status?: ('ACTIVE' | 'PAUSED' | 'COMMITED')[];
};
export type CreateTransactionApiResponse = /** status 200 OK */ TransactionResponse;
export type CreateTransactionApiArg = {
	authorization?: string;
	createTransactionRequest: CreateTransactionRequest;
};
export type CreateRestrictionTableApiResponse = /** status 200 OK */ CreateEventResponse;
export type CreateRestrictionTableApiArg = {
	authorization: string;
	body: CreateRestrictionTableRequest[];
};
export type DeleteRestrictionTableApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteRestrictionTableApiArg = {
	authorization: string;
	body: DeleteEntityRequest[];
};
export type UpdateRestrictionTableApiResponse = /** status 200 OK */ CreateEventResponse;
export type UpdateRestrictionTableApiArg = {
	authorization: string;
	body: UpdateRestrictionTableRequest[];
};
export type CreateRestrictionTable1ApiResponse = /** status 200 OK */ CreateEventResponse;
export type CreateRestrictionTable1ApiArg = {
	authorization: string;
	body: CreateRestrictionTableDeclarationRequest[];
};
export type DeleteRestrictionTable1ApiResponse = /** status 200 OK */ CreateEventResponse;
export type DeleteRestrictionTable1ApiArg = {
	authorization: string;
	body: DeleteEntityRequest[];
};
export type UpdateRestrictionTable1ApiResponse = /** status 200 OK */ CreateEventResponse;
export type UpdateRestrictionTable1ApiArg = {
	authorization: string;
	body: UpdateRestrictionTableDeclarationRequest[];
};
export type ConvertApiResponse = /** status 200 OK */ ConvertValueResponse;
export type ConvertApiArg = {
	convertValueRequest: ConvertValueRequest;
};
export type AddCreateEventApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddCreateEventApiArg = {
	authorization: string;
	body: CreateMeasurementRequest[];
};
export type AddDeleteEventApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type AddDeleteEventApiArg = {
	authorization: string;
	body: DeleteEntityRequest[];
};
export type AddUpdateEventApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddUpdateEventApiArg = {
	authorization: string;
	body: UpdateMeasurementRequest[];
};
export type AddCreateEvent1ApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddCreateEvent1ApiArg = {
	authorization: string;
	body: CreateMeasurementGroupRequest[];
};
export type AddDeleteEvent1ApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type AddDeleteEvent1ApiArg = {
	authorization: string;
	body: DeleteEntityRequest[];
};
export type AddUpdateEvent1ApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddUpdateEvent1ApiArg = {
	authorization: string;
	body: UpdateMeasurementGroupRequest[];
};
export type AddCreateEvent2ApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddCreateEvent2ApiArg = {
	authorization: string;
	body: CreateItemRequest[];
};
export type AddDeleteEvent2ApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type AddDeleteEvent2ApiArg = {
	authorization: string;
	body: DeleteEntityRequest[];
};
export type AddUpdateEvent2ApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddUpdateEvent2ApiArg = {
	authorization: string;
	body: UpdateItemRequest[];
};
export type AddCreateEvent3ApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddCreateEvent3ApiArg = {
	authorization: string;
	body: CreateClassifierRequest[];
};
export type AddDeleteEvent3ApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type AddDeleteEvent3ApiArg = {
	authorization: string;
	body: DeleteEntityRequest[];
};
export type AddUpdateEvent3ApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddUpdateEvent3ApiArg = {
	authorization: string;
	body: UpdateClassifierRequest[];
};
export type AddCreateEvent4ApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddCreateEvent4ApiArg = {
	authorization: string;
	body: CreateClassifierItemsRequest[];
};
export type AddDeleteEvent4ApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type AddDeleteEvent4ApiArg = {
	authorization: string;
	body: DeleteEntityRequest[];
};
export type AddUpdateEvent4ApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddUpdateEvent4ApiArg = {
	authorization: string;
	body: UpdateClassifierItemsRequest[];
};
export type AddCreateEvent5ApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddCreateEvent5ApiArg = {
	authorization: string;
	body: CreateClassifierAttributeDeclarationRequest[];
};
export type AddDeleteEvent5ApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type AddDeleteEvent5ApiArg = {
	authorization: string;
	body: DeleteEntityRequest[];
};
export type AddUpdateEvent5ApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddUpdateEvent5ApiArg = {
	authorization: string;
	body: UpdateClassifierAttributeDeclarationRequest[];
};
export type AddItemImportEventApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddItemImportEventApiArg = {
	authorization: string;
	id: string;
	importItemRequest: ImportItemRequest;
};
export type AddCreateCatalogGroupEventApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddCreateCatalogGroupEventApiArg = {
	authorization: string;
	body: CreateCatalogRequest[];
};
export type AddDeleteCatalogGroupEventApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type AddDeleteCatalogGroupEventApiArg = {
	authorization: string;
	body: DeleteEntityRequest[];
};
export type AddUpdateCatalogGroupEventApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddUpdateCatalogGroupEventApiArg = {
	authorization: string;
	body: UpdateCatalogRequest[];
};
export type AddCreateAttributeGroupEventApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddCreateAttributeGroupEventApiArg = {
	authorization: string;
	body: CreateAttributeRequest[];
};
export type AddDeleteAttributeGroupEventApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type AddDeleteAttributeGroupEventApiArg = {
	authorization: string;
	body: DeleteEntityRequest[];
};
export type AddUpdateAttributeGroupEventApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddUpdateAttributeGroupEventApiArg = {
	authorization: string;
	body: UpdateAttributeRequest[];
};
export type AddCreateAttributeGroupEvent1ApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddCreateAttributeGroupEvent1ApiArg = {
	authorization: string;
	body: CreateAttributeGroupRequest[];
};
export type AddDeleteAttributeGroupEvent1ApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type AddDeleteAttributeGroupEvent1ApiArg = {
	authorization: string;
	body: DeleteEntityRequest[];
};
export type AddUpdateAttributeGroupEvent1ApiResponse = /** status 200 OK */ CreateEventResponse;
export type AddUpdateAttributeGroupEvent1ApiArg = {
	authorization: string;
	body: UpdateAttributeGroupRequest[];
};
export type AddCreateAttributeDeclarationGroupEventApiResponse =
	/** status 200 OK */ CreateEventResponse;
export type AddCreateAttributeDeclarationGroupEventApiArg = {
	authorization: string;
	body: CreateAttributeDeclarationRequest[];
};
export type AddDeleteAttributeDeclarationGroupEventApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type AddDeleteAttributeDeclarationGroupEventApiArg = {
	authorization: string;
	body: DeleteEntityRequest[];
};
export type AddUpdateAttributeDeclarationGroupEventApiResponse =
	/** status 200 OK */ CreateEventResponse;
export type AddUpdateAttributeDeclarationGroupEventApiArg = {
	authorization: string;
	body: UpdateAttributeDeclarationRequest[];
};
export type UpdateRestrictionTableAttributeValueApiResponse =
	/** status 200 OK */ CreateEventResponse;
export type UpdateRestrictionTableAttributeValueApiArg = {
	authorization: string;
	body: UpdateAttributeValueRequest[];
};
export type GetEventsByEntityIdApiResponse = /** status 200 OK */ SliceStateChangeEventDto;
export type GetEventsByEntityIdApiArg = {
	id: string;
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: string;
	entityIds?: string[];
	entityTypes?: string[];
};
export type GetBlockingEntitiesApiResponse = /** status 200 OK */ BlockingEventResponse[];
export type GetBlockingEntitiesApiArg = {
	authorization: string;
};
export type GetBlockedEntitiesApiResponse = /** status 200 OK */ BlockedEntityResponse[];
export type GetBlockedEntitiesApiArg = {
	parentEntityId: string;
	entityType:
		| 'CATALOG'
		| 'ATTRIBUTE'
		| 'ATTRIBUTE_GROUP'
		| 'ATTRIBUTE_DECLARATION'
		| 'EXCHANGE_CLASS'
		| 'EXCHANGE_KEY'
		| 'ITEM'
		| 'MEASUREMENT'
		| 'MEASUREMENT_GROUP'
		| 'CLASSIFIER'
		| 'CLASSIFIER_ATTRIBUTE_DECLARATION'
		| 'CLASSIFIER_ITEM'
		| 'RESTRICTION_TABLE_DECLARATION'
		| 'RESTRICTION_TABLE'
		| 'RESTRICTION_TABLE_ITEM'
		| 'FORMULA';
};
export type RemoveLastEventApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type RemoveLastEventApiArg = {
	authorization: string;
};
export type AddDeleteDuplicateItemEventApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type AddDeleteDuplicateItemEventApiArg = {
	authorization: string;
	ids: string[];
};
export type TransactionDto = {
	id: string;
	transactionStatus?: 'ACTIVE' | 'PAUSED' | 'COMMITED';
	userId: string;
	displayName?: string;
	description?: string;
	createdAt?: string;
	updatedAt?: string;
	committedAt?: string;
};
export type StateChangeEventDto = {
	id: string;
	entityId: string;
	entityType:
		| 'CATALOG'
		| 'ATTRIBUTE'
		| 'ATTRIBUTE_GROUP'
		| 'ATTRIBUTE_DECLARATION'
		| 'EXCHANGE_CLASS'
		| 'EXCHANGE_KEY'
		| 'ITEM'
		| 'MEASUREMENT'
		| 'MEASUREMENT_GROUP'
		| 'CLASSIFIER'
		| 'CLASSIFIER_ATTRIBUTE_DECLARATION'
		| 'CLASSIFIER_ITEM'
		| 'RESTRICTION_TABLE_DECLARATION'
		| 'RESTRICTION_TABLE'
		| 'RESTRICTION_TABLE_ITEM'
		| 'FORMULA';
	eventType:
		| 'CREATE'
		| 'UPDATE'
		| 'DELETE'
		| 'ROLLBACK_CREATE'
		| 'ROLLBACK_UPDATE'
		| 'ROLLBACK_DELETE';
	userId: string;
	parentEventId?: string;
	transactionId: string;
	data: {
		[key: string]: object;
	};
	createdAt: string;
	committedAt?: string;
};
export type TransactionResponse = {
	transaction: TransactionDto;
	events?: StateChangeEventDto[];
};
export type UpdateTransactionRequest = {
	displayName?: string;
	description?: string;
	transactionStatus?: 'ACTIVE' | 'PAUSED' | 'COMMITED';
	userId?: string;
};
export type CreateTransactionRequest = {
	displayName: string;
	description?: string;
};
export type BlockingEventResponse = {
	entityId: string;
	notification?: string;
};
export type CreateEventResponse = {
	persistedEvents: StateChangeEventDto[];
	blockedEvents: BlockingEventResponse[];
};
export type CreateRestrictionTableRequest = {
	displayName: string;
	description?: string;
	attributeIds: string[];
	attributeValues: {
		[key: string]: object;
	}[];
};
export type DeleteEntityRequest = {
	id: string;
};
export type UpdateRestrictionTableRequest = {
	id: string;
	displayName?: string;
	description?: string;
	newAttributeIds?: string[];
	deletedAttributeIds?: string[];
	attributeValues: {
		[key: string]: object;
	}[];
};
export type CreateRestrictionTableDeclarationRequest = {
	required: boolean;
	generationValues: boolean;
	restrictionTableId: string;
	catalogId: string;
};
export type UpdateRestrictionTableDeclarationRequest = {
	id: string;
	required?: boolean;
	generationValues?: boolean;
};
export type ConvertValueResponse = {
	convertedValue?: object;
	message?: string;
};
export type ConvertValueRequest = {
	value: object;
	fromMeasurementId: string;
	toMeasurementId: string;
};
export type CreateMeasurementRequest = {
	displayName: string;
	shortName?: string;
	isDefault: boolean;
	measurementGroupId: string;
	formula?: string;
	inverseFormula?: string;
	coefficient?: number;
};
export type UpdateMeasurementRequest = {
	id: string;
	displayName: string;
	shortName?: string;
	isDefault: boolean;
	measurementGroupId: string;
	formula?: string;
	inverseFormula?: string;
	coefficient?: number;
};
export type CreateMeasurementGroupRequest = {
	displayName: string;
	description?: string;
};
export type UpdateMeasurementGroupRequest = {
	id: string;
	displayName: string;
	description?: string;
};
export type CreateItemRequest = {
	catalogId: string;
	values: {
		[key: string]: object;
	};
	status: 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';
};
export type UpdateItemRequest = {
	id: string;
	catalogId: string;
	values: {
		[key: string]: object;
	};
	status?: 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';
};
export type CreateClassifierRequest = {
	displayName: string;
	description?: string;
	groupId?: string;
	linkClassifierAttributes?: string[];
	createFormulaId?: string;
	updateFormulaId?: string;
	displayNameAttributeDeclarationId?: string;
};
export type UpdateClassifierRequest = {
	id: string;
	displayName: string;
	description?: string;
	groupId?: string;
	linkClassifierAttributes?: string[];
	createFormulaId?: string;
	updateFormulaId?: string;
	displayNameAttributeDeclarationId?: string;
};
export type CreateClassifierItemsRequest = {
	classifierId: string;
	description?: string;
	displayName?: string;
	status: 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';
	parentItemId?: string;
	values: {
		[key: string]: object;
	};
};
export type UpdateClassifierItemsRequest = {
	id: string;
	classifierId: string;
	displayName?: string;
	status?: 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';
	description?: string;
	parentItemId?: string;
	values: {
		[key: string]: object;
	};
};
export type CreateClassifierAttributeDeclarationRequest = {
	classifierId: string;
	attributeId: string;
	isCopy: boolean;
	copyClassifierAttributeDeclarationId?: string;
	inherited: boolean;
	measurementId?: string;
	restrictions: {
		[key: string]: object;
	};
};
export type UpdateClassifierAttributeDeclarationRequest = {
	id: string;
	classifierId: string;
	attributeId: string;
	inherited: boolean;
	measurementId?: string;
	restrictions?: {
		[key: string]: object;
	};
};
export type ImportItemRequest = {
	content: string;
};
export type CreateCatalogRequest = {
	displayName: string;
	description?: string;
	parentId?: string;
	createFormulaId?: string;
	updateFormulaId?: string;
	displayNameAttributeDeclarationId?: string;
};
export type UpdateCatalogRequest = {
	id: string;
	displayName: string;
	description?: string;
	createFormulaId?: string;
	updateFormulaId?: string;
	displayNameAttributeDeclarationId?: string;
};
export type CreateAttributeRequest = {
	displayName: string;
	description?: string;
	type:
		| 'STRING'
		| 'FLOAT'
		| 'INTEGER'
		| 'BOOLEAN'
		| 'IMAGE'
		| 'TEXT'
		| 'DATE_TIME'
		| 'COLOR'
		| 'FILE'
		| 'FORMULA'
		| 'RELATION';
	list: boolean;
	associatedAttributeIds?: string[];
	attributeGroupId: string;
	measurementGroupId?: string;
	restrictions: {
		[key: string]: object;
	};
};
export type UpdateAttributeRequest = {
	id: string;
	displayName: string;
	description?: string;
	type:
		| 'STRING'
		| 'FLOAT'
		| 'INTEGER'
		| 'BOOLEAN'
		| 'IMAGE'
		| 'TEXT'
		| 'DATE_TIME'
		| 'COLOR'
		| 'FILE'
		| 'FORMULA'
		| 'RELATION';
	list: boolean;
	associatedAttributeIds?: string[];
	attributeGroupId: string;
	measurementGroupId?: string;
	restrictions?: {
		[key: string]: object;
	};
};
export type CreateAttributeGroupRequest = {
	displayName: string;
	description?: string;
	parentId?: string;
};
export type UpdateAttributeGroupRequest = {
	id: string;
	displayName: string;
	description?: string;
	parentId?: string;
};
export type CreateAttributeDeclarationRequest = {
	catalogId: string;
	attributeId: string;
	isCopy: boolean;
	copyAttributeDeclarationId?: string;
	inherited: boolean;
	measurementId?: string;
	restrictions: {
		[key: string]: object;
	};
};
export type UpdateAttributeDeclarationRequest = {
	id: string;
	catalogId: string;
	attributeId: string;
	inherited: boolean;
	measurementId?: string;
	restrictions?: {
		[key: string]: object;
	};
};
export type UpdateAttributeValueRequest = {
	restrictionTableId: string;
	value: {
		[key: string]: object;
	};
};
export type SliceMeta = {
	page: number;
	limit: number;
	total: number;
	sortBy: string;
	sortType: string;
};
export type SliceStateChangeEventDto = {
	data: StateChangeEventDto[];
	meta: SliceMeta;
};
export type BlockedEntityResponse = {
	transactionId: string;
	entityIds: string[];
};
export const {
	useGetTransactionDataQuery,
	useLazyGetTransactionDataQuery,
	useUpdateTransactionMutation,
	useDeleteTransactionMutation,
	useCommitTransactionMutation,
	useGetTransactionsDataQuery,
	useLazyGetTransactionsDataQuery,
	useCreateTransactionMutation,
	useCreateRestrictionTableMutation,
	useDeleteRestrictionTableMutation,
	useUpdateRestrictionTableMutation,
	useCreateRestrictionTable1Mutation,
	useDeleteRestrictionTable1Mutation,
	useUpdateRestrictionTable1Mutation,
	useConvertMutation,
	useAddCreateEventMutation,
	useAddDeleteEventMutation,
	useAddUpdateEventMutation,
	useAddCreateEvent1Mutation,
	useAddDeleteEvent1Mutation,
	useAddUpdateEvent1Mutation,
	useAddCreateEvent2Mutation,
	useAddDeleteEvent2Mutation,
	useAddUpdateEvent2Mutation,
	useAddCreateEvent3Mutation,
	useAddDeleteEvent3Mutation,
	useAddUpdateEvent3Mutation,
	useAddCreateEvent4Mutation,
	useAddDeleteEvent4Mutation,
	useAddUpdateEvent4Mutation,
	useAddCreateEvent5Mutation,
	useAddDeleteEvent5Mutation,
	useAddUpdateEvent5Mutation,
	useAddItemImportEventMutation,
	useAddCreateCatalogGroupEventMutation,
	useAddDeleteCatalogGroupEventMutation,
	useAddUpdateCatalogGroupEventMutation,
	useAddCreateAttributeGroupEventMutation,
	useAddDeleteAttributeGroupEventMutation,
	useAddUpdateAttributeGroupEventMutation,
	useAddCreateAttributeGroupEvent1Mutation,
	useAddDeleteAttributeGroupEvent1Mutation,
	useAddUpdateAttributeGroupEvent1Mutation,
	useAddCreateAttributeDeclarationGroupEventMutation,
	useAddDeleteAttributeDeclarationGroupEventMutation,
	useAddUpdateAttributeDeclarationGroupEventMutation,
	useUpdateRestrictionTableAttributeValueMutation,
	useGetEventsByEntityIdQuery,
	useLazyGetEventsByEntityIdQuery,
	useGetBlockingEntitiesQuery,
	useLazyGetBlockingEntitiesQuery,
	useGetBlockedEntitiesQuery,
	useLazyGetBlockedEntitiesQuery,
	useRemoveLastEventMutation,
	useAddDeleteDuplicateItemEventMutation,
} = injectedRtkApi;
