import { RiDownloadLine } from '@remixicon/react';
import { Button, Checkbox, Flex, Modal } from 'antd';
import React, { useMemo } from 'react';
import './exportCatalogRecord.css';
import { randomString } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks';
import { Search } from 'shared/ui';
import { useExportCatalogRecord } from './exportCatalogRecord.model';

export const ExportCatalogRecordUi = () => {
	const { t } = useTypedTranslation();

	const {
		isModalOpen,
		openModal,
		closeModal,
		catalogRecordsColumnsWithChecked,
		isAllChecked,
		toggleCheck,
		updateColumnCheckedState,
		searchColumn,
		searchColumnsCatalogRecord,
		exportItems,
	} = useExportCatalogRecord();

	const handleOk = async () => {
		exportItems();
		closeModal();
	};

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={closeModal} type="text">
					{t(l => l.common.buttons.cancel)}
				</Button>

				<Button
					key="submit"
					type="primary"
					onClick={handleOk}
					disabled={
						catalogRecordsColumnsWithChecked.find((a) => a.checked === true)
							? false
							: true
					}
					// loading={isOriginalItemSetting}
				>
					{t(l => l.catalogs.export.exportCatalogBtn)}
				</Button>
			</Flex>
		),
		[handleOk, closeModal]
	);

	return (
		<>
			<Button icon={<RiDownloadLine />} onClick={openModal}>
				{t(l => l.catalogs.export.exportCatalogBtn)}
			</Button>
			<Modal
				title={t(l => l.catalogs.export.exportCatalogTitle)}
				open={isModalOpen}
				closable={false}
				// onOk={handleOk}
				onCancel={closeModal}
				width={'calc(100vw - 1106px)'}
				footer={footerButtons}
				styles={{ body: { height: 334 } }}
			>
				<div className="modalTitle">
					{t(l => l.catalogs.export.exportCatalogTooltip)}
				</div>
				<Search
					onChange={searchColumn}
					//defaultValue={searchValue}
				/>

				<Flex vertical gap={12} className="modalContent">
					<Checkbox checked={isAllChecked} onChange={toggleCheck}>
						{t(l => l.common.buttons.selectAll)}
					</Checkbox>
					{(searchColumnsCatalogRecord.length > 0
						? searchColumnsCatalogRecord
						: catalogRecordsColumnsWithChecked
					).map((column, n) => {
						if (typeof column.title === 'string') {
							return (
								<Checkbox
									key={n}
									checked={column.checked}
									onChange={() =>
										updateColumnCheckedState(!column.checked, column.key)
									}
								>
									{column.title}
								</Checkbox>
							);
						}
					})}
				</Flex>
			</Modal>
		</>
	);
};
