import { Localization } from './types';

const localization: Localization = {
	common: {
		buttons: {
			confirm: 'Подтвердить',
			cancel: 'Отменить',
			back: 'Назад',
			delete: 'Удалить',
			edit: 'Редактировать',
			columnsSetup: 'Настройка колонок',
			create: 'Создать',
			selectAll: 'Выбрать все',
		},
		statuses: {
			normalized: 'Нормализована',
			nonNormalized: 'Не нормализована',
			blocked: 'Заблокирована',
			deleted: 'Удалена',
			dupe: 'Дубликат',
			error: 'Ошибка',
		},
		defaultNames: {
			emptyName: '<Название не заполнено>',
			status: 'Статус',
			name: 'Название',
			type: 'Тип',
			isMulti: 'Множественный',
		},
		search: {
			nothingFound: 'По вашему запросу ничего не найдено',
			checkRequest: 'Проверьте, правильно ли введен ваш запрос, или попробуйте его изменить',
		},
		inputs: {
			emptyValueLabel: 'Отсутствует',
			trueValueLabel: 'Правда',
			falseValueLabel: 'Ложь',
		},
		tables: {
			total: 'Всего'
		}
	},
	catalogs: {
		mainInfo: 'Общая информация',
		catalogs: 'Справочники',
		catalog: 'Справочник',
		catalogInfo: 'Сведения о справочнике',
		attributesDeclarations: {
			attributesDeclarationsErr: 'Ошибка при получении списка деклараций!',
			addAttribute: 'Добавить атрибут',
			addAttributeDeclModal: 'Для добавления атрибута необходимо выбрать атрибут из списка',
			mappingCreated: 'Маппинг создан',
			mappingUpdated: 'Маппинг обновлен',
			mappingDeleted: 'Маппинг удален',
		},
		deduplication: {
			main: 'Дедубликация позиций',
			dupesGroupsErr: 'Ошибка при получении групп дубликатов',
			dupesSearchResult: 'Результаты поиска дубликатов',
			deleteDupesForSelectedItems: 'Удалить дубликаты для выбранных позиций',
			makeDupesForSelectedItems: 'Установить статус «Дубликат» для выбранных позиций',
			searchSaved: 'Вариант поиска сохранен',
			searchCompleted: 'Вариант поиска произведен',
			updateSearchErr: 'Ошибка обновления варианта поиска дубликатов',
			importSearch: 'Поиск при импорте',
			addCatalog: 'Добавление справочника',
			attributesList: 'Атрибуты, по которым осуществляется поиск дубликатов',
			addAttribute: 'Атрибуты, по которым осуществляется поиск дубликатов',
			selectAttribute: 'Для добавления атрибута необходимо выбрать атрибут из списка',
			startDupeSearch: 'Запустить поиск',
			dupeSearchResult: 'Просмотреть результаты поиска дубликатов',
			variantN: 'Вариант №',
			relaunchSearch: 'Перезапустить поиск',
			addNewVariant: 'Добавить вариант поиска дубликатов',
			isOriginal: 'Является оригиналом',
			setAsOriginal: 'Принять за оригинал',
			position: 'Позиция',
			source: 'Источник',
			unionAndDelete: 'Объединить и удалить дубликаты',
			setStatus: 'Установить статус «Дубликат»',
			deleteDupes: 'Удалить дубликаты для позиции',
			deleteSearchOption: 'Удалить',
		},
		groups: {
			noGroups: 'Нет справочных групп',
			selectGroup: 'Нет справочных групп',
			currCatalogErr: 'Ошибка получения текущего справочника',
			catalogErr: 'Ошибка получения справочника',
			rootGroupErr: 'Ошибка при получении корневой группы справочников!',
			listErr: 'Ошибка при получении корневой группы справочников!',
			noGroupErr: 'Вы не добавили еще ни одной справочной группы',
			createNewGroup:
				'Для создания новой группы нажмите на кнопку «Добавить справочную группу»',
			createNestedGroup: 'Добавить вложенный справочник',
			addNewCatalog: 'Добавить новый справочник',
		},
		records: {
			recordsValues: 'Значения атрибутов',
			attributesSetup: 'Настройка атрибутов',
			editRecord: 'Редактирование записи',
			recordCard: 'Карточка позиции справочника',
			recordsRelationsErr: 'Ошибка получения записей для атрибута типа "Связь"!',
			recordsListErr: 'Ошибка при получении списка записей!',
			declarationsListErr: 'Ошибка получения деклараций',
			noRecords: 'Справочник не содержит позиций',
			addRecordTooltip:
				'Для добавления новой позиции нажмите на кнопку «Добавить справочную запись»',
		},
		export: {
			exportCatalogTitle: 'Экспорт в Excel',
			exportCatalogTooltip: 'Для экспорта справочных записей в Excel Вам необходимо выбрать экспортируемые колонки',
			exportCatalogBtn: 'Экспортировать',
			fetchErr: 'Ошибка получения данных для экспорта',
		},
		restrictions: {
			restrictions: 'Ограничения',
			restrictionsTableErr: 'Ошибка редактирования таблицы допустимых значений',
			declarationsTableErr: 'Ошибка редактирования таблицы допустимых значений',
			addTable: 'Добавление таблиц допустимых значений',
			noTableErr: 'Вы ещё не создали ни одной ограничительной таблицы',
		},
	},
};

export default localization;
