import {
	RiFileCopyLine,
	RiCloseCircleLine,
	RiDeleteBinLine,
	RiListSettingsLine,
	RiAddCircleLine,
	RiFileEditLine,
	RiDownloadLine,
	RiUploadLine,
} from '@remixicon/react';
import { Button, Flex, Table, TableProps } from 'antd';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { routes } from 'shared/config';
import { DetailFooter, DropdownLink, Search } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';

const ExchangeKeysUi: React.FC = () => {
	const navigate = useNavigate();
	const data = [
		{
			key: '1',
			externalId: 'f8694061-4885-11ed-9415-000c2922ad3b',
			internalId: '85e5037c-b394-4966-9b7f-3aaeecf26a62',
			referencePosition: 'АО «Ромашка»',
			menu: (
				<ItemActions
					items={[
						{
							key: '0',
							label: (
								<DropdownLink
									icon={<RiFileCopyLine size={16} />}
									title="Скопировать ссылку на позицию"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
						{
							key: '1',
							label: (
								<DropdownLink
									icon={<RiCloseCircleLine size={16} />}
									title="Отклонить позицию"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
						{
							key: '2',
							label: (
								<DropdownLink
									icon={<RiDeleteBinLine size={16} />}
									title="Удалить"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
					]}
				/>
			),
		},
		{
			key: '1',
			externalId: 'f8694061-4885-11ed-9415-000c2922ad3b',
			internalId: '85e5037c-b394-4966-9b7f-3aaeecf26a62',
			referencePosition: 'АО «Ромашка»',
			menu: (
				<ItemActions
					items={[
						{
							key: '0',
							label: (
								<DropdownLink
									icon={<RiFileCopyLine size={16} />}
									title="Скопировать ссылку на позицию"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
						{
							key: '1',
							label: (
								<DropdownLink
									icon={<RiCloseCircleLine size={16} />}
									title="Отклонить позицию"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
						{
							key: '2',
							label: (
								<DropdownLink
									icon={<RiDeleteBinLine size={16} />}
									title="Удалить"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
					]}
				/>
			),
		},
	];
	const columns: TableProps['columns'] = [
		{
			title: 'Внешний идентификатор',
			dataIndex: 'externalId',
			key: 'externalId',
		},
		{
			title: 'Внутренний идентификатор',
			dataIndex: 'internalId',
			key: 'internalId',
		},

		{
			title: 'Позиция',
			dataIndex: 'referencePosition',
			key: 'referencePosition',
		},

		{
			title: <RiListSettingsLine size={20} />,
			dataIndex: 'menu',
			key: 'menu',
		},
	];

	const rowSelection: TableProps['rowSelection'] = {
		onChange: (selectedRowKeys: React.Key[], selectedRows) => {
			console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
		},
	};

	const customHandleBack = useCallback(() => navigate(`/${routes.integrations.main}`), []);

	return (
		<Flex vertical gap={24}>
			<Flex vertical gap={12}>
				<Search />
				<Flex gap={4}>
					<Button icon={<RiAddCircleLine />}>Создать переходной ключ</Button>
					<Button icon={<RiFileEditLine />}>Скачать шаблон</Button>
					<Button icon={<RiDownloadLine style={{ rotate: '180deg' }} />}>
						Импортировать
					</Button>
					<Button icon={<RiUploadLine style={{ rotate: '180deg' }} />}>
						Экспортировать
					</Button>
				</Flex>
			</Flex>
			<Table dataSource={data} columns={columns} rowSelection={rowSelection} />
			<DetailFooter customHandleBack={customHandleBack}>
				<Button type="text" icon={<RiDeleteBinLine />}>
					Удалить выбранные позиции
				</Button>
			</DetailFooter>
		</Flex>
	);
};

export const ExchangeKeys = React.memo(ExchangeKeysUi);
