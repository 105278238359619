import { RiDeleteBin2Line } from '@remixicon/react';
import { Form, Modal, Typography, Checkbox, Flex, Button } from 'antd';
import React, { useState } from 'react';
import { DropdownLink } from 'shared/ui';
import { useDeleteCatalogRecord } from './deleteCatalogRecord.model';
import styles from './deleteCatalogRecord.module.scss';

interface IDeleteCatalogRecordUiProps {
	recordId: string;
}

export const DeleteCatalogRecordUi = ({ recordId }: IDeleteCatalogRecordUiProps) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isChecked, setIsChecked] = useState<boolean>(false);

	const { loading, error, handleDeleteCatalogRecord } = useDeleteCatalogRecord();

	const [form] = Form.useForm();

	const onFinish = async () => {
		await handleDeleteCatalogRecord(recordId);
		if (!error) {
			setIsChecked(false);
			setIsModalOpen(false);
		}
	};

	const showModal = () => setIsModalOpen(true);

	const handleOk = () => setIsModalOpen(false);

	const handleCancel = () => setIsModalOpen(false);

	const toggleCheck = () => setIsChecked(!isChecked);

	return (
		<>
			<DropdownLink
				icon={<RiDeleteBin2Line size={16} />}
				title="Удалить"
				callback={showModal}
			/>

			<Modal
				title="Удаление справочной записи"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={onFinish}>
					<Form.Item>
						<Typography.Text>
							Вы действительно хотите удалить данную справочную запись? Справочная
							запись и сопутствующие данные будут удалены без возможности
							восстановления
						</Typography.Text>
					</Form.Item>
					<Form.Item>
						<Typography.Text>
							Сопутствующие данные:
							<ul>
								<li className={styles.removeListItem}>
									сообщения обмена с интеграцией
								</li>
								<li className={styles.removeListItem}>переходные ключи</li>
							</ul>
						</Typography.Text>
					</Form.Item>
					<Form.Item>
						<Checkbox checked={isChecked} onChange={toggleCheck}>
							Я согласен удалить все вышеперечисленные сопутствующие данные
						</Checkbox>
					</Form.Item>
					<Flex gap={4} justify="flex-end">
						<Button type="text" onClick={handleCancel}>
							Отменить
						</Button>
						<Button
							htmlType="submit"
							type="primary"
							loading={loading}
							disabled={!isChecked}
						>
							Удалить
						</Button>
					</Flex>
				</Form>
			</Modal>
		</>
	);
};
