import { Moment } from 'moment';

export enum ChartGanttViewType {
	Year = 'Year',
	Quarter = 'Quarter',
	Month = 'Month',
	Week = 'Week',
}

export interface IChartGanttProps {
	data: IChartGanttItem[];
	startMoment?: Moment;
	endMoment?: Moment;
	onClickItem: (id: string | number) => void;
}

export interface IChartGanttItemProps extends IChartGanttInternalItem {
	onClickItem: () => void;
}

export interface IChartGanttItem {
	id: number | string;
	title: string;
	dateFrom: Moment;
	dateTo: Moment;
}

export interface IChartGanttInternalItem {
	id: number | string;
	title: string;
	startColumn: number;
	width: number;
	startRow: number;
}