import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ItemDto } from 'shared/api/generatedApi/mdmgApi';
import { ItemValuesType } from 'shared/helpers/types';
import { PaginationModel } from 'shared/models';

interface ICatalogRecordsState {
	catalogRecords: ItemDto[] | null;
	pagination: PaginationModel;
}

const initialState: ICatalogRecordsState = {
	catalogRecords: null,
	pagination: {
		pageSizeOptions: [20, 50, 100, 200],
		current: 1,
		pageSize: 20,
		total: 0,
	},
};

interface IDeleteRecordAction {
	id: string;
}

export type ItemValues = {
	[key: string]: ItemValuesType;
};

const catalogRecordsSlice = createSlice({
	initialState,
	name: 'catalogRecords',
	reducers: {
		setCatalogRecords(state, action: PayloadAction<ItemDto[]>) {
			state.catalogRecords = action.payload;
		},
		deleteCatalogRecord(state, action: PayloadAction<IDeleteRecordAction>) {
			state.catalogRecords = state.catalogRecords.filter(
				(record) => record.id !== action.payload.id
			);
		},
		addCatalogRecord(state, action: PayloadAction<ItemDto>) {
			state.catalogRecords = [
				action.payload,
				...(state.catalogRecords ? state.catalogRecords : []),
			];
		},
		removeCatalogRecords(state) {
			state.catalogRecords = null;
		},
		setPagination(state, action: PayloadAction<PaginationModel>) {
			state.pagination = action.payload;
		},
		decreasePaginationTotal(state) {
			state.pagination = {
				...state.pagination,
				total: state.pagination.total - 1,
			};
		},
	},
});

export const {
	deleteCatalogRecord,
	setCatalogRecords,
	addCatalogRecord,
	removeCatalogRecords,
	setPagination,
	decreasePaginationTotal,
} = catalogRecordsSlice.actions;

export const catalogRecordsReducer = catalogRecordsSlice.reducer;
