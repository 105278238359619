import { DisplayTreeNode } from './types';

export const findCatalogById = (
	tree: DisplayTreeNode[],
	catalogGroupId: string
): DisplayTreeNode => {
	for (const item of tree) {
		if (item.id === catalogGroupId) {
			return item;
		}
		if (item.children) {
			const found = findCatalogById(item.children, catalogGroupId);
			if (found) {
				return found;
			}
		}
	}
	return null;
};
