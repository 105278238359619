import { RiMore2Line } from '@remixicon/react';
import { Dropdown, MenuProps } from 'antd';
import React from 'react';

interface IItemActionsProps {
	items:
		| {
				label: JSX.Element;
				key: string;
		  }[]
		| MenuProps['items'];
	placement?:
		| 'bottomRight'
		| 'topLeft'
		| 'topCenter'
		| 'topRight'
		| 'bottomLeft'
		| 'bottomCenter'
		| 'top'
		| 'bottom';
}

const ItemActions = ({ items, placement }: IItemActionsProps) => {
	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			<Dropdown trigger={['click']} menu={{ items }} placement={placement}>
				<RiMore2Line
					style={{ width: 22, height: 22, cursor: 'pointer', padding: '2px' }}
					onClick={(e) => e.preventDefault()}
				/>
			</Dropdown>
		</div>
	);
};

export default ItemActions;
