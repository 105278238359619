import { App } from 'antd';
import { deleteClassifierGroup } from 'entities/classifiers/classifiersGroups/classifier.store';
import { useDeleteClassifierMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteClassifier = () => {
	const [deleteItem, { isLoading }] = useDeleteClassifierMutation();
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const handleDelete = async (id: string): Promise<boolean> => {
		let res = false;
		await deleteItem({ ids: [id] })
			.then(() => {
				dispatch(deleteClassifierGroup(id));
				res = true;
			})
			.catch((e) => {
				errorHelper('Не удалось удалить классификатор!', e, notification);
				res = false;
			});

		return res;
	};

	return {
		handleDelete,
		isLoading,
	};
};
