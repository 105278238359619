import { RiAddCircleLine } from '@remixicon/react';
import { Button, Flex } from 'antd';
import React, { useCallback, useState } from 'react';
import EmptyListPlug from 'shared/assets/emptyListPlug.png';
import { ApprovalRouteStep, ApprovalRouteStepModel } from '../ApprovalRouteStep/ApprovalRouteStep';

const approvalStepMockFactory: (props?: Partial<ApprovalRouteStepModel>) => {
	id: string,
	data: ApprovalRouteStepModel
} =
	(props) => ({
		id: Math.random().toString(),
		data: {
			title: null,
			assigneeIds: [],
			isEditAllowed: false,
			coordinationType: null,
			coordinationTime: null,
			...props,
		},
	});

const ApprovalRouteWidget = () => {

	const [ routeStages, setRouteStages ] = useState<any[][]>([
		[ approvalStepMockFactory({
			title: 'Этап согласования 1 - Шаг 1',
			isEditAllowed: true,
			coordinationType: 'ONE_OF_GROUP',
			coordinationTime: '2h',
		}) ],
		[
			approvalStepMockFactory({ title: 'Этап согласования 2 - Шаг 1' }),
			approvalStepMockFactory({ title: 'Этап согласования 2 - Шаг 2', isEditAllowed: true }),
			approvalStepMockFactory({ title: 'Этап согласования 2 - Шаг 3' }),
		],
		[ approvalStepMockFactory({ title: 'Этап согласования 3 - Шаг 1' }) ],
	]);


	const addParallelStep = useCallback((stage) => {
		const newStages = routeStages.map((s) => {
			if (s == stage) {
				//	todo: post /api/v1/application-route-steps
				return [ ...s, approvalStepMockFactory() ];
			}
			return s;
		});
		setRouteStages(newStages);
		//	todo: post /api/v1/application-templates/{id}/application-route-steps/order
	}, [ routeStages ]);

	const addStageHandler = useCallback(() => {
		//	todo: post /api/v1/application-route-steps
		const newRouteStages = [
			...routeStages,
			[ approvalStepMockFactory() ],
		];
		setRouteStages(newRouteStages);
	}, [ routeStages ]);

	const deleteHandler = useCallback((stage, step) => {
		//	 todo: запрос на удаление шага
		const newStages = routeStages.map((s) => {
			if (s == stage) {
				return s.filter((iStep) => iStep != step);
			}
			return s;
		});
		setRouteStages(newStages);
	}, [ routeStages ]);

	const makeStepSequentialHandler = useCallback((stage, step) => {
		const newStages = routeStages.reduce((acc, s) => {
			if (s == stage) {
				acc.push(s.filter((iStep) => iStep != step));
				acc.push([ step ]);
			} else {
				acc.push(s);
			}
			return acc;
		}, []);
		setRouteStages(newStages);
		//	todo: post /api/v1/application-templates/{id}/application-route-steps/order
	}, [ routeStages ]);


	return (<Flex vertical={true} gap={12}>
		<Button icon={<RiAddCircleLine size={16}/>}
				style={{ alignSelf: 'start' }}
				onClick={addStageHandler}
		>
			Добавить этап согласования
		</Button>

		{
			!routeStages || routeStages.length == 0 && (
				<Flex vertical={true} justify={'center'} align={'center'} gap={24}
					  style={{
						  padding: 16,
						  color: 'rgba(21, 29, 52, 0.65)',
						  textAlign: 'center',
						  fontSize: 21,
						  fontWeight: 700,
						  lineHeight: '130%',
					  }}
				>
					<img width={386} src={EmptyListPlug} alt=""/>

					<div>
						Вы не создали еще ни одного этапа маршрута согласования
					</div>
					<div style={{ maxWidth: 650 }}>
						Нажмите «Сохранить шаблон заявки», если хотите пропустить создание этапов
					</div>
				</Flex>
			)
		}

		{
			routeStages.map((stage, stageNumber) => {
				if (stage.length == 1) {
					const step = stage[0];
					return <ApprovalRouteStep key={step.id}
											  data={step.data}
											  onClickAddParallelStep={() => addParallelStep(stage)}
											  onClickRemoveStep={() => deleteHandler(stage, step)}
					/>;
				} else {
					return <Flex key={stageNumber} vertical={true} gap={12} style={{
						padding: 16,
						borderRadius: 6,
						background: 'var(--bg-table)',
						borderLeft: '6px solid var(--primary)',
					}}>
						{
							stage.map((step) =>
								<ApprovalRouteStep key={step.id}
												   isParallel
												   data={step.data}
												   onClickMakeStepSequential={() => makeStepSequentialHandler(stage, step)}
												   onClickRemoveStep={() => deleteHandler(stage, step)}
								/>,
							)
						}
					</Flex>;
				}
			})
		}
	</Flex>);
};

export {
	ApprovalRouteWidget,
};