import { Flex } from 'antd';
import React from 'react';
import styles from './ChartGantt.module.scss';
import { IChartGanttItemProps } from './models';

const equalItems =
	(prevProps: IChartGanttItemProps, nextProps: IChartGanttItemProps) =>
		Object
			.entries(prevProps)
			.filter(([ key ]) => key !== 'onClickItem')
			.map(([ key, value ]) => nextProps[key] === value)
			.findIndex(x => x === false) === -1;

const ChartGanttItem = React.memo(({
									   title,
									   startColumn,
									   width,
									   startRow,
									   onClickItem,
								   }: IChartGanttItemProps) => {
	return <Flex vertical={false}
				 wrap={'nowrap'}
				 align={'center'}
				 gap={4}
				 className={styles.item}
				 style={{
					 gridColumnStart: startColumn,
					 gridColumnEnd: startColumn + width,
					 gridRowStart: startRow,
					 gridRowEnd: startRow + 1,
					 overflow: 'hidden',
					 textOverflow: 'ellipsis',
				 }}
				 onClick={onClickItem}
	>
		<div style={{ cursor: 'move', padding: '4px 2px' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="6" height="8" viewBox="0 0 6 8" fill="none"
			>
				<path
					d="M2.26951 7.15333C2.26951 7.62167 1.89117 8 1.42284 8C0.954505 8 0.576172 7.62167 0.576172 7.15333C0.576172 6.685 0.954505 6.30667 1.42284 6.30667C1.89117 6.30667 2.26951 6.685 2.26951 7.15333ZM2.26951 4C2.26951 4.46833 1.89117 4.84667 1.42284 4.84667C0.954505 4.84667 0.576172 4.46833 0.576172 4C0.576172 3.53167 0.954505 3.15333 1.42284 3.15333C1.89117 3.15333 2.26951 3.53167 2.26951 4ZM2.26951 0.846666C2.26951 1.315 1.89117 1.69333 1.42284 1.69333C0.954505 1.69333 0.576172 1.315 0.576172 0.846666C0.576172 0.378333 0.954505 0 1.42284 0C1.89117 0 2.26951 0.378333 2.26951 0.846666ZM5.42284 7.15333C5.42284 7.62167 5.04451 8 4.57617 8C4.10784 8 3.72951 7.62167 3.72951 7.15333C3.72951 6.685 4.10784 6.30667 4.57617 6.30667C5.04451 6.30667 5.42284 6.685 5.42284 7.15333ZM5.42284 4C5.42284 4.46833 5.04451 4.84667 4.57617 4.84667C4.10784 4.84667 3.72951 4.46833 3.72951 4C3.72951 3.53167 4.10784 3.15333 4.57617 3.15333C5.04451 3.15333 5.42284 3.53167 5.42284 4ZM5.42284 0.846666C5.42284 1.315 5.04451 1.69333 4.57617 1.69333C4.10784 1.69333 3.72951 1.315 3.72951 0.846666C3.72951 0.378333 4.10784 0 4.57617 0C5.04451 0 5.42284 0.378333 5.42284 0.846666Z"
					fill="#151D34" fillOpacity="0.24"/>
			</svg>
		</div>

		<div style={{ padding: 4 }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
				<circle cx="4" cy="4" r="4" fill="#4EAE6F"/>
			</svg>
		</div>

		<span className={styles.itemTitle}>
            {title}
        </span>

		<Flex justify={'center'} align={'center'}>
			<svg xmlns="http://www.w3.org/2000/svg" width="2" height="2" viewBox="0 0 2 2" fill="none">
				<circle cx="1" cy="1" r="1" fill="#151D34" fillOpacity="0.9"/>
			</svg>
		</Flex>

		<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            10 дней
        </span>

		<Flex justify={'center'} align={'center'} style={{ cursor: 'pointer' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
				 fill="none">
				<path
					d="M1.25635 0.851562L0 2.10791L2.82679 4.9347L0 7.76149L1.25635 9.01783L5.33949 4.9347L1.25635 0.851562ZM16 15.151V13.3742H0.00924803V15.151H16ZM16 8.93237V7.15562H8.00464V8.93237H16ZM16 2.71376V0.937015H8.00464V2.71376H16Z"
					fill="#151D34" fillOpacity="0.4"/>
			</svg>
		</Flex>
	</Flex>;
}, equalItems);

export {
	ChartGanttItem,
};