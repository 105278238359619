import { App } from 'antd';
import {
	editCurrentClassifier,
	setCurrentClassifier,
} from 'entities/classifiers/classifiersGroups/classifier.store';
import {
	ClassifierResponse,
	useUpdateClassifierMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';

type PartialExceptId<T extends { id: string; displayName: string }> = Partial<Omit<T, 'id'>> & {
	id: T['id'];
	displayName: T['displayName'];
};

export const useEditClassifierMainInfo = () => {
	const { currentClassifierGroup } = useAppSelector(
		(state) => state.entities.classifiers.classifierGroups
	);
	const dispatch = useAppDispatch();

	const [editClassifier] = useUpdateClassifierMutation();

	const { notification } = App.useApp();

	const handleUpdate = async (arg: PartialExceptId<ClassifierResponse>) => {
		await editClassifier({
			id: currentClassifierGroup.id,
			updateClassifierRequest: {
				...arg,
				linkClassifierAttributes: arg.linkClassifierAttributes as unknown as string[],
			},
		})
			.unwrap()
			.then((res) => {
				dispatch(editCurrentClassifier(res));
			})
			.catch((e) => {
				dispatch(setCurrentClassifier(currentClassifierGroup));
				errorHelper('Ошибка при редактировании классификатора!', e, notification);
			});
	};

	return {
		handleUpdate,
	};
};
