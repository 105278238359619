import React from 'react';
import { colors } from '../../../styles/cssConstants';
import type { IIcon } from '../../../types';

const AddCopyIconUI: React.FC<IIcon> = ({ size = 16, color = `${colors.primary}` }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.00062 0.8V3.2H1.60832C1.16289 3.2 0.802941 3.55849 0.802861 4.0007L0.800781 15.1993C0.800781 15.6443 1.16125 16 1.60611 16H11.1952C11.6407 16 12.0006 15.6415 12.0006 15.1993V12.8H14.4006C14.8425 12.8 15.2006 12.4418 15.2006 12V0.8C15.2006 0.358176 14.8425 0 14.4006 0H4.80062C4.3588 0 4.00062 0.358176 4.00062 0.8ZM12.0006 3.2H5.60062V1.6H13.6006V11.2H12.0006V3.2ZM5.8 6V8.8H3V10.2H5.8V13H7.2V10.2H10V8.8H7.2V6H5.8Z"
				fill={color}
			/>
		</svg>
	);
};

export const AddCopyIcon = React.memo(AddCopyIconUI);
