export const translateType = (
	type: string | undefined,
	atType?: 'AttributeTransfer' | 'AggregationStereotypeTransfer'
): string => {
	if (atType === 'AttributeTransfer' || atType === undefined) {
		switch (type) {
			case 'DOUBLE':
				return 'Число с плавающей точкой';
			case 'FLOAT':
				return 'Число с плавающей точкой';
			case 'BOOL':
				return 'Логический тип';
			case 'BOOLEAN':
				return 'Логический тип';
			case 'STRING':
				return 'Строка';
			case 'DATE_TIME':
				return 'Дата';
			case 'FILE':
				return 'Файл';
			case 'INTEGER':
				return 'Целое число';
			case 'IMAGE':
				return 'Изображение';
			case 'TEXT':
				return 'Многострочный текст';
			case 'FORMULA':
				return 'Формула';
			case 'COLOR':
				return 'Цвет';
			case 'RELATION':
				return 'Связь';
			case undefined:
				return 'не определен тип атрибута в AttributeTransfer';
		}
	} else if (atType === 'AggregationStereotypeTransfer') {
		return 'Атрибут связи';
	} else return '@type не отпределен';
};
