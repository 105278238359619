import { RiDeleteBinLine } from '@remixicon/react';
import { App, Button, Checkbox, CheckboxProps, Flex, Modal, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useDeleteConnectionMdmIntegrationsV1ConnectionsConnectionIdDeleteMutation } from 'shared/api/generatedApi/integrationsApi';
import { routes } from 'shared/config';
import { errorHelper, randomString } from 'shared/helpers';
import { DropdownLink } from 'shared/ui';
import { ml15 } from './deleteIntegration.style';

interface IDeleteIntegration {
	id: string;
	isButton?: boolean;
	isNew?: boolean;
}

const DeleteIntegrationUi: React.FC<IDeleteIntegration> = ({ id, isButton, isNew }) => {
	const [open, setOpen] = useState<boolean>(false);
	const [confirm, setConfirm] = useState<boolean>(false);

	const navigate = useNavigate();

	const { notification } = App.useApp();

	const [deleteIntegration, { isLoading }] =
		useDeleteConnectionMdmIntegrationsV1ConnectionsConnectionIdDeleteMutation();

	const showModal = useCallback(() => {
		setOpen(true);
	}, []);

	const handleOk = useCallback(async () => {
		await deleteIntegration({ connectionId: id })
			.unwrap()
			.then(() => isNew && navigate(`/${routes.integrations.main}`))
			.catch((e) => {
				errorHelper('Ошибка при удалении интеграции!', e, notification);
			})
			.finally(() => setConfirm(false));
	}, [id, isNew]);

	const handleConfirmDelete: CheckboxProps['onChange'] = useCallback(() => {
		setConfirm(!confirm);
	}, [confirm]);

	const handleCancel = useCallback(() => {
		setConfirm(false);
		setOpen(false);
	}, []);

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					Отмена
				</Button>

				<Button
					key="submit"
					type="primary"
					onClick={handleOk}
					disabled={!confirm || isLoading}
				>
					Удалить
				</Button>
			</Flex>
		),
		[confirm, isLoading]
	);

	return (
		<>
			{isButton || isNew ? (
				<Button type="text" onClick={showModal} icon={<RiDeleteBinLine size={18} />}>
					Удалить
				</Button>
			) : (
				<DropdownLink
					title="Удалить"
					callback={showModal}
					icon={<RiDeleteBinLine size={16} />}
				/>
			)}

			<Modal
				open={open}
				title="Подтверждение удаления"
				footer={[footerButtons]}
				onCancel={handleCancel}
			>
				<Flex vertical gap={24} style={{ marginBottom: 24 }}>
					<Typography.Paragraph>
						Вы действительно хотите удалить данную интеграцию? Интеграция и
						сопутствующие данные будут удалены без возможности восстановления
					</Typography.Paragraph>

					<ul>
						<Typography.Paragraph>Сопутствующие данные:</Typography.Paragraph>
						<li style={ml15}>конфигурации обмена</li>
						<li style={ml15}>репликационные сообщения</li>
						<li style={ml15}>переходные ключи</li>
					</ul>
					<Checkbox onChange={handleConfirmDelete} checked={confirm}>
						Я согласен удалить все вышеперечисленные сопутствующие данные
					</Checkbox>
				</Flex>
			</Modal>
		</>
	);
};

export const DeleteIntegration = React.memo(DeleteIntegrationUi);
