import { RiInformation2Line } from '@remixicon/react';
import { Button, Flex, Modal, Table, TableProps, Typography } from 'antd';
import React, { ReactElement, useMemo } from 'react';
import { useSetOriginalItem } from 'features/catalogs/setOriginalItem';
import {
	setDeletingSuspectedDuplicatesIds,
	setPotentialOriginalItemId,
} from 'entities/catalogs/catalogDeduplication/catalogDeduplication.store';
import { randomString } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { colors } from 'shared/styles';
import { Hint } from 'shared/ui';
import { SuspectedDuplicate } from '../SuspectedDuplicate/SuspectedDuplicate.ui';
import { useSuspectedDuplicates } from './SuspectedDuplicates.model';

interface IDataSource {
	key: string;
	name: string;
	catalog: string;
	menu: ReactElement;
}

export const SuspectedDuplicates = () => {
	const suspectedDuplicates = useAppSelector(
		(state) => state.entities.catalogs.catalogDeduplication.suspectedDuplicates
	);

	const potentialOriginalItemId = useAppSelector(
		(state) => state.entities.catalogs.catalogDeduplication.potentialOriginalItemId
	);

	const {
		columns,
		dataSource,
		isSuspectedLoading,
		isModalOpen,
		openModal,
		closeModal,
		suspectedDuplicateId,
	} = useSuspectedDuplicates();

	const { setOriginalItem, isOriginalItemSetting } = useSetOriginalItem();

	const dispatch = useAppDispatch();

	const searchDate = suspectedDuplicates?.data[0]?.updatedAt
		? new Date(suspectedDuplicates?.data[0]?.updatedAt).toLocaleDateString()
		: null;

	const rowSelection: TableProps<IDataSource>['rowSelection'] = {
		onChange: (selRowKeys: React.Key[]) => {
			dispatch(setDeletingSuspectedDuplicatesIds(selRowKeys as string[]));
		},
	};

	const handleOk = async () => {
		if (potentialOriginalItemId) {
			await setOriginalItem({
				suspectedDuplicateId,
				newOriginalItemId: potentialOriginalItemId,
			});
			dispatch(setPotentialOriginalItemId(null));
		}
		closeModal();
	};

	const handleCancel = () => {
		dispatch(setPotentialOriginalItemId(null));
		closeModal();
	};

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					Отменить
				</Button>

				<Button
					key="submit"
					type="primary"
					onClick={handleOk}
					disabled={!potentialOriginalItemId}
					loading={isOriginalItemSetting}
				>
					Продолжить
				</Button>
			</Flex>
		),
		[handleOk, handleCancel]
	);

	return (
		<>
			<Flex vertical gap={24}>
				{searchDate && (
					<Flex align="center" gap={4}>
						<Typography>Актуально на {searchDate}</Typography>
						<Hint
							title="Для получения актуального результата запустите поиск заново"
							placement="bottomLeft"
						>
							<RiInformation2Line color={colors.grayIcon} size={16} />
						</Hint>
					</Flex>
				)}

				<Table
					columns={columns}
					dataSource={dataSource}
					loading={isSuspectedLoading}
					rowSelection={rowSelection}
					onRow={(r) => {
						return {
							onClick: () => openModal(r.key),
						};
					}}
				/>
			</Flex>

			<Modal
				title="Дедубликация"
				open={isModalOpen}
				closable={false}
				onOk={handleOk}
				onCancel={handleCancel}
				width={'calc(100vw - 200px)'}
				footer={footerButtons}
				styles={{ body: { height: 'calc(100vh - 400px)' } }}
			>
				<SuspectedDuplicate id={suspectedDuplicateId} closeModal={closeModal} />
			</Modal>
		</>
	);
};
