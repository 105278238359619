import { RiCheckboxCircleFill } from '@remixicon/react';
import { Flex, List, Result, Spin, Typography } from 'antd';
import React from 'react';
import { useAppSelector } from 'shared/hooks';
import { colors } from 'shared/styles';
import { Placeholder } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';
import { EditClassifierMainInfo } from '../../DetailClassifier/EditClassifierMainInfo';
import { DeleteClassifierAttributeDeclaration } from '../DeleteClassifierAttribiteDeclaration';
import { SetupClassifierAttribute } from '../SetupClassifierAttribute';
import { useEditAttributeList } from './editAttributeList.model';

const EditClassifierAttributeListUi = () => {
	const { classifierAttributeDeclarations, loading, error, descriptions } =
		useEditAttributeList();

	const { currentClassifierGroup } = useAppSelector(
		(state) => state.entities.classifiers.classifierGroups
	);

	if (error) {
		return <Result title="Ошибка!" subTitle={JSON.stringify(error)} />;
	}

	return (
		<>
			{loading && (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Spin size="large" />
				</div>
			)}
			{!loading && !classifierAttributeDeclarations?.length && (
				<Placeholder
					title="Пока не создано ни одного атрибута"
					subTitle="Для добавления нового атрибута нажмите на кнопку «Добавить атрибут»"
				/>
			)}
			{!loading && classifierAttributeDeclarations?.length > 0 && (
				<List
					itemLayout="horizontal"
					dataSource={classifierAttributeDeclarations}
					renderItem={(item, i) => (
						<List.Item
							actions={[
								<ItemActions
									key={1}
									items={[
										currentClassifierGroup?.displayNameAttributeDeclarationId !==
										item?.id
											? {
													label: (
														<EditClassifierMainInfo
															classifier={currentClassifierGroup}
															isDropdownLink={true}
															displayNameAttributeDeclarationId={
																item?.id
															}
														/>
													),
													key: `${item?.id}`,
												}
											: null,
										{
											label: <SetupClassifierAttribute attribute={item} />,
											key: `${item?.id}-${i + 1}`,
										},
										{
											label: (
												<DeleteClassifierAttributeDeclaration
													id={item?.id}
												/>
											),
											key: `${item?.id}-${i}`,
										},
									]}
								/>,
							]}
						>
							<List.Item.Meta
								title={
									currentClassifierGroup?.displayNameAttributeDeclarationId !==
									item?.id ? (
										item?.attribute?.displayName
									) : (
										<Flex gap={6}>
											{item?.attribute?.displayName}

											<Flex align="center" gap={2}>
												<RiCheckboxCircleFill
													color={colors.success}
													size={14}
												/>
												<Typography.Text style={{ fontWeight: 400 }}>
													Используется в названии справочной записи
												</Typography.Text>
											</Flex>
										</Flex>
									)
								}
								description={descriptions ? descriptions[item?.id] : []}
							/>
						</List.Item>
					)}
				/>
			)}
		</>
	);
};

export const EditClassifierAttributeList = React.memo(EditClassifierAttributeListUi);
