import { App } from 'antd';
import { editAttributeDeclaration } from 'entities/catalogs/attributeDeclarations/attributeDeclarations.store';
import { AttributeDeclarationDto } from 'shared/api/generatedApi/mdmgApi';
import { useUpdateCatalogAttributeDeclarationMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { ItemValues } from 'shared/helpers/types';
import { useAppDispatch } from 'shared/hooks';

export const useSetupAttributeDeclaration = () => {
	const [ addUpdateEvent3, { isLoading } ] = useUpdateCatalogAttributeDeclarationMutation();
	const dispatch = useAppDispatch();
	const { notification } = App.useApp();

	const editData = async (classifier: AttributeDeclarationDto, v: ItemValues) => {
		addUpdateEvent3({
			id: classifier.id,
			updateCatalogAttributeDeclarationRequest:
				{
					restrictions: {
						measurementId: classifier.measurementId,
						...v,
					} as unknown as { [key: string]: object; },
				},
		})
			.unwrap()
			.then((res) => {
				dispatch(
					editAttributeDeclaration({
						...classifier,
						restrictions: {
							...classifier.restrictions,
							...res.restrictions,
						},
					}),
				);
			})
			.catch((e) => {
				errorHelper(
					'Ошибка при редактировании декларации атрибута классификатора!',
					e,
					notification,
				);
			});
	};

	return {
		editData,
		isLoading,
	};
};
