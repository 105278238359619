import { RiMenuSearchLine, RiPlayCircleLine } from '@remixicon/react';
import { Button } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { routes } from 'shared/config';
import { useAppSelector, useTypedTranslation } from 'shared/hooks';
import { useCatalogDeduplicationCondition } from '../CatalogDeduplicationCondition/CatalogDeduplicationCondition.model';

interface IDeduplicationOptionStartButton {
	id: string;
}

export const CatalogDeduplicationSearchButton = ({
	id,
}: IDeduplicationOptionStartButton): React.ReactElement => {
	const { t } = useTypedTranslation();

	const deduplicationOptions = useAppSelector(
		(state) => state.entities.catalogs.catalogDeduplication.catalogDeduplication
	);

	const currentDeduplicationOption = deduplicationOptions.find((item) => item.id === id);

	const { deduplicationAttributeMappings, isRestart } = currentDeduplicationOption
		? currentDeduplicationOption
		: { deduplicationAttributeMappings: [], isRestart: false };

	const isDisabled = !deduplicationAttributeMappings?.length;

	const { startDeduplicationSearch, isSearchingDuplicates, isFetchigCurrentDeduplicationOption } =
		useCatalogDeduplicationCondition();

	const navigate = useNavigate();
	const { catalogGroupId } = useParams();

	const showSuspectedDuplicates = (e: React.SyntheticEvent) => {
		e.stopPropagation();
		navigate(
			`/${routes.catalogs.main}/${catalogGroupId}/${routes.catalogs.deduplication}/${id}`
		);
	};

	return (
		<>
			{!isRestart && (
				<Button
					type="text"
					icon={<RiPlayCircleLine size={16} />}
					onClick={(e) => {
						e.stopPropagation();
						startDeduplicationSearch(id);
					}}
					disabled={isDisabled}
					loading={isSearchingDuplicates || isFetchigCurrentDeduplicationOption}
				>
					{isDisabled
						? t((l) => l.catalogs.deduplication.selectAttribute)
						: t((l) => l.catalogs.deduplication.startDupeSearch)}
				</Button>
			)}
			{isRestart && (
				<Button
					type="text"
					icon={<RiMenuSearchLine size={16} />}
					onClick={(e) => showSuspectedDuplicates(e)}
					loading={isSearchingDuplicates || isFetchigCurrentDeduplicationOption}
				>
					{t((l) => l.catalogs.deduplication.dupeSearchResult)}
				</Button>
			)}
		</>
	);
};
