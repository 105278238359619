import { App } from 'antd';
import { useParams } from 'react-router';
import { AttributeGroupItem } from 'entities/metadata/attributesGroups/attributesGroups.model';
import {
	addMetaAttributesGroup,
	updateMetaAttributesGroupChildTreeItem,
} from 'entities/metadata/attributesGroups/attributesGroups.store';
import { useTransactions } from 'entities/transactions';
import { useCreateAttributeGroupMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useCreateAttributeGroup = () => {
	const { notification } = App.useApp();

	const [createAttrGroup, { isLoading, error }] = useCreateAttributeGroupMutation();

	const { metaAttributeGroupId } = useParams();

	const dispatch = useAppDispatch();

	const { getTransactions } = useTransactions();

	const createNewGroup = async (displayName: string, description?: string, id?: string) => {
		await createAttrGroup({
			createAttributeGroupRequest: {
				displayName,
				description,
				...(id && { parentId: id }),
				...(metaAttributeGroupId && !id && { parentId: metaAttributeGroupId }),
			},
		})
			.unwrap()
			.then((res) => {
				getTransactions();

				const newData = {
					...(res as AttributeGroupItem),
					key: res.id,
					isLeaf: !res.parent,
				};

				if (!metaAttributeGroupId && !id) dispatch(addMetaAttributesGroup(newData));
				if (metaAttributeGroupId || id) {
					dispatch(
						updateMetaAttributesGroupChildTreeItem({
							item: newData,
							parentId: newData.parentId,
						})
					);
				}
			})
			.catch((err) => {
				errorHelper('Ошибка при добавлении новой группы атрибутов!', err, notification);
			});
	};

	return {
		loading: isLoading,
		error,
		createNewGroup,
	};
};
