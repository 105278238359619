import { App } from 'antd';
import { deleteCatalogRestrictionTableDeclaration } from 'entities/catalogs/catalogRestrictions';
import { useTransactions } from 'entities/transactions';
import { useDeleteCatalogAttributeDeclarationMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteCatalogRestrictionTable = () => {
	const [deleteTableDeclaration, { isLoading }] = useDeleteCatalogAttributeDeclarationMutation();

	const { notification } = App.useApp();

	const { getTransactions } = useTransactions();

	const dispatch = useAppDispatch();

	const handleDeleteDeclaration = async (declarationId: string) => {
		await deleteTableDeclaration({ ids: [declarationId] })
			.unwrap()
			.then(() => {
				dispatch(deleteCatalogRestrictionTableDeclaration({ id: declarationId }));
				getTransactions();
			})
			.catch((err) =>
				errorHelper('Ошибка удаления таблицы допустимых значений', err, notification)
			);
	};

	return {
		handleDeleteDeclaration,
		isLoading,
	};
};
