import { App } from 'antd';
import { useParams } from 'react-router';
import { ItemDto, useLazyGetClassifierByItemIdQuery } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';

export const useClassifierLinkAttributesFilter = () => {
	const { notification } = App.useApp();

	const { classifierItemId } = useParams();

	const [ getItems, { isLoading } ] = useLazyGetClassifierByItemIdQuery();

	const getAttributesByIds = (attributeId: string): Promise<Array<ItemDto> | null> => {
		return getItems({ attributeId, classifierItemId })
			.unwrap()
			.then((res) => res)
			.catch((e) => {
				errorHelper('Ошибка при получении списка атрибутов', e, notification);
				return null;
			});
	};

	return {
		getAttributesByIds,
		isLoading,
	};
};
