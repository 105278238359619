import { RiLinkM } from '@remixicon/react';
import { App } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
	removeCurrentMetaAttribute,
	setCurrentMetaAttribute,
	updateMetaAttributeItem,
} from 'entities/metadata/metaAttributes/metaAttributes.store';
import { useTransactions } from 'entities/transactions';
import {
	AttributeResponse,
	useLazyGetAttributeQuery,
	useLazyGetCatalogsByAttributeQuery,
} from 'shared/api/generatedApi/mdmgApi';
import {
	useCreateAttributeMutation,
	useUpdateAttributeMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { NEW_ENTITY } from 'shared/helpers/Constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { colors } from 'shared/styles';
import { Chip } from 'shared/ui';

export const useEditSimpleAttribute = () => {
	const [formVals, setFormVals] = useState<AttributeResponse>(null);
	const [usageList, setUsageList] = useState<Array<JSX.Element>>(null);

	const { currentAttribute } = useAppSelector((state) => state.entities.metadata.metaAttributes);

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const [getAttribute, { isLoading, error: getAttributeError }] = useLazyGetAttributeQuery();
	const [updateAttribute] = useUpdateAttributeMutation();
	const [createAttribute] = useCreateAttributeMutation();
	const [getUsage, { isLoading: usageLoading, error: usageError }] =
		useLazyGetCatalogsByAttributeQuery();

	const { metaAttributeId, metaAttributeGroupId } = useParams();
	const navigate = useNavigate();

	const { getTransactions } = useTransactions();

	const handleUpdateAttribute = async (attr) => {
		if (!attr.displayName || attr.displayName.length === 0 || !attr.type) {
			metaAttributeId !== NEW_ENTITY &&
				errorHelper(
					'Ошибка при обновлении атрибута!',
					new Error('Необходимо заполнить обязательные поля!'),
					notification
				);
			setFormVals({ ...currentAttribute });
			return;
		}

		if (metaAttributeId !== NEW_ENTITY) {
			await updateAttribute({
				attributeGroupId: metaAttributeGroupId,
				id: metaAttributeId,
				updateAttributeRequest: { ...attr },
			})
				.unwrap()
				.then((res) => {
					getTransactions();

					dispatch(updateMetaAttributeItem(res));
					dispatch(setCurrentMetaAttribute(res));
				})
				.catch((err) => {
					errorHelper('Ошибка при обновлении атрибута!', err, notification);
					setFormVals({ ...currentAttribute });
				});
		} else {
			createAttribute({
				attributeGroupId: metaAttributeGroupId,
				createAttributeRequest: { ...attr, list: !!attr.list },
			})
				.unwrap()
				.then((res) => {
					dispatch(updateMetaAttributeItem(res));
					dispatch(setCurrentMetaAttribute(res));
					navigate(
						`/${routes.metadata.main}/${routes.metadata.group}/${metaAttributeGroupId}/${routes.metadata.attribute}/${res.id}`
					);
				})
				.then(() => getTransactions())
				.catch((err) => {
					errorHelper('Ошибка при обновлении атрибута!', err, notification);
				});
		}
	};

	useEffect(() => {
		if (!currentAttribute && metaAttributeId !== NEW_ENTITY) {
			const getData = async () => {
				const data = await getAttribute({
					id: metaAttributeId,
				});

				if ('data' in data) {
					dispatch(setCurrentMetaAttribute(data.data));
				}
			};

			getData();
		}

		return () => {
			dispatch(removeCurrentMetaAttribute());
			setFormVals(null);
		};
	}, []);

	useEffect(() => {
		if (currentAttribute) {
			getUsage({ attributeId: currentAttribute?.id })
				.unwrap()
				.then((res) => {
					setUsageList(
						res.map((item) => (
							<Chip
								color="primary"
								icon={<RiLinkM size={16} />}
								key={item.id}
								onClick={() => navigate(`/${routes.catalogs.main}/${item?.id}`)}
								style={{ color: colors.primary }}
							>
								{item?.displayName}
							</Chip>
						))
					);
				})
				.catch((e) => {
					errorHelper(
						'Ошибка при загрузке применимости атрибута в справочниках!',
						e,
						notification
					);
				});
		}
	}, [currentAttribute]);

	useEffect(() => {
		if (currentAttribute) setFormVals(currentAttribute);
	}, [currentAttribute]);

	return {
		formVals,
		usageList,
		usageLoading,
		usageError,
		isLoading,
		getAttributeError,
		handleUpdateAttribute,
	};
};
