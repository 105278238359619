import { removeToken, removeUserId, removeUserName, removeUserRoles } from 'entities/session';
import { useAppDispatch } from 'shared/hooks';
import keycloak from '../../../keycloak';

export const useLogout = () => {
	const dispatch = useAppDispatch();

	const logout = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(removeToken());
		dispatch(removeUserName());
		dispatch(removeUserRoles());
		dispatch(removeUserId());
		keycloak.logout();
	};
	return { logout };
};
