import { TabsProps, Tabs } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { EditExchangeConfigInfo } from 'features/integrations/ExchangeConfiguration/editExchangeConfigInfo';
import { SetupMapping } from 'features/integrations/ExchangeConfiguration/setupMapping';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { routes } from 'shared/config';
import { useAppDispatch } from 'shared/hooks';

const ExchangeConfigurationDetailPageUi: React.FC = () => {
	const dispatch = useAppDispatch();
	const { integrationId } = useParams();
	useEffect(() => {
		dispatch(
			setBreadcrumbs({
				displayName: 'Интеграции',
				url: routes.integrations.main,
				child: {
					displayName: 'Карточка подключения',
					url: `${routes.integrations.main}/${routes.integrations.detail}/${integrationId}/exchangeConfigs`,
					child: {
						displayName: 'Карточка конфигурации обмена',
						url: `${routes.integrations.main}/${routes.integrations.detail}/${integrationId}`,
					},
				},
			})
		);
	}, []);
	const tabs: TabsProps['items'] = useMemo(
		() => [
			{
				key: 'editExchangeConfigInfo',
				label: 'Информация о конфигурации обмена',
				children: <EditExchangeConfigInfo />,
			},
			{
				key: 'mappingSetup',
				label: 'Настройка маппинга',
				children: <SetupMapping />,
			},
		],
		[]
	);
	return <Tabs items={tabs} style={{ marginBottom: 74 }} />;
};

export const ExchangeConfigurationDetailPage = React.memo(ExchangeConfigurationDetailPageUi);
