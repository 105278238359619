import { App } from 'antd';
import { useParams } from 'react-router';
import { addCatalogRestrictionTablesDeclarations } from 'entities/catalogs/catalogRestrictions';
import { useTransactions } from 'entities/transactions';
import { useCreateCatalogRestrictionTableDeclarationsMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';

export const useAddCatalogRestrictionTable = () => {
	const { t } = useTypedTranslation();

	const [addTableDeclaration, { isLoading }] =
		useCreateCatalogRestrictionTableDeclarationsMutation();

	const { catalogGroupId } = useParams();

	const { notification } = App.useApp();

	const { getTransactions } = useTransactions();

	const dispatch = useAppDispatch();

	const handleAddTablesDeclarations = async (tableIds: string[]) => {
		await addTableDeclaration({
			catalogId: catalogGroupId,
			body: tableIds.map((id) => ({
				generationValues: true,
				required: true,
				restrictionTableId: id,
			})),
		})
			.unwrap()
			.then((res) => {
				dispatch(addCatalogRestrictionTablesDeclarations(res));
				getTransactions();
			})
			.catch((err) => errorHelper(t(l => l.common.statuses.error), err, notification));
	};

	return {
		handleAddTablesDeclarations,
		isLoading,
	};
};
