import { colors } from '../../cssConstants';

const buttonStyles = {
	fontFamily: 'Manrope',
	fontSize: 12,
	lineHeight: 1.3,
	fontWeight: 700,
	colorText: colors.primary,

	defaultColor: colors.primary,
	defaultBorderColor: colors.primaryBorderFaded,
	defaultBgColor: colors.baseWhte,
	defaultHoverBorderColor: colors.primary,
	defaultHoverColor: colors.primary,

	borderColorDisabled: colors.disabled,

	primaryColor: colors.whiteBase,
	primaryBorderColor: colors.primary,
	primaryBgColor: colors.primary,
	primaryHoverBorderColor: colors.primaryFaded,
	primaryHoverColor: colors.primaryFaded,
};

export default buttonStyles;
