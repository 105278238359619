import React from 'react';

function SettingPage() {
	return (
    <div>
      settings
    </div>
	);
}

export default SettingPage;
