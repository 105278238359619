import { AttributeDeclarationDto } from '../api/generatedApi/mdmgApi';
import { ItemValuesType } from './types';

const isNullOrEmptyString = (value: any) =>
	value === '' || value == null;

const mapValueForDto = (
	attributeDeclaration: AttributeDeclarationDto,
	value: ItemValuesType,
) => {
	const type = attributeDeclaration.attribute.type;

	if (type == 'STRING' || type == 'TEXT') {
		return isNullOrEmptyString(value)
			? null
			: value;
	}

	if (type == 'INTEGER' || type == 'FLOAT') {
		return isNullOrEmptyString(value)
			? null
			: Number(value);
	}

	if (type == 'BOOLEAN') {
		return isNullOrEmptyString(value)
			? null
			: Boolean(value);
	}

	if (type == 'COLOR') {
		return isNullOrEmptyString(value)
			? null
			: value;
	}

	if (type == 'DATE_TIME') {
		return isNullOrEmptyString(value)
			? null
			: new Date(value as string).getTime();
	}

	if (type == 'RELATION') {
		return isNullOrEmptyString(value)
			? null
			: value;
	}

	return value;
};

export { mapValueForDto };