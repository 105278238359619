import { RiArrowLeftRightLine } from '@remixicon/react';
import {
	App,
	Button,
	Card,
	Flex,
	Form,
	Input,
	Result,
	Spin,
	Steps,
	Tabs,
	TabsProps,
	Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ConnectionParameter } from 'shared/api/generatedApi/integrationsApi';
import ApacheKafka from 'shared/assets/integrations/KafkaLogo.png';
import MySql from 'shared/assets/integrations/MySqlLogo.png';
import Oracle from 'shared/assets/integrations/OracleLogo.png';
import PostgresImg from 'shared/assets/integrations/PostgresLogo.png';
import S3 from 'shared/assets/integrations/S3Logo.png';
import { routes } from 'shared/config';
import { errorHelper, randomString } from 'shared/helpers';
import { DetailFooter } from 'shared/ui';
import { DeleteIntegration } from '../deleteIntegration';
import { EditExchangeConfigInfo } from '../ExchangeConfiguration/editExchangeConfigInfo';
import { SetupMapping } from '../ExchangeConfiguration/setupMapping';
import { useCreateNewIntegration } from './createNewIntegration.model';

const connections: Array<{ name: string; image: string }> = [
	{
		name: 'PostgreSQL',
		image: PostgresImg,
	},
	{
		name: 'Kafka',
		image: ApacheKafka,
	},
	{
		name: 'Oracle',
		image: Oracle,
	},
	{
		name: 'MySQL',
		image: MySql,
	},
	{
		name: 'Amazon S3',
		image: S3,
	},
];

const CreateNewIntegrationUi: React.FC = () => {
	const [current, setCurrent] = useState<number>(0);
	const [connectionType, setConnectionType] = useState<string>('');
	const { createdIntegrationId } = useParams<string>();

	const [form] = useForm();

	const { notification } = App.useApp();

	const { connectionList, isLoading, error, create, createConnectionLoading } =
		useCreateNewIntegration();

	const handleSetConnectionType = (name: string) => setConnectionType(name);

	const onFinish = () => {
		const currentConnectionFields: Array<ConnectionParameter> = connectionList.find(
			(item) => item.system_name === connectionType
		).connection_parameters;
		const vals = form.getFieldsValue();
		let err: boolean = false;

		for (const param of currentConnectionFields) {
			if (param.mandatory && !vals[param.return_parametr]) {
				errorHelper(
					'Ошибка!',
					new Error('Необходимо заполнить все обязательные поля!'),
					notification
				);
				err = true;
			}
		}

		if (vals.extra) {
			try {
				JSON.parse(vals.extra);
			} catch (e) {
				errorHelper(
					'Ошибка!',
					new Error('Поле "extra" должно содержать данные в формате JSON!'),
					notification
				);
				err = true;
			}
		}

		if (err) return;

		const { connection_name, extra, ...connectionParams } = vals;

		create({
			connectionCreate: {
				system_id: connectionList.find((item) => item.system_name === connectionType)
					?.system_id,
				connection_name,
				connection_params: {
					connection_params: {
						...connectionParams,
						extra: JSON.parse(extra),
					},
				},
			},
		}).then((res) => res && next());
	};

	const tabs: TabsProps['items'] = useMemo(
		() => [
			{
				key: 'editExchangeConfigInfo',
				label: 'Информация о конфигурации обмена',
				children: <EditExchangeConfigInfo />,
			},
			{
				key: 'mappingSetup',
				label: 'Настройка маппинга',
				children: <SetupMapping />,
			},
		],
		[]
	);

	const steps = useMemo(
		() => [
			{
				title: 'Выбор подключения',
				content: (
					<Flex gap={12}>
						{connections.map((item) => (
							<Card
								style={{ width: 300 }}
								key={randomString(5)}
								onClick={() => handleSetConnectionType(item.name)}
								hoverable
							>
								<Flex vertical gap={12} align="center">
									<img src={item.image} width={64} height={64} alt={item.name} />
									<Typography.Text strong>{item.name}</Typography.Text>
								</Flex>
							</Card>
						))}
					</Flex>
				),
			},
			{
				title: 'Параметры подключения',
				content: (
					<>
						{isLoading && <Spin />}
						{error ? (
							<Result
								status="error"
								title="Ошибка при получении списка возможных подключений!"
								subTitle={JSON.stringify(error)}
							/>
						) : (
							!isLoading && (
								<Form
									layout="vertical"
									form={form}
									style={{ marginBottom: 54 }}
									onFinish={onFinish}
								>
									<Typography.Title level={2} style={{ marginBottom: 24 }}>
										Общая информация
									</Typography.Title>
									<Form.Item
										label="Наименование подключения"
										rules={[
											{ required: true, message: 'Это поле обязательно' },
										]}
										name="connection_name"
									>
										<Input />
									</Form.Item>
									<Typography.Title level={2} style={{ marginBottom: 24 }}>
										Настройки параметров подключения
									</Typography.Title>
									{connectionList
										?.find((item) => item.system_name === connectionType)
										?.connection_parameters.map((param) => (
											<Form.Item
												key={randomString(5)}
												name={param.return_parametr}
												rules={[
													{
														required: param.mandatory,
														message: 'Это поле обязательно',
													},
												]}
												label={param.title}
											>
												{param.type === 'json' ? (
													<Input.TextArea rows={5} />
												) : (
													<Input
														type={
															param.type === 'integer'
																? 'number'
																: 'text'
														}
													/>
												)}
											</Form.Item>
										))}

									<Button
										icon={<RiArrowLeftRightLine />}
										style={{ width: '100%' }}
										onClick={onFinish}
										loading={createConnectionLoading}
									>
										Создать подключение
									</Button>
								</Form>
							)
						)}
					</>
				),
			},
			{
				title: 'Настройка конфигураций обмена',
				content: <Tabs items={tabs} style={{ marginBottom: 74 }} />,
			},
		],
		[error, isLoading, connectionList, connectionType, createConnectionLoading]
	);

	const navigate = useNavigate();
	const items = steps.map((item) => ({ key: item.title, title: item.title }));

	const prev = () => {
		setCurrent(current - 1);
		if (current === 1) setConnectionType('');
	};

	const next = () => {
		setCurrent(current + 1);
	};

	const handleNavigate = () => navigate(`/${routes.integrations.main}`);

	useEffect(() => {
		if (connectionType) {
			next();
		} else {
			setConnectionType('');
			form.resetFields();
		}
	}, [connectionType, form]);

	useEffect(() => {
		if (createdIntegrationId) setCurrent(2);
	}, [createdIntegrationId]);

	return (
		<>
			<Flex vertical gap={24}>
				<Typography.Title level={1}>Создание нового подключения</Typography.Title>
				<Steps current={current} items={items} />
				<div>{steps[current]?.content}</div>
			</Flex>
			<DetailFooter customHandleBack={current > 0 ? prev : handleNavigate}>
				{current > 1 && <DeleteIntegration isNew id={createdIntegrationId} />}
			</DetailFooter>
		</>
	);
};

export const CreateNewIntegration = CreateNewIntegrationUi;
