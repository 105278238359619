import { Flex, Result, Typography } from 'antd';
import React from 'react';
import { FilterByActivityType } from 'features/integrations/IntegrationsListFilters/FilterByError';
import {
	useGetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetQuery,
	useListConnectionsMdmIntegrationsV1ConnectionsGetQuery,
} from 'shared/api/generatedApi/integrationsApi';
import { IntegrationCard, IntegrationCardSkeleton } from './components/integrationCard';

const IntegrationsListWidgetUi = () => {
	const { data, isLoading, error } = useListConnectionsMdmIntegrationsV1ConnectionsGetQuery({
		reverse: false,
	});

	const {
		data: systems,
		error: systemsErr,
		isLoading: systemsLoading,
	} = useGetSupportedSystemsMdmIntegrationsV1ConnectionsSystemsSupportedSystemsGetQuery({
		page: 1,
		pageSize: 10,
	});

	if (error || systemsErr)
		return (
			<Result
				status="error"
				title="Ошибка при получении списка интеграций!"
				subTitle={JSON.stringify(error)}
			/>
		);

	const groupedData = data?.data?.reduce((acc, item) => {
		if (!acc[item.source_type]) {
			acc[item.source_type] = [];
		}
		acc[item.source_type].push(item);
		return acc;
	}, {});

	return (
		<>
			<FilterByActivityType />
			{(isLoading || systemsLoading) && <IntegrationCardSkeleton />}
			{!isLoading && !systemsLoading && !error && !systemsErr && (
				<Flex vertical gap={12}>
					{systems.data.map((system) => {
						const items = groupedData[system.system_short_name];
						return (
							items?.length > 0 && (
								<Flex vertical gap={12} key={system.system_id}>
									<Typography.Title level={2}>
										{system.system_name}
									</Typography.Title>
									<Flex wrap={'wrap'} gap={12}>
										{items.map((item) => (
											<IntegrationCard
												key={item.connection_id}
												connection_id={item.connection_id}
												source_type={item.source_type}
												connection_info={item.connection_info}
											/>
										))}
									</Flex>
								</Flex>
							)
						);
					})}
				</Flex>
			)}
		</>
	);
};

export const IntegrationsListWidget = React.memo(IntegrationsListWidgetUi);
