import { Table } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import { ClassifierItemDto, ItemDto } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { useClassifierColumns } from './classifierItemsTable.model';

const ClassifierItemsTableUi = () => {
	const navigate = useNavigate();

	const {
		columns,
		dataSource,
		loading: colsLoading,
		handleTableChange,
		pagination,
		classifierGroupId,
	} = useClassifierColumns();

	return (
		<Table<ClassifierItemDto | ItemDto>
			loading={colsLoading}
			columns={columns}
			dataSource={dataSource}
			onChange={(pagination) => handleTableChange(pagination)}
			pagination={pagination}
			onRow={(r) => ({
				onClick: () =>
					navigate(
						`/${routes.classifiers.main}/${classifierGroupId}/${routes.classifiers.records}/${r.id}/${routes.classifiers.edit}`,
					),
			})}
			scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}
		/>
	);
};

export const ClassifierItemsTable = React.memo(ClassifierItemsTableUi);
