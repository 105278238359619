import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICatalogRecordsColumns } from '../types';

interface IUi {
	subMenuCollapsed: boolean;
	hasSider: boolean;
	catalogRecordsOriginalColumns: ICatalogRecordsColumns | null;
	catalogRecordsAjustedColumns: ICatalogRecordsColumns | null;
}

const initialState: IUi = {
	subMenuCollapsed: true,
	hasSider: false,
	catalogRecordsOriginalColumns: null,
	catalogRecordsAjustedColumns: null,
};

const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		setSubMenuCollapsed(state, { payload }: PayloadAction<boolean>) {
			state.subMenuCollapsed = payload;
		},
		addSider(state) {
			state.hasSider = true;
		},
		removeSider(state) {
			state.hasSider = false;
		},

		setCatalogRecordsOriginalColumns(state, action: PayloadAction<ICatalogRecordsColumns>) {
			state.catalogRecordsOriginalColumns = action.payload;
		},
		removeStoreCatalogRecordsColumns(state) {
			state.catalogRecordsOriginalColumns = null;
		},

		setCatalogRecordsAjustedColumns(state, action: PayloadAction<ICatalogRecordsColumns>) {
			state.catalogRecordsAjustedColumns = action.payload;
		},
		removeCatalogRecordsAjustedColumns(state) {
			state.catalogRecordsAjustedColumns = null;
		},
	},
});

export const {
	setSubMenuCollapsed,
	addSider,
	removeSider,
	setCatalogRecordsOriginalColumns,
	removeStoreCatalogRecordsColumns,
	setCatalogRecordsAjustedColumns,
	removeCatalogRecordsAjustedColumns,
} = uiSlice.actions;
export const uiReducer = uiSlice.reducer;
