import { Flex, Switch, Typography } from 'antd';
import React from 'react';
import { useCatalogRecords } from 'entities/catalogs/catalogRecords';
import { useAppSelector } from 'shared/hooks';

export const ToggleSubordinatedCatalogsRecords = () => {
	const currentCatalog = useAppSelector(
		(state) => state.entities.catalogs.catalogGroups.currentCatalogGroup
	);
	const disabled = !currentCatalog?.parent;
	const {
		isGetItemsLoading,
		isSubordinatedCatalogsRecordsShown,
		toggleSubordinatedCatalogsRecords,
	} = useCatalogRecords();

	return (
		<Flex gap={4}>
			<Switch
				size="small"
				checked={isSubordinatedCatalogsRecordsShown}
				disabled={disabled}
				onChange={toggleSubordinatedCatalogsRecords}
				loading={isGetItemsLoading}
			/>
			<Typography.Text disabled={disabled}>
				Отображать позиции подчиненных групп
			</Typography.Text>
		</Flex>
	);
};
