import { App } from 'antd';

import { removeMeasuresItem } from 'entities/metadata/measures';
import { useTransactions } from 'entities/transactions';
import { MeasurementGroupResponse } from 'shared/api/generatedApi/mdmgApi';
import { useDeleteMeasurementGroupMutation } from 'shared/api/generatedApi/mdmgApi';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteMeasureGroup = () => {
	const [deleteMeasureGroup, { isLoading }] = useDeleteMeasurementGroupMutation();
	const { getTransactions } = useTransactions();

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const deleteMesasureGroup = async (measure: MeasurementGroupResponse) => {
		const deleteMeasure = await deleteMeasureGroup({ ids: [measure.id] });
		if ('error' in deleteMeasure) {
			notification.error({ message: 'Ошибка!', description: `${deleteMeasure.error}` });
		}
		if ('data' in deleteMeasure) {
			dispatch(removeMeasuresItem(measure));
			getTransactions();
		}
	};

	return {
		deleteMesasureGroup,
		loading: isLoading,
	};
};
