import { notification } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setConstraintTables } from 'entities/metadata/constraintTables';
import { useLazyGetSearchResultQuery } from 'shared/api/generatedApi/searchApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';
import { useDebounce } from 'shared/hooks/useDebounce';

export const useSearchConstraintTables = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [getSearchResult, { isFetching: isSearchItemsLoading }] = useLazyGetSearchResultQuery();

	const searchHandler = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 0) {
			searchParams.set('searchValue', e.target.value);
			setSearchParams(searchParams);
		} else {
			searchParams.delete('searchValue');
			setSearchParams(searchParams);
		}
	}, 1000);

	const dispatch = useAppDispatch();

	const fetchSearch = (searchValue: string) => {
		getSearchResult({ entityType: 'restriction_table', textRequest: searchValue })
			.unwrap()
			.then((res) => {
				dispatch(setConstraintTables(res));
			})
			.catch((err) => {
				errorHelper(
					'Ошибка при получении списка ограничительных таблиц!',
					err,
					notification
				);
			});
	};

	useEffect(() => {
		const searchValue = searchParams.get('searchValue');
		if (searchValue) {
			fetchSearch(searchValue);
		}
	}, [searchParams.get('searchValue')]);

	return {
		searchHandler,
		searchValue: searchParams.get('searchValue'),
		isLoading: isSearchItemsLoading,
	};
};
