import { RiAddCircleLine } from '@remixicon/react';
import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import { routes } from 'shared/config';
import { NEW_ENTITY } from 'shared/helpers/Constants';

export const AddSimpleAttributeUi = () => {
	const navigate = useNavigate();

	const onClick = () => {
		navigate(`${window.location.pathname}/${routes.metadata.attribute}/${NEW_ENTITY}`);
	};

	return (
		<Button icon={<RiAddCircleLine />} style={{ maxWidth: 'fit-content' }} onClick={onClick}>
			Добавить атрибут
		</Button>
	);
};
