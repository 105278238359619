import { RiDeleteBinLine, RiEditLine, RiPushpinLine, RiUnpinLine } from '@remixicon/react';
import { Button, MenuProps } from 'antd';
import React from 'react';
import { aStyles, styles } from './DropdownButtonsStyles';

type DropdownButtonsType = (args: {
	handleEdit: () => void;
	handleSetFavourite: () => void;
	handleDelete: () => void;
	isFavorite: boolean;
	isPinButton?: boolean;
}) => MenuProps['items'];

export const DropdownButtons: DropdownButtonsType = ({
	handleEdit,
	isFavorite,
	isPinButton,
	handleSetFavourite,
	handleDelete,
}) => {
	return [
		{
			label: (
				<>
					{isPinButton ? (
						<Button
							type="text"
							onClick={handleSetFavourite}
							icon={
								isFavorite ? <RiUnpinLine size={16} /> : <RiPushpinLine size={16} />
							}
						>
							{isFavorite ? 'Открепить' : 'Закрепить'}
						</Button>
					) : (
						<a onClick={handleSetFavourite} style={styles}>
							{isFavorite ? <RiUnpinLine size={16} /> : <RiPushpinLine size={16} />}
							{isFavorite ? 'Открепить' : 'Закрепить'}
						</a>
					)}
				</>
			),
			key: '0',
		},

		{
			label: (
				<a onClick={handleEdit} type="text" style={aStyles}>
					<RiEditLine size={16} />
					Редактировать
				</a>
			),
			key: '1',
		},
		{
			label: (
				<a onClick={handleDelete} type="text" style={aStyles}>
					<RiDeleteBinLine size={16} />
					Удалить
				</a>
			),
			key: '2',
		},
	];
};
