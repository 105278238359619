import { Flex, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Transactions } from 'widgets/Transactions';
import { EditSimpleAttribute } from 'features/metadata/editSimpleAttribute';
import { clearBreadcrumbs, setBreadcrumbs } from 'entities/breadcrumbs';
import { useMetaAttributesGroups } from 'entities/metadata/attributesGroups/attributesGroups.model';
import { routes } from 'shared/config';
import { useAppDispatch, useAppSelector } from 'shared/hooks';

const AttributeDetailPageUi: React.FC = () => {
	const dispatch = useAppDispatch();
	const { currAttrGroup } = useMetaAttributesGroups();

	const { attributeId } = useParams();

	const currAttribute = useAppSelector(
		(state) => state.entities.metadata.metaAttributes.currentAttribute
	);

	useEffect(() => {
		if (currAttrGroup) {
			dispatch(
				setBreadcrumbs({
					displayName: 'Метаданные',
					url: `${routes.metadata.main}`,
					child: {
						displayName: `${currAttrGroup.displayName}`,
						url: `${routes.metadata.main}/${routes.metadata.group}/${currAttrGroup.id}`,
						child: {
							displayName: 'Редактирование атрибута',
							url: `${routes.metadata.main}/${routes.metadata.group}/${currAttrGroup.id}/${routes.metadata.attribute}/${attributeId}`,
						},
					},
				})
			);
		}
	}, [currAttrGroup, attributeId]);

	useEffect(() => {
		return () => {
			dispatch(clearBreadcrumbs());
		};
	}, []);

	return (
		<Flex vertical gap={24}>
			<Flex justify="space-between">
				<Flex vertical gap={12}>
					<Typography.Title level={1}>
						{currAttribute?.displayName ? currAttribute.displayName : 'Нет названия'}
					</Typography.Title>
				</Flex>

				<Transactions/>
			</Flex>
			<Flex gap={12}>
				<EditSimpleAttribute />
			</Flex>
		</Flex>
	);
};

export const AttributeDetailPage = React.memo(AttributeDetailPageUi);
