import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import React from 'react';
import { ICalendarProps } from './models';

const Calendar = React.memo(({ data, onClickItem } : ICalendarProps) => {
    return (
        <FullCalendar
            plugins={[ dayGridPlugin ]}
            initialView="dayGridMonth"
            events={data}
            eventClick={(e) => onClickItem(e.event.id)}
        />
    );
});

export {
    Calendar
};