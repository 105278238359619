import { Button, Flex } from 'antd';
import React from 'react';

interface ClassifierLinkedAttribute {
	id: string;
	displayName: string;
}

interface IClassifierNameFilterProps {
	attributes: ClassifierLinkedAttribute[];
	selectedAttribute: string | null;
	onChangeSelectedAttribute: (id: string | null) => void;
}

const ClassifierLinkAttributesFilterUi: React.FC<IClassifierNameFilterProps> = ({
																					attributes,
																					selectedAttribute,
																					onChangeSelectedAttribute,
																				}) => {
	const handleShowAll = async () => {
		onChangeSelectedAttribute(null);
	};

	const handleFilter = async (id: string) => {
		onChangeSelectedAttribute(id);
	};

	return (
		<Flex gap={4}>
			<Button type={selectedAttribute == null ? 'primary' : 'text'}
					onClick={handleShowAll}
			>
				Наполнение классификатора
			</Button>

			{attributes?.map((attr) => (
				<Button key={attr.id}
						type={selectedAttribute === attr.id ? 'primary' : 'text'}
						onClick={() => handleFilter(attr.id)}
				>
					{attr.displayName}
				</Button>
			))}
		</Flex>
	);
};

export const ClassifierLinkAttributesFilter = ClassifierLinkAttributesFilterUi;
