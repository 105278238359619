import { RiSearchLine } from '@remixicon/react';
import { Input, InputProps } from 'antd';
import React from 'react';
import s from './search.module.scss';

export const CustomSearch: React.FC<InputProps> = (props) => {
	return (
		<Input
			prefix={<RiSearchLine className={s.icon} />}
			placeholder="Найти"
			{...props}
			className={s.placeholder}
			allowClear={true}
		/>
	);
};
