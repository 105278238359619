import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ClassifierWithChildren = {
	id: string;
	displayName?: string;
	parent: boolean;
	children?: ClassifierWithChildren[];
};

interface IClassifiersTreeState {
	classifiersItemsTree: Array<ClassifierWithChildren>;
	currentClassifierTreeItem: ClassifierWithChildren;
}

const initialState: IClassifiersTreeState = {
	classifiersItemsTree: null,
	currentClassifierTreeItem: null,
};

const updateCatalogGroupById = (
	arr: ClassifierWithChildren[],
	parentId: string,
	node: ClassifierWithChildren,
): ClassifierWithChildren[] => {
	return arr.map((item) => {
		if (item.id === parentId) {
			return {
				...item,
				parent: true,
				isLeaf: false,
				children: item.children ? [ ...item.children, node ] : [ node ],
			};
		}

		if (item.children) {
			return {
				...item,
				children: updateCatalogGroupById(item.children, parentId, node),
			};
		}
		return item;
	});
};

const deleteCatalog = (
	tree: ClassifierWithChildren[],
	id: string,
): ClassifierWithChildren[] => {
	const data = tree.filter((item) => {
		if (item.id !== id && item.children?.length) {
			return deleteCatalog(item.children, id);
		}
		return item.id !== id;
	});

	return data.map((item) => {
		if (item.children) {
			return {
				...item,
				children: deleteCatalog(item.children, id),
			};
		}
		return item;
	});
};

const setChildren = (
	items: ClassifierWithChildren[],
	parentItemId: string,
	children: ClassifierWithChildren[],
) => {
	if (!items?.length) {
		return [];
	}

	return items.reduce((acc, item) => {
		if (item.id === parentItemId) {
			acc.push({
				...item,
				children: children,
			});
		} else {
			acc.push({
				...item,
				children: setChildren(item.children, parentItemId, children),
			});
		}
		return acc;
	}, []);
};

const classifiersItemsTreeSlice = createSlice({
	name: 'classifiersItemsTree',
	initialState,
	reducers: {
		setClassifiersItemsTree(state, action: PayloadAction<ClassifierWithChildren[]>) {
			state.classifiersItemsTree = action.payload;
		},
		setCurrentClassifierTreeItem(state, action: PayloadAction<ClassifierWithChildren>) {
			state.currentClassifierTreeItem = action.payload;
		},
		editCurrentClassifierItem(
			state,
			{ payload }: PayloadAction<Partial<ClassifierWithChildren>>,
		) {
			state.currentClassifierTreeItem = { ...state.currentClassifierTreeItem, ...payload };
		},
		addNewClassifierTreeItem(state, action: PayloadAction<ClassifierWithChildren>) {
			state.classifiersItemsTree = [
				...(state.classifiersItemsTree && state.classifiersItemsTree),
				action.payload,
			];
		},
		updateClassifierTreeItem(state, { payload }: PayloadAction<ClassifierWithChildren>) {
			state.classifiersItemsTree = state.classifiersItemsTree.map((item) => {
				if (item.id === payload.id) {
					return payload;
				}
				return item;
			});
		},
		setChildrenForItem(state, { payload }: PayloadAction<{
			parentItemId: string;
			children: ClassifierWithChildren[]
		}>) {
			const { parentItemId, children } = payload;

			state.classifiersItemsTree = setChildren(state.classifiersItemsTree, parentItemId, children);
		},
		updateClassifierTreeItemChild(
			state,
			{ payload }: PayloadAction<{ item: ClassifierWithChildren; parentId: string }>,
		) {
			state.classifiersItemsTree = updateCatalogGroupById(
				state.classifiersItemsTree,
				payload.parentId,
				payload.item,
			);
		},
		deleteClassifierTreeItem(state, { payload }: PayloadAction<ClassifierWithChildren>) {
			state.classifiersItemsTree = state.classifiersItemsTree.filter(
				(catalog) => catalog.id !== payload.id,
			);
		},
		removeCurrentClassifierTreeItem(state) {
			state.classifiersItemsTree = null;
		},
		deleteClassifierTreeItemById(state, { payload }: PayloadAction<string>) {
			state.classifiersItemsTree = deleteCatalog(state.classifiersItemsTree, payload);
		},
	},
});

export const {
	setClassifiersItemsTree,
	setCurrentClassifierTreeItem,
	editCurrentClassifierItem,
	addNewClassifierTreeItem,
	updateClassifierTreeItem,
	updateClassifierTreeItemChild,
	deleteClassifierTreeItem,
	removeCurrentClassifierTreeItem,
	deleteClassifierTreeItemById,
	setChildrenForItem,
} = classifiersItemsTreeSlice.actions;

export const classifiersItemsTreeReducer = classifiersItemsTreeSlice.reducer;
