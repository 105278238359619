import { App, TreeDataNode } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
	ClassifierItemTreeNode,
	useLazyGetClassifierItemTreeQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
	ClassifierWithChildren,
	removeCurrentClassifierTreeItem,
	setChildrenForItem,
	setClassifiersItemsTree,
	setCurrentClassifierTreeItem,
} from './classifiersItemsTree.store';

export type ClassifierItem = ClassifierItemTreeNode &
	TreeDataNode & {
	key: React.Key;
	title: string;
	children?: Array<ClassifierItem>;
};

export type DisplayTreeNode = Omit<ClassifierItem, 'title'> & { title: React.ReactNode };

const findChild = <T extends { id: string, children?: T[] }>(
	arr: Array<T>,
	id: string,
): T | undefined => {
	if (arr) {
		for (const attr of arr) {
			if (attr.id === id) {
				return attr;
			}
			if (attr.children && attr.children.length) {
				const child = findChild(attr.children, id);
				if (child) {
					return child;
				}
			}
		}
	}

	return undefined;
};

export const useClassifierItemsTreeHook = () => {
	const { notification } = App.useApp();

	const { classifierItemId, classifierGroupId } = useParams();

	const [ selectedClassifierTreeItem, setSelectedClassifierTreeItem ] =
		useState<ClassifierWithChildren>(null);
	const [ classifiersTree, setClassifiersTree ] = useState<Array<ClassifierWithChildren> | null>(null);

	const [
		getClassifierWithParentsById,
		{ isLoading: treeItemWithParentLoading, error: classifierWithParentsError },
	] = useLazyGetClassifierItemTreeQuery();

	const dispatch = useAppDispatch();
	const { classifiersItemsTree, currentClassifierTreeItem } = useAppSelector(
		(state) => state.entities.classifiers.classifiersItemsTree,
	);

	const getClassifiersTreeItem = async (
		parentItemId?: string,
	): Promise<ClassifierItemTreeNode | null> => {
		return await getClassifierWithParentsById({
			classifierId: classifierGroupId,
			fromItemId: parentItemId,
		})
			.unwrap()
			.then((res) => {
				dispatch(setChildrenForItem({
					parentItemId: parentItemId,
					children: res,
				}));
				return res;
			})
			.catch((e) => {
				errorHelper('Ошибка при получении вложенной записи!', e, notification);
				return null;
			});
	};

	const getClassifiersItemsTree = () =>
		getClassifierWithParentsById({
			classifierId: classifierGroupId,
			...(classifierItemId ? { includePathToItemId: classifierItemId } : {}),
		})
			.unwrap()
			.then(data => {
				dispatch(setClassifiersItemsTree(data));
				dispatch(setCurrentClassifierTreeItem(findChild(data, classifierItemId)));
			})
			.catch(err => {
				errorHelper(
					'Ошибка при получении справочников!',
					err,
					notification,
				);
			});

	useEffect(() => {
		if (!classifiersItemsTree) {
			getClassifiersItemsTree();
		}
	}, []);

	useEffect(() => {
		if (classifiersItemsTree) {
			setClassifiersTree(classifiersItemsTree);
		}
	}, [ classifiersItemsTree ]);

	useEffect(() => {
		setSelectedClassifierTreeItem(currentClassifierTreeItem);
	}, [ currentClassifierTreeItem ]);

	useEffect(() => {
		if (!classifierItemId && currentClassifierTreeItem) {
			dispatch(removeCurrentClassifierTreeItem());
		}
		if (classifierItemId) {
			dispatch(
				setCurrentClassifierTreeItem(findChild(classifiersItemsTree, classifierItemId)),
			);
		}
	}, [ classifierItemId ]);

	return {
		selectedClassifierTreeItem,
		classifiersTree,
		getClassifiersTreeItem,
		error: classifierWithParentsError,
		classifierWithParentsError,
		loading: treeItemWithParentLoading,
		findChild,
	};
};
