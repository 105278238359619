import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Providers } from 'app/providers';
import './i18n';
import 'moment/locale/ru';

moment.locale('ru');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Providers />);
