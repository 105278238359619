import { Col, Flex, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { AttributesWidget } from 'widgets/Catalogs/AttributesListWidget';
import { CatalogDeduplicationWidget } from 'widgets/Catalogs/CatalogDeduplicationWidget';
import { CatalogRestrictionsWidget } from 'widgets/Catalogs/CatalogRestrictionsWidget';
import { Transactions } from 'widgets/Transactions';
import { EditCatalogMainInfo } from 'features/catalogs/editCatalogMainInfo';
import { clearBreadcrumbs, setBreadcrumbs } from 'entities/breadcrumbs';
import { useCatalogGroups } from 'entities/catalogs/catalogGroups/catalog.model';
import { routes } from 'shared/config';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { DetailFooter, setSubMenuCollapsed } from 'shared/ui';

export const CatalogGroupPage: React.FC = () => {
	const { t } = useTypedTranslation();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { selectedCatalog, loading, error } = useCatalogGroups();
	const { catalogGroupId } = useParams();

	const items: TabsProps['items'] = [
		{
			key: '1',
			label: t(l => l.catalogs.mainInfo),
			children: (
				<EditCatalogMainInfo
					selectedCatalog={selectedCatalog}
					loading={loading}
					error={error}
				/>
			),
		},
		{
			key: '2',
			label: t(l => l.catalogs.records.attributesSetup),
			children: <AttributesWidget />,
		},
		{
			key: '3',
			label: t(l => l.catalogs.restrictions.restrictions),
			children: <CatalogRestrictionsWidget />,
		},
		{
			key: '4',
			label: t(l => l.catalogs.deduplication.main),
			children: <CatalogDeduplicationWidget />,
		},
	];

	// TODO переделать на роут
	const { defaultTabKey } = location.state?.defaultTabKey
		? location.state
		: { defaultTabKey: null };

	useEffect(() => {
		if (selectedCatalog) {
			dispatch(
				setBreadcrumbs({
					displayName: t(l => l.catalogs.catalogs),
					url: `${routes.catalogs.main}`,
					child: {
						displayName: `${selectedCatalog.displayName}`,
						url: `${routes.catalogs.main}/${selectedCatalog.id}`,
						child: {
							displayName: t(l => l.catalogs.catalogInfo),
							url: `${routes.catalogs.main}/${selectedCatalog.id}/edit`,
						},
					},
				})
			);
		}
	}, [selectedCatalog]);

	useEffect(() => {
		return () => {
			dispatch(clearBreadcrumbs());
			dispatch(setSubMenuCollapsed(false));
		};
	}, []);

	const handleBackClick = () => {
		navigate(`/${routes.catalogs.main}/${catalogGroupId}`);
	};

	return (
		<>
			<Flex vertical gap={12} style={{ marginBottom: 65 }}>
				<Flex justify="space-between">
					<Flex vertical gap={12} justify="start">
						<Typography.Title level={1}>
							{selectedCatalog && selectedCatalog.displayName}
						</Typography.Title>
					</Flex>

					<Transactions />
				</Flex>
				<Col xl={18} xs={24} sm={24} md={24} lg={18}>
					<Tabs items={items} destroyInactiveTabPane defaultActiveKey={defaultTabKey} />
				</Col>
			</Flex>

			<DetailFooter customHandleBack={handleBackClick} />
		</>
	);
};
