import { RiArrowRightCircleLine, RiCheckboxCircleFill } from '@remixicon/react';
import { Flex, List, Result, Spin, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import { routes } from 'shared/config';
import { useAppSelector } from 'shared/hooks';
import { colors } from 'shared/styles';
import { DropdownLink, Placeholder } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';
import { DeleteAttributeDeclaration } from '../AttrbuteDeclarations/DeleteAttribiteDeclaration';
import { EditCatalogMainInfo } from '../editCatalogMainInfo';
import { SetupAttributeDeclaration } from '../SetupAttributeDeclaration';
import { useEditAttributeList } from './editAttributeList.model';

const EditAttributeListUi: React.FC = () => {
	const { descriptions, attributes, loading, error } = useEditAttributeList();

	const currentCatalog = useAppSelector(
		(state) => state.entities.catalogs.catalogGroups.currentCatalogGroup
	);

	const navigate = useNavigate();

	if (error) {
		return <Result title="Ошибка!" subTitle={JSON.stringify(error)} />;
	}

	return (
		<>
			{loading && (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Spin size="large" />
				</div>
			)}
			{!loading && !attributes.length && (
				<Placeholder
					title="Пока не создано ни одного атрибута"
					subTitle="Для добавления нового атрибута нажмите на кнопку «Добавить атрибут»"
				/>
			)}
			{!loading && attributes.length > 0 && (
				<List
					itemLayout="horizontal"
					dataSource={attributes}
					renderItem={(item, i) => (
						<List.Item
							actions={[
								<ItemActions
									key={1}
									items={[
										currentCatalog?.displayNameAttributeDeclarationId !==
										item?.id
											? {
													label: (
														<EditCatalogMainInfo
															selectedCatalog={currentCatalog}
															loading={false}
															error={null}
															isDropdownLink={true}
															displayNameAttributeDeclarationId={
																item?.id
															}
														/>
													),
													key: `${item.id}-${i} `,
												}
											: null,
										{
											label: (
												<DropdownLink
													title="Перейти в карточку атрибута"
													icon={<RiArrowRightCircleLine size={16} />}
													callback={() =>
														navigate(
															`/${routes.metadata.main}/${routes.metadata.group}/${item.attribute.attributeGroupId}/${routes.metadata.attribute}/${item.attribute.id}`
														)
													}
												/>
											),
											key: `${item.id}-${i + 1} `,
										},
										{
											label: <SetupAttributeDeclaration attributeDeclaration={item} />,
											key: `${item?.id}-${i + 2} `,
										},
										{
											label: <DeleteAttributeDeclaration id={item.id} />,
											key: `${item.id}-${i + 3} `,
										},
									]}
								/>,
							]}
						>
							<List.Item.Meta
								title={
									currentCatalog?.displayNameAttributeDeclarationId !==
									item?.id ? (
										item?.attribute?.displayName
									) : (
										<Flex gap={6}>
											{`${item?.attribute?.displayName}`}

											<Flex align="center" gap={2}>
												<RiCheckboxCircleFill
													color={colors.success}
													size={14}
												/>
												<Typography.Text style={{ fontWeight: 400 }}>
													Используется в названии справочной записи
												</Typography.Text>
											</Flex>
										</Flex>
									)
								}
								description={descriptions ? descriptions[item?.id] : []}
							/>
						</List.Item>
					)}
				/>
			)}
		</>
	);
};

export const EditAttributeList = React.memo(EditAttributeListUi);
