import { RiDeleteBin6Line } from '@remixicon/react';
import { Flex, Button, Modal, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { randomString } from 'shared/helpers';
import { DropdownLink } from 'shared/ui';
import { useDeleteMeasureUnits } from './deleteMeasureUnit.model';

interface IDeleteMeasuresUnitProps {
	unitId: Array<string>;
	isDropdown?: boolean;
	detailUnitPage?: boolean;
}

const DeleteMeasureUnitUi: React.FC<IDeleteMeasuresUnitProps> = ({
	isDropdown,
	unitId,
	detailUnitPage,
}) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const { deleteMeasureUnits, loading } = useDeleteMeasureUnits();
	const navigate = useNavigate();

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = async (e: React.SyntheticEvent) => {
		e.stopPropagation();
		setIsModalOpen(false);
		await deleteMeasureUnits(unitId);
		if (!isDropdown && detailUnitPage) navigate(-1);
	};

	const handleCancel = (e: React.SyntheticEvent) => {
		e.stopPropagation();
		setIsModalOpen(false);
	};

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					Отмена
				</Button>

				<Button key="submit" type="primary" onClick={handleOk} loading={loading}>
					Удалить
				</Button>
			</Flex>
		),
		[loading, unitId]
	);

	return (
		<>
			{isDropdown ? (
				<DropdownLink
					title="Удалить"
					icon={<RiDeleteBin6Line size={16} />}
					callback={showModal}
				/>
			) : (
				<Button
					icon={<RiDeleteBin6Line />}
					type="text"
					onClick={showModal}
					disabled={!unitId?.length}
				>
					Удалить
				</Button>
			)}

			<Modal
				title="Удаление единицы измерения"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[footerButtons]}
				closeIcon={null}
				width={600}
			>
				<Flex vertical gap={24}>
					<Typography.Text>
						Вы действительно хотите удалить данную единицу измерения? Единица измерения
						будет удалена без возможности восстановления
					</Typography.Text>
				</Flex>
			</Modal>
		</>
	);
};

export const DeleteMeasureUnit = React.memo(DeleteMeasureUnitUi);
