import { NotificationConfig } from 'antd/es/notification/interface';

export const notificationStyles = {
	width: 310,
};

export const notificationConfig: NotificationConfig = {
	placement: 'topRight',
	top: 80,
	duration: 5,
};
