import { CSSProperties } from 'react';
import { colors } from '../../../styles';

export const styles: CSSProperties = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	color: colors.primary,
	fontWeight: 'bold',
	minWidth: '125px',
	gap: 5,
};
