import { Result, Spin } from 'antd';
import React, { ReactElement, ReactNode } from 'react';
import { Placeholder } from '../ui';

type RenderPlaceholderDescription = { title: string, subTitle?: string, isSearchIcon?: boolean };
type RenderErrorDescription = { title: string, subTitle: string };

type RenderElementType =
	{ placeholder: RenderPlaceholderDescription } |
	{ error: RenderErrorDescription } |
	{ content: ReactNode } |
	{ component: React.FC<any>, props: object } |
	null | void | undefined;

const renderContent = (fn: () => RenderElementType): ReactElement => {
	const result = fn();

	if (typeof result === 'object') {
		if ('component' in result) {
			const { component: Component, props } = result;
			return <Component {...props}></Component>;
		} else if ('content' in result) {
			const { content } = result;
			return <>{content}</>;
		} else if ('placeholder' in result) {
			const { title, subTitle, isSearchIcon} = result.placeholder;
			return <Placeholder title={title} subTitle={subTitle} isSearchIcon={isSearchIcon}/>;
		} else if ('error' in result) {
			const { title, subTitle } = result.error;
			return <Result title={title} subTitle={subTitle}/>;
		}
	}

	return <Spin size="large"/>;
};

export {
	renderContent,
};

export type { RenderElementType };
