import { Modal, Flex, Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';

interface IEditConstraintTableModalProps {
	isModalOpen: boolean;
	isEditLoading: boolean;
	handleEditTable: (tableName: string, tableDescr?: string) => void;
	handleCancel: () => void;
	title: string;
	description: string;
}

export const EditConstraintTableModal = ({
	handleCancel,
	handleEditTable,
	isEditLoading,
	isModalOpen,
	description,
	title,
}: IEditConstraintTableModalProps) => {
	const [isValid, setIsValid] = useState<boolean>(false);
	const [tableName, setTableName] = useState<string>(title);
	const [tableDescr, setTableDescr] = useState(description);

	const onSubmit = () => {
		handleEditTable(tableName, tableDescr);
	};

	const onCancel = () => {
		handleCancel();
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsValid(e.target.value.length > 0 ? true : false);
		setTableName(e.target.value);
	};

	const handleDescriptionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsValid(tableName.length > 0 ? true : false);
		setTableDescr(e.target.value);
	};

	useEffect(() => {
		if (!isModalOpen) setIsValid(false);
	}, [isModalOpen]);

	useEffect(() => {
		setTableName(title);
		setTableDescr(description);
	}, [isModalOpen]);
	return (
		<Modal
			open={isModalOpen}
			title="Редактирование ограничительной таблицы"
			closeIcon={null}
			onCancel={onCancel}
			onOk={onSubmit}
			destroyOnClose
			width={600}
			footer={
				<Flex gap={12} justify="flex-end">
					<Button onClick={onCancel} key="back" type="text">
						Отменить
					</Button>

					<Button
						disabled={!isValid}
						type="primary"
						key="submit"
						loading={isEditLoading}
						onClick={onSubmit}
					>
						Сохранить
					</Button>
				</Flex>
			}
		>
			<Form layout="vertical">
				<Form.Item label="Наименование ограничительной таблицы" required>
					<Input value={tableName} onChange={handleInputChange} allowClear />
				</Form.Item>
				<Form.Item label="Описание ограничительной таблицы">
					<Input value={tableDescr} onChange={handleDescriptionInputChange} allowClear />
				</Form.Item>
			</Form>
		</Modal>
	);
};
