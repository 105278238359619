import { Flex, Typography, Spin, Tree, Result } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { AddAttributeGroup } from 'features/metadata/addAttributeGroup/addAttributeGroup.ui';
import { useSearchAttributeGroups } from 'features/metadata/SearchAttributeGroups/SearchAttributeGroups.model';
import { SearchAttributeGroups } from 'features/metadata/SearchAttributeGroups/SearchAttributeGroups.ui';
import { DisplayTreeNode } from 'entities/catalogs/catalogGroups/catalog.model';
import { useAppDispatch } from 'shared/hooks';
import { Placeholder, setSubMenuCollapsed } from 'shared/ui';
import { useMetadataTreeData } from './metadataGroupTreeWidget.model';

const MetadataGroupTreeWidgetUi = () => {
	const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

	const dispatch = useAppDispatch();

	const {
		selectedTreeItem,
		selectCurrentAttributeGroup,
		onExpandTree,
		loading,
		error,
		expandedKeys,
		addMenuToTreeNodeItems,
		attrList,
		formatToTree,
		handleExpand,
	} = useMetadataTreeData();

	const { loading: isSearchLoading, searchValue } = useSearchAttributeGroups();

	const onSelect = (
		key,
		info: { node: DisplayTreeNode; selectedNodes: Array<DisplayTreeNode> }
	) => {
		setSelectedKeys(key);

		selectCurrentAttributeGroup(info.selectedNodes[0]);
	};

	useEffect(() => {
		if (selectedTreeItem) {
			setSelectedKeys([selectedTreeItem.key]);
		}
	}, [selectedTreeItem, attrList]);

	useEffect(() => {
		return () => {
			dispatch(setSubMenuCollapsed(true));
		};
	}, []);

	const treeData = useMemo(() => addMenuToTreeNodeItems(formatToTree(attrList)), [attrList]);

	return (
		<Flex vertical gap={24}>
			<Typography.Title level={1}>Группы атрибутов</Typography.Title>
			<SearchAttributeGroups />
			<AddAttributeGroup />
			{error && <Result status="error" subTitle="Ошибка при получении групп атрибутов!" />}
			{(loading || isSearchLoading) && <Spin size="small" />}
			{!loading &&
				!isSearchLoading &&
				!error &&
				(attrList && attrList.length > 0 ? (
					<Tree
						treeData={treeData}
						onSelect={onSelect}
						selectedKeys={selectedKeys}
						loadData={(treeNode) => onExpandTree(treeNode.id, !!treeNode.children)}
						checkStrictly
						expandedKeys={expandedKeys}
						onExpand={handleExpand}
					/>
				) : (
					<Placeholder
						title={
							searchValue
								? 'По вашему запросу ничего не найдено'
								: 'Вы не добавили еще ни одной группы атрибутов'
						}
						subTitle={
							searchValue
								? 'Проверьте, правильно ли введен ваш запрос, или попробуйте его изменить'
								: 'Для создания новой группы нажмите на кнопку «Добавить группу атрибутов»'
						}
						small
						isSearchIcon={!!searchValue}
					/>
				))}
		</Flex>
	);
};

export const MetadataGroupTreeWidget = React.memo(MetadataGroupTreeWidgetUi);
