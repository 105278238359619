import { App } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { deleteClassifierItemById } from 'entities/classifiers/classifiersItems/classifierItems.store';
import { deleteClassifierTreeItemById } from 'entities/classifiers/classifiersItemsTree/classifiersItemsTree.store';
import { useDeleteClassifierItemMutation } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteClassifierRecord = () => {
	const { notification } = App.useApp();

	const [fetchDeleteClassifierRecord, { error, isLoading }] = useDeleteClassifierItemMutation();

	const dispatch = useAppDispatch();

	const navigate = useNavigate();
	const { classifierGroupId, classifierItemId } = useParams();

	const handleDeleteClassifierRecord = async (id: string, isButton?: boolean) => {
		await fetchDeleteClassifierRecord({
			ids: [id],
		})
			.unwrap()
			.then(() => {
				dispatch(deleteClassifierItemById(id));
				dispatch(deleteClassifierTreeItemById(id));
				if (classifierItemId)
					navigate(
						`/${routes.classifiers.main}/${classifierGroupId}/${routes.classifiers.records}`
					);
				if (isButton)
					navigate(
						`/${routes.classifiers.main}/${classifierGroupId}/${routes.classifiers.records}`
					);
			})
			.catch((e) => {
				errorHelper('Ошибка при удалении записи классификатора!', e, notification);
			});
	};

	return {
		loading: isLoading,
		error,
		handleDeleteClassifierRecord,
	};
};
