import { Flex, Typography, Spin, Tree, Result } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { AddNewCatalog } from 'features/catalogs/AddNewCatalog';
import { useSearchCatalogs } from 'features/catalogs/SearchCatalogs/SearchCatalogs.model';
import { SearchCatalogs } from 'features/catalogs/SearchCatalogs/SearchCatalogs.ui';
import { DisplayTreeNode } from 'entities/catalogs/catalogGroups/catalog.model';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { Placeholder, setSubMenuCollapsed } from 'shared/ui';
import { useCatalogGroupTree } from './catalogGroupTreeWidget.model';

const CatalogGroupTreeWidgetUi = () => {
	const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

	const dispatch = useAppDispatch();
	const {
		selectedTreeItem,
		selectCurrentCatalog,
		onExpandTree,
		loading,
		error,
		catalogList,
		addMenuToTreeNodeItems,
		formatToTree,
		expandedKeys,
		handleExpand,
	} = useCatalogGroupTree();

	const { loading: isSearchLoading, searchValue } = useSearchCatalogs();

	const { t } = useTypedTranslation();

	const onSelect = (
		key,
		info: { node: DisplayTreeNode; selectedNodes: Array<DisplayTreeNode> }
	) => {
		setSelectedKeys(key);
		selectCurrentCatalog(info.selectedNodes[0]);
	};

	useEffect(() => {
		if (selectedTreeItem) {
			setSelectedKeys([selectedTreeItem.key]);
		}
	}, [selectedTreeItem, catalogList]);

	useEffect(() => {
		return () => {
			dispatch(setSubMenuCollapsed(true));
		};
	}, []);

	const treeData = useMemo(
		() => addMenuToTreeNodeItems(formatToTree(catalogList)),
		[catalogList]
	);

	return (
		<Flex vertical gap={24}>
			<Typography.Title level={1}>Справочники</Typography.Title>
			<SearchCatalogs />
			<AddNewCatalog />
			{error && (
				<Result title={t(l => l.common.statuses.error)} subTitle={JSON.stringify(error)} />
			)}
			{(loading || isSearchLoading) && <Spin size="small" />}
			{!loading &&
				!isSearchLoading &&
				!error &&
				(catalogList && catalogList?.length > 0 ? (
					<Tree
						treeData={treeData}
						onSelect={onSelect}
						selectedKeys={selectedKeys}
						loadData={(treeNode) => onExpandTree(treeNode.id, !!treeNode.children)}
						checkStrictly
						expandedKeys={expandedKeys}
						onExpand={handleExpand}
					/>
				) : (
					<Placeholder
						title={
							searchValue
								? t(l => l.common.search.nothingFound)
								: t(l => l.catalogs.groups.noGroupErr)
						}
						subTitle={
							searchValue
								? t(l => l.common.search.checkRequest)
								: t(l => l.catalogs.groups.createNewGroup)
						}
						small
						isSearchIcon={!!searchValue}
					/>
				))}
		</Flex>
	);
};

export const CatalogGroupTreeWidget = React.memo(CatalogGroupTreeWidgetUi);
