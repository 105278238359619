import { App } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { useCreateConfigurationMdmIntegrationsV1ConnectionsConnectionIdConfigurationsPostMutation } from 'shared/api/generatedApi/integrationsApi';
import { errorHelper } from 'shared/helpers';

export const useCreateExchangeConfig = () => {
	const { integrationId } = useParams();
	const navigate = useNavigate();

	const { notification } = App.useApp();

	const [createConfig, { isLoading }] =
		useCreateConfigurationMdmIntegrationsV1ConnectionsConnectionIdConfigurationsPostMutation();

	const createExchangeConfig = async () => {
		await createConfig({
			connectionId: integrationId,
			configurationCreateRequest: {
				name: '',
				description: '',
				on_delete_action: '',
				status: 'active',
				execution_type: 'manual',
				schedule_interval: '',
				execution: false,
				extraction_targets: [],
			},
		})
			.unwrap()
			.then((res) => {
				navigate(`${res.configuration_id}`);
			})
			.catch((e) => errorHelper('Ошибка при создании конфигурации обмена!', e, notification));
	};

	return {
		createExchangeConfig,
		isLoading,
	};
};
