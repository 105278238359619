import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogDto, CatalogTreeNode } from 'shared/api/generatedApi/mdmgApi';
import { isEmpty } from 'shared/helpers';

interface ICatalogsState {
	catalogGroups: Array<CatalogDto>;
	currentCatalogGroup: CatalogDto;
}

const initialState: ICatalogsState = {
	catalogGroups: null,
	currentCatalogGroup: null,
};

const catalogsSlice = createSlice({
	name: 'catalogGroups',
	initialState,
	reducers: {
		setCatalogGroups(state, action: PayloadAction<CatalogDto[]>) {
			state.catalogGroups = action.payload;
		},
		setCurrentCatalog(state, action: PayloadAction<CatalogDto>) {
			state.currentCatalogGroup = action.payload;
		},
		editCurrentCatalog(state, { payload }: PayloadAction<Partial<CatalogDto>>) {
			state.currentCatalogGroup = { ...state.currentCatalogGroup, ...payload };
			if (!isEmpty(state.catalogGroups)) {
				state.catalogGroups = state.catalogGroups.map((catalog) => {
					if (catalog?.id === payload?.id) return { ...catalog, ...payload };
					return catalog;
				});
			}
		},
		addNewCatalogGroup(state, action: PayloadAction<CatalogDto>) {
			state.catalogGroups.push(action.payload);
		},
		updateCatalogGroup(state, { payload }: PayloadAction<CatalogDto>) {
			const updateCatalogGroupById = (
				tree: CatalogDto[],
				updatedItem: CatalogDto
			): CatalogDto[] => {
				return tree.map((item) => {
					if (item.id === updatedItem.id) {
						return updatedItem;
					}
					if (
						'children' in item &&
						Array.isArray(item.children) &&
						item.children.length
					) {
						return {
							...item,
							children: updateCatalogGroupById(item.children, updatedItem),
						};
					}
					return item;
				});
			};

			state.catalogGroups = updateCatalogGroupById(state.catalogGroups, payload);
		},
		updateCatalogGroupChildTreeItem(
			state,
			{ payload }: PayloadAction<{ item: CatalogDto[]; parentId: string }>
		) {
			const updateCatalogGroupById = (
				arr: CatalogTreeNode[],
				parentId: string,
				node: CatalogTreeNode[]
			): CatalogTreeNode[] => {
				return arr.map((item) => {
					if (item.id === parentId) {
						return {
							...item,
							parent: true,
							isLeaf: false,
							children: item.children ? [...item.children, ...node] : [...node],
						};
					}

					if (item.children) {
						return {
							...item,
							children: updateCatalogGroupById(item.children, parentId, node),
						};
					}
					return item;
				});
			};

			state.catalogGroups = updateCatalogGroupById(
				state.catalogGroups,
				payload.parentId,
				payload.item
			);
		},
		deleteCatalogGroup(state, { payload }: PayloadAction<CatalogDto>) {
			state.catalogGroups = state.catalogGroups.filter(
				(catalog) => catalog.id !== payload.id
			);
		},
		removeCurrentCatalogGroup(state) {
			state.currentCatalogGroup = null;
		},
		deleteCatalogById(state, { payload }: PayloadAction<string>) {
			const deleteCatalog = (tree: CatalogTreeNode[], id: string): CatalogTreeNode[] => {
				const data = tree.filter((item) => {
					if (item.id !== id && item.children?.length) {
						return deleteCatalog(item.children, id);
					}
					return item.id !== id;
				});

				return data.map((item) => {
					if (item.children) {
						return {
							...item,
							children: deleteCatalog(item.children, id),
						};
					}
					return item;
				});
			};

			state.catalogGroups = deleteCatalog(state.catalogGroups, payload);
		},
	},
});

export const {
	setCatalogGroups,
	setCurrentCatalog,
	updateCatalogGroup,
	updateCatalogGroupChildTreeItem,
	deleteCatalogGroup,
	removeCurrentCatalogGroup,
	addNewCatalogGroup,
	editCurrentCatalog,
	deleteCatalogById,
} = catalogsSlice.actions;

export const catalogsReducer = catalogsSlice.reducer;
