import { App } from 'antd';
import { useNavigate } from 'react-router';
import { deleteMetaAttributesGroup } from 'entities/metadata/attributesGroups/attributesGroups.store';
import { useTransactions } from 'entities/transactions';
import { useDeleteAttributeGroupMutation } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteAttributeGroup = () => {
	const { notification } = App.useApp();

	const [deleteAttributeGroup, { isLoading, error }] = useDeleteAttributeGroupMutation();

	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	const { getTransactions } = useTransactions();

	const deleteGroup = async (id: string) => {
		const data = await deleteAttributeGroup({ ids: [id] });
		if ('error' in data) {
			errorHelper('Ошибка при добавлении новой группы атрибутов!', data.error, notification);
		}

		if ('data' in data) {
			getTransactions();
			dispatch(deleteMetaAttributesGroup(id));
			navigate(`/${routes.metadata.main}`);
		}
	};

	return {
		loading: isLoading,
		error,
		deleteGroup,
	};
};
