import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
	AttributeDto,
	RestrictionTableDto,
	RestrictionTableItemResponse,
} from 'shared/api/generatedApi/mdmgApi';

export interface IConstraintTableDetailsStore {
	table: RestrictionTableDto | null;
	columns: AttributeDto[] | null;
	tableItems: RestrictionTableItemResponse[] | null;
}

const initialState: IConstraintTableDetailsStore = {
	table: null,
	columns: null,
	tableItems: null,
};

export const constraintTableDetailsSlice = createSlice({
	name: 'constraintTableDetails',
	initialState,
	reducers: {
		setConstraintTableInfo(state, { payload }: PayloadAction<RestrictionTableDto>) {
			state.table = payload;
		},
		setConstraintTableColumns(state, { payload }: PayloadAction<AttributeDto[]>) {
			state.columns = payload;
		},
		removeConstraintTableDetails(state) {
			state.columns = null;
			state.table = null;
		},
		setConstraintTableItems(state, { payload }: PayloadAction<RestrictionTableItemResponse[]>) {
			state.tableItems = payload;
		},
		addConstraintTableItem(state, { payload }: PayloadAction<RestrictionTableItemResponse>) {
			state.tableItems = [...state.tableItems, payload];
		},
		updateConstraintTableItem(state, { payload }: PayloadAction<RestrictionTableItemResponse>) {
			state.tableItems = state.tableItems.map((item) =>
				item.id === payload.id ? payload : item
			);
		},
		deleteConstraintTableItems(state, { payload }: PayloadAction<string[]>) {
			state.tableItems = state.tableItems.filter((item) => !payload.includes(item.id));
		},
	},
});

export const constraintTableDetailsReducer = constraintTableDetailsSlice.reducer;
export const {
	setConstraintTableInfo,
	setConstraintTableColumns,
	removeConstraintTableDetails,
	setConstraintTableItems,
	addConstraintTableItem,
	updateConstraintTableItem,
	deleteConstraintTableItems,
} = constraintTableDetailsSlice.actions;
