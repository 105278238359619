const changeSearchParam = (searchParams, key, value) => {
	const newSearchParams = new URLSearchParams(searchParams);
	if (value !== undefined && value !== null) {
		newSearchParams.set(key, String(value));
	} else {
		newSearchParams.delete(key);
	}
	return newSearchParams;
};

export {
	changeSearchParam,
};