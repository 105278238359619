import { RiArrowDownSLine } from '@remixicon/react';
import { Button, Dropdown, Flex, MenuProps } from 'antd';
import moment, { Moment } from 'moment';
import React, { useEffect } from 'react';
import { useEnumStateViaSearchParam } from '../../../hooks/useEnumStateViaSearchParam';
import { ChartGanttViewType } from './models';

const searchParamKey = 'ganttViewType';

const chartGanttViewTypeNames: Record<ChartGanttViewType, string> = {
	Month: 'Месяц',
	Quarter: 'Квартал',
	Week: 'Неделя',
	Year: 'Год',
};

interface PeriodSelectorProps {
	onChangeMoments: (startMoment: Moment, endMoment: Moment) => void;
}

const PeriodSelector = React.memo(({ onChangeMoments }: PeriodSelectorProps) => {
	const [viewType, setViewType] = useEnumStateViaSearchParam(
		ChartGanttViewType,
		searchParamKey,
		ChartGanttViewType.Year
	);

	useEffect(() => {
		if (viewType == ChartGanttViewType.Year) {
			onChangeMoments(moment().startOf('year'), moment().endOf('year'));
		} else if (viewType == ChartGanttViewType.Month) {
			onChangeMoments(moment().startOf('month'), moment().endOf('month'));
		} else if (viewType == ChartGanttViewType.Week) {
			onChangeMoments(moment().startOf('week'), moment().endOf('week'));
		} else if (viewType == ChartGanttViewType.Quarter) {
			onChangeMoments(moment().startOf('quarter'), moment().endOf('quarter'));
		}
	}, [viewType]);

	const items: MenuProps['items'] = Object.entries(chartGanttViewTypeNames).map(
		([key, value]) => ({
			key: key,
			label: <div onClick={() => setViewType(key as ChartGanttViewType)}>{value}</div>,
		})
	);
	const menu = {
		items,
		selectable: true,
		selectedKeys: [viewType],
	};

	return (
		<Flex style={{ marginBottom: '24px' }}>
			<div style={{ marginLeft: 'auto' }}>
				<Dropdown menu={menu} placement="bottom" trigger={['hover']}>
					<Button icon={<RiArrowDownSLine />} type="text" iconPosition={'end'}>
						{chartGanttViewTypeNames[viewType]}
					</Button>
				</Dropdown>
			</div>
		</Flex>
	);
});

export { PeriodSelector };
