import { App } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import {
	useLazyGetCatalogsQuery,
	useLazyGetCatalogItemsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { ItemValuesType } from 'shared/helpers/types';

export interface IChip {
	id: string;
	displayName: string;
}

export const useEditRelationAttribute = (value: ItemValuesType[]) => {
	const { notification } = App.useApp();

	const [getCatalogs] = useLazyGetCatalogsQuery();

	const [getCatalogItems, { isFetching: isCatalogItemsLoading }] = useLazyGetCatalogItemsQuery();

	const [treeData, setTreeData] = useState<Omit<DefaultOptionType, 'label'>[]>([]);
	const [chips, setChips] = useState<IChip[]>([]);

	const [isTableColumnsLoading, setIsTableColumnsLoading] = useState(false);
	const [isRootGroupsLoading, setIsRootGroupsLoading] = useState(false);

	const getRootGroups = async () => {
		setIsRootGroupsLoading(true);
		getCatalogs({ root: true })
			.unwrap()
			.then((res) => {
				setTreeData(
					res.map((group) => ({
						id: group.id,
						title: group.displayName || '<Название не заполнено>',
						value: group.id,
						isLeaf: !group.parent,
					}))
				);
			})
			.catch((err) => errorHelper('Ошибка получения справочников!', err, notification))
			.finally(() => setIsRootGroupsLoading(false));
	};

	const getSubGroups = async (parentId: string) => {
		await getCatalogs({
			parentIds: [parentId],
		})
			.unwrap()
			.then((res) => {
				setTreeData((prev) => {
					return [
						...prev,
						...res.map((subGroup) => ({
							id: subGroup.id,
							pId: subGroup.parentId,
							title: subGroup.displayName || '<Название не заполнено>',
							value: subGroup.id,
							isLeaf: !subGroup.parent,
						})),
					];
				});
			})
			.catch((err) => errorHelper('Ошибка получения справочников!', err, notification));
	};

	const clearTreeData = () => {
		setTreeData([]);
	};

	const getProperties = (parentId: string) => {
		return getCatalogItems({
			catalogIds: [parentId],
		})
			.unwrap()
			.then((res) => res.data)
			.catch((err) =>
				errorHelper('Ошибка получения записей справочников!', err, notification)
			);
	};

	useEffect(() => {
		if (value && value.length > 0) {
			setIsTableColumnsLoading(true);
			getCatalogItems({
				ids: value as string[],
			})
				.unwrap()
				.then((res) => {
					setChips(
						res.data.map((item) => ({
							displayName: item.displayName || '<Название не заполнено>',
							id: item.id,
						}))
					);
				})
				.catch((err) =>
					errorHelper(
						'Ошибка получения выбранных записей справочников для атрибута типа "Связь"!',
						err,
						notification
					)
				)
				.finally(() => setIsTableColumnsLoading(false));
		}
	}, [value]);

	return {
		getRootGroups,
		getSubGroups,
		getProperties,
		isRootGroupsLoading,
		isCatalogItemsLoading,
		isTableColumnsLoading,
		treeData,
		chips,
		clearTreeData,
	};
};
