import { colors } from '../../cssConstants';

export const inputStyles = {
	activeBorderColor: colors.primaryBorderFaded,
	activeBg: colors.whiteBase,
	border: 'none',
	hoverBg: colors.lightGray,
	hoverBorderColor: colors.primaryBorderFaded,
	paddingBlock: 8,
	paddingInline: 10,
};
