import { Checkbox, Flex, Input, Tree } from 'antd';
import type { GetProp } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
	DeduplicationOptionDto,
	UpdateDeduplicationOptionRequest,
} from 'shared/api/generatedApi/deduplicationApi';
import { CatalogDto } from 'shared/api/generatedApi/mdmgApi';
import { useAppSelector, useTypedTranslation } from 'shared/hooks';
import { SelectModal } from 'shared/ui';
import { useCatalogDeduplicationCondition } from './CatalogDeduplicationCondition.model';

export type ISelectModalValueType = {
	label: string;
	callback: () => void;
	key?: React.Key;
};

export type ISelectAttributesType = {
	label: string;
	value: string;
	callback: () => void;
	key?: React.Key;
};

interface ICatalogDeduplicationCondition {
	deduplicationOption?: DeduplicationOptionDto;
}

export const CatalogDeduplicationCondition = ({
	deduplicationOption,
}: ICatalogDeduplicationCondition): React.ReactElement => {
	const {
		filters = null,
		startMode,
		scheduler = '',
		searchDirection,
		autoConsolidation,
		useForImport,
	} = deduplicationOption;

	const { t } = useTypedTranslation();

	const [attributes, setAtributes] = useState<Array<ISelectAttributesType>>([]);
	const [delCatalogId, setDelCatalogId] = useState<string>('');
	const [delAttributesId, setDelAttributesId] = useState<string>('');
	const [selectedAttributes, setSelectedAttributes] = useState<Array<ISelectAttributesType>>([]);
	const [checkedAttributes, setCheckedAttributes] = useState<Array<ISelectAttributesType>>([]);

	const {
		tree,
		getCatalogs,
		passSelectedCatalog,
		declarations,
		editDeduplicationOption,
		initialCatalogs,
		passDeduplicationOption,
		declaratedAttributes,
		editMappingList,
		isChangingMappingList,
	} = useCatalogDeduplicationCondition();

	const { catalogGroupId } = useParams();

	const currentCatalogName = useAppSelector(
		(state) => state.entities.catalogs?.catalogGroups?.currentCatalogGroup?.displayName
	);
	const currentCatalog = {
		label: currentCatalogName,
		callback: () => {},
		key: catalogGroupId,
	};

	const [selectedCatalogs, setSelectedCatalogs] = useState<Array<ISelectModalValueType>>([
		currentCatalog,
	]);
	const [checkedCatalogs, setCheckedCatalogs] = useState<Array<ISelectModalValueType>>([
		currentCatalog,
	]);
	const [optionName, setOptionName] = useState<string>(deduplicationOption.displayName);

	const optionRequestData: UpdateDeduplicationOptionRequest = {
		displayName: optionName,
		targetCatalogIds: selectedCatalogs?.map((item) => item.key as string),
		filters: filters,
		startMode: startMode,
		scheduler: scheduler,
		searchDirection: searchDirection,
		autoConsolidation: autoConsolidation,
		useForImport: useForImport,
	};

	const onCatalogCheck = (_, { checkedNodes }) => {
		const newData = convertCatalogsToSelectModalFormat(checkedNodes);
		setCheckedCatalogs([currentCatalog, ...newData]);
	};

	const convertCatalogsToSelectModalFormat = (
		catalogs: CatalogDto[] | any
	): Array<ISelectModalValueType> => {
		return catalogs
			.map(({ displayName, id }) => ({
				label: displayName,
				callback: () => setDelCatalogId(id as string),
				key: id,
			}))
			.filter((catalog) => catalog.key !== currentCatalog.key);
	};

	const onAttributesChange: GetProp<typeof Checkbox.Group, 'onChange'> = (atrs) => {
		setCheckedAttributes(attributes.filter((atr) => atrs.includes(atr.value)));
	};

	const onCatalogOkCallback = () => {
		setSelectedCatalogs(checkedCatalogs);

		const newOptionRequestData = {
			...optionRequestData,
			targetCatalogIds: checkedCatalogs.map((item) => item.key as string),
		};

		editDeduplicationOption(deduplicationOption.id, newOptionRequestData);
	};

	const onCatalogCloseCallback = () => setCheckedCatalogs(selectedCatalogs);

	const onAttributesOkCallback = () => {
		setSelectedAttributes(checkedAttributes);
		editMappingList(checkedAttributes.map((d) => d.value));
	};

	const onAttributesCloseCallback = () => setCheckedAttributes(selectedAttributes);

	const onChangeOptionName = (displayName: string) => setOptionName(displayName);

	const saveOptionName = () => editDeduplicationOption(deduplicationOption.id, optionRequestData);

	useEffect(() => {
		if (delCatalogId) {
			const newCatalogs = selectedCatalogs.filter(
				(cat) => ![delCatalogId, currentCatalog.key].includes(cat.key as string)
			);

			setSelectedCatalogs([currentCatalog, ...newCatalogs]);
			setCheckedCatalogs([currentCatalog, ...newCatalogs]);
			setDelCatalogId('');

			const newOptionRequestData = {
				...optionRequestData,
				targetCatalogIds: [currentCatalog, ...newCatalogs].map(
					(item) => item.key as string
				),
			};

			editDeduplicationOption(deduplicationOption.id, newOptionRequestData);
		}
	}, [delCatalogId]);

	useEffect(() => {
		if (delAttributesId) {
			const newAttributes = selectedAttributes.filter((atr) => atr.label !== delAttributesId);

			setSelectedAttributes(newAttributes);
			setCheckedAttributes(newAttributes);
			setDelAttributesId('');
		}
	}, [delAttributesId]);

	useEffect(() => {
		passSelectedCatalog(selectedCatalogs);
	}, [selectedCatalogs]);

	useEffect(() => {
		if (!declarations.length) setAtributes([]);
		if (declarations.length) {
			const newAttributes = declarations.map((d) => ({
				label: d.attribute.displayName,
				value: d.id,
				callback: () => setDelAttributesId(d.id),
				key: d.id,
			}));

			setAtributes(newAttributes);
		}
	}, [declarations]);

	useEffect(() => {
		getCatalogs(null, deduplicationOption.targetCatalogIds);
		passDeduplicationOption(deduplicationOption);
	}, []);

	useEffect(() => {
		const initCatalogs = convertCatalogsToSelectModalFormat(initialCatalogs);
		setCheckedCatalogs([currentCatalog, ...initCatalogs]);
		setSelectedCatalogs([currentCatalog, ...initCatalogs]);
	}, [initialCatalogs]);

	useEffect(() => {
		setCheckedAttributes(declaratedAttributes);
	}, [declaratedAttributes]);

	return (
		<Flex vertical gap={12}>
			<Flex vertical gap={6}>
				Наименование
				<Input
					placeholder={t(l => l.catalogs.deduplication.importSearch)}
					allowClear
					value={optionName}
					onChange={(e) => onChangeOptionName(e.currentTarget.value)}
					onBlur={saveOptionName}
				/>
			</Flex>

			<Flex vertical gap={6}>
				Проверяемые справочники
				<SelectModal
					modalTitle={t(l => l.catalogs.deduplication.addCatalog)}
					onOkCallback={onCatalogOkCallback}
					onCloseCallback={onCatalogCloseCallback}
					modalWidth={600}
					selectValues={checkedCatalogs}
				>
					<div style={{ maxHeight: 'calc(100vh - 350px)', overflow: 'scroll' }}>
						<Tree
							checkable
							checkStrictly
							selectable={false}
							onCheck={onCatalogCheck}
							treeData={tree}
							loadData={(treeNode) => getCatalogs(treeNode.id)}
							onExpand={(_, { node }) => getCatalogs(node.id)}
							checkedKeys={checkedCatalogs.map((cat) => cat.key)}
						/>
					</div>
				</SelectModal>
			</Flex>

			<Flex vertical gap={6}>
				{t(l => l.catalogs.deduplication.attributesList)}
				<SelectModal
					modalTitle={t(l => l.catalogs.deduplication.addAttribute)}
					onOkCallback={onAttributesOkCallback}
					onCloseCallback={onAttributesCloseCallback}
					modalWidth={600}
					selectValues={declaratedAttributes}
					loading={isChangingMappingList}
				>
					<Flex vertical gap={12}>
						{t(l => l.catalogs.deduplication.selectAttribute)}
						<Checkbox.Group
							onChange={onAttributesChange}
							value={checkedAttributes.map((atr) => atr.value)}
						>
							<Flex vertical gap={12}>
								{attributes.map((atr) => (
									<Checkbox value={atr.value} key={atr.key}>
										{atr.label}
									</Checkbox>
								))}
							</Flex>
						</Checkbox.Group>
					</Flex>
				</SelectModal>
			</Flex>
		</Flex>
	);
};
