import { App } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLazyGetCatalogItemsQuery } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useTypedTranslation } from 'shared/hooks';
import { DropdownSelect } from 'shared/ui';

interface RelationToCatalogInputProps {
	catalogIds: string[];
	value: string;
	onChange: (value: string) => void;
}

const RelationToCatalogInput = React.memo(({
											   catalogIds,
											   value,
											   onChange,
										   }: RelationToCatalogInputProps) => {
	const { notification } = App.useApp();
	const { t } = useTypedTranslation();

	const [ options, setOptions ] = useState<any[] | null>(null);

	const [ getItems ] = useLazyGetCatalogItemsQuery();

	useEffect(() => {
		if (catalogIds.length > 0) {
			getItems({ catalogIds: catalogIds })
				.unwrap()
				.then((res) => {
					setOptions(res.data
						.map((item) => ({
							label: item.displayName || t(l => l.common.defaultNames.emptyName),
							value: item.id,
						})));
				})
				.catch((err) => {
					errorHelper(
						'Ошибка получения записей справочников для атрибута с типом "Связь"!',
						err,
						notification,
					);
					return new Error();
				});
		}
	}, [ catalogIds ]);


	return (
		<DropdownSelect allowClear
						options={options}
						value={value}
						onChange={onChange}
		/>
	);
});

export {
	RelationToCatalogInput,
};