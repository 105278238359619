import { CSSProperties } from 'react';
import { colors } from '../../../styles';

export const chipStyles: CSSProperties = {
	display: 'flex',
	alignItems: 'center',
	gap: 8,
	border: 'none',
	borderRadius: '30px',
	color: colors.text,
	paddingInline: 9,
	paddingBlock: 4,
	maxWidth: 'fit-content',
	fontWeight: 500,
	margin: 0,
	fontSize: 12,
};

interface IColorHelperProps {
	main: string;
	bg: string;
}

export enum ChipStatusEnum {
	normalized = 'normalized',
	non_normalized = 'non_normalized',
	blocked = 'blocked',
	deleted = 'deleted',
	duplicate = 'duplicate',
}

export enum ChipStatusRuEnum {
	normalized = 'Нормализована',
	non_normalized = 'Не нормализована',
	blocked = 'Заблокирована',
	deleted = 'Удалена',
	duplicate = 'Дубликат',
}

export type ChipStatus =
	| 'primary'
	| 'secondary'
	| 'success'
	| 'error'
	| 'warning'
	| 'normalized'
	| 'non_normalized'
	| 'blocked'
	| 'deleted'
	| 'duplicate';

export const chipStyleHelper = (status: ChipStatus, noBg?: boolean): IColorHelperProps => {
	switch (status) {
		case 'primary':
			return {
				main: colors.primary,
				bg: !noBg ? colors.primaryBg : 'transparent',
			};
		case 'secondary':
			return {
				main: colors.grayIcon,
				bg: !noBg ? colors.gray : 'transparent',
			};
		case 'success':
			return {
				main: colors.success,
				bg: !noBg ? colors.successBg : 'transparent',
			};
		case 'error':
			return {
				main: colors.error,
				bg: !noBg ? colors.errorBg : 'transparent',
			};
		case 'warning':
			return {
				main: colors.warning,
				bg: !noBg ? colors.warningBg : 'transparent',
			};
		case 'normalized':
			return {
				main: colors.success,
				bg: !noBg ? colors.successBg : 'transparent',
			};
		case 'non_normalized':
			return {
				main: colors.primary,
				bg: !noBg ? colors.primaryBg : 'transparent',
			};
		case 'blocked':
			return {
				main: colors.blocked,
				bg: !noBg ? colors.blockedBg : 'transparent',
			};
		case 'deleted':
			return {
				main: colors.error,
				bg: !noBg ? colors.errorBg : 'transparent',
			};
		case 'duplicate':
			return {
				main: colors.blocked,
				bg: !noBg ? colors.blockedBg : 'transparent',
			};

		default:
			return {
				main: colors.primary,
				bg: !noBg && colors.grayFaded,
			};
	}
};
