import { Layout } from 'antd';
import React, { PropsWithChildren } from 'react';
import s from './header.module.scss';

const { Header } = Layout;

interface ILayoutHeaderProps {
	styles?: React.CSSProperties;
}

export const LayoutHeader: React.FC<PropsWithChildren<ILayoutHeaderProps>> = ({
	children,
	styles,
}) => {
	return (
		<Header className={s.header} style={styles && styles}>
			{children}
		</Header>
	);
};
