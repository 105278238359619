import React from 'react';

interface IPlaceholderSvgProps {
	width?: number | string;
	height?: number | string;
}

export const SearchPlaceholderSvg = ({ width = 120, height = 87 }: IPlaceholderSvgProps) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 104 104"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M80.2109 14.5156H54.0001C52.9601 14.5156 52.1309 15.3589 52.1309 16.3848C52.1309 17.4248 52.9741 18.254 54.0001 18.254H80.2109C81.2509 18.254 82.08 17.4108 82.08 16.3848C82.08 15.3589 81.2509 14.5156 80.2109 14.5156Z"
				fill="#676C7B"
			/>
			<path
				d="M42.1665 37.9453C31.64 37.9453 23.0811 46.5042 23.0811 57.0307C23.0811 58.0707 23.9243 58.8999 24.9502 58.8999C25.9902 58.8999 26.8194 58.0567 26.8194 57.0307C26.8194 48.5702 33.7059 41.6977 42.1524 41.6977C43.1924 41.6977 44.0216 40.8545 44.0216 39.8286C44.0216 38.8026 43.2065 37.9453 42.1665 37.9453Z"
				fill="#676C7B"
			/>
			<path
				d="M70.5699 70.3294C69.8391 69.5986 68.6586 69.5986 67.9277 70.3294L66.6067 71.6505L64.8499 69.8937C71.3429 59.7467 70.1764 46.0862 61.3223 37.2321C56.3753 32.2851 49.784 29.5586 42.785 29.5586C35.7861 29.5586 29.1948 32.2851 24.2477 37.2321C19.3007 42.1791 16.5742 48.7705 16.5742 55.7694C16.5742 62.7683 19.3007 69.3597 24.2477 74.3067C29.1948 79.2537 35.7861 81.9802 42.785 81.9802C47.8726 81.9802 52.7353 80.5186 56.9094 77.8343L58.6661 79.591L57.345 80.9121C56.6142 81.6429 56.6142 82.8235 57.345 83.5543L67.9137 94.1651C73.6477 99.4635 79.3256 95.9921 81.1526 94.1651C84.8067 90.511 84.8067 84.5802 81.1526 80.9262L70.5699 70.3294ZM42.7569 78.2418C36.7558 78.2418 31.1061 75.9089 26.8618 71.6645C22.6175 67.4202 20.2845 61.7845 20.2845 55.7694C20.2845 49.7683 22.6175 44.1186 26.8618 39.8743C31.1061 35.6299 36.7418 33.297 42.7569 33.297C48.758 33.297 54.4077 35.6299 58.6521 39.8743C66.5786 47.8008 67.3094 60.2105 60.8867 68.9943C60.5213 69.4862 54.6326 78.2418 42.7569 78.2418ZM61.2942 74.3067C61.7158 73.8851 62.1375 73.4213 62.5591 72.9294L63.9364 74.3067L61.2942 76.9489L59.9169 75.5716C60.4088 75.1499 60.8726 74.7283 61.2942 74.3067ZM78.5104 91.5089C76.318 93.7013 72.7623 93.7013 70.5699 91.5089L61.3083 82.2332L69.2488 74.2926L78.5104 83.5543C80.7029 85.7608 80.7029 89.3305 78.5104 91.5089Z"
				fill="#676C7B"
			/>
			<path
				d="M83.9498 7.02734H12.8081C9.71625 7.02734 7.18652 9.54302 7.18652 12.649V72.5614C7.18652 75.6533 9.7022 78.183 12.8081 78.183H18.4719C19.5119 78.183 20.3411 77.3398 20.3411 76.3138C20.3411 75.2738 19.4979 74.4446 18.4719 74.4446H12.8081C11.7681 74.4446 10.939 73.6014 10.939 72.5754V25.7473H85.833V72.5614C85.833 73.4609 85.1725 74.2338 84.2871 74.3884L82.0384 74.4306C80.9984 74.4587 80.1833 75.316 80.2114 76.3419C80.2395 77.3679 81.0687 78.169 82.0806 78.169C82.0946 78.169 82.1087 78.169 82.1227 78.169L84.4838 78.1128C84.5541 78.1128 84.6244 78.0987 84.6946 78.0987C87.4773 77.7333 89.5714 75.3441 89.5714 72.5473V12.649C89.5714 9.54302 87.0557 7.02734 83.9498 7.02734ZM85.833 22.009H10.9249V12.649C10.9249 11.609 11.7681 10.7798 12.7941 10.7798H83.9498C84.9898 10.7798 85.819 11.623 85.819 12.649V22.009H85.833Z"
				fill="#676C7B"
			/>
			<path
				d="M16.5464 14.6484C15.5907 14.6484 14.8037 15.4214 14.8037 16.3911C14.8037 17.3468 15.5767 18.1338 16.5464 18.1338C17.5021 18.1338 18.2891 17.3609 18.2891 16.3911C18.2891 15.4355 17.5021 14.6484 16.5464 14.6484Z"
				fill="#676C7B"
			/>
			<path
				d="M23.6704 14.6484C22.7148 14.6484 21.9277 15.4214 21.9277 16.3911C21.9277 17.3468 22.7007 18.1338 23.6704 18.1338C24.6261 18.1338 25.4131 17.3609 25.4131 16.3911C25.4131 15.4355 24.6402 14.6484 23.6704 14.6484Z"
				fill="#676C7B"
			/>
			<path
				d="M30.8111 14.6484C29.8554 14.6484 29.0684 15.4214 29.0684 16.3911C29.0684 17.3468 29.8413 18.1338 30.8111 18.1338C31.7667 18.1338 32.5538 17.3609 32.5538 16.3911C32.5538 15.4355 31.7667 14.6484 30.8111 14.6484Z"
				fill="#676C7B"
			/>
			<path
				d="M100 58.5C100 65.9558 93.9558 72 86.5 72C79.0442 72 73 65.9558 73 58.5C73 51.0442 79.0442 45 86.5 45C93.9558 45 100 51.0442 100 58.5Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M86.1351 69.0008C91.9565 69.0008 96.6756 64.2817 96.6756 58.4603C96.6756 52.6389 91.9565 47.9198 86.1351 47.9198C80.3137 47.9198 75.5946 52.6389 75.5946 58.4603C75.5946 64.2817 80.3137 69.0008 86.1351 69.0008ZM86.1351 72.5144C93.8969 72.5144 100.189 66.2221 100.189 58.4603C100.189 50.6985 93.8969 44.4062 86.1351 44.4062C78.3733 44.4062 72.0811 50.6985 72.0811 58.4603C72.0811 66.2221 78.3733 72.5144 86.1351 72.5144Z"
				fill="#676C7B"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M80.6767 53.0028C81.3627 52.3168 82.475 52.3168 83.1611 53.0028L86.1351 55.9768L89.1091 53.0028C89.7951 52.3168 90.9075 52.3168 91.5935 53.0028C92.2796 53.6889 92.2796 54.8012 91.5935 55.4873L88.6195 58.4613L91.5935 61.4353C92.2796 62.1213 92.2796 63.2336 91.5935 63.9197C90.9075 64.6057 89.7951 64.6057 89.1091 63.9197L86.1351 60.9457L83.1611 63.9197C82.475 64.6057 81.3627 64.6057 80.6767 63.9197C79.9906 63.2336 79.9906 62.1213 80.6767 61.4353L83.6507 58.4613L80.6767 55.4873C79.9906 54.8012 79.9906 53.6889 80.6767 53.0028Z"
				fill="#676C7B"
			/>
		</svg>
	);
};
