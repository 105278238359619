import { Tooltip, TooltipProps } from 'antd';
import React, { PropsWithChildren } from 'react';

const CustomHint: React.FC<PropsWithChildren<TooltipProps>> = ({ children, ...rest }) => {
	return (
		<Tooltip color="#fff" arrow={false} {...rest}>
			{children}
		</Tooltip>
	);
};

export const Hint = React.memo(CustomHint);

