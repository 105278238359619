import { App } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { deleteMetaAttributeItems } from 'entities/metadata/metaAttributes/metaAttributes.store';
import { useTransactions } from 'entities/transactions';
import { useDeleteAttributeMutation } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteSimpleAttribute = () => {
	const { notification } = App.useApp();

	const [fetchDeleteAttribute, { isLoading, error }] = useDeleteAttributeMutation();

	const dispatch = useAppDispatch();

	const navigate = useNavigate();
	const { metaAttributeGroupId, metaAttributeId } = useParams();

	const { getTransactions } = useTransactions();

	const deleteAttribute = async (ids: Array<string>) => {
		await fetchDeleteAttribute({ ids })
			.unwrap()
			.then(() => {
				getTransactions();
				dispatch(deleteMetaAttributeItems(ids));
				if (metaAttributeId)
					navigate(
						`/${routes.metadata.main}/${routes.metadata.group}/${metaAttributeGroupId}`
					);
			})
			.catch((err) => {
				errorHelper('Ошибка при добавлении новой группы атрибутов!', err, notification);
			});
	};

	return {
		loading: isLoading,
		error,
		deleteAttribute,
	};
};
