import { ConfigProvider } from 'antd';
import ruRu from 'antd/locale/ru_RU';
import React, { PropsWithChildren } from 'react';
import { componentsStyles } from 'shared/styles';
import appStyles from '../styles/appStyles';

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => (
	<ConfigProvider
		locale={ruRu}
		wave={{ disabled: true }}
		theme={{
			token: {
				...appStyles,
			},
			components: {
				...componentsStyles,
			},
		}}
	>
		{children}
	</ConfigProvider>
);
