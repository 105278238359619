import { RiDownloadLine } from '@remixicon/react';
import { Button, Flex, Modal } from 'antd';
import React, { useState } from 'react';
import { UploadBase64File } from 'shared/ui';
import { useImportCatalogRecord } from './importCatalogRecord.model';

const ImportCatalogRecordUi = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [base64, setBase64] = useState<Array<string>>([]);

	const { handleImport, isLoading } = useImportCatalogRecord();

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		setBase64([]);
	};

	const handleClick = async () => {
		const res = await handleImport(base64[0]);
		if (res) handleCancel();
	};

	return (
		<>
			<Button icon={<RiDownloadLine style={{ rotate: '180deg' }} />} onClick={showModal}>
				Импортировать
			</Button>
			<Modal
				title="Импорт из Excel"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<Flex vertical gap={12}>
					<UploadBase64File setString={setBase64} />
					<Flex gap={4} justify="flex-end">
						<Button onClick={handleCancel}>Отменить</Button>
						<Button
							type="primary"
							disabled={!base64.length}
							loading={isLoading}
							onClick={handleClick}
						>
							Импортировать
						</Button>
					</Flex>
				</Flex>
			</Modal>
		</>
	);
};

export const ImportCatalogRecord = React.memo(ImportCatalogRecordUi);
