import { RiAddCircleLine } from '@remixicon/react';
import { Button } from 'antd';
import React from 'react';
import { useCreateClassifier } from './createClassifierGroup.model';

const CreateClassifierGroupUi: React.FC = () => {
	const { create, isLoading } = useCreateClassifier();

	return (
		<Button
			icon={<RiAddCircleLine />}
			style={{ maxWidth: 'fit-content' }}
			onClick={create}
			loading={isLoading}
		>
			Добавить классификатор
		</Button>
	);
};

export const CreateClassifierGroup = React.memo(CreateClassifierGroupUi);
