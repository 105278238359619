import { App } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router';
import {
	addNewCatalogGroup,
	updateCatalogGroupChildTreeItem,
} from 'entities/catalogs/catalogGroups/catalog.store';
import { useTransactions } from 'entities/transactions';
import { useCreateCatalogMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';

export const useAddNewCatalog = () => {
	const { t } = useTypedTranslation();

	const [loading, setLoading] = useState<boolean>(false);
	const { notification } = App.useApp();
	const [fetchAddnewCatalog, { error }] = useCreateCatalogMutation();
	const { getTransactions } = useTransactions();
	const dispatch = useAppDispatch();
	const { catalogGroupId } = useParams();

	const addNewCatalog = async (displayName: string, description?: string, parentId?: string) => {
		const parId = parentId ? parentId : catalogGroupId ? catalogGroupId : null;
		setLoading(true);

		fetchAddnewCatalog({
			createCatalogRequest: {
				displayName,
				...(description && { description }),
				...(parId && { parentId: parId }),
			},
		})
			.unwrap()
			.then((data) => {
				getTransactions();

				if (!parId) {
					dispatch(addNewCatalogGroup(data));
				} else {
					dispatch(updateCatalogGroupChildTreeItem({ item: [data], parentId: parId }));
				}
			})
			.catch((err) => {
				errorHelper(t(l => l.common.statuses.error), err, notification);
			})
			.finally(() => setLoading(false));
	};
	return { loading, error, addNewCatalog };
};
