import { App } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router';
// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import { useTransactions } from 'entities/transactions';
import {
	useLazyGetCatalogRestrictionTableDeclarationsQuery,
	useUpdateCatalogRestrictionTableDeclarationMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector, useTypedTranslation } from 'shared/hooks';
import { setCatalogRestrictionTablesDeclarations } from './catalogRestrictions.store';

export const useCatalogRestrictionTableDeclarations = () => {
	const { t } = useTypedTranslation();

	const [getRestrictions, { isLoading, error }] =
		useLazyGetCatalogRestrictionTableDeclarationsQuery();
	const [patchRestrictionTable, { isLoading: isPatchLoading }] =
		useUpdateCatalogRestrictionTableDeclarationMutation();
	const { catalogRestrictionTablesDeclarations } = useAppSelector(
		(state) => state.entities.catalogs.catalogRestrictionTablesDeclarations
	);
	const dispatch = useAppDispatch();
	const { notification } = App.useApp();
	const { catalogGroupId } = useParams();
	const { getTransactions } = useTransactions();

	const onChangeRequired = async (declarationId: string, isRequired: boolean) => {
		patchRestrictionTable({
			id: declarationId,
			updateCatalogRestrictionTableDeclarationRequest: {
				generationValues: true,
				required: isRequired,
			},
		})
			.unwrap()
			.then((res) => {
				if (res) {
					getTransactions();
				}
			})
			.catch((err) =>
				errorHelper(t(l => l.catalogs.restrictions.restrictionsTableErr), err, notification)
			);
	};

	useEffect(() => {
		getRestrictions({ catalogId: catalogGroupId })
			.unwrap()
			.then((res) => dispatch(setCatalogRestrictionTablesDeclarations(res)))
			.catch((e) =>
				errorHelper(t(l => l.catalogs.restrictions.declarationsTableErr), e, notification)
			);
	}, [catalogGroupId]);

	return {
		catalogRestrictionTablesDeclarations,
		onChangeRequired,
		isPatchLoading,
		isLoading,
		error,
	};
};
