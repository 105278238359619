import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { App } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
	RestrictionTableResponse,
	useLazyGetRestrictionTablesQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { useDidUpdateEffect } from 'shared/hooks/useDidUpdateEffect';
import { setConstraintTables } from './constraintTables.store';

export const useConstraintTables = () => {
	const [error, setError] = useState<string>();

	const dispatch = useAppDispatch();

	const [getRestrictionTables, { isFetching }] = useLazyGetRestrictionTablesQuery();

	const constraintTables = useAppSelector(
		(state) => state.entities.metadata.constraintTables.constraintTables
	);

	const { notification } = App.useApp();

	const [searchParams] = useSearchParams();

	const getConstraintTables = (
		ids?: Array<string>
	): Promise<Array<RestrictionTableResponse> | FetchBaseQueryError | SerializedError> => {
		return getRestrictionTables({ ...(ids && { ids }) })
			.unwrap()
			.then((res) => {
				return res;
			})
			.catch((err) => {
				errorHelper('Ошибка загрузки ограничительных таблиц!', err, notification);
				setError(err);
				return err;
			});
	};

	useEffect(() => {
		const searchValue = searchParams.get('searchValue');
		if (
			!constraintTables &&
			!searchValue &&
			window.location.pathname.includes(routes.metadata.constraintTables)
		) {
			getConstraintTables()
				.then((res) => {
					if (Array.isArray(res)) {
						dispatch(setConstraintTables(res));
					}
				})
				.catch((err) => {
					console.warn('Ошибка при получении таблиц ограничений', err);
				});
		}
	}, []);

	useDidUpdateEffect(() => {
		const searchValue = searchParams.get('searchValue');

		if (!searchValue) {
			getConstraintTables()
				.then((res) => {
					if (Array.isArray(res)) {
						dispatch(setConstraintTables(res));
					}
				})
				.catch((err) => {
					console.warn('Ошибка при получении таблиц ограничений', err);
				});
		}
	}, [searchParams]);

	return {
		loading: isFetching,
		getConstraintTables,
		constraintTables,
		error,
	};
};
