const typographyStyles = {
	fontFamily: 'Manrope',
	fontSizeHeading1: 21,
	lineHeightHeading1: 1.3,
	titleMarginTop: 0,
	titleMarginBottom: 12,
	fontSizeHeading2: 18,
	lineHeightHeading2: 1.3,
	fontSizeHeading3: 12,
	lineHeightHeading3: 1.3,
	letterSpacing: 0,
};

export default typographyStyles;
