import { App } from 'antd';
import { useParams } from 'react-router';
import { useCatalogRecords } from 'entities/catalogs/catalogRecords';
import { setCatalogRecords } from 'entities/catalogs/catalogRecords/catalogRecords.store';
import { useImportXlsxMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useImportCatalogRecord = () => {
	const { notification } = App.useApp();

	const [importRecord, { isLoading }] = useImportXlsxMutation();
	const { fetchItems, isGetItemsLoading } = useCatalogRecords();
	const { catalogGroupId } = useParams();

	const dispatch = useAppDispatch();

	const handleImport = async (base64: string): Promise<boolean> => {
		return await importRecord({
			catalogId: catalogGroupId,
			xlsxImportCatalogItemsRequest: { content: base64 },
		})
			.unwrap()
			.then(async () => {
				return await fetchItems(catalogGroupId).then((res) => {
					if (Array.isArray(res)) {
						dispatch(setCatalogRecords(res));
						return true;
					} else {
						errorHelper(
							'Ошибка при обновлении записей справочника!',
							res,
							notification
						);
						return false;
					}
				});
			})
			.catch((e) => {
				errorHelper('Ошибка при импорте записи!', e, notification);
				return false;
			});
	};

	return {
		handleImport,
		isLoading: isLoading || isGetItemsLoading,
	};
};
