import { Flex, Result, Skeleton, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Transactions } from 'widgets/Transactions';
import { DeleteMeasureUnit } from 'features/metadata/Measures/deleteMeasureUnit';
import { EditMeasureUnit } from 'features/metadata/Measures/editMeasureUnit';
import { setBreadcrumbs } from 'entities/breadcrumbs';

import {
	useGetCurrentMeausureGroup,
	useGetCurrentMeasureUnit,
	removeCurrentMeasureUnit,
} from 'entities/metadata/measures';
import { routes } from 'shared/config';
import { useAppDispatch } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';

const DetailMeasureUnitPageUi = () => {
	const dispatch = useAppDispatch();
	const { currentMeasureGroup } = useGetCurrentMeausureGroup();
	const { currentMeasureUnit, loading, error } = useGetCurrentMeasureUnit();
	const navigate = useNavigate();
	const { metaMeasureGroupId } = useParams();

	const customCallback = () =>
		navigate(`/${routes.metadata.main}/${routes.metadata.measures}/${metaMeasureGroupId}`);

	useEffect(() => {
		return () => {
			dispatch(removeCurrentMeasureUnit());
		};
	}, []);

	useEffect(() => {
		if (currentMeasureUnit && currentMeasureGroup) {
			dispatch(
				setBreadcrumbs({
					displayName: 'Метаданные',
					url: `${routes.metadata.main}/${routes.metadata.measures}`,
					child: {
						displayName: `${currentMeasureGroup.displayName}`,
						url: `${routes.metadata.main}/${routes.metadata.measures}/${currentMeasureGroup.id}`,
						child: {
							displayName: 'Карточка единицы измерения',
							url: window.location.pathname,
						},
					},
				})
			);
		}
	}, [currentMeasureUnit]);

	if (error) {
		return <Result title="Ошибка!" subTitle={JSON.stringify(error)} />;
	}

	return (
		<>
			{loading ? (
				<>
					<Skeleton paragraph={{ rows: 3 }} active />
				</>
			) : (
				<Flex vertical gap={24}>
					<Flex justify="space-between">
						<Typography.Title level={1}>Карточка единицы измерения</Typography.Title>

						<Transactions />
					</Flex>

					<EditMeasureUnit unit={currentMeasureUnit} />
					<DetailFooter customHandleBack={customCallback}>
						<DeleteMeasureUnit unitId={[currentMeasureUnit?.id]} detailUnitPage />
					</DetailFooter>
				</Flex>
			)}
		</>
	);
};

export const DetailMeasureUnitPage = React.memo(DetailMeasureUnitPageUi);
