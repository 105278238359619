import { RiSaveLine } from '@remixicon/react';
import { Table, Select, Button } from 'antd';
import React, { useState } from 'react';
import { DropdownSelect } from 'shared/ui';

const { Option } = Select;

const dictionaryFields = [
	{ id: 1, name: 'Field 1', type: 'Type 1' },
	{ id: 2, name: 'Field 2', type: 'Type 2' },
	{ id: 3, name: 'Field 3', type: 'Type 1' },
	{ id: 4, name: 'Field 4', type: 'Type 3' },
];

const dataSourceFields = [
	{ id: 101, name: 'Source Field A', type: 'Type 1' },
	{ id: 102, name: 'Source Field B', type: 'Type 2' },
	{ id: 103, name: 'Source Field C', type: 'Type 3' },
	{ id: 104, name: 'Source Field D', type: 'Type 1' },
];

const SetupMappingUi: React.FC = () => {
	const [mapping, setMapping] = useState(
		dictionaryFields.map((field) => ({
			...field,
			sourceFieldId: null,
		}))
	);

	const handleSelectChange = (dictionaryFieldId, selectedSourceFieldId) => {
		setMapping((prevMapping) =>
			prevMapping.map((field) =>
				field.id === dictionaryFieldId
					? { ...field, sourceFieldId: selectedSourceFieldId }
					: field
			)
		);
	};

	const columns = [
		{
			title: 'Поля справочника',
			dataIndex: 'name',
			key: 'name',
			render: (text, record) => `${record.name} (${record.type})`,
		},
		{
			title: 'Поля источника данных',
			dataIndex: 'sourceFieldId',
			key: 'sourceFieldId',
			render: (text, record) => (
				<DropdownSelect
					placeholder="Выберите поле"
					style={{ width: '100%' }}
					value={record.sourceFieldId}
					onChange={(value) => handleSelectChange(record.id, value)}
				>
					{dataSourceFields.map((sourceField) => (
						<Option key={sourceField.id} value={sourceField.id}>
							{sourceField.name} ({sourceField.type})
						</Option>
					))}
				</DropdownSelect>
			),
		},
	];

	const getFinalMapping = () => {
		return mapping.map((field) => ({
			id: field.id,
			name: field.name,
			type: field.type,
			sourceFieldId: field.sourceFieldId,
			sourceFieldName:
				dataSourceFields.find((src) => src.id === field.sourceFieldId)?.name || null,
			sourceFieldType:
				dataSourceFields.find((src) => src.id === field.sourceFieldId)?.type || null,
		}));
	};

	const handleSubmit = () => {
		const finalMapping = getFinalMapping();
		console.log('Final Mapping:', finalMapping);
	};

	return (
		<div>
			<Table columns={columns} dataSource={mapping} rowKey="id" pagination={false} />
			<Button
				onClick={handleSubmit}
				style={{ marginTop: '16px', width: '100%' }}
				icon={<RiSaveLine />}
			>
				Сохранить настройки маппинга
			</Button>
		</div>
	);
};

export const SetupMapping = React.memo(SetupMappingUi);
