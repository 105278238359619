import { RiCheckboxCircleLine } from '@remixicon/react';
import { App, Button, Flex, Form, Input, Result, Skeleton, Typography } from 'antd';
import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
	ConfigurationStatus,
	ExecutionType,
	useGetConfigurationMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGetQuery,
	usePatchConfigurationMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdPatchMutation,
} from 'shared/api/generatedApi/integrationsApi';
import { errorHelper } from 'shared/helpers';
import { Chip, DropdownSelect } from 'shared/ui';

const executionTypeOpts: Array<{ label: string; value: ExecutionType }> = [
	{
		label: 'По расписанию',
		value: 'scheduled',
	},
	{
		label: 'Ручной',
		value: 'manual',
	},
	{
		label: 'Ручной немедленно',
		value: 'manual_immediate',
	},
	{
		label: 'По событию из вне',
		value: 'external_event',
	},
];
const statusOpts: Array<{ label: React.ReactElement; value: ConfigurationStatus }> = [
	{
		label: <Chip title="Остановлен" small status="error" />,
		value: 'inactive',
	},
	{
		label: <Chip title="Выполняется" small status="success" />,
		value: 'active',
	},
];

const EditExchangeConfigInfoUi: FC = () => {
	const { exchangeConfigId, integrationId } = useParams();
	const { data, isLoading, error } =
		useGetConfigurationMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdGetQuery(
			{ connectionId: integrationId, configurationId: exchangeConfigId }
		);
	const [patch] =
		usePatchConfigurationMdmIntegrationsV1ConnectionsConnectionIdConfigurationsConfigurationIdPatchMutation();

	const [form] = Form.useForm();
	const { notification } = App.useApp();

	const onFinish = async (v) => {
		const { execution_type, schedule_interval, ...rest } = v;
		await patch({
			connectionId: integrationId,
			configurationId: exchangeConfigId,
			exchangeConfiguration: {
				...rest,
				activation_mode: {
					...data.activation_mode,
					execution_type,
					schedule_interval,
				},
			},
		})
			.unwrap()
			.catch((e) => {
				errorHelper('Ошибка при обновлении полей формы!', e, notification);
				if (data) {
					const { execution_type, schedule_interval } = data.activation_mode;
					form.setFieldsValue({ ...data, execution_type, schedule_interval });
				}
			});
	};

	useEffect(() => {
		if (data) {
			const { execution_type, schedule_interval } = data.activation_mode;
			form.setFieldsValue({ ...data, execution_type, schedule_interval });
		}
	}, [data]);

	if (error) return <Result status="error" title="Ошибка!" subTitle={JSON.stringify(error)} />;

	return (
		<>
			{isLoading ? (
				<Flex vertical style={{ width: '100%' }} gap={12}>
					<Skeleton.Input active style={{ width: '100%' }} />
					<Skeleton.Input active style={{ width: '100%' }} />
					<Skeleton.Input active style={{ width: '100%' }} />
					<Skeleton.Input active style={{ width: '100%' }} />
				</Flex>
			) : (
				<Form layout="vertical" form={form} onFinish={onFinish}>
					<Typography.Title level={1} style={{ marginBottom: 12 }}>
						Настройки конфигурации обмена
					</Typography.Title>
					<Form.Item label="Наименование конфигурации" name="name" required>
						<Input />
					</Form.Item>
					<Form.Item label="Описание" name="description">
						<Input />
					</Form.Item>
					<Form.Item
						label="Действие при удалении позиции во внешней системе"
						name="on_delete_action"
					>
						<DropdownSelect />
					</Form.Item>
					<Form.Item label="Статус" name="status">
						<DropdownSelect options={statusOpts} />
					</Form.Item>
					<Form.Item label="Режим импорта позиций" name="execution_type">
						<DropdownSelect options={executionTypeOpts} />
					</Form.Item>
					<Form.Item
						label="Регулярное выражение для настройки расписания обмена"
						name="schedule_interval"
					>
						<Input placeholder="Введите CRON-выражение" />
					</Form.Item>
					<Typography.Title level={2} style={{ marginBottom: 12 }}>
						Настройки маппинга
					</Typography.Title>
					<Form.Item
						label="Справочник, в который будут импортированы позиции"
						name="catalog_id"
					>
						<DropdownSelect />
					</Form.Item>
					<Form.Item label="Источник данных" name="extraction_targets" required>
						<Input />
					</Form.Item>
					<Button
						icon={<RiCheckboxCircleLine />}
						style={{ width: '100%' }}
						htmlType="submit"
					>
						Подтвердить выбранный источник данных
					</Button>
				</Form>
			)}
		</>
	);
};

export const EditExchangeConfigInfo = React.memo(EditExchangeConfigInfoUi);
