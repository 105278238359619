import { RiInformationLine } from '@remixicon/react';
import { TreeSelect, TreeSelectProps } from 'antd';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { colors } from '../../../styles';
import { Hint } from '../Hint';
import s from './dropdownSelect.module.scss';

interface IDropdownTreeSelectProps extends TreeSelectProps {
	info?: string;
	error?: boolean;
	errorText?: string;
}

const DropdownTreeSelectUi: React.FC<IDropdownTreeSelectProps> = ({ info, error, errorText, ...props }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [dropdownWidth, setDropdownWidth] = useState<number | undefined>(undefined);
	const [isFocused, setIsFocused] = useState<boolean>(false);

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleBlur = () => {
		setIsFocused(false);
	};

	useEffect(() => {
		if (containerRef.current) {
			setDropdownWidth(containerRef.current.offsetWidth);
		}
	}, [containerRef.current]);

	return (
		<div className={s.componentContainer}>
			<div
				ref={containerRef}
				className={cn(s.dropdownSelect, {
					[s.focused]: isFocused,
					[s.additionalPadding]: info,
					[s.error]: error,
				})}
			>
				<TreeSelect
					{...props}
					variant="filled"
					onFocus={handleFocus}
					onBlur={handleBlur}
					className={s.select}
					dropdownStyle={{ width: dropdownWidth }}
				/>
				{info && (
					<div className={s.iconContainer}>
						<Hint title={info && info}>
							<RiInformationLine size={16} color={colors.grayIcon} />
						</Hint>
					</div>
				)}
			</div>
			{error && errorText && <p className={s.errorText}>{errorText}</p>}
		</div>
	);
};

export const DropdownTreeSelect = React.memo(DropdownTreeSelectUi);
