import { App } from 'antd';
import { transactionStatusType } from 'entities/transactions';
import { useUpdateTransactionMutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';

export const useUpdateTransaction = () => {
	const [fetchUpdateTransaction] = useUpdateTransactionMutation();
	const { notification } = App.useApp();

	const chooseStatus = (trStatus: transactionStatusType): transactionStatusType => {
		switch (trStatus) {
			case 'ACTIVE':
				return 'PAUSED';

			case 'PAUSED':
				return 'ACTIVE';

			case 'COMMITED':
				return 'COMMITED';
		}
	};

	const updateTransaction = async (id: string, trStatus: transactionStatusType) => {
		const transactionStatus = chooseStatus(trStatus);

		const result = await fetchUpdateTransaction({
			id,
			updateTransactionRequest: { transactionStatus },
		});

		if ('error' in result) {
			errorHelper('Ошибка обновления транзакции!', result.error, notification);
		}

		if ('data' in result) {
			notification.success({ message: 'Транзакция обновлена' });
		}
	};

	return { updateTransaction };
};
