import { RiInformationLine } from '@remixicon/react';
import { Checkbox, Flex, Form, Input, Typography } from 'antd';
import React, { useEffect } from 'react';
import { MeasurementResponse } from 'shared/api/generatedApi/mdmgApi';
import { colors } from 'shared/styles';
import { Hint } from 'shared/ui';
import { useEditMeasureUnit } from './editMeasureUnit.model';

interface IEditMeasureUnitProps {
	unit: MeasurementResponse;
}

const EditMeasureUnitUi: React.FC<IEditMeasureUnitProps> = ({ unit }) => {
	const { editInput, editBasic } = useEditMeasureUnit();
	const [form] = Form.useForm();
	useEffect(() => {
		if (unit) {
			form.setFieldsValue({
				name: unit?.displayName,
				isBasic: unit?.isDefault,
				code: unit?.shortName,
				factor: unit?.isDefault ? '—' : unit?.coefficient,
				formula: unit?.isDefault ? '—' : unit?.formula,
				revFormula: unit?.isDefault ? '—' : unit?.inverseFormula,
			});
		}
	}, [unit]);
	return (
		<Flex vertical gap={12} style={{ maxWidth: 920, marginBottom: 65 }}>
			<Typography.Title level={2}>Общая информация</Typography.Title>
			<Form layout="vertical" form={form}>
				<Flex align="center" style={{ marginBottom: 12 }}>
					<Form.Item
						valuePropName="checked"
						name="isBasic"
						style={{ display: 'flex', alignItems: 'center', margin: 0 }}
					>
						<Checkbox onChange={editBasic} checked={unit?.isDefault}>
							Базовая ЕИ
						</Checkbox>
					</Form.Item>
					<Hint
						placement="right"
						title="В группе единиц измерения может быть только одна базовая ЕИ. Остальные ЕИ связываются с базовой через коэффициент пересчёта или формулу пересчёта. Для базовых ЕИ коэффициент пересчёта равен 0."
					>
						<RiInformationLine size={16} color={colors.grayIcon} />
					</Hint>
				</Flex>

				<Form.Item label="Наименование" name="name">
					<Input allowClear onBlur={editInput} />
				</Form.Item>

				<Form.Item label="Обозначение" name="code">
					<Input allowClear onBlur={editInput} />
				</Form.Item>
				<Form.Item label="Коэффициент пересчета" name="factor">
					<Input
						allowClear
						onBlur={editInput}
						disabled={unit?.isDefault || !!unit?.inverseFormula || !!unit?.formula}
						suffix={
							<Hint title="Коэффициент пересчета">
								<RiInformationLine size={16} color={colors.grayIcon} />
							</Hint>
						}
					/>
				</Form.Item>
				{/* Закомментил пока не добавят формулы*/}
				{/* <Form.Item label="Формула пересчета" name="formula">
					<Input
						allowClear
						disabled={unit?.isDefault || !!unit?.coefficient}
						onBlur={editInput}
						suffix={
							<Hint title="Формула пересчета">
								<RiInformationLine size={16} color={colors.grayIcon} />
							</Hint>
						}
					/>
				</Form.Item> */}

				{/* <Form.Item label="Обратная формула пересчета" name="revFormula">
					<Input
						allowClear
						onBlur={editInput}
						disabled={unit?.isDefault || !!unit?.coefficient}
						suffix={
							<Hint title="Обратная формула пересчета">
								<RiInformationLine size={16} color={colors.grayIcon} />
							</Hint>
						}
					/>
				</Form.Item> */}
			</Form>
		</Flex>
	);
};

export const EditMeasureUnit = React.memo(EditMeasureUnitUi);
