import {
	RiDeleteBinLine,
	RiFileListLine,
	RiFileUploadLine,
	RiPauseCircleLine,
	RiPlayCircleLine,
} from '@remixicon/react';
import { Flex, Spin, Typography } from 'antd';
import React, { ReactElement } from 'react';
import { useCommitTransaction } from 'features/transactions/commitTransaction';
import { useDeleteTransaction } from 'features/transactions/deleteTransaction';
import { useUpdateTransaction } from 'features/transactions/updateTransaction';
import { transactionStatusType, useTransactions } from 'entities/transactions';
import { useAppSelector } from 'shared/hooks';
import { colors } from 'shared/styles';
import { DropdownLink, RightDrawer } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';
import s from './Transactions.module.scss';

export const Transactions = () => {
	const { transactionsLoading, getTransactions } = useTransactions();
	const { deleteTransaction } = useDeleteTransaction();
	const { updateTransaction } = useUpdateTransaction();
	const { commitTransaction, commitTransactionLoading } = useCommitTransaction();

	const transactions = useAppSelector((state) => state.entities.transactions.transactionsStore);
	const isTransactionsContainActive = transactions.find(
		(tr) => tr.transactionStatus === 'ACTIVE'
	);
	const isTransactionsEmpty = !transactions.length;
	const iconColor = isTransactionsEmpty ? colors.disabled : colors.primary;

	const trIcon = (trStatus: transactionStatusType): ReactElement => {
		switch (trStatus) {
			case 'ACTIVE':
				return <RiPlayCircleLine color={`${colors.success}`} size={16} />;

			case 'PAUSED':
				return <RiPauseCircleLine color={`${colors.warning}`} size={16} />;

			default:
				return <RiPauseCircleLine color={`${colors.textFaded}`} size={16} />;
		}
	};

	const activeOrPause = (
		trStatus: transactionStatusType
	): { icon: ReactElement; title: string } => {
		switch (trStatus) {
			case 'ACTIVE':
				return {
					icon: <RiPauseCircleLine size={16} />,
					title: 'Приостановить сохранение изменений',
				};

			case 'PAUSED':
				return {
					icon: <RiPlayCircleLine size={16} />,
					title: 'Активировать сохранение',
				};

			default:
				return { icon: <></>, title: '' };
		}
	};

	return (
		<RightDrawer
			disabled={isTransactionsEmpty}
			title="Перечень версий обновлений"
			openButton={
				<Flex align="center" gap={4}>
					<>
						{isTransactionsContainActive ? (
							<RiPlayCircleLine size={16} />
						) : (
							<RiFileListLine size={16} color={iconColor} />
						)}
						Управление обновлениями данных
					</>
				</Flex>
			}
		>
			<>
				{transactionsLoading ? (
					<Flex justify="center">
						<Spin />
					</Flex>
				) : (
					transactions
						.filter((tr) => tr.transactionStatus !== 'COMMITED')
						.map((tr) => (
							<div key={tr.id} className={s.transactionsContainer}>
								<Flex align="center" gap={12}>
									{trIcon(tr.transactionStatus)}

									<div className={s.transactionStyle} title={tr.description}>
										<p>
											<Typography.Text strong>
												{tr.displayName}
											</Typography.Text>
										</p>
										<Flex gap={6}>
											<Typography.Text className={s.dateTitleStyle}>
												дата создания:
											</Typography.Text>
											<Typography.Text>
												{new Date(tr.createdAt).toLocaleDateString()}
											</Typography.Text>
											<Typography.Text>
												{new Date(tr.createdAt).toLocaleTimeString()}
											</Typography.Text>
										</Flex>
									</div>

									<ItemActions
										items={[
											tr.transactionStatus === 'ACTIVE' && {
												label: (
													<DropdownLink
														icon={<RiFileUploadLine size={16} />}
														loading={commitTransactionLoading}
														title="Опубликовать изменения"
														callback={async () => {
															await commitTransaction();
															getTransactions();
														}}
													/>
												),
												key: 1,
											},
											{
												label: (
													<DropdownLink
														icon={
															activeOrPause(tr.transactionStatus).icon
														}
														title={
															activeOrPause(tr.transactionStatus)
																.title
														}
														callback={async () => {
															await updateTransaction(
																tr.id,
																tr.transactionStatus
															);
															getTransactions();
														}}
													/>
												),
												key: 2,
											},
											{
												label: (
													<DropdownLink
														icon={<RiDeleteBinLine size={16} />}
														title="Удалить все изменения"
														callback={async () => {
															await deleteTransaction(tr.id);
															getTransactions();
														}}
													/>
												),
												key: 3,
											},
										]}
									/>
								</Flex>
							</div>
						))
				)}
			</>
		</RightDrawer>
	);
};
