import { App } from 'antd';
import { useGetCurrentMeausureGroup, editCurrentMeasureGroup } from 'entities/metadata/measures';
import { useTransactions } from 'entities/transactions';
import { useDeleteMeasurementMutation } from 'shared/api/generatedApi/mdmgApi';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteMeasureUnits = () => {
	const { currentMeasureGroup } = useGetCurrentMeausureGroup();

	const { notification } = App.useApp();
	const [deleteUnits, { isLoading }] = useDeleteMeasurementMutation();
	const dispatch = useAppDispatch();

	const { getTransactions } = useTransactions();

	const deleteMeasureUnits = async (unitId: Array<string>) => {
		await deleteUnits({ ids: unitId })
			.unwrap()
			.then(() => {
				dispatch(
					editCurrentMeasureGroup({
						...currentMeasureGroup,
						childrenMeasures: currentMeasureGroup.childrenMeasures.filter(
							(item) => !unitId.includes(item.id)
						),
					})
				);
				getTransactions();
			})
			.catch((e) => {
				notification.error({
					message: 'Ошибка при удалении ЕИ!',
					description: JSON.stringify(e),
				});
			});
	};

	return {
		deleteMeasureUnits,
		loading: isLoading,
	};
};
