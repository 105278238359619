import { CSSProperties } from 'react';

export const cardGridFirstStyles: CSSProperties = {
    boxShadow: 'unset',
    maxWidth: '48px',
    display: 'flex',
    justifyContent: 'center',
	alignItems: 'center'
};

export const cardGridMiddleStyles: CSSProperties = {
	boxShadow: 'unset',
	display: 'flex',
	flexDirection: 'column',
	alignContent: 'center',
	justifyContent: 'center',
	minWidth: 225,
	rowGap: 4,
	padding: 0,
	paddingLeft: 12,
	paddingRight: 4,
  width: '100%'
};


export const cardGridLastItem: CSSProperties = { boxShadow: 'unset', padding: 0, width: 'fit-content' };