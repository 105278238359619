import { RiAddCircleLine } from '@remixicon/react';
import { Button } from 'antd';
import React from 'react';
import { useCreateExchangeConfig } from './CreateExchangeConfig.model';

const CreateExchangeConfigUi: React.FC = () => {
	const { createExchangeConfig, isLoading } = useCreateExchangeConfig();
	return (
		<Button
			icon={<RiAddCircleLine />}
			style={{ maxWidth: 'fit-content' }}
			onClick={createExchangeConfig}
			loading={isLoading}
		>
			Создать новую конфигурацию обмена
		</Button>
	);
};

export const CreateExchangeConfig = React.memo(CreateExchangeConfigUi);
