import { App } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
	CatalogDto,
	CatalogTreeNode,
	useLazyGetCatalogQuery,
	useLazyGetCatalogsQuery,
	useLazyGetCatalogTreeQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { CatalogItem } from 'shared/helpers/types';
import { useAppDispatch, useAppSelector, useTypedTranslation } from 'shared/hooks';
import {
	removeCurrentCatalogGroup,
	setCatalogGroups,
	setCurrentCatalog,
	updateCatalogGroupChildTreeItem,
} from './catalog.store';

export type DisplayTreeNode = Omit<CatalogItem, 'title'> & { title: React.ReactNode };

export const useCatalogGroups = () => {
	const { t } = useTypedTranslation();

	const [selectedCatalog, setSelectedCatalog] = useState<CatalogDto>(null);
	const [catalogList, setCatalogList] = useState<Array<CatalogDto>>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const prevQueryParams = useRef<string>('');

	const [getCatalog] = useLazyGetCatalogQuery();
	const [getCatalogArr, { error: catalogListError }] = useLazyGetCatalogsQuery();
	const [getCatalogWithParentsById, { error: catalogWithParentsError }] =
		useLazyGetCatalogTreeQuery();

	const { notification } = App.useApp();

	const { catalogGroupId } = useParams();

	const dispatch = useAppDispatch();
	const { catalogGroups, currentCatalogGroup } = useAppSelector(
		(state) => state.entities.catalogs.catalogGroups
	);

	const [searchParams] = useSearchParams();

	const addNewItemToList = (arr: Array<CatalogDto>, group: CatalogDto): Array<CatalogDto> =>
		arr.map((item) => {
			return item.id === group.id ? group : item;
		});

	const findChild = (arr: Array<CatalogTreeNode>, id: string): CatalogTreeNode | undefined => {
		if (arr) {
			for (const attr of arr) {
				if (attr.id === id) {
					return attr;
				}
				if (attr.children && attr.children.length) {
					const child = findChild(attr.children, id);
					if (child) {
						return child;
					}
				}
			}
		}

		return undefined;
	};

	const getCurrentCatalog = (id: string) => {
		getCatalog({ id })
			.unwrap()
			.then((catalog) => dispatch(setCurrentCatalog(catalog)))
			.catch((err) => errorHelper(t(l => l.catalogs.groups.currCatalogErr), err, notification));
	};

	const getCatalogs = async (parentId?: string): Promise<Array<CatalogDto> | null> => {
		const res = await getCatalogArr({
			...(parentId ? { parentIds: [parentId] } : { root: true }),
		});

		if ('error' in res) {
			errorHelper(t(l => l.catalogs.groups.rootGroupErr), res.error, notification);
			return null;
		}

		if ('data' in res) {
			if (parentId) {
				dispatch(
					updateCatalogGroupChildTreeItem({
						item: res.data as unknown as CatalogDto[],
						parentId,
					})
				);
			}

			return res.data;
		}

		return null;
	};

	const getGroups = async () => {
		const list = await getCatalogArr({ root: true });
		if ('error' in list) {
			errorHelper(t(l => l.catalogs.groups.rootGroupErr), list.error, notification);
		}
		if ('data' in list) {
			if (list.data.length) {
				if (catalogGroupId && !currentCatalogGroup) {
					const data = await getCatalogWithParentsById({
						id: catalogGroupId,
						direction: 'up',
						includeChildren: true,
					});
					if ('error' in data) {
						errorHelper(t(l => l.catalogs.groups.listErr), data.error, notification);
					}
					if ('data' in data) {
						const completeData = addNewItemToList(list.data, data.data);
						dispatch(setCatalogGroups(completeData));
						dispatch(setCurrentCatalog(findChild([data.data], catalogGroupId)));
						setLoading(false);
						return;
					}
				}
			}
		}
		dispatch(setCatalogGroups(list.data));

		setLoading(false);
	};

	useEffect(() => {
		if (!catalogGroups) {
			const searchParentValue = searchParams.get('searchParentValue');
			!searchParentValue && getGroups();
		}
	}, []);

	useEffect(() => {
		if (catalogGroups) setCatalogList(catalogGroups);
	}, [catalogGroups]);

	useEffect(() => {
		setSelectedCatalog(currentCatalogGroup);
	}, [currentCatalogGroup]);

	useEffect(() => {
		if (!catalogGroupId && currentCatalogGroup) dispatch(removeCurrentCatalogGroup());
		if (catalogGroupId) getCurrentCatalog(catalogGroupId);
	}, [catalogGroupId]);

	useEffect(() => {
		const searchParentValue = searchParams.get('searchParentValue');

		if (searchParentValue !== prevQueryParams.current) {
			prevQueryParams.current = searchParentValue;

			if (!searchParentValue) {
				getGroups();
			}
		}
	}, [searchParams]);

	return {
		catalogGroupId,
		selectedCatalog,
		catalogList,
		getCatalogs,
		error: catalogListError || catalogWithParentsError,
		loading,
		setLoading,
		findChild,
	};
};
