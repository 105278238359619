import { App } from 'antd';
import { deleteCatalogDeduplicationOption } from 'entities/catalogs/catalogDeduplication/catalogDeduplication.store';
import { useDeleteDeduplicationOptionMutation } from 'shared/api/generatedApi/deduplicationApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { deleteDeduplicationAttributeMapping } from '../AttrbuteDeclarations/deleteDeduplicationAttributeMapping';

export const useDeleteDeduplicationOption = () => {
	const [fetchDeleteDeduplicationOption] = useDeleteDeduplicationOptionMutation();
	const { deleteMapping } = deleteDeduplicationAttributeMapping();

	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const deduplicationOptions = useAppSelector(
		(state) => state.entities.catalogs.catalogDeduplication.catalogDeduplication
	);

	const deleteDeduplicationOption = (id: string) => {
		const currentDeduplicationOption = deduplicationOptions.filter((item) => item.id === id)[0];
		if (
			currentDeduplicationOption &&
			currentDeduplicationOption.deduplicationAttributeMappings.length
		) {
			currentDeduplicationOption.deduplicationAttributeMappings
				.map((mapping) => mapping.id)
				.forEach((mappingId) => deleteMapping(id, mappingId));
		}

		fetchDeleteDeduplicationOption({ id })
			.unwrap()
			.then(() => {
				dispatch(deleteCatalogDeduplicationOption(id));
				notification.success({ message: 'Вариант поиска удален' });
			})
			.catch((err) =>
				errorHelper('Ошибка при удалении варианта поиска дубликатов', err, notification)
			);
	};
	return { deleteDeduplicationOption };
};
