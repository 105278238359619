import {
	RiCloseCircleLine,
	RiDeleteBinLine,
	RiFileCopyLine,
	RiListSettingsLine,
	RiRefreshLine,
} from '@remixicon/react';
import { Button, Flex, Table, TableProps } from 'antd';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { routes } from 'shared/config';
import { Chip, DetailFooter, DropdownLink, Search } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';

const JournalListUi: React.FC = () => {
	const navigate = useNavigate();
	const data = [
		{
			key: '1',
			id: '12344',
			configuration: '1С Бухгалтерия 8.3',
			status: 'Отправка запрещена',
			referencePosition: 'АО «Ромашка»',
			group: 'Российские организации',
			menu: (
				<ItemActions
					items={[
						{
							key: '0',
							label: (
								<DropdownLink
									icon={<RiFileCopyLine size={16} />}
									title="Скопировать ссылку на позицию"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
						{
							key: '1',
							label: (
								<DropdownLink
									icon={<RiCloseCircleLine size={16} />}
									title="Отклонить позицию"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
						{
							key: '2',
							label: (
								<DropdownLink
									icon={<RiDeleteBinLine size={16} />}
									title="Удалить"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
					]}
				/>
			),
		},
		{
			key: '2',
			id: '53545',
			configuration: '1С Бухгалтерия 8.3',
			status: 'Отправка запрещена',
			referencePosition: 'АО «Ромашка»',
			group: 'Превышен срок обработки сообщения',
			menu: (
				<ItemActions
					items={[
						{
							key: '0',
							label: (
								<DropdownLink
									icon={<RiFileCopyLine size={16} />}
									title="Скопировать ссылку на позицию"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
						{
							key: '1',
							label: (
								<DropdownLink
									icon={<RiCloseCircleLine size={16} />}
									title="Отклонить позицию"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
						{
							key: '2',
							label: (
								<DropdownLink
									icon={<RiDeleteBinLine size={16} />}
									title="Удалить"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
					]}
				/>
			),
		},
		{
			key: '3',
			id: '53546',
			configuration: '1С Бухгалтерия 8.3',
			status: 'Отправка запрещена',
			referencePosition: 'АО «Ромашка»',
			group: 'Российские организации',
			menu: (
				<ItemActions
					items={[
						{
							key: '0',
							label: (
								<DropdownLink
									icon={<RiFileCopyLine size={16} />}
									title="Скопировать ссылку на позицию"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
						{
							key: '1',
							label: (
								<DropdownLink
									icon={<RiCloseCircleLine size={16} />}
									title="Отклонить позицию"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
						{
							key: '2',
							label: (
								<DropdownLink
									icon={<RiDeleteBinLine size={16} />}
									title="Удалить"
									callback={() => {
										console.log();
									}}
								/>
							),
						},
					]}
				/>
			),
		},
	];
	const columns: TableProps['columns'] = [
		{
			title: 'Идентификатор',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Наименование конфигурации обмена',
			dataIndex: 'configuration',
			key: 'configuration',
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'status',
			render: (status) => (
				<Chip color={status === 'Отправка запрещена' ? 'error' : 'success'}>{status}</Chip>
			),
		},
		{
			title: 'Позиция справочника',
			dataIndex: 'referencePosition',
			key: 'referencePosition',
		},
		{
			title: 'Группа',
			dataIndex: 'group',
			key: 'group',
		},
		{
			title: <RiListSettingsLine size={20} />,
			dataIndex: 'menu',
			key: 'menu',
		},
	];

	const rowSelection: TableProps['rowSelection'] = {
		onChange: (selectedRowKeys: React.Key[], selectedRows) => {
			console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
		},
	};

	const customHandleBack = useCallback(() => navigate(`/${routes.integrations.main}`), []);
	return (
		<Flex vertical gap={12}>
			<Search />
			<Button icon={<RiRefreshLine />} style={{ maxWidth: 'fit-content' }}>
				Обновить данные
			</Button>
			<Flex gap={4}>
				<Button type="primary">Показать все</Button>
				<Button>Показать только позиции импорта</Button>
				<Button>Показать только позиции экспорта</Button>
			</Flex>
			<Table
				dataSource={data}
				columns={columns}
				rowSelection={rowSelection}
				onRow={(record) => {
					return {
						onClick: () => {
							navigate(`${routes.integrations.message}/${record.id}`);
						},
					};
				}}
			/>
			<DetailFooter customHandleBack={customHandleBack}>
				<Button icon={<RiRefreshLine />} type="text">
					Перезапустить обработку выбранных позиций
				</Button>
				<Button icon={<RiCloseCircleLine />} type="text">
					Отклонить обработку выбранных позиций
				</Button>
				<Button icon={<RiDeleteBinLine />} type="text">
					Удалить выбранные позиции
				</Button>
			</DetailFooter>
		</Flex>
	);
};

export const JournalList = React.memo(JournalListUi);
