import { Flex, Result } from 'antd';
import cn from 'classnames';
import React from 'react';
import s from './placeholderStyles.module.scss';
import { PlaceholderSvg } from './PlaceholderSvg';
import { SearchPlaceholderSvg } from './SearchPlaceholderSvg';

interface IPlaceholderProps {
	title: string;
	subTitle?: string;
	small?: boolean;
	isSearchIcon?: boolean;
}

const PlaceholderUi: React.FC<IPlaceholderProps> = ({ title, subTitle, small, isSearchIcon }) => {
	return (
		<Result
			icon={
				isSearchIcon ? (
					<SearchPlaceholderSvg width={small && 80} height={small && 51} />
				) : (
					<PlaceholderSvg width={small && 60} height={small && 31} />
				)
			}
		>
			<Flex vertical gap={20}>
				<p className={cn([s.placeholderText, small && s.smallText])}>{title}</p>
				{subTitle && (
					<p className={cn([s.placeholderText, small && s.smallText])}>{subTitle}</p>
				)}
			</Flex>
		</Result>
	);
};

export const Placeholder = React.memo(PlaceholderUi);
