import { RiEditLine, RiErrorWarningLine, RiPushpinLine, RiUnpinLine } from '@remixicon/react';
import { Flex, MenuProps, Skeleton, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { DeleteIntegration } from 'features/integrations/deleteIntegration';
import { Integration } from 'shared/api/generatedApi/integrationsApi';
import ApacheKafka from 'shared/assets/integrations/KafkaLogo.png';
import MySql from 'shared/assets/integrations/MySqlLogo.png';
import Oracle from 'shared/assets/integrations/OracleLogo.png';
import PostgresImg from 'shared/assets/integrations/PostgresLogo.png';
import S3 from 'shared/assets/integrations/S3Logo.png';
import { routes } from 'shared/config';
import { truncateString, capitalizeFirstLetter } from 'shared/helpers';
import { colors } from 'shared/styles';
import { Chip, DetailCard, DropdownLink, Hint } from 'shared/ui';

const connections: Array<{ name: string; image: string }> = [
	{
		name: 'postgres',
		image: PostgresImg,
	},
	{
		name: 'kafka',
		image: ApacheKafka,
	},
	{
		name: 'oracle',
		image: Oracle,
	},
	{
		name: 'mysql',
		image: MySql,
	},
	{
		name: 'amazon_s3',
		image: S3,
	},
];

const IntegrationCardUi: React.FC<Integration> = ({
	connection_id,
	source_type,
	connection_info,
}) => {
	const navigate = useNavigate();
	const {
		name,
		date_last_import,
		count_configuration,
		count_successful_imports,
		count_failed_imports,
		is_pinned,
		is_failed,
	} = connection_info;

	const handleEditIntegration = useCallback(() => {
		navigate(`${routes.integrations.detail}/${connection_id}`);
	}, [navigate]);

	const items: MenuProps['items'] = useMemo(
		() => [
			{
				label: (
					<DropdownLink
						title={is_pinned ? 'Открепить' : 'Закрепить'}
						callback={() => console.log(123)}
						icon={is_pinned ? <RiUnpinLine size={16} /> : <RiPushpinLine size={16} />}
					/>
				),
				key: '0',
			},

			{
				label: (
					<DropdownLink
						title="Редактировать"
						icon={<RiEditLine size={16} />}
						callback={handleEditIntegration}
					/>
				),
				key: '1',
			},
			{
				label: <DeleteIntegration id={connection_id} />,
				key: '2',
			},
		],
		[handleEditIntegration, connection_id, is_pinned]
	);

	return (
		<DetailCard
			firstSection={
				<img
					src={
						connections.find((item) => item.name === source_type).image
							? connections.find((item) => item.name === source_type).image
							: ''
					}
					alt={(name && truncateString(name, 20)) || ''}
					style={{ objectFit: 'contain', width: 48, height: '48px' }}
				/>
			}
			secondSection={
				<Flex vertical justify="start" gap={4}>
					<Flex gap={4} justify="start">
						<Hint title={name && name.length > 20 ? name : ''}>
							<Typography.Title level={3}>
								{name
									? capitalizeFirstLetter(truncateString(name, 20))
									: 'Нет данных'}
							</Typography.Title>
						</Hint>
						{is_failed && <RiErrorWarningLine size={15} color={colors.error} />}
					</Flex>

					<Flex gap={2}>
						<Typography.Paragraph type="secondary">Идентификатор:</Typography.Paragraph>
						<Typography.Paragraph>
							{connection_id ? truncateString(connection_id, 9) : ''}
						</Typography.Paragraph>
					</Flex>

					<Flex gap={2}>
						<Typography.Paragraph type="secondary">
							Последняя активность:
						</Typography.Paragraph>
						<Typography.Paragraph>
							{(date_last_import &&
								new Date(date_last_import).toLocaleDateString()) ||
								'-'}
						</Typography.Paragraph>
					</Flex>

					<Flex justify="flex-start" gap={6}>
						<Chip color="success">Выполн... {count_successful_imports}</Chip>
						<Chip color="error">Останов... {count_failed_imports}</Chip>
					</Flex>
				</Flex>
			}
			dropdownButtons={items}
			link={`${routes.integrations.detail}/${connection_id}/connectionInfo`}
			tooltip={count_configuration && `${count_configuration}`}
		/>
	);
};

export const IntegrationCardSkeleton: React.FC = React.memo(() => (
	<Flex gap={25}>
		<Skeleton active avatar style={{ minWidth: 325, display: 'flex', alignItems: 'center' }} />
		<Skeleton active avatar style={{ minWidth: 325, display: 'flex', alignItems: 'center' }} />
		<Skeleton active avatar style={{ minWidth: 325, display: 'flex', alignItems: 'center' }} />
	</Flex>
));

export const IntegrationCard = React.memo(IntegrationCardUi);
