import { RiDeleteBin2Line } from '@remixicon/react';
import { Flex, Button, Modal, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { randomString } from 'shared/helpers';
import { DropdownLink } from 'shared/ui';
import { useDeleteCatalogRestrictionTable } from './DeleteCatalogRestrictionTable.model';

interface IDeleteCatalogRestrictionTableUiProps {
	declarationId: string;
}

const DeleteCatalogRestrictionTableUi = ({
	declarationId,
}: IDeleteCatalogRestrictionTableUiProps) => {
	const [open, setOpen] = useState<boolean>(false);

	const { handleDeleteDeclaration, isLoading } = useDeleteCatalogRestrictionTable();

	const showModal = useCallback(() => {
		setOpen(true);
	}, []);

	const handleOk = useCallback(
		async (e: React.SyntheticEvent) => {
			e.stopPropagation();
			handleDeleteDeclaration(declarationId).finally(() => setOpen(false));
		},
		[declarationId]
	);

	const handleCancel = useCallback(() => {
		setOpen(false);
	}, []);

	const footerButtons = useMemo(
		() => (
			<Flex key={randomString(4)} justify="flex-end" gap={4}>
				<Button key="back" onClick={handleCancel} type="text">
					Отмена
				</Button>

				<Button key="submit" type="primary" onClick={handleOk} loading={isLoading}>
					Удалить
				</Button>
			</Flex>
		),
		[isLoading]
	);

	return (
		<>
			<DropdownLink
				icon={<RiDeleteBin2Line size={16} />}
				title="Удалить"
				callback={showModal}
			/>
			<Modal
				open={open}
				title="Подтверждение удаления"
				footer={[footerButtons]}
				onCancel={handleCancel}
			>
				<Flex vertical gap={20}>
					<Typography.Paragraph>
						Вы действительно хотите удалить данную таблицу допустимых значений? Таблицу
						допустимых значений будет удалена без возможности восстановления
					</Typography.Paragraph>
				</Flex>
			</Modal>
		</>
	);
};

export const DeleteCatalogRestrictionTable = React.memo(DeleteCatalogRestrictionTableUi);
