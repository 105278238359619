import React from 'react';
import { colors } from '../../../styles/cssConstants';
import type { IIcon } from '../../../types';

const AddNewNestedGroupUI: React.FC<IIcon> = ({ size = 16, color = `${colors.primary}` }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.603 1.19922V10.7992H16.003L12.803 14.7992L9.60298 10.7992H12.003V1.19922H13.603ZM4.00298 12.3992V13.9992H0.00297737V12.3992H4.00298Z"
				fill={color}
			/>
			<path
				d="M3.40298 1.5V3.9H1.00298V5.1H3.40298V7.5H4.60298V5.1H7.00298V3.9H4.60298V1.5H3.40298Z"
				fill={color}
			/>
			<path d="M8.79683 9.90742V8.30742H-0.00317383V9.90742H8.79683Z" fill={color} />
		</svg>
	);
};

export const AddNewNestedGroup = React.memo(AddNewNestedGroupUI);
