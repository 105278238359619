import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { App } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { EditRecordStatus } from 'features/catalogs/CatalogRecords/EditRecordStatus/EditRecordStatus';
import { setAttributeDeclarationsList } from 'entities/catalogs/attributeDeclarations/attributeDeclarations.store';
import { ItemStatusDto } from 'entities/catalogs/catalogRecords/catalogRecords.model';
import { useCatalogRestrictionTableDeclarations } from 'entities/catalogs/catalogRestrictions';
import { useTransactions } from 'entities/transactions';
import {
	ItemDto,
	useLazyGetCatalogAttributeDeclarationsQuery,
	useLazyGetCatalogItemQuery,
	useLazyGetRestrictionTableItemsQuery,
	useUpdateCatalogItemMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper, mapValueForDto } from 'shared/helpers';
import { ItemValuesType } from 'shared/helpers/types';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { ChipStatusEnum } from 'shared/ui/components/Chip/chipStylehelper';
import { AttributeInputFactory } from './AttributeInputFactory';

export type RecordType = {
	id: string;
	attributeName: string;
	value: string | JSX.Element | JSX.Element[];
	key: string;
};

type Values = {
	[key: string]: {
		attributeId: string;
		attributeName: string;
		value: string;
	};
};

type RestrictionItem = Record<string, string[]>;

type RestrictionTable = {
	id: string,
	items: RestrictionItem[],
};

export const useEditCatalogRecord = () => {
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();
	const { recordId, catalogGroupId } = useParams();

	const [ recordsList, setRecordsList ] = useState<RecordType[]>([]);
	const [ record, setRecord ] = useState<ItemDto | null>(null);
	const [ error, setError ] = useState<FetchBaseQueryError | SerializedError>(null);
	const [ restrictions, setRestrictions ] = useState<RestrictionTable[]>(null);

	const { getTransactions } = useTransactions();
	const { catalogRestrictionTablesDeclarations } = useCatalogRestrictionTableDeclarations();

	const { attributeDeclarationsList } = useAppSelector(
		(state) => state.entities.catalogs.attributeDeclarationsList,
	);

	const [ getRecords, { error: recordsError, isFetching: isRecordsLoading } ] =
		useLazyGetCatalogItemQuery();
	const [ fetchGetAttributeDeclarations, { isFetching: isDeclarationsLoading } ] =
		useLazyGetCatalogAttributeDeclarationsQuery();
	const [ fetchUpdateItem ] = useUpdateCatalogItemMutation();
	const [ fetchRestrictionTableItems, { isFetching: isRestrictionTableItemsLoading } ] =
		useLazyGetRestrictionTableItemsQuery();

	const getRestrictionValuesForAttribute = (attributeId: string, dataValues: Values): DefaultOptionType[] => {
		if (isRestrictionTableItemsLoading || !restrictions) {
			return [];
		}

		const restrictionDeclarations = catalogRestrictionTablesDeclarations
			.filter(x =>
				x.restrictionTable.attributeIds.includes(attributeId));

		if (restrictionDeclarations.length === 0) {
			return [];
		}

		return Array.from(
			restrictionDeclarations
				.map(restrictionDeclaration => {
					const tableId = restrictionDeclaration.restrictionTable.id;
					const restrictionTableItems: Record<string, string[]>[] = restrictions
						.find(x => x.id === tableId)
						?.items;

					return restrictionTableItems.reduce((acc, item) => {
						const isAllAttributesSatisfy = Object.entries(item)
							.every(([ attrId, restrictionValues ]) => {
								if (attrId == attributeId) {
									return true;
								}

								const currentValue = Object.values(dataValues)
									.find((x) => x.attributeId == attrId)
									?.value;

								return currentValue == null || currentValue === '' || restrictionValues.includes(currentValue);
							});

						if (isAllAttributesSatisfy && attributeId in item) {
							acc.push(...item[attributeId]);
						}

						return acc;
					}, []);
				})
				.reduce((acc, item) => {
					//	ищем пересечения значений с учетом всех ограничительных таблиц
					if (acc.size == 0) {
						item.forEach(x => acc.add(x));
					} else {
						acc.forEach(value => {
							if (!item.find(x => x === value)) {
								acc.delete(value);
							}
						});
					}
					return acc;
				}, new Set<string>()),
		).map(x => ({
			label: x || '<Значение не задано>',
			value: x || '',
		}));
	};

	const mapValues = async (data?: ItemDto) => {
		if (!attributeDeclarationsList) {
			return;
		}

		const dataValues = (data.values as Values);

		const editItem = (declarationId: string, value: ItemValuesType) => {
			const attributeDeclaration = attributeDeclarationsList
				.find(x => x.id == declarationId);

			const mappedPrevValue = mapValueForDto(attributeDeclaration, dataValues[declarationId]?.value);
			const mappedCurrentValue = mapValueForDto(attributeDeclaration, value);

			if (mappedPrevValue == mappedCurrentValue) {
				return;
			}

			const updatedValuesObject = Object.fromEntries(
				attributeDeclarationsList.map(x => [
					x.id,
					(x.id == declarationId
							? mappedCurrentValue
							: mapValueForDto(x, dataValues[x.id]?.value)
					) as object,
				]));

			updateItem(updatedValuesObject, data.status)
				.then(() => {
					setRecord((prev) => {
						return {
							...prev,
							values: {
								...prev.values,
								[declarationId]: {
									...prev.values[declarationId],
									value: mappedCurrentValue,
								},
							},
						};
					});
				});
		};

		const onStatusChange = (status: ChipStatusEnum) => {
			const newStatus = status.toUpperCase() as ItemStatusDto;

			if (data.status === newStatus) {
				return;
			}

			const updatedValuesObject = Object.fromEntries(
				attributeDeclarationsList.map(x => [
					x.id,
					mapValueForDto(x, dataValues[x.id]?.value) as object,
				]));

			updateItem(updatedValuesObject, newStatus)
				.then(() => {
					setRecord((prev) => ({
						...prev,
						status: newStatus,
					}));
				});
		};

		const flattenedRecords: RecordType[] = await Promise.all(
			attributeDeclarationsList?.map(async (attributeDeclaration) => {
				const { id: key, attribute } = attributeDeclaration;
				const recordValue = dataValues[key]?.value;
				const relationOptions = getRestrictionValuesForAttribute(attribute.id, dataValues);
				return {
					id: key,
					key: key,
					attributeName: attribute.displayName,
					value: AttributeInputFactory(
						recordValue,
						attributeDeclaration,
						editItem,
						relationOptions,
					),
				};
			}),
		);

		flattenedRecords.unshift({
			attributeName: 'Статус позиции',
			id: 'status',
			key: 'status',
			value: <EditRecordStatus data={data} onChange={onStatusChange}/>,
		});
		setRecordsList(flattenedRecords);
	};

	const updateItem = (values: { [key: string]: object }, status: ItemStatusDto) =>
		fetchUpdateItem({
			catalogId: catalogGroupId,
			id: recordId,
			updateCatalogItemRequest: {
				status,
				values,
			},
		})
			.unwrap()
			.then(() => getTransactions())
			.catch((err) => {
				errorHelper('Ошибка при редактировании записи', err, notification);
			});

	useEffect(() => {
		if (!recordsError) {
			setError(null);
		}
	}, [ recordsError ]);

	useEffect(() => {
		if (record) {
			mapValues(record);
		}
	}, [ record, restrictions ]);

	useEffect(() => {
		if (!attributeDeclarationsList) {
			fetchGetAttributeDeclarations({ catalogId: catalogGroupId })
				.unwrap()
				.then((res) => dispatch(setAttributeDeclarationsList(res)))
				.catch((err) => errorHelper('Ошибка при получении списка атрибутов', err, notification));
		} else {
			getRecords({ id: recordId })
				.unwrap()
				.then(data =>
					setRecord({
						...data,
					}))
				.catch(err => errorHelper('Ошибка при получении списка атрибутов', err, notification));
		}
	}, [ attributeDeclarationsList ]);

	useEffect(() => {
		if (!catalogRestrictionTablesDeclarations) {
			return;
		}

		Promise.all(
			catalogRestrictionTablesDeclarations
				.map(async x => {
					const items = await fetchRestrictionTableItems({
						restrictionTableId: x.restrictionTable.id,
					})
						.unwrap();

					return {
						id: x.restrictionTable.id,
						items: items
							.map<RestrictionItem>(x => Object.fromEntries(Object.entries(x.values)
								.map(([ key, values ]) => [
									key,
									(values as Array<any>)
										.filter(value =>
											value != null &&
											value != '',
										),
								]),
							)),
					} as RestrictionTable;
				}),
		).then((res) => setRestrictions(res));
	}, [ catalogRestrictionTablesDeclarations ]);

	return {
		recordsList,
		attributeDeclarations: attributeDeclarationsList,
		loading: isRecordsLoading || isDeclarationsLoading || isRestrictionTableItemsLoading,
		error,
	};
};
