import { useEffect } from 'react';
import { debounce } from '../helpers/debounce';

/*
хук принимает аргументы:
1. коллбэк функцию, которую необходимо выполнить с задержкой
2. значение задержки в мс

возвращает функцию, которая будет выполнять коллбэк с заданной возобновляемой задержкой.

Пример использования: отправка запроса на сервер через 1 секунду после ввода последнего символа в инпут.
Дебаунс будет обновлять таймер в 1 секунду при вводе каждого нового символа
*/
export const useDebounce = <A = unknown, R = void>(
	fn: (args: A) => R,
	ms: number
): ((args: A) => Promise<R>) => {
	const [debouncedFun, teardown] = debounce<A, R>(fn, ms);

	useEffect(() => () => teardown(), []);

	return debouncedFun;
};
