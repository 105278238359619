import { RiDeleteBin6Line } from '@remixicon/react';
import React from 'react';
import { useTypedTranslation } from 'shared/hooks';
import { DropdownLink } from 'shared/ui';
import { useDeleteDeduplicationOption } from './DeleteDeduplicationOption.model';

interface IDeleteDeduplicationOption {
	id: string;
}

export const DeleteDeduplicationOption = ({ id }: IDeleteDeduplicationOption) => {
	const { t } = useTypedTranslation();
	const { deleteDeduplicationOption } = useDeleteDeduplicationOption();

	return (
		<DropdownLink
			title={t((l) => l.catalogs.deduplication.deleteSearchOption)}
			icon={<RiDeleteBin6Line size={16} />}
			callback={() => deleteDeduplicationOption(id)}
		/>
	);
};
