import React from 'react';

function AdminPage() {
	return (
    <div>
      AdminPage
    </div>
	);
}

export default AdminPage;
