import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { breadcrumbsReducer } from 'entities/breadcrumbs';
import { attributeDeclarationsListReducer } from 'entities/catalogs/attributeDeclarations/attributeDeclarations.store';
import { catalogDeduplicationReducer } from 'entities/catalogs/catalogDeduplication/catalogDeduplication.store';
import { catalogsReducer } from 'entities/catalogs/catalogGroups/catalog.store';
import { catalogRecordsReducer } from 'entities/catalogs/catalogRecords/catalogRecords.store';
import { catalogRestrictionTablesDeclarationsReducer } from 'entities/catalogs/catalogRestrictions/catalogRestrictions.store';
import { classifierAttributesDeclarationsListReducer } from 'entities/classifiers/classifierAttributeDeclarations/attributeDeclarations.store';
import { classifiersReducer } from 'entities/classifiers/classifiersGroups/classifier.store';
import { classifierItemsReducer } from 'entities/classifiers/classifiersItems/classifierItems.store';
import { classifiersItemsTreeReducer } from 'entities/classifiers/classifiersItemsTree/classifiersItemsTree.store';
import { attributesGroupsReducer } from 'entities/metadata/attributesGroups/attributesGroups.store';
import {
	constraintTableDetailsReducer,
	constraintTablesReducer,
} from 'entities/metadata/constraintTables';
import { measuresReducer } from 'entities/metadata/measures';
import { metaAttributesReducer } from 'entities/metadata/metaAttributes/metaAttributes.store';
import { sessionApi, sessionReducer } from 'entities/session';
import { transactionsStoreReducer } from 'entities/transactions/transactions.store';
import { exportApi } from 'shared/api';
import { deduplicationApi } from 'shared/api/generatedApi/deduplicationApi';
import { integrationsApi, mdmgApi } from 'shared/api/generatedApi/newCreateApiFile';
import { searchApi } from 'shared/api/generatedApi/searchApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { uiReducer } from 'shared/ui';

const metadata = combineReducers({
	attributesGroups: attributesGroupsReducer,
	metaAttributes: metaAttributesReducer,
	measures: measuresReducer,
	constraintTables: constraintTablesReducer,
	constraintTableDetails: constraintTableDetailsReducer,
});

const catalogs = combineReducers({
	catalogGroups: catalogsReducer,
	catalogRecords: catalogRecordsReducer,
	attributeDeclarationsList: attributeDeclarationsListReducer,
	catalogDeduplication: catalogDeduplicationReducer,
	catalogRestrictionTablesDeclarations: catalogRestrictionTablesDeclarationsReducer,
});

const classifiers = combineReducers({
	classifierGroups: classifiersReducer,
	classifierItems: classifierItemsReducer,
	classifiersItemsTree: classifiersItemsTreeReducer,
	classifierAttributesDeclarations: classifierAttributesDeclarationsListReducer,
});

const entities = combineReducers({
	session: sessionReducer,
	metadata,
	catalogs,
	transactions: transactionsStoreReducer,
	classifiers,
});

const shared = combineReducers({
	ui: uiReducer,
});

export const store = configureStore({
	reducer: {
		[sessionApi.reducerPath]: sessionApi.reducer,
		[transactionServiceApi.reducerPath]: transactionServiceApi.reducer,
		[searchApi.reducerPath]: searchApi.reducer,
		[deduplicationApi.reducerPath]: deduplicationApi.reducer,
		[integrationsApi.reducerPath]: integrationsApi.reducer,
		[mdmgApi.reducerPath]: mdmgApi.reducer,
		[exportApi.reducerPath]: exportApi.reducer,
		entities,
		breadcrumbs: breadcrumbsReducer,
		shared,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ['customApi/executeQuery/fulfilled'],
				ignoredPaths: ['customApi.queries'],
			},
		}).concat(
			mdmgApi.middleware,
			sessionApi.middleware,
			transactionServiceApi.middleware,
			searchApi.middleware,
			deduplicationApi.middleware,
			integrationsApi.middleware,
			exportApi.middleware,
		),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
